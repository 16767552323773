import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  UUID: string;
  Date: string;
  JSONString: string;
  Decimal: any;
  BigIntScalar: any;
  GenericScalar: any;
  Time: any;
};

export type Query = {
  __typename?: 'Query';
  doctorLead?: Maybe<DoctorLeadType>;
  doctorLeads?: Maybe<PageBasedAccessDoctorLeadType>;
  verifyDoctorLeadToken?: Maybe<VerifyDoctorLeadTokenType>;
  publicDoctorLeadInfoByToken?: Maybe<PublicDoctorLeadInfoType>;
  medicalHistory?: Maybe<MedicalHistoryType>;
  memberMedicalHistory?: Maybe<MedicalHistoryType>;
  medicalNote?: Maybe<MedicalNoteType>;
  doctorAssistantMedicalNote?: Maybe<LimitedMedicalNoteType>;
  memberMedicalNotes?: Maybe<PaginatedMedicalNoteType>;
  doctorMedicalNotes?: Maybe<PaginatedMedicalNoteType>;
  doctorAssistantMedicalNotes?: Maybe<PaginatedLimitedMedicalNoteType>;
  consultsLatestMedicalNotes?: Maybe<PaginatedMedicalNoteType>;
  bariatricsProgram?: Maybe<BariatricsProgramType>;
  maternityProgram?: Maybe<MaternityProgramType>;
  medicalProgramMedicalNotes?: Maybe<PaginatedMedicalNoteType>;
  memberMedicalHistorySummary?: Maybe<MemberMedicalHistorySummaryType>;
  medicalNotePossibleCies?: Maybe<MedicalNotePossibleCiesType>;
  memberMedicalNoteRecommendations?: Maybe<MedicalNoteRecommendationsType>;
  medLabRecomendationByCie?: Maybe<KeyValueType>;
  procedureCategory?: Maybe<ProcedureCategoryType>;
  procedureCategories?: Maybe<PaginatedProcedureCategoryType>;
  memberNonPathologicalHistoryIsFilled?: Maybe<Scalars['Boolean']>;
  performedProcedure?: Maybe<PerformedProcedureType>;
  scheduledProcedure?: Maybe<ScheduledProcedureType>;
  memberNotesDoctors?: Maybe<Array<DoctorProfileType>>;
  memberNotesMedicalSpecializations?: Maybe<Array<Maybe<MedicalSpecializationModelType>>>;
  allServices?: Maybe<PaginatedServices>;
  memberYearServiceSummary?: Maybe<MemberYearServiceSummary>;
  reinbursementServiceInfo?: Maybe<ReinbursementServiceInfo>;
  event?: Maybe<CaseEventType>;
  allEvents?: Maybe<PaginatedCaseEvent>;
  doctorsByEvent?: Maybe<Array<EventDoctorSelectorType>>;
  servicesByEvent?: Maybe<Array<ServicesType>>;
  assignableUsers?: Maybe<Array<KeyValueType>>;
  exclusion?: Maybe<ExclusionsCatalogType>;
  exclusionsAutocomplete?: Maybe<Array<Maybe<KeyValueType>>>;
  exclusionsCatalog?: Maybe<PaginatedExclusionsCatalogType>;
  cptPriceReference?: Maybe<PaginatedCptPriceReferenceType>;
  multipleCptPriceReference?: Maybe<PaginatedCptPriceReferenceType>;
  cptPriceReferenceGrouped?: Maybe<PaginatedCptPriceReferenceGroupedType>;
  recentCptQuoteItems?: Maybe<PaginatedQuoteItemType>;
  servicesByDoctor?: Maybe<PaginatedDoctorLimitedServices>;
  doctorPayoutServiceById?: Maybe<DoctorLimitedServicesType>;
  memberAvailableOfficeProcedureEvents?: Maybe<PaginatedLimitedCaseEvent>;
  cptCatalog?: Maybe<PaginatedCptCatalogType>;
  cpt?: Maybe<CptCatalogType>;
  healthPlanApplications?: Maybe<PageBasedAccessHealthPlanApplicationType>;
  healthPlanApplication?: Maybe<HealthPlanApplicationType>;
  healthPlanApplicationItem?: Maybe<HealthPlanApplicationItemType>;
  productQuestionnaire?: Maybe<Scalars['JSONString']>;
  questionnaireForMember?: Maybe<Scalars['JSONString']>;
  questionnaireStateForMember?: Maybe<Scalars['Boolean']>;
  products?: Maybe<PageBasedAccessProductType>;
  product?: Maybe<ProductType>;
  ineData?: Maybe<NubariumIneType>;
  satInfoValidation?: Maybe<NubariumSatInfoValidationType>;
  checkBirthday?: Maybe<InAppRejectionType>;
  currentPublicProductData?: Maybe<PublicProductType>;
  availableProductModules?: Maybe<Array<PublicKeyValueType>>;
  productData?: Maybe<PublicProductType>;
  multipleSubscriptionsPrice?: Maybe<MultipleSubscriptionsPriceType>;
  latestScheduledApprovalCall?: Maybe<ScheduledApprovalCallType>;
  scheduledApprovalCalls?: Maybe<PagedBasedScheduledApprovalCallType>;
  leadInformation?: Maybe<LeadInformationType>;
  productDiscountConfigurations?: Maybe<Array<Maybe<ProductDiscountConfigurationType>>>;
  user?: Maybe<UserType>;
  users?: Maybe<PageBasedAccessUserType>;
  documents?: Maybe<PageBasedAccessDocumentType>;
  members?: Maybe<PageBasedAccessMemberType>;
  profilingDataMemberList?: Maybe<PageBasedAccessMemberType>;
  profilingDataList?: Maybe<PageBasedProfilingDataType>;
  profilingData?: Maybe<ProfilingDataType>;
  member?: Maybe<MemberType>;
  memberSearch?: Maybe<MemberSearchResultsType>;
  document?: Maybe<DocumentType>;
  /** @deprecated Use managedMembers */
  healthPlanMembers?: Maybe<SequentialAccessMemberType>;
  managedMembers?: Maybe<SequentialAccessMemberType>;
  memberRepresentedById?: Maybe<MemberType>;
  dependentMemberById?: Maybe<MemberType>;
  grantAccessMembers?: Maybe<GrantAccessRepresentedMembersType>;
  sponsorRepresentedMembersById?: Maybe<RepresentedMembersByIdType>;
  lastRegisteredTestUser?: Maybe<UserType>;
  checkPassword?: Maybe<Array<Maybe<Scalars['String']>>>;
  verifyPassword?: Maybe<VerifyPasswordType>;
  minSupportedVersion?: Maybe<MinSupportedVersionType>;
  giftAVcUrl?: Maybe<Scalars['String']>;
  coronaidActiveStatus?: Maybe<CoronaidActiveStatus>;
  authenticatedVars?: Maybe<AuthenticatedVars>;
  waitingRoomEnabledStatus?: Maybe<WaitingRoomEnabledStatus>;
  schemaIntrospection?: Maybe<IntrospectionQueryResult>;
  isTelephoneAlreadyOnUse?: Maybe<IsTelephoneAlreadyOnUseType>;
  ipAddress?: Maybe<IpAddressType>;
  checkEmail?: Maybe<CheckEmailType>;
  inAppNotifications?: Maybe<InAppNotificationsType>;
  checkExpertsWorkingHours?: Maybe<CheckExpertsWorkingHoursType>;
  hasPaymentsPending?: Maybe<CheckUserHasPaymentsPending>;
  verifyPasswordToken?: Maybe<VerifyPasswordTokenType>;
  referralCodeFeatureFlag?: Maybe<Scalars['Boolean']>;
  memberExclusions?: Maybe<PageBasedMemberExclusionType>;
  memberExclusion?: Maybe<MemberExclusionType>;
  memberConfirmedExclusions?: Maybe<SequentialAccessMemberExclusionType>;
  memberMatchingExclusions?: Maybe<PageBasedMemberExclusionType>;
  contentTypes?: Maybe<Array<ContentTypeType>>;
  memberSpecializationsExclusions?: Maybe<Array<MemberExclusionType>>;
  memberFavouriteDoctors?: Maybe<PaginatedDoctorProfileType>;
  memberFavouriteProviderBranches?: Maybe<PaginatedProviderBranchesType>;
  memberVisitedDoctors?: Maybe<PaginatedDoctorProfileType>;
  providerIsMembersFavourite?: Maybe<Scalars['Boolean']>;
  memberCervicalCancerCategory?: Maybe<CervicalCancerType>;
  referralCode?: Maybe<ReferralCodeType>;
  remainingInvitations?: Maybe<Scalars['Int']>;
  validateReferralCode?: Maybe<ReferralCodeValidationType>;
  doctorFriend?: Maybe<DoctorFriendType>;
  doctorFriends?: Maybe<PaginatedDoctorFriendsType>;
  doctorPatients?: Maybe<PaginatedDoctorPatientsType>;
  allDoctorPatients?: Maybe<PaginatedDoctorPatientsType>;
  applicants?: Maybe<PaginatedDoctorPatientsType>;
  providerCategories?: Maybe<Array<ProviderCategoryType>>;
  medicalSpecialization?: Maybe<MedicalSpecializationModelType>;
  publicMedicalSpecializations?: Maybe<Array<PublicMedicalSpecializationModelType>>;
  medicalSpecializations?: Maybe<Array<MedicalSpecializationModelType>>;
  paginatedMedicalSpecializations?: Maybe<PaginatedMedicalSpecializationsType>;
  medicalSpecializationGroup?: Maybe<MedicalSpecializationGroupType>;
  medicalSpecializationGroups?: Maybe<Array<MedicalSpecializationGroupType>>;
  medicalSpecializationDoctors?: Maybe<Array<DoctorProfileType>>;
  providerBranchOffice?: Maybe<ProviderBranchOfficeType>;
  providerBranchOffices?: Maybe<PaginatedProviderBranchOfficesType>;
  providerBranches?: Maybe<PaginatedProviderBranchesType>;
  providerBranch?: Maybe<ProviderBranchType>;
  careDirectory?: Maybe<SequentialAccessDirectoryListType>;
  careDirectoryContinue?: Maybe<SequentialAccessDirectoryListType>;
  specialtiesDoctorsDirectory?: Maybe<SequentialAccessDirectoryListType>;
  specialtiesDoctorsDirectoryContinue?: Maybe<SequentialAccessDirectoryListType>;
  provider?: Maybe<ProvidersType>;
  providers?: Maybe<PaginatedProvidersType>;
  careProviderDirectory?: Maybe<SequentialAccessProviderListType>;
  careProviderDirectoryContinue?: Maybe<SequentialAccessProviderListType>;
  doctor?: Maybe<DoctorType>;
  doctorProfile?: Maybe<DoctorProfileType>;
  doctors?: Maybe<PageBasedAccessDoctorType>;
  onDemandAuthorizedDoctors?: Maybe<PageBasedAccessDoctorType>;
  doctorsInPersonConsultsByWeek?: Maybe<PageBasedAccessDoctorType>;
  doctorHuliData?: Maybe<HuliDoctorDataType>;
  specializationFare?: Maybe<SpecializationFareType>;
  specializationFares?: Maybe<Array<Maybe<SpecializationFareType>>>;
  doctorReminders?: Maybe<SequentialAccessDoctorReminderListType>;
  doctorReminder?: Maybe<DoctorReminderType>;
  doctorAssistant?: Maybe<DoctorAssistantType>;
  doctorAssistants?: Maybe<PaginatedDoctorAssistantType>;
  doctorPayoutMethod?: Maybe<PaymentMethodType>;
  doctorsAmount?: Maybe<Scalars['Int']>;
  publicDoctorProfile?: Maybe<PublicDoctorType>;
  publicDoctorsDirectory?: Maybe<Array<PublicDoctorType>>;
  paginatedLocationBasedDoctors?: Maybe<PaginatedDoctorType>;
  locationBasedDoctorOfficeAddresses?: Maybe<Array<LimitedDoctorOfficeAddress>>;
  doctorByOfficeAddressId?: Maybe<DoctorProfileType>;
  coordinatesBasedDoctorOfficeAddresses?: Maybe<Array<LimitedDoctorOfficeAddress>>;
  paginatedLocationBasedBranchOffices?: Maybe<PaginatedProviderBranchesType>;
  locationBasedBranchOfficeAddresses?: Maybe<Array<LimitedProviderBranchOfficeAddress>>;
  branchOfficeByAddressId?: Maybe<ProviderBranchOfficeType>;
  coordinatesBasedProviderBranchOfficeAddresses?: Maybe<Array<LimitedProviderBranchOfficeAddress>>;
  closestProviderBranchOfficeByCoordinates?: Maybe<ProviderBranchOfficeType>;
  doctorUserGroups?: Maybe<Array<GroupName>>;
  inPersonCalendarDoctors?: Maybe<PaginatedDoctorProfileType>;
  pharmacyOrders?: Maybe<PaginatedPharmacyOrdersType>;
  pharmacyOrder?: Maybe<PharmacyOrderType>;
  group?: Maybe<GroupType>;
  groups?: Maybe<PaginatedGroupType>;
  permission?: Maybe<PermissionType>;
  permissions?: Maybe<PaginatedPermissionType>;
  paymentMethods?: Maybe<SequentialAccessPaymentMethodsType>;
  payoutPaymentMethods?: Maybe<SequentialAccessPaymentMethodsType>;
  payinPaymentMethods?: Maybe<SequentialAccessPaymentMethodsType>;
  billingInfo?: Maybe<Array<Maybe<BillingInfoType>>>;
  stripeSources?: Maybe<SequentialAccessStripeSourceType>;
  transactions?: Maybe<Array<Maybe<TransactionType>>>;
  paginatedTransactions?: Maybe<PageBasedAccessTransactionType>;
  locations?: Maybe<Array<Maybe<LocationType>>>;
  paginatedLocations?: Maybe<PageBasedAccessLocationType>;
  locationsSearch?: Maybe<Array<Maybe<LimitedLocationType>>>;
  invoice?: Maybe<InvoiceType>;
  invoiceItemIssuers: Array<Maybe<IssuerType>>;
  invoiceItems?: Maybe<PaginatedInvoiceItemsType>;
  payinInvoices?: Maybe<PageBasedInvoiceType>;
  invoices?: Maybe<PageBasedInvoiceType>;
  electronicVouchers?: Maybe<PageBasedElectronicVoucherType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  incomes?: Maybe<PageBasedIncomeType>;
  manualPaymentSourcesSchema?: Maybe<Scalars['GenericScalar']>;
  manualPaymentTypeSatMap?: Maybe<Scalars['JSONString']>;
  runAutomaticPaymentChecks?: Maybe<AutomaticPaymentChecksResultType>;
  automaticPaymentChecksLog?: Maybe<AutomaticPaymentChecksResultType>;
  incentiveCode?: Maybe<IncentiveCodeType>;
  publicIncentiveCodeData?: Maybe<PublicIncentiveCodeType>;
  referralIncentiveCode?: Maybe<IncentiveCodeType>;
  referralReferredBetweenDates?: Maybe<ReferredOnDatesType>;
  lastValidIncentiveStage?: Maybe<ReferralIncentiveStageType>;
  incentiveCodesList?: Maybe<PageBasedAccessIncentiveCodeType>;
  incentiveCampaignsList?: Maybe<ListIncentiveCampaignsType>;
  healthPlans?: Maybe<PageBasedAccessHealthPlanType>;
  healthPlan?: Maybe<HealthPlanType>;
  healthPlanAmendments?: Maybe<PageBasedAccessHealthPlanAmendmentType>;
  healthPlanAmendment?: Maybe<HealthPlanAmendmentType>;
  healthPlanRenewQuote?: Maybe<HealthPlanQuoteDifferenceType>;
  chatMessages?: Maybe<SequentialAccessMessageType>;
  huliEmrs?: Maybe<PageBasedAccessHuliEmrEntryType>;
  huliEmrEntry?: Maybe<HuliEmrEntryType>;
  memberOnDemandConsults?: Maybe<SequentialAccessOnDemandConsultType>;
  doctorOnDemandConsults: SequentialAccessOnDemandConsultType;
  doctorOnDemandConsultsWithMessages?: Maybe<SequentialAccessOnDemandConsultType>;
  doctorOnDemandConsult: OnDemandConsultType;
  doctorPaginatedScheduledConsults?: Maybe<PaginatedScheduledConsultType>;
  doctorAssistantPaginatedScheduledConsults?: Maybe<PaginatedLimitedScheduledConsultType>;
  doctorScheduledConsult?: Maybe<ScheduledConsultType>;
  availableOnDemandDoctorCount?: Maybe<Scalars['Int']>;
  readyOnDemandConsults?: Maybe<SequentialAccessOnDemandConsultType>;
  consultState?: Maybe<ActualConsultType>;
  consult?: Maybe<ConsultUnionType>;
  onDemandConsultHourlyFees?: Maybe<OnDemandConsultHourlyFeesType>;
  onDemandConsultPrice?: Maybe<OnDemandConsultPriceType>;
  inPersonConsultFee?: Maybe<InPersonConsultFeeType>;
  inPersonConsultTaxes?: Maybe<TaxesForInPersonConsults>;
  prescription?: Maybe<PrescriptionType>;
  memberPrescription?: Maybe<PrescriptionType>;
  lastValidScheduledConsult?: Maybe<WelcomeScheduledConsultType>;
  lastScheduledConsult?: Maybe<ScheduledConsultType>;
  allScheduledConsults?: Maybe<SequentialAccessScheduledConsultType>;
  listScheduledConsults?: Maybe<Array<Maybe<ScheduledConsultType>>>;
  listMemberScheduledConsults?: Maybe<Array<Maybe<ScheduledConsultType>>>;
  listAllScheduledConsults?: Maybe<PaginatedScheduledConsultType>;
  scheduledConsult?: Maybe<ScheduledConsultType>;
  onDemandAvailableTimeBlocks?: Maybe<Array<CalendarSlotType>>;
  onDemandAvailable?: Maybe<Scalars['Boolean']>;
  pediatricOnDemandAvailableTimeBlocks?: Maybe<Array<CalendarSlotType>>;
  pediatricOnDemandAvailable?: Maybe<Scalars['Boolean']>;
  createGoogleCalendarAuthUrl: Scalars['String'];
  calendarEvents?: Maybe<Scalars['JSONString']>;
  calendarEvent?: Maybe<Scalars['JSONString']>;
  calendarDatesAvailability?: Maybe<Array<CalendarDatesAvailabilityType>>;
  allCalendarDatesAvailability?: Maybe<Array<CalendarDatesDoctorsAvailabilityType>>;
  calendarDatesSlotsAvailability?: Maybe<Array<CalendarDatesSlotsAvailability>>;
  allCalendarDatesSlotsAvailability?: Maybe<Array<CalendarDatesDoctorsSlotsAvailability>>;
  calendarNextAvailability?: Maybe<CalendarDatesSlotsAvailability>;
  allCalendarNextAvailability?: Maybe<CalendarDateNextSlotAvailability>;
  firstTodayScheduledConsult?: Maybe<ScheduledConsultType>;
  unclaimedConsults?: Maybe<Array<SubscriptionConsultType>>;
  memberRequestedOutOfNetworkScheduledConsults?: Maybe<Array<ScheduledConsultType>>;
  paginatedOutOfNetworkScheduledConsults?: Maybe<PaginatedScheduledConsultType>;
  memberHasReference?: Maybe<Scalars['Boolean']>;
  chronologicalMemberConsults?: Maybe<PaginatedChronologicalLimitedConsultsType>;
  memberSpecializationIpcCount?: Maybe<SpecializationGroupConsultCountType>;
  complianceAlerts?: Maybe<PageBasedComplianceAlertType>;
  complianceAlert?: Maybe<ComplianceAlertType>;
  serviceType?: Maybe<PaginatedServiceType>;
  cieAutocomplete?: Maybe<Array<Maybe<KeyValueType>>>;
  services?: Maybe<ServicesType>;
  limitedServices?: Maybe<LimitedServicesType>;
  cptAutocomplete?: Maybe<Array<Maybe<KeyValueType>>>;
  evidence?: Maybe<EvidenceType>;
  serviceCoverageRuling?: Maybe<ServicesCoverageRulingType>;
  memberCaseUndefinedServicesCount?: Maybe<Scalars['Int']>;
  memberDefinedDiseaseCases?: Maybe<Array<Maybe<DiseaseCaseType>>>;
  doctorMemberDefinedDiseaseCases?: Maybe<Array<Maybe<LimitedDiseaseCaseType>>>;
  memberServices?: Maybe<PaginatedServices>;
  appMemberServices?: Maybe<Array<Maybe<AppLimitedServicesType>>>;
  appMemberServicesInfo?: Maybe<AppLimitedServicesInfoType>;
  chronologicalAppMemberServices?: Maybe<PaginatedChronologicalAppLimitedServices>;
  appMemberService?: Maybe<AppLimitedServicesType>;
  hospitalizationSubtotalAmountByDays?: Maybe<Scalars['Int']>;
  checksLog?: Maybe<PaginatedChecksLogType>;
  runChecks?: Maybe<PaginatedChecksLogType>;
  rulingChecksLog?: Maybe<PaginatedChecksLogType>;
  checkCfdiExists?: Maybe<Array<Scalars['ID']>>;
  invoiceItemsByEvent?: Maybe<Array<InvoiceItemType>>;
  invoicesByEvent?: Maybe<Array<InvoiceType>>;
  providersCoveragePayouts?: Maybe<PaginatedProviderServiceCoveragePayoutType>;
  serviceCoveragePayouts?: Maybe<PaginatedServiceCoveragePayoutType>;
  serviceCoveragePayout?: Maybe<ServiceCoveragePayoutType>;
  serviceEvidenceAttachments?: Maybe<PaginatedTypeFormAttachmentType>;
  coveragePayoutIssuers: Array<Maybe<IssuerType>>;
  businessHealthPlan?: Maybe<HealthPlanType>;
  businessInvoiceItems?: Maybe<PaginatedInvoiceItemType>;
  businessHealthPlanApplications?: Maybe<PageBasedAccessHealthPlanApplicationType>;
  businessHealthPlans?: Maybe<PageBasedAccessBusinessHealthPlanType>;
  businessServicesStatistics?: Maybe<BusinessServicesStatisticsType>;
  business?: Maybe<BusinessType>;
  businesses?: Maybe<PageBasedBusinessType>;
  businessesPaymentStatus?: Maybe<Array<BusinessPaymentStatusType>>;
  businessGroupsConfig?: Maybe<BusinessGroupsConfigType>;
  businessGroupsConfigs?: Maybe<Array<BusinessGroupsConfigType>>;
  businessGroupsConfigEditCategory?: Maybe<BusinessGroupsConfigEditCategoryQueryType>;
  businessMaternityAddonChangesCategory?: Maybe<BusinessMaternityAddonChangesCategoryQueryType>;
  businessStakeholder?: Maybe<BusinessStakeholderType>;
  businessEmployee?: Maybe<BusinessEmployeeType>;
  businessBeneficiaryByMember?: Maybe<BusinessEmployeeType>;
  businessEmployees?: Maybe<PageBasedBusinessEmployeeType>;
  businessEmployeesStatistics?: Maybe<BusinessEmployeeStatisticsType>;
  searchBusiness?: Maybe<SearchBusinessType>;
  searchBusinessEmployees?: Maybe<SearchBusinessEmployeesType>;
  businessBillInfo?: Maybe<BusinessBillInfoType>;
  businessLastPaidBillInfo?: Maybe<BusinessLastPaidBillInfoType>;
  businessInvoice?: Maybe<LimitedInvoiceType>;
  businessInvoices?: Maybe<PageBasedBusinessInvoiceType>;
  businessElectronicVoucher?: Maybe<ElectronicVoucherType>;
  businessElectronicVouchers?: Maybe<PageBasedElectronicVoucherType>;
  businessPaymentMethods?: Maybe<SequentialAccessPaymentMethodsType>;
  businessAdmin?: Maybe<BusinessAdminType>;
  businessAdmins?: Maybe<Array<BusinessAdminType>>;
  businessLatestRenewalProposal?: Maybe<BusinessPlansGeneralConfigProposalType>;
  limitedBusinessLatestRenewalProposal?: Maybe<LimitedBusinessPlansGeneralConfigProposalType>;
  businessProposalBeneficiaries?: Maybe<PageBasedBusinessBeneficiaryConfigProposalType>;
  businessPlansGeneralConfigProposals?: Maybe<PageBasedBusinessPlansGeneralConfigProposalType>;
  businessMaternityEligibleWomen?: Maybe<BusinessMaternityEligibleBeneficiariesType>;
  businessMaternityEligibleBeneficiaries?: Maybe<BusinessMaternityEligibleBeneficiariesType>;
  businessPlansGeneralConfigProposal?: Maybe<BusinessPlansGeneralConfigProposalType>;
  businessPlansGroupConfigProposal?: Maybe<BusinessPlansGroupConfigProposalType>;
  businessPlansGeneralConfigProposalChanges?: Maybe<BusinessPlansGeneralConfigProposalChangesQueryType>;
  businessBeneficiariesToCancelForReissue?: Maybe<BusinessBeneficiariesToCancelForReissueType>;
  balanceAmounts?: Maybe<CurrentBalanceType>;
  miniServicesParams?: Maybe<Array<ServiceParameters>>;
  businessLite?: Maybe<BusinessLiteType>;
};


export type QueryDoctorLeadArgs = {
  id: Scalars['ID'];
};


export type QueryDoctorLeadsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  cityFilter?: InputMaybe<Scalars['String']>;
  stateFilter?: InputMaybe<Array<DoctorLeadState>>;
  specializationIdFilter?: InputMaybe<Array<Scalars['ID']>>;
  hasCvFilter?: InputMaybe<Scalars['Boolean']>;
  duplicateFilter?: InputMaybe<Scalars['Boolean']>;
};


export type QueryVerifyDoctorLeadTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type QueryPublicDoctorLeadInfoByTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type QueryMedicalHistoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryMemberMedicalHistoryArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
  memberUuid?: InputMaybe<Scalars['UUID']>;
};


export type QueryMedicalNoteArgs = {
  id?: InputMaybe<Scalars['ID']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};


export type QueryDoctorAssistantMedicalNoteArgs = {
  id?: InputMaybe<Scalars['ID']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};


export type QueryMemberMedicalNotesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  memberId?: InputMaybe<Scalars['ID']>;
  memberUuid?: InputMaybe<Scalars['UUID']>;
  includeCancelled?: InputMaybe<Scalars['Boolean']>;
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  diseaseCaseId?: InputMaybe<Scalars['ID']>;
  doctorId?: InputMaybe<Scalars['ID']>;
};


export type QueryDoctorMedicalNotesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  includeCancelled?: InputMaybe<Scalars['Boolean']>;
  noteState?: InputMaybe<NoteState>;
};


export type QueryDoctorAssistantMedicalNotesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  includeCancelled?: InputMaybe<Scalars['Boolean']>;
};


export type QueryConsultsLatestMedicalNotesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  includeCancelled?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  doctorName?: InputMaybe<Scalars['String']>;
  memberName?: InputMaybe<Scalars['String']>;
  noteType?: InputMaybe<Array<NoteType>>;
  isAudited?: InputMaybe<Scalars['Boolean']>;
  noProcedures?: InputMaybe<Scalars['Boolean']>;
  randomlySelectedForAudit?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBariatricsProgramArgs = {
  id: Scalars['ID'];
};


export type QueryMaternityProgramArgs = {
  id: Scalars['ID'];
};


export type QueryMedicalProgramMedicalNotesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  medicalProgramId: Scalars['ID'];
};


export type QueryMemberMedicalHistorySummaryArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
  memberUuid?: InputMaybe<Scalars['UUID']>;
};


export type QueryMedicalNotePossibleCiesArgs = {
  suffering: Scalars['String'];
  diagnosis: Scalars['String'];
};


export type QueryMemberMedicalNoteRecommendationsArgs = {
  cie: Scalars['String'];
};


export type QueryMedLabRecomendationByCieArgs = {
  cies: Array<Scalars['String']>;
  prescriptionType: PrescriptionTypeEnum;
};


export type QueryProcedureCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryProcedureCategoriesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  cptKey?: InputMaybe<Scalars['String']>;
  categoryType?: InputMaybe<ProcedureCategoryClassification>;
  isScheduled?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMemberNonPathologicalHistoryIsFilledArgs = {
  memberId: Scalars['ID'];
};


export type QueryPerformedProcedureArgs = {
  id: Scalars['ID'];
};


export type QueryScheduledProcedureArgs = {
  id: Scalars['ID'];
};


export type QueryMemberNotesDoctorsArgs = {
  memberId: Scalars['ID'];
};


export type QueryMemberNotesMedicalSpecializationsArgs = {
  memberId: Scalars['ID'];
};


export type QueryAllServicesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['ID']>;
  memberId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  memberNameFilter?: InputMaybe<Scalars['String']>;
  doctorOrProviderNameFilter?: InputMaybe<Scalars['String']>;
  doctorId?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['Int']>;
  diagnosisIcdIsNull?: InputMaybe<Scalars['Boolean']>;
  cptIsNull?: InputMaybe<Scalars['Boolean']>;
  hasDiagnosis?: InputMaybe<Scalars['Boolean']>;
  latestAmountIsLargeFilter?: InputMaybe<Scalars['Boolean']>;
  serviceTypeValue?: InputMaybe<Array<InputMaybe<ServiceType>>>;
  productFamilyValue?: InputMaybe<ProductFamilies>;
  processState?: InputMaybe<Array<InputMaybe<ServiceProcessStateTypeEnum>>>;
  scheduled?: InputMaybe<Scalars['Boolean']>;
  assignedToId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  createdAtSort?: InputMaybe<QuerySortBy>;
};


export type QueryMemberYearServiceSummaryArgs = {
  memberId: Scalars['ID'];
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryReinbursementServiceInfoArgs = {
  serviceId?: InputMaybe<Scalars['ID']>;
};


export type QueryEventArgs = {
  eventId: Scalars['Int'];
};


export type QueryAllEventsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  eventId?: InputMaybe<Scalars['Int']>;
  invoiceId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  memberNameFilter?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['Int']>;
  doctorOrProviderNameFilter?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Array<InputMaybe<CaseEventCategory>>>;
  stage?: InputMaybe<Array<InputMaybe<CaseEventStage>>>;
  state?: InputMaybe<Array<InputMaybe<CaseEventState>>>;
  finalizedEvents?: InputMaybe<Scalars['Boolean']>;
  assignedToId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  createdAtSort?: InputMaybe<QuerySortBy>;
  scheduledHospitalDateSort?: InputMaybe<QuerySortBy>;
  version?: InputMaybe<Scalars['Int']>;
  stateSort?: InputMaybe<QuerySortBy>;
  pendingMemberAction?: InputMaybe<Scalars['Boolean']>;
  pendingDoctorAction?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDoctorsByEventArgs = {
  eventId: Scalars['ID'];
};


export type QueryServicesByEventArgs = {
  eventId: Scalars['ID'];
  serviceId?: InputMaybe<Scalars['ID']>;
  doctorOrProviderNameFilter?: InputMaybe<Scalars['String']>;
  createdAtSort?: InputMaybe<QuerySortBy>;
  occurrenceDateSort?: InputMaybe<QuerySortBy>;
  scheduledHospitalDateSort?: InputMaybe<QuerySortBy>;
  processState?: InputMaybe<Array<InputMaybe<ServiceProcessStateTypeEnum>>>;
  serviceTypeValue?: InputMaybe<Array<InputMaybe<ServiceType>>>;
};


export type QueryAssignableUsersArgs = {
  assignToEvent?: InputMaybe<Scalars['Boolean']>;
};


export type QueryExclusionArgs = {
  exclusionId: Scalars['ID'];
};


export type QueryExclusionsAutocompleteArgs = {
  term?: InputMaybe<Scalars['String']>;
};


export type QueryExclusionsCatalogArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
};


export type QueryCptPriceReferenceArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  cptCode?: InputMaybe<Scalars['String']>;
  referenceSource?: InputMaybe<CptPriceSource>;
};


export type QueryMultipleCptPriceReferenceArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  cptCodes: Array<InputMaybe<Scalars['String']>>;
  referenceSources?: InputMaybe<Array<InputMaybe<CptPriceSource>>>;
  providersClasses?: InputMaybe<Array<InputMaybe<CptProviderClassification>>>;
};


export type QueryCptPriceReferenceGroupedArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  cptCode?: InputMaybe<Scalars['String']>;
  referenceSource?: InputMaybe<CptPriceSource>;
};


export type QueryRecentCptQuoteItemsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  cptCode: Scalars['String'];
};


export type QueryServicesByDoctorArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorPayoutServiceByIdArgs = {
  serviceId: Scalars['ID'];
};


export type QueryMemberAvailableOfficeProcedureEventsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['ID'];
};


export type QueryCptCatalogArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  cptCode?: InputMaybe<Scalars['ID']>;
  term?: InputMaybe<Scalars['String']>;
};


export type QueryCptArgs = {
  cptId: Scalars['ID'];
};


export type QueryHealthPlanApplicationsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Array<InputMaybe<HealthPlanApplicationState>>>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
  memberName?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
  productFamily?: InputMaybe<ProductFamilies>;
  reviewNeededOps?: InputMaybe<Scalars['Boolean']>;
  moreInfoNeededOps?: InputMaybe<Scalars['Boolean']>;
  reviewNeededMedical?: InputMaybe<Scalars['Boolean']>;
  moreInfoNeededMedical?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHealthPlanApplicationArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryHealthPlanApplicationItemArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryProductQuestionnaireArgs = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryQuestionnaireForMemberArgs = {
  memberId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  healthPlanApplicationId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryQuestionnaireStateForMemberArgs = {
  memberId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  healthPlanApplicationId?: InputMaybe<Scalars['Int']>;
  applicationItemId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryProductsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
};


export type QueryProductArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryIneDataArgs = {
  frontsideBase64: Scalars['String'];
  backsideBase64?: InputMaybe<Scalars['String']>;
};


export type QuerySatInfoValidationArgs = {
  rfc: Scalars['String'];
  legalName: Scalars['String'];
  zipCode: Scalars['String'];
};


export type QueryCheckBirthdayArgs = {
  date: Scalars['Date'];
  isOwner?: InputMaybe<Scalars['Boolean']>;
  isMini?: InputMaybe<Scalars['Boolean']>;
};


export type QueryProductDataArgs = {
  productFamily: Scalars['String'];
};


export type QueryMultipleSubscriptionsPriceArgs = {
  input: Array<SubscriptionPriceInputType>;
  insuredAmountCents?: InputMaybe<InsuredAmountCents>;
  insuredAmountCentsList?: InputMaybe<Array<InsuredAmountCents>>;
  discountPercent?: InputMaybe<Scalars['Float']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  maternityAddon?: InputMaybe<Scalars['Boolean']>;
  intlCoverageAddon?: InputMaybe<Scalars['Boolean']>;
  quoteType?: InputMaybe<QuoteType>;
};


export type QueryScheduledApprovalCallsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  userId: Scalars['Int'];
};


export type QueryProductDiscountConfigurationsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  group?: InputMaybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  emailFilter?: InputMaybe<Scalars['String']>;
  emailFilterRegex?: InputMaybe<Scalars['String']>;
  groupFilter?: InputMaybe<Array<GroupName>>;
  telephoneFilter?: InputMaybe<Scalars['String']>;
};


export type QueryDocumentsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryMembersArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['Int']>;
  isBeneficiary?: InputMaybe<Scalars['Boolean']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
  isB2b?: InputMaybe<Scalars['Boolean']>;
  hpState?: InputMaybe<Scalars['String']>;
  nameFilter?: InputMaybe<Scalars['String']>;
};


export type QueryProfilingDataMemberListArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  businessNameFilter?: InputMaybe<Scalars['String']>;
  profilingDataStateList?: InputMaybe<Array<ProfilingDataState>>;
  validSinceStartFilter?: InputMaybe<Scalars['String']>;
  validSinceEndFilter?: InputMaybe<Scalars['String']>;
};


export type QueryProfilingDataListArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  businessNameFilter?: InputMaybe<Scalars['String']>;
  stateList?: InputMaybe<Array<ProfilingDataState>>;
  validSinceStartFilter?: InputMaybe<Scalars['String']>;
  validSinceEndFilter?: InputMaybe<Scalars['String']>;
  submittedSinceStartFilter?: InputMaybe<Scalars['String']>;
  submittedSinceEndFilter?: InputMaybe<Scalars['String']>;
  reviewNeededOps?: InputMaybe<Scalars['Boolean']>;
  moreInfoNeededOps?: InputMaybe<Scalars['Boolean']>;
  reviewNeededMedical?: InputMaybe<Scalars['Boolean']>;
  moreInfoNeededMedical?: InputMaybe<Scalars['Boolean']>;
  submittedAtSort?: InputMaybe<QuerySortBy>;
};


export type QueryProfilingDataArgs = {
  id: Scalars['ID'];
};


export type QueryMemberArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryMemberSearchArgs = {
  term?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['String']>;
  hasActiveHealthPlan?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDocumentArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryHealthPlanMembersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryManagedMembersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryMemberRepresentedByIdArgs = {
  id: Scalars['ID'];
};


export type QueryDependentMemberByIdArgs = {
  id: Scalars['ID'];
};


export type QuerySponsorRepresentedMembersByIdArgs = {
  memberId: Scalars['ID'];
};


export type QueryCheckPasswordArgs = {
  candidatePassword?: InputMaybe<Scalars['String']>;
};


export type QueryVerifyPasswordArgs = {
  currentPassword?: InputMaybe<Scalars['String']>;
};


export type QueryIsTelephoneAlreadyOnUseArgs = {
  telephone: Scalars['String'];
};


export type QueryCheckEmailArgs = {
  email: Scalars['String'];
};


export type QueryHasPaymentsPendingArgs = {
  id: Scalars['Int'];
};


export type QueryVerifyPasswordTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type QueryMemberExclusionsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['ID'];
};


export type QueryMemberExclusionArgs = {
  memberId: Scalars['ID'];
  memberExclusionId: Scalars['ID'];
};


export type QueryMemberConfirmedExclusionsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  memberId: Scalars['ID'];
};


export type QueryMemberMatchingExclusionsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['ID'];
  icds: Array<InputMaybe<Scalars['String']>>;
};


export type QueryContentTypesArgs = {
  contentTypeNames: Array<InputMaybe<Scalars['String']>>;
};


export type QueryMemberSpecializationsExclusionsArgs = {
  memberId: Scalars['ID'];
  doctorId: Scalars['ID'];
};


export type QueryMemberFavouriteDoctorsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['ID'];
};


export type QueryMemberFavouriteProviderBranchesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['ID'];
  providerCategoryNames: Array<Scalars['String']>;
};


export type QueryMemberVisitedDoctorsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['ID'];
};


export type QueryProviderIsMembersFavouriteArgs = {
  memberId: Scalars['ID'];
  providerId: Scalars['ID'];
  isDoctor: Scalars['Boolean'];
};


export type QueryMemberCervicalCancerCategoryArgs = {
  memberId: Scalars['ID'];
};


export type QueryValidateReferralCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorFriendArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorFriendsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  taxNumberFilter?: InputMaybe<Scalars['String']>;
  emailFilter?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorPatientsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryAllDoctorPatientsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryApplicantsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  filteredBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryMedicalSpecializationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPublicMedicalSpecializationsArgs = {
  specializationTypeList?: InputMaybe<Array<InputMaybe<SpecializationType>>>;
};


export type QueryMedicalSpecializationsArgs = {
  inCatalog?: InputMaybe<Scalars['Boolean']>;
  specializationType?: InputMaybe<SpecializationType>;
};


export type QueryPaginatedMedicalSpecializationsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  inCatalog?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMedicalSpecializationGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryMedicalSpecializationDoctorsArgs = {
  medicalSpecializationName: Scalars['String'];
};


export type QueryProviderBranchOfficeArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryProviderBranchOfficesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['ID']>;
  providerName?: InputMaybe<Scalars['String']>;
  providerCategoryId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<ProviderState>;
};


export type QueryProviderBranchesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  providerCategoryName?: InputMaybe<Scalars['String']>;
  providerCategoryNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  stateName?: InputMaybe<States>;
  tiers?: InputMaybe<Array<InputMaybe<ProviderTier>>>;
};


export type QueryProviderBranchArgs = {
  providerBranchId?: InputMaybe<Scalars['Int']>;
};


export type QueryCareDirectoryArgs = {
  term?: InputMaybe<Scalars['String']>;
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  providerTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  specialties?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tiers?: InputMaybe<Array<InputMaybe<ProviderTier>>>;
  isAuthorizedForOnDemand?: InputMaybe<Scalars['Boolean']>;
  isAuthorizedForInPersonConsult?: InputMaybe<Scalars['Boolean']>;
  showDoctorInApp?: InputMaybe<Scalars['Boolean']>;
  sortByTier?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<States>;
  sortByDistance?: InputMaybe<Scalars['Boolean']>;
  providerState?: InputMaybe<ProviderState>;
  showInApp?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCareDirectoryContinueArgs = {
  cursor?: InputMaybe<Scalars['String']>;
};


export type QuerySpecialtiesDoctorsDirectoryArgs = {
  term?: InputMaybe<Scalars['String']>;
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  isAuthorizedForOnDemand?: InputMaybe<Scalars['Boolean']>;
  isAuthorizedForInPersonConsult?: InputMaybe<Scalars['Boolean']>;
  showDoctorInApp?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<States>;
  sortByDistance?: InputMaybe<Scalars['Boolean']>;
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  hasVirtualAddress?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QuerySpecialtiesDoctorsDirectoryContinueArgs = {
  cursor?: InputMaybe<Scalars['String']>;
};


export type QueryProviderArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryProvidersArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  providerCategoryId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<ProviderState>;
};


export type QueryCareProviderDirectoryArgs = {
  term?: InputMaybe<Scalars['String']>;
  providerTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  specialties?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tiers?: InputMaybe<Array<InputMaybe<ProviderTier>>>;
  sortByTier?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<States>;
  sortByDistance?: InputMaybe<Scalars['Boolean']>;
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  providerState?: InputMaybe<ProviderState>;
  showInApp?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCareProviderDirectoryContinueArgs = {
  cursor?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryDoctorProfileArgs = {
  doctorId?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  cityFilter?: InputMaybe<Scalars['String']>;
  primaryCare?: InputMaybe<Scalars['Boolean']>;
  allowPrimaryCareMembersAssignment?: InputMaybe<Scalars['Boolean']>;
  specializationIdFilter?: InputMaybe<Array<Scalars['ID']>>;
  isPediatricsCareDoctor?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
};


export type QueryOnDemandAuthorizedDoctorsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorsInPersonConsultsByWeekArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  currentDate?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorHuliDataArgs = {
  doctorId?: InputMaybe<Scalars['Int']>;
};


export type QuerySpecializationFareArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryDoctorRemindersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DoctorReminderStatus>;
  memberUuid?: InputMaybe<Scalars['UUID']>;
  consultId?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type QueryDoctorReminderArgs = {
  reminderId?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorAssistantArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryDoctorAssistantsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryPublicDoctorProfileArgs = {
  uuid?: InputMaybe<Scalars['UUID']>;
};


export type QueryPublicDoctorsDirectoryArgs = {
  medicalSpecializationIds?: InputMaybe<Array<Scalars['ID']>>;
  locationState?: InputMaybe<States>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryPaginatedLocationBasedDoctorsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  locationInput?: InputMaybe<LimitedLocationInputType>;
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  medicalSpecializationName?: InputMaybe<Scalars['String']>;
  isAuthorizedForOnDemand?: InputMaybe<Scalars['Boolean']>;
  isAuthorizedForInPersonConsult?: InputMaybe<Scalars['Boolean']>;
  virtualOffice?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLocationBasedDoctorOfficeAddressesArgs = {
  locationInput: LimitedLocationInputType;
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  medicalSpecializationName?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorByOfficeAddressIdArgs = {
  addressId: Scalars['ID'];
};


export type QueryCoordinatesBasedDoctorOfficeAddressesArgs = {
  topLeftCoordinates: Array<InputMaybe<Scalars['Float']>>;
  bottomRightCoordinates: Array<InputMaybe<Scalars['Float']>>;
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  medicalSpecializationName?: InputMaybe<Scalars['String']>;
};


export type QueryPaginatedLocationBasedBranchOfficesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  locationInput?: InputMaybe<LimitedLocationInputType>;
  providerCategoryName?: InputMaybe<Scalars['String']>;
  providerCategoryNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tiers?: InputMaybe<Array<InputMaybe<ProviderTier>>>;
};


export type QueryLocationBasedBranchOfficeAddressesArgs = {
  locationInput: LimitedLocationInputType;
  providerCategoryName?: InputMaybe<Scalars['String']>;
  providerCategoryNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tiers?: InputMaybe<Array<InputMaybe<ProviderTier>>>;
};


export type QueryBranchOfficeByAddressIdArgs = {
  addressId: Scalars['ID'];
};


export type QueryCoordinatesBasedProviderBranchOfficeAddressesArgs = {
  topLeftCoordinates: Array<InputMaybe<Scalars['Float']>>;
  bottomRightCoordinates: Array<InputMaybe<Scalars['Float']>>;
  providerCategoryName?: InputMaybe<Scalars['String']>;
  providerCategoryNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tiers?: InputMaybe<Array<InputMaybe<ProviderTier>>>;
};


export type QueryClosestProviderBranchOfficeByCoordinatesArgs = {
  providerCategoryNames: Array<InputMaybe<Scalars['String']>>;
  coordinates: Array<InputMaybe<Scalars['Float']>>;
  tiers?: InputMaybe<Array<InputMaybe<ProviderTier>>>;
};


export type QueryInPersonCalendarDoctorsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  medicalSpecializationName?: InputMaybe<Scalars['String']>;
};


export type QueryPharmacyOrdersArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  memberNameFilter?: InputMaybe<Scalars['String']>;
};


export type QueryPharmacyOrderArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGroupArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGroupsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryPermissionArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryPermissionsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryPaymentMethodsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  paymentFlow?: InputMaybe<PaymentFlowTypes>;
};


export type QueryPayoutPaymentMethodsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryPayinPaymentMethodsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryStripeSourcesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryTransactionsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  paymentResult?: InputMaybe<TransactionResult>;
  withInvoices?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPaginatedTransactionsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  uuid?: InputMaybe<Scalars['UUID']>;
  paymentResult?: InputMaybe<TransactionResult>;
  withInvoices?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLocationsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  zipcode?: InputMaybe<Scalars['String']>;
};


export type QueryPaginatedLocationsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  zipcodes?: InputMaybe<Array<Scalars['String']>>;
  zipcodeRegex?: InputMaybe<Array<Scalars['String']>>;
  neighborhoods?: InputMaybe<Array<Scalars['String']>>;
  neighborhoodType?: InputMaybe<Array<Scalars['String']>>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  isCore?: InputMaybe<Scalars['Boolean']>;
  municipalities?: InputMaybe<Array<Scalars['String']>>;
  stateNames?: InputMaybe<Array<Scalars['String']>>;
  cities?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryLocationsSearchArgs = {
  term: Scalars['String'];
};


export type QueryInvoiceArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryInvoiceItemIssuersArgs = {
  paid?: InputMaybe<Scalars['Boolean']>;
};


export type QueryInvoiceItemsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  issuerId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  contentType?: InputMaybe<Scalars['String']>;
};


export type QueryPayinInvoicesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  modelType?: InputMaybe<Scalars['String']>;
};


export type QueryInvoicesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  idList?: InputMaybe<Array<Scalars['ID']>>;
  uuidList?: InputMaybe<Array<Scalars['ID']>>;
  withElectronicVoucherError?: InputMaybe<Scalars['Boolean']>;
  modelType?: InputMaybe<Scalars['String']>;
  paymentFlow?: InputMaybe<Scalars['String']>;
  invoiceState?: InputMaybe<Scalars['String']>;
  invoiceStateFilters?: InputMaybe<Array<InvoiceState>>;
  voucherType?: InputMaybe<SatVoucherType>;
  counterpartyName?: InputMaybe<Scalars['String']>;
  counterpartyId?: InputMaybe<Scalars['ID']>;
  productFamilyFilter?: InputMaybe<ProductFamilies>;
  counterparty?: InputMaybe<Array<InvoiceCounterpartyType>>;
  withoutInvoiceItems?: InputMaybe<Scalars['Boolean']>;
  filterByUsersWithNoPaymentMethod?: InputMaybe<Scalars['Boolean']>;
  withAutoEmittedInvoice?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<Array<InvoicesQueryServiceType>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  isUploadedByDoctor?: InputMaybe<Scalars['Boolean']>;
};


export type QueryElectronicVouchersArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  voucherType?: InputMaybe<SatVoucherType>;
  state?: InputMaybe<SatOperationTypeStatus>;
  stateListFilter?: InputMaybe<Array<SatOperationTypeStatus>>;
  recipientTaxNumber?: InputMaybe<Scalars['String']>;
  issuerOrRecipientId?: InputMaybe<Scalars['ID']>;
  issuerTaxNumber?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  createdFrom?: InputMaybe<Scalars['Date']>;
  createdTo?: InputMaybe<Scalars['Date']>;
  issuerOrRecipientTaxNumber?: InputMaybe<Scalars['String']>;
};


export type QueryElectronicVoucherArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryIncomesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  paymentFlow?: InputMaybe<Scalars['String']>;
  counterpartyName?: InputMaybe<Scalars['String']>;
};


export type QueryManualPaymentSourcesSchemaArgs = {
  invoiceId?: InputMaybe<Scalars['Int']>;
};


export type QueryRunAutomaticPaymentChecksArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  invoiceId: Scalars['ID'];
};


export type QueryAutomaticPaymentChecksLogArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  invoiceId: Scalars['ID'];
};


export type QueryIncentiveCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPublicIncentiveCodeDataArgs = {
  code?: InputMaybe<Scalars['String']>;
};


export type QueryReferralReferredBetweenDatesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type QueryIncentiveCodesListArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  internalName?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryHealthPlansArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  uuidFilter?: InputMaybe<Scalars['String']>;
  stateFilter?: InputMaybe<Scalars['String']>;
  productFamilyFilter?: InputMaybe<ProductFamilies>;
};


export type QueryHealthPlanArgs = {
  healthPlanId?: InputMaybe<Scalars['Int']>;
};


export type QueryHealthPlanAmendmentsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  categoryFilter?: InputMaybe<Scalars['String']>;
  reviewResultFilter?: InputMaybe<Scalars['String']>;
  nameFilter?: InputMaybe<Scalars['String']>;
};


export type QueryHealthPlanAmendmentArgs = {
  id: Scalars['Int'];
};


export type QueryHealthPlanRenewQuoteArgs = {
  healthPlanId?: InputMaybe<Scalars['Int']>;
};


export type QueryChatMessagesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  consultId?: InputMaybe<Scalars['Int']>;
};


export type QueryHuliEmrsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  emrType?: InputMaybe<Array<EmrType>>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  doctorName?: InputMaybe<Scalars['String']>;
  patientName?: InputMaybe<Scalars['String']>;
  huliConsultId?: InputMaybe<Scalars['ID']>;
};


export type QueryHuliEmrEntryArgs = {
  id: Scalars['ID'];
};


export type QueryMemberOnDemandConsultsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorOnDemandConsultsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  state?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorOnDemandConsultsWithMessagesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorOnDemandConsultArgs = {
  consultId?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorPaginatedScheduledConsultsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  todayConsults?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDoctorAssistantPaginatedScheduledConsultsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorScheduledConsultArgs = {
  scheduledConsultId?: InputMaybe<Scalars['Int']>;
};


export type QueryReadyOnDemandConsultsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryConsultStateArgs = {
  consultId?: InputMaybe<Scalars['Int']>;
};


export type QueryConsultArgs = {
  consultId?: InputMaybe<Scalars['Int']>;
};


export type QueryPrescriptionArgs = {
  id: Scalars['Int'];
};


export type QueryMemberPrescriptionArgs = {
  id: Scalars['ID'];
};


export type QueryAllScheduledConsultsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryListMemberScheduledConsultsArgs = {
  id: Scalars['ID'];
  order?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<ScheduledConsultState>>>;
  scheduledConsultClassification?: InputMaybe<GrapheneScheduledConsultClassification>;
};


export type QueryListAllScheduledConsultsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['Int']>;
  isRequest: Scalars['Boolean'];
  isCancelled: Scalars['Boolean'];
  scheduledFrom?: InputMaybe<Scalars['DateTime']>;
  scheduledTo?: InputMaybe<Scalars['DateTime']>;
  memberName?: InputMaybe<Scalars['String']>;
  doctorName?: InputMaybe<Scalars['String']>;
  assignedTo?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  scheduledConsultClassification?: InputMaybe<GrapheneScheduledConsultClassification>;
  createdAtSort?: InputMaybe<QuerySortBy>;
  scheduledAtSort?: InputMaybe<QuerySortBy>;
};


export type QueryScheduledConsultArgs = {
  id: Scalars['ID'];
};


export type QueryCalendarEventsArgs = {
  doctorId: Scalars['ID'];
  fromDate?: InputMaybe<Scalars['DateTime']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryCalendarEventArgs = {
  doctorId: Scalars['ID'];
  eventId: Scalars['String'];
};


export type QueryCalendarDatesAvailabilityArgs = {
  doctorId: Scalars['ID'];
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
  calendarSlotClassification?: InputMaybe<CalendarSlotClassification>;
};


export type QueryAllCalendarDatesAvailabilityArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
  isPediatric?: InputMaybe<Scalars['Boolean']>;
  calendarSlotClassification?: InputMaybe<CalendarSlotClassification>;
};


export type QueryCalendarDatesSlotsAvailabilityArgs = {
  doctorId: Scalars['ID'];
  isWelcome?: InputMaybe<Scalars['Boolean']>;
  isPrimaryCare?: InputMaybe<Scalars['Boolean']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
  calendarSlotClassification?: InputMaybe<CalendarSlotClassification>;
};


export type QueryAllCalendarDatesSlotsAvailabilityArgs = {
  isWelcome?: InputMaybe<Scalars['Boolean']>;
  isPrimaryCare?: InputMaybe<Scalars['Boolean']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
  isPediatric?: InputMaybe<Scalars['Boolean']>;
  calendarSlotClassification?: InputMaybe<CalendarSlotClassification>;
};


export type QueryCalendarNextAvailabilityArgs = {
  doctorId: Scalars['ID'];
  isWelcome?: InputMaybe<Scalars['Boolean']>;
  isPrimaryCare?: InputMaybe<Scalars['Boolean']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
  calendarSlotClassification?: InputMaybe<CalendarSlotClassification>;
};


export type QueryAllCalendarNextAvailabilityArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
  isPediatric?: InputMaybe<Scalars['Boolean']>;
  calendarSlotClassification?: InputMaybe<CalendarSlotClassification>;
};


export type QueryFirstTodayScheduledConsultArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
  memberUuid?: InputMaybe<Scalars['UUID']>;
};


export type QueryMemberRequestedOutOfNetworkScheduledConsultsArgs = {
  memberId: Scalars['ID'];
};


export type QueryPaginatedOutOfNetworkScheduledConsultsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['Int']>;
  isCancelled?: InputMaybe<Scalars['Boolean']>;
  memberName?: InputMaybe<Scalars['String']>;
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  createdAtSort?: InputMaybe<QuerySortBy>;
};


export type QueryMemberHasReferenceArgs = {
  memberId: Scalars['ID'];
  doctorId: Scalars['ID'];
};


export type QueryChronologicalMemberConsultsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['ID'];
  rulingState?: InputMaybe<Array<InputMaybe<RulingState>>>;
  serviceState?: InputMaybe<Array<InputMaybe<ServiceProcessStateTypeEnum>>>;
  onDemandConsultState?: InputMaybe<Array<InputMaybe<OnDemandConsultStateEnum>>>;
};


export type QueryMemberSpecializationIpcCountArgs = {
  memberId: Scalars['ID'];
  medicalSpecializationName: Scalars['String'];
};


export type QueryComplianceAlertsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  alertCategoryFilter?: InputMaybe<Array<InputMaybe<ComplianceAlertCategory>>>;
  applicationStateFilter?: InputMaybe<Array<InputMaybe<HealthPlanApplicationState>>>;
  nameFilter?: InputMaybe<Scalars['String']>;
  annotationFilter?: InputMaybe<ComplianceAlertAnnotation>;
};


export type QueryComplianceAlertArgs = {
  id: Scalars['Int'];
};


export type QueryServiceTypeArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  productId: Scalars['ID'];
};


export type QueryCieAutocompleteArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryServicesArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryLimitedServicesArgs = {
  id?: InputMaybe<Scalars['ID']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};


export type QueryCptAutocompleteArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryEvidenceArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryServiceCoverageRulingArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryMemberCaseUndefinedServicesCountArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryMemberDefinedDiseaseCasesArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorMemberDefinedDiseaseCasesArgs = {
  id?: InputMaybe<Scalars['Int']>;
  memberUuid?: InputMaybe<Scalars['UUID']>;
};


export type QueryMemberServicesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  memberId?: InputMaybe<Scalars['Int']>;
};


export type QueryAppMemberServicesArgs = {
  memberId: Scalars['ID'];
  onlyServicesByLatestHealthPlan?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAppMemberServicesInfoArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['ID'];
  processStateList?: InputMaybe<Array<ServiceProcessStateTypeEnum>>;
  serviceTypeValueList?: InputMaybe<Array<ServiceType>>;
};


export type QueryChronologicalAppMemberServicesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['ID'];
  onlyServicesByLatestHealthPlan?: InputMaybe<Scalars['Boolean']>;
  processStateList?: InputMaybe<Array<ServiceProcessStateTypeEnum>>;
  serviceTypeValueList?: InputMaybe<Array<ServiceType>>;
  rulingStateList?: InputMaybe<Array<RulingState>>;
  onlyRefundServices?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAppMemberServiceArgs = {
  memberId: Scalars['ID'];
  serviceId: Scalars['ID'];
};


export type QueryHospitalizationSubtotalAmountByDaysArgs = {
  serviceId: Scalars['ID'];
};


export type QueryChecksLogArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  invoiceId: Scalars['ID'];
  byLastCheckedDate?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRunChecksArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  invoiceId: Scalars['ID'];
};


export type QueryRulingChecksLogArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  serviceId: Scalars['ID'];
  byLastCheckedDate?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCheckCfdiExistsArgs = {
  cfdiUuid?: InputMaybe<Scalars['String']>;
};


export type QueryInvoiceItemsByEventArgs = {
  eventId: Scalars['ID'];
};


export type QueryInvoicesByEventArgs = {
  eventId: Scalars['ID'];
};


export type QueryProvidersCoveragePayoutsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  issuerId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
};


export type QueryServiceCoveragePayoutsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  serviceTypeValue?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  states?: InputMaybe<Array<ServiceTransactionState>>;
  serviceId?: InputMaybe<Scalars['ID']>;
};


export type QueryServiceCoveragePayoutArgs = {
  id: Scalars['ID'];
};


export type QueryServiceEvidenceAttachmentsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  serviceId: Scalars['ID'];
  formId?: InputMaybe<Scalars['ID']>;
  states: Array<AttachmentState>;
};


export type QueryCoveragePayoutIssuersArgs = {
  paid?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBusinessHealthPlanArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryBusinessInvoiceItemsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  invoiceId?: InputMaybe<Scalars['ID']>;
  itemDescription?: InputMaybe<Scalars['String']>;
};


export type QueryBusinessHealthPlanApplicationsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  businessFilter?: InputMaybe<Scalars['ID']>;
  linkedToBusinessEmployee?: InputMaybe<Scalars['Boolean']>;
  statesFilter?: InputMaybe<Array<HealthPlanApplicationState>>;
};


export type QueryBusinessHealthPlansArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  businessFilter?: InputMaybe<Scalars['ID']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  statesFilter?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryBusinessArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  nameSort?: InputMaybe<QuerySortBy>;
  hasActiveBeneficiariesFilter?: InputMaybe<Scalars['Boolean']>;
  hasActiveBeneficiariesSort?: InputMaybe<QuerySortBy>;
  isCollectiveFilter?: InputMaybe<Scalars['Boolean']>;
  isCollectiveSort?: InputMaybe<QuerySortBy>;
  businessStateFilter?: InputMaybe<Array<BusinessState>>;
};


export type QueryBusinessGroupsConfigArgs = {
  id: Scalars['ID'];
  businessId?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessGroupsConfigsArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  stateFilter?: InputMaybe<Array<BusinessGroupsConfigState>>;
};


export type QueryBusinessGroupsConfigEditCategoryArgs = {
  businessGroupsConfigId: Scalars['ID'];
  allowMultimember?: InputMaybe<Scalars['Boolean']>;
  coinsuranceConfig?: InputMaybe<CoInsuranceConfigFactor>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  dentalMembershipAddon?: InputMaybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  insuredAmountCentsCoverage?: InputMaybe<InsuredAmountCents>;
  intlCoverageAddon?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBusinessMaternityAddonChangesCategoryArgs = {
  businessId: Scalars['ID'];
  maternityAddon: Scalars['Boolean'];
  maternityAddonWaitingPeriodMonths?: InputMaybe<MaternityWaitingPeriodMonths>;
};


export type QueryBusinessStakeholderArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryBusinessEmployeeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessBeneficiaryByMemberArgs = {
  id?: InputMaybe<Scalars['ID']>;
  businessId?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessEmployeesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  businessFilter?: InputMaybe<Scalars['ID']>;
  groupsConfigFilter?: InputMaybe<Scalars['ID']>;
  emailFilter?: InputMaybe<Scalars['String']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  hasLatestHealthPlanApplication?: InputMaybe<Scalars['Boolean']>;
  latestHealthPlanApplicationStates?: InputMaybe<Array<HealthPlanApplicationState>>;
  beneficiaryStates?: InputMaybe<Array<BusinessBeneficiaryState>>;
  beneficiaryCategories?: InputMaybe<Array<BusinessBeneficiaryCategory>>;
  userAppStageRegistered?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBusinessEmployeesStatisticsArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
};


export type QuerySearchBusinessArgs = {
  searchTerm: Scalars['String'];
};


export type QuerySearchBusinessEmployeesArgs = {
  name: Scalars['String'];
  businessId?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<BusinessBeneficiaryCategory>;
  state?: InputMaybe<BusinessBeneficiaryState>;
};


export type QueryBusinessInvoiceArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryBusinessInvoicesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  idList?: InputMaybe<Array<Scalars['ID']>>;
  uuidList?: InputMaybe<Array<Scalars['ID']>>;
  paymentFlow?: InputMaybe<Scalars['String']>;
  invoiceStateFilters?: InputMaybe<Array<InvoiceState>>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
};


export type QueryBusinessElectronicVoucherArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessElectronicVouchersArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  employeeName?: InputMaybe<Scalars['String']>;
  voucherType?: InputMaybe<SatVoucherType>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  invoiceId?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessPaymentMethodsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  paymentFlow?: InputMaybe<PaymentFlowTypes>;
};


export type QueryBusinessAdminArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessAdminsArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessLatestRenewalProposalArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
};


export type QueryLimitedBusinessLatestRenewalProposalArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessProposalBeneficiariesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  proposalId?: InputMaybe<Scalars['ID']>;
  groupId?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<BusinessBeneficiaryCategory>;
};


export type QueryBusinessPlansGeneralConfigProposalsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  businessId?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessMaternityEligibleWomenArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  includeEdgeCases?: InputMaybe<Scalars['Boolean']>;
  onlyEdgeCases?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBusinessMaternityEligibleBeneficiariesArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  includeUneligibleHolders?: InputMaybe<Scalars['Boolean']>;
  onlyUneligibleHolders?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<BusinessBeneficiaryState>;
};


export type QueryBusinessPlansGeneralConfigProposalArgs = {
  proposalId?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessPlansGroupConfigProposalArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessPlansGeneralConfigProposalChangesArgs = {
  proposalId?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessBeneficiariesToCancelForReissueArgs = {
  proposalId?: InputMaybe<Scalars['ID']>;
};


export type QueryBalanceAmountsArgs = {
  healthPlanId?: InputMaybe<Scalars['Int']>;
};


export type QueryMiniServicesParamsArgs = {
  healthPlanId: Scalars['ID'];
  serviceType?: InputMaybe<ServiceType>;
};

export type DoctorLeadType = {
  __typename?: 'DoctorLeadType';
  firstName: Scalars['String'];
  firstLastName: Scalars['String'];
  secondLastName?: Maybe<Scalars['String']>;
  waTelephone?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  uuid: Scalars['UUID'];
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  curriculumVitae?: Maybe<DocumentType>;
  state?: Maybe<DoctorLeadState>;
  otherMedicalSpecialization?: Maybe<Scalars['String']>;
  otherMedicalSubspecialization?: Maybe<Scalars['String']>;
  lostReason?: Maybe<DoctorLeadLostReason>;
  isDuplicate: Scalars['Boolean'];
  doctorMedicalSpecializations?: Maybe<Array<MedicalSpecializationModelType>>;
  doctorLeadUserCreationLink?: Maybe<Scalars['String']>;
  doctorId?: Maybe<Scalars['ID']>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  id: Scalars['ID'];
  s3Key?: Maybe<Scalars['String']>;
  isValidated: Scalars['Boolean'];
  mimeType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  uploadedBy?: Maybe<BriefUserType>;
  url?: Maybe<Scalars['String']>;
  validatedBy?: Maybe<BriefUserType>;
  category?: Maybe<DocumentCategory>;
  documentSubtype?: Maybe<DocumentSubtype>;
  documentVersions?: Maybe<Array<Maybe<DocumentVersionType>>>;
  latestVersion?: Maybe<Scalars['Int']>;
};

export type BriefUserType = {
  __typename?: 'BriefUserType';
  id: Scalars['ID'];
};

export enum DocumentCategory {
  Signature = 'SIGNATURE',
  Identity = 'IDENTITY',
  IdentityBackside = 'IDENTITY_BACKSIDE',
  Curp = 'CURP',
  ProofOfAddress = 'PROOF_OF_ADDRESS',
  Dependent = 'DEPENDENT',
  Rfc = 'RFC',
  ElectronicVoucherXml = 'ELECTRONIC_VOUCHER_XML',
  ElectronicVoucherPdf = 'ELECTRONIC_VOUCHER_PDF',
  CollectiveInitialSubscriptionAmendmentCover = 'COLLECTIVE_INITIAL_SUBSCRIPTION_AMENDMENT_COVER',
  CollectiveAdditionalSubscriptionAmendmentCover = 'COLLECTIVE_ADDITIONAL_SUBSCRIPTION_AMENDMENT_COVER',
  CollectiveSubscriptionReissueAmendmentCover = 'COLLECTIVE_SUBSCRIPTION_REISSUE_AMENDMENT_COVER',
  CollectiveSubscriptionCancellationAmendmentCover = 'COLLECTIVE_SUBSCRIPTION_CANCELLATION_AMENDMENT_COVER',
  SubscriptionAmendmentCover = 'SUBSCRIPTION_AMENDMENT_COVER',
  MaternityAmendmentCover = 'MATERNITY_AMENDMENT_COVER',
  DentalAmendmentCover = 'DENTAL_AMENDMENT_COVER',
  DentalMembershipAmendmentCover = 'DENTAL_MEMBERSHIP_AMENDMENT_COVER',
  IntlCoverageAmendmentCover = 'INTL_COVERAGE_AMENDMENT_COVER',
  SubscriptionBrochure = 'SUBSCRIPTION_BROCHURE',
  SubscriptionGeneralConditions = 'SUBSCRIPTION_GENERAL_CONDITIONS',
  ClaimsEvidence = 'CLAIMS_EVIDENCE',
  ClaimsInvoiceXml = 'CLAIMS_INVOICE_XML',
  ClaimsInvoicePdf = 'CLAIMS_INVOICE_PDF',
  ClaimsInvoiceAnalysis = 'CLAIMS_INVOICE_ANALYSIS',
  AmendmentComplementary = 'AMENDMENT_COMPLEMENTARY',
  AmendmentSummary = 'AMENDMENT_SUMMARY',
  InvoiceXml = 'INVOICE_XML',
  InvoicePdf = 'INVOICE_PDF',
  InvoiceXlsx = 'INVOICE_XLSX',
  CfdiConciliationXlsx = 'CFDI_CONCILIATION_XLSX',
  InvoicePdfSnapshot = 'INVOICE_PDF_SNAPSHOT',
  IntlCoverageAxaCert = 'INTL_COVERAGE_AXA_CERT',
  BusinessDocument = 'BUSINESS_DOCUMENT',
  StakeholderDocument = 'STAKEHOLDER_DOCUMENT',
  BusinessTaxRegistration = 'BUSINESS_TAX_REGISTRATION',
  BusinessDigitalTaxSignatureProof = 'BUSINESS_DIGITAL_TAX_SIGNATURE_PROOF',
  BusinessTradeRegistrationProof = 'BUSINESS_TRADE_REGISTRATION_PROOF',
  BusinessProofOfAddress = 'BUSINESS_PROOF_OF_ADDRESS',
  BusinessContractDocument = 'BUSINESS_CONTRACT_DOCUMENT',
  BusinessRegistrationToCommercePublicRegistry = 'BUSINESS_REGISTRATION_TO_COMMERCE_PUBLIC_REGISTRY',
  BusinessInvoiceElectronicVouchersXmlZip = 'BUSINESS_INVOICE_ELECTRONIC_VOUCHERS_XML_ZIP',
  BusinessInvoiceElectronicVouchersPdfZip = 'BUSINESS_INVOICE_ELECTRONIC_VOUCHERS_PDF_ZIP',
  BusinessQuoteDocument = 'BUSINESS_QUOTE_DOCUMENT',
  BusinessBeneficiariesReport = 'BUSINESS_BENEFICIARIES_REPORT',
  StakeholderId = 'STAKEHOLDER_ID',
  StakeholderRoleProof = 'STAKEHOLDER_ROLE_PROOF',
  StakeholderForeignerDoc = 'STAKEHOLDER_FOREIGNER_DOC',
  StakeholderCurpDoc = 'STAKEHOLDER_CURP_DOC',
  PrescriptionPdf = 'PRESCRIPTION_PDF',
  TransactionProof = 'TRANSACTION_PROOF',
  CovidVaccineProof = 'COVID_VACCINE_PROOF',
  OdontoprevCard = 'ODONTOPREV_CARD',
  OdontoprevCover = 'ODONTOPREV_COVER',
  OdontoprevCollectiveCover = 'ODONTOPREV_COLLECTIVE_COVER',
  EmrDocument = 'EMR_DOCUMENT',
  CaseEventQuote = 'CASE_EVENT_QUOTE',
  CaseEventAuthorizationLetter = 'CASE_EVENT_AUTHORIZATION_LETTER',
  Quote = 'QUOTE',
  DoctorCurriculumVitae = 'DOCTOR_CURRICULUM_VITAE',
  DoctorLiabilityInsurance = 'DOCTOR_LIABILITY_INSURANCE',
  DoctorPhoto = 'DOCTOR_PHOTO',
  DoctorIntroVideo = 'DOCTOR_INTRO_VIDEO',
  TaxCertificate = 'TAX_CERTIFICATE',
  AntiquityCertificate = 'ANTIQUITY_CERTIFICATE',
  AntiquityPreviousPolicy = 'ANTIQUITY_PREVIOUS_POLICY',
  AntiquityProofOfPayment = 'ANTIQUITY_PROOF_OF_PAYMENT',
  EventQuoteLetter = 'EVENT_QUOTE_LETTER',
  CaseEventBudget = 'CASE_EVENT_BUDGET',
  CaseEventFinalLetter = 'CASE_EVENT_FINAL_LETTER',
  DoctorSpecializationCertificate = 'DOCTOR_SPECIALIZATION_CERTIFICATE'
}

export enum DocumentSubtype {
  Unspecified = 'UNSPECIFIED',
  Ine = 'INE',
  Passport = 'PASSPORT'
}

export type DocumentVersionType = {
  __typename?: 'DocumentVersionType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  selfDocument?: Maybe<DocumentType>;
  deleted?: Maybe<Scalars['DateTime']>;
  previousDocument?: Maybe<DocumentType>;
  version: Scalars['Int'];
  replacedAt: Scalars['DateTime'];
};

export enum DoctorLeadState {
  Created = 'CREATED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  DoctorCreated = 'DOCTOR_CREATED'
}

export enum DoctorLeadLostReason {
  UnrequiredSpecialty = 'UNREQUIRED_SPECIALTY',
  InvalidAgeRange = 'INVALID_AGE_RANGE',
  UnrequiredForCity = 'UNREQUIRED_FOR_CITY',
  NoSpeciality = 'NO_SPECIALITY',
  NotADoctor = 'NOT_A_DOCTOR',
  NoCertification = 'NO_CERTIFICATION',
  NoExperience = 'NO_EXPERIENCE',
  LimitedAcademicReqsForMeds = 'LIMITED_ACADEMIC_REQS_FOR_MEDS',
  LimitedAcademicReqsForSpecialists = 'LIMITED_ACADEMIC_REQS_FOR_SPECIALISTS',
  SufficientSimilarSpecialists = 'SUFFICIENT_SIMILAR_SPECIALISTS',
  DoesNotMeetAcademicProfile = 'DOES_NOT_MEET_ACADEMIC_PROFILE',
  DoctorNotInterested = 'DOCTOR_NOT_INTERESTED',
  DoctorNotReachable = 'DOCTOR_NOT_REACHABLE',
  HigherFee = 'HIGHER_FEE'
}

export type MedicalSpecializationModelType = {
  __typename?: 'MedicalSpecializationModelType';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isPrimaryCare: Scalars['Boolean'];
  inCatalog: Scalars['Boolean'];
  referencedBy?: Maybe<Array<MedicalSpecializationModelType>>;
  medicalSpecializationGroup?: Maybe<MedicalSpecializationGroupType>;
  type?: Maybe<SpecializationType>;
  assignedDoctorsAmount?: Maybe<Scalars['Int']>;
  assignedActiveDoctorsAmount?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  needsReference?: Maybe<Scalars['Boolean']>;
};

export type MedicalSpecializationGroupType = {
  __typename?: 'MedicalSpecializationGroupType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  medicalSpecializations?: Maybe<Array<MedicalSpecializationModelType>>;
};

export enum SpecializationType {
  Specialization = 'SPECIALIZATION',
  SubSpecialization = 'SUB_SPECIALIZATION',
  HighSpecialization = 'HIGH_SPECIALIZATION',
  Master = 'MASTER',
  Fellowship = 'FELLOWSHIP',
  Diplomat = 'DIPLOMAT',
  Bachelor = 'BACHELOR',
  Doctorate = 'DOCTORATE'
}

export type PageBasedAccessDoctorLeadType = {
  __typename?: 'PageBasedAccessDoctorLeadType';
  totalCount?: Maybe<Scalars['Int']>;
  doctorLeads?: Maybe<Array<DoctorLeadType>>;
};

export type VerifyDoctorLeadTokenType = {
  __typename?: 'VerifyDoctorLeadTokenType';
  ok: Scalars['Boolean'];
};

export type PublicDoctorLeadInfoType = {
  __typename?: 'PublicDoctorLeadInfoType';
  firstName: Scalars['String'];
  firstLastName: Scalars['String'];
  secondLastName?: Maybe<Scalars['String']>;
  waTelephone?: Maybe<Scalars['String']>;
};

export type MedicalHistoryType = {
  __typename?: 'MedicalHistoryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  member: MemberType;
  nonPathologicalHistory: NonPathologicalHistoryType;
  lastUpdatedBy?: Maybe<UserType>;
  authorizedDoctors: Array<DoctorProfileType>;
  lastHuliSyncSnapshot?: Maybe<Scalars['JSONString']>;
  familyPathologiesNotes?: Maybe<Scalars['String']>;
  familyPathologiesState?: Maybe<MedicalHistorySectionState>;
  pathologiesNotes?: Maybe<Scalars['String']>;
  pathologiesState?: Maybe<MedicalHistorySectionState>;
  hospitalizationsNotes?: Maybe<Scalars['String']>;
  hospitalizationsState?: Maybe<MedicalHistorySectionState>;
  surgeriesNotes?: Maybe<Scalars['String']>;
  surgeriesState?: Maybe<MedicalHistorySectionState>;
  allergiesNotes?: Maybe<Scalars['String']>;
  allergiesState?: Maybe<MedicalHistorySectionState>;
  medicinesNotes?: Maybe<Scalars['String']>;
  vaccinesNotes?: Maybe<Scalars['String']>;
  vaccinesState?: Maybe<MedicalHistorySectionState>;
  examsNotes?: Maybe<Scalars['String']>;
  malformationsNotes?: Maybe<Scalars['String']>;
  malformationsState?: Maybe<MedicalHistorySectionState>;
  mentalHealthPsychologyNotes?: Maybe<Scalars['String']>;
  mentalHealthPsychologyState?: Maybe<MedicalHistoryMentalHealthPsychologyState>;
  mentalHealthPsychiatryNotes?: Maybe<Scalars['String']>;
  mentalHealthPsychiatryState?: Maybe<MedicalHistoryMentalHealthPsychiatryState>;
  hasTherapy?: Maybe<Scalars['Boolean']>;
  therapyNotes?: Maybe<Scalars['String']>;
  healthSummary?: Maybe<Scalars['String']>;
  perinatalHistory?: Maybe<PerinatalHistoryType>;
  parentPerinatalHistory?: Maybe<ParentPerinatalHistoryType>;
  basicInfo?: Maybe<BasicInfoType>;
  obstetricGynecologicalHistory?: Maybe<ObstetricGynecologicalHistoryType>;
  allergies: Array<AllergyType>;
  exams: Array<ExamType>;
  familyPathologies: Array<FamilyPathologyType>;
  hospitalizations: Array<HospitalizationType>;
  malformations: Array<MalformationType>;
  medicines: Array<MedicineType>;
  pathologies: Array<PathologyType>;
  surgeries: Array<SurgeryType>;
  vaccines: Array<VaccineType>;
  mentalHealthDiagnoses: Array<MentalHealthDiagnosisType>;
  generalObservations: Array<GeneralObservationType>;
  medicalPrograms: Array<MedicalProgramType>;
  mentalHealthDiagnosesState?: Maybe<MedicalHistorySectionState>;
};

export type MemberType = {
  __typename?: 'MemberType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
  dateOfBirth?: Maybe<Scalars['Date']>;
  nickname: Scalars['String'];
  pronouns?: Maybe<Scalars['String']>;
  actualGender?: Maybe<Scalars['String']>;
  gender: MemberGender;
  assignedPrimaryCareDoctor?: Maybe<DoctorType>;
  antiquitySince?: Maybe<Scalars['Date']>;
  curp?: Maybe<Scalars['String']>;
  riskFactor?: Maybe<Scalars['Decimal']>;
  heightCm?: Maybe<Scalars['Int']>;
  weightKg?: Maybe<Scalars['Int']>;
  rfc?: Maybe<Scalars['String']>;
  representedByUser?: Maybe<UserType>;
  activeMemberSince?: Maybe<Scalars['Date']>;
  birthCountryCode: MemberBirthCountryCode;
  nationalityCountryCode: MemberNationalityCountryCode;
  birthCountry?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  huliPatientFileId?: Maybe<Scalars['String']>;
  maternityWaitingUntil?: Maybe<Scalars['Date']>;
  dentalWaitingUntil?: Maybe<Scalars['Date']>;
  complianceAlerts: Array<ComplianceAlertType>;
  emergencyContacts: Array<EmergencyContactType>;
  diseaseCases: Array<DiseaseCaseType>;
  user?: Maybe<UserType>;
  /** @deprecated Use representedByUser */
  createdByUser?: Maybe<UserType>;
  healthPlan?: Maybe<HealthPlanType>;
  residenceAddress?: Maybe<AddressType>;
  idDoc?: Maybe<DocumentType>;
  rfcDoc?: Maybe<DocumentType>;
  curpDoc?: Maybe<DocumentType>;
  idBacksideDoc?: Maybe<DocumentType>;
  latestApplicationItem?: Maybe<HealthPlanApplicationItemType>;
  memberPhotoUrl?: Maybe<Scalars['String']>;
  memberInformalPhotoUrl?: Maybe<Scalars['String']>;
  antiquityMonths?: Maybe<Scalars['Int']>;
  antiquitySinceSofia?: Maybe<Scalars['DateTime']>;
  antiquitySinceSofiaInMonths?: Maybe<Scalars['Int']>;
  antiquityWithTheMostRecentProductInMonths?: Maybe<Scalars['Int']>;
  antiquityWithTheMostRecentProduct?: Maybe<Scalars['DateTime']>;
  onDemandConsults?: Maybe<Array<Maybe<OnDemandConsultType>>>;
  welcomeConsultStatus?: Maybe<WelcomeConsultStatusCategory>;
  services?: Maybe<Array<Maybe<ServicesType>>>;
  chatToken?: Maybe<Scalars['String']>;
  idIsPassport?: Maybe<Scalars['Boolean']>;
  insuranceRemainingBalance?: Maybe<Scalars['Int']>;
  percentageInsuranceRemainingBalance?: Maybe<Scalars['String']>;
  briefBusinessBeneficiary?: Maybe<BriefBusinessBeneficiaryType>;
  displayName: Scalars['String'];
  isBusinessBeneficiary?: Maybe<Scalars['Boolean']>;
  yearlyPsychologyConsultsCount?: Maybe<Scalars['Int']>;
  comments?: Maybe<Array<Maybe<CommentType>>>;
  exclusions?: Maybe<Array<Maybe<MemberExclusionType>>>;
  hasEmergencyContacts?: Maybe<Scalars['Boolean']>;
  latestProfilingData?: Maybe<ProfilingDataType>;
  isRepresentative?: Maybe<Scalars['Boolean']>;
  collectiveValidationMissingInfo?: Maybe<Scalars['String']>;
  latestAntiquityRequest?: Maybe<AntiquityRequestType>;
  pendingActivationCampaigns?: Maybe<Array<ActivationCampaignType>>;
};

export enum MemberGender {
  U = 'U',
  M = 'M',
  F = 'F'
}

export type DoctorType = {
  __typename?: 'DoctorType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
  nickname: Scalars['String'];
  licenseNumber?: Maybe<Scalars['String']>;
  licenseInstitution?: Maybe<Scalars['String']>;
  availableOnDemand: Scalars['Boolean'];
  isAuthorizedForOnDemand?: Maybe<Scalars['Boolean']>;
  isAuthorizedForInPersonConsult?: Maybe<Scalars['Boolean']>;
  showDoctorInApp?: Maybe<Scalars['Boolean']>;
  directAppointment: Scalars['Boolean'];
  appointmentTelephone?: Maybe<Scalars['String']>;
  huliId?: Maybe<Scalars['Int']>;
  descriptor?: Maybe<Scalars['String']>;
  autoEmitEnabled: Scalars['Boolean'];
  isPrimaryCareDoctor?: Maybe<Scalars['Boolean']>;
  calendlyUrl?: Maybe<Scalars['String']>;
  welcomeCalendlyUrl?: Maybe<Scalars['String']>;
  onLeaveFrom?: Maybe<Scalars['Date']>;
  onLeaveUntil?: Maybe<Scalars['Date']>;
  leaveReason?: Maybe<DoctorLeaveReason>;
  allowPrimaryCareMembersAssignment: Scalars['Boolean'];
  specializationFare?: Maybe<SpecializationFareType>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  isPediatricsCareDoctor?: Maybe<Scalars['Boolean']>;
  isPediatric?: Maybe<Scalars['Boolean']>;
  residencyHospital?: Maybe<Scalars['String']>;
  curp?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  internalNotes?: Maybe<Scalars['String']>;
  doctoraliaUrl?: Maybe<Scalars['String']>;
  photo?: Maybe<DocumentType>;
  liabilityInsurance?: Maybe<DocumentType>;
  curriculumVitae?: Maybe<DocumentType>;
  deferInvoice?: Maybe<Scalars['Boolean']>;
  leadInfoState?: Maybe<DoctorLeadInfoState>;
  doctorLead?: Maybe<DoctorLeadType>;
  providerBranchOffice?: Maybe<ProviderBranchOfficeType>;
  onboardingModalWatched: Scalars['Boolean'];
  onboardingTourWatched: Scalars['Boolean'];
  introVideo?: Maybe<DocumentType>;
  extraInfo?: Maybe<Scalars['String']>;
  triviaInfo?: Maybe<Scalars['String']>;
  availabilityHours?: Maybe<Scalars['Int']>;
  user?: Maybe<UserType>;
  billingInfo?: Maybe<BillingInfoType>;
  officeAddress?: Maybe<AddressType>;
  secondaryOfficeAddress?: Maybe<AddressType>;
  officeAddresses?: Maybe<Array<AddressType>>;
  doctorSpecialistDetails?: Maybe<Array<DoctorSpecialistDetailType>>;
  availableDoctorSpecialistDetails?: Maybe<Array<DoctorSpecialistDetailType>>;
  prefixedFullName?: Maybe<Scalars['String']>;
  chatToken?: Maybe<Scalars['String']>;
  feedToken?: Maybe<Scalars['String']>;
  unverifiedEmail?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  isOnLeave?: Maybe<Scalars['Boolean']>;
  isAllowedForScheduledConsults?: Maybe<Scalars['Boolean']>;
  signatureImageUrl?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  onboardingTrainingStep?: Maybe<DoctorTrainingStep>;
  priorityLevel?: Maybe<DoctorPriorityLevel>;
  zapsignContractUrls?: Maybe<ZapsignContractUrlsType>;
  doctorGroups?: Maybe<Array<GroupName>>;
  invoiceIssuer?: Maybe<UserType>;
};

export enum DoctorLeaveReason {
  Maternity = 'MATERNITY',
  Paternity = 'PATERNITY',
  Vacationing = 'VACATIONING',
  Recovery = 'RECOVERY'
}

export type SpecializationFareType = {
  __typename?: 'SpecializationFareType';
  id: Scalars['ID'];
  name: Scalars['String'];
  medicalSpecialization?: Maybe<MedicalSpecializationModelType>;
  amountCents: Scalars['Int'];
};

export enum DoctorLeadInfoState {
  PersonalData = 'PERSONAL_DATA',
  AcademicInfo = 'ACADEMIC_INFO',
  PaymentData = 'PAYMENT_DATA',
  Addresses = 'ADDRESSES',
  DataCompleted = 'DATA_COMPLETED',
  Reviewed = 'REVIEWED',
  ContractSent = 'CONTRACT_SENT',
  Active = 'ACTIVE'
}

export type ProviderBranchOfficeType = {
  __typename?: 'ProviderBranchOfficeType';
  id: Scalars['ID'];
  name: Scalars['String'];
  telephones?: Maybe<Array<Scalars['String']>>;
  website?: Maybe<Scalars['String']>;
  deferInvoice: Scalars['Boolean'];
  tier?: Maybe<ProviderTier>;
  state?: Maybe<ProviderState>;
  csgCertified?: Maybe<Scalars['Boolean']>;
  showInApp: Scalars['Boolean'];
  isReferenceBranch: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  filesFolderUrl?: Maybe<Scalars['String']>;
  provider: ProvidersType;
  adminUser?: Maybe<UserType>;
  billingInfo?: Maybe<BillingInfoType>;
  payoutMethod?: Maybe<PaymentMethodType>;
  branchOfficeHospitalInfo?: Maybe<BranchOfficeHospitalInfoType>;
  contactInfos?: Maybe<Array<BranchOfficeContactInfoType>>;
};

export enum ProviderTier {
  Regular = 'REGULAR',
  Premium = 'PREMIUM'
}

export enum ProviderState {
  Pending = 'PENDING',
  Inactive = 'INACTIVE',
  Active = 'ACTIVE'
}

export type ProvidersType = {
  __typename?: 'ProvidersType';
  id: Scalars['ID'];
  name: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  category?: Maybe<ProviderCategoryType>;
  state?: Maybe<ProviderState>;
  contractCategory?: Maybe<ProviderContractCategory>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  notes?: Maybe<Scalars['String']>;
};

export type ProviderCategoryType = {
  __typename?: 'ProviderCategoryType';
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['Int'];
};

export enum ProviderContractCategory {
  Services = 'SERVICES',
  CashAgreement = 'CASH_AGREEMENT',
  CreditAgreement = 'CREDIT_AGREEMENT'
}

export type UserType = {
  __typename?: 'UserType';
  id: Scalars['ID'];
  nickname?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  isSuperuser: Scalars['Boolean'];
  deleted?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['UUID'];
  intercomContactId?: Maybe<Scalars['String']>;
  unverifiedEmail?: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  /** @deprecated Use user.is_doctor */
  doctorEnabled?: Maybe<Scalars['Boolean']>;
  memberEnabled: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  isOnboarded: Scalars['Boolean'];
  billingDay?: Maybe<Scalars['Int']>;
  appStage: UserAppStage;
  subscriptionState?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<UserNamePrefix>;
  productFamilySubscriptionIntent?: Maybe<ProductFamilies>;
  member?: Maybe<MemberType>;
  membersRepresented: Array<MemberType>;
  healthPlans?: Maybe<Array<Maybe<HealthPlanType>>>;
  businessAdmin?: Maybe<BusinessAdminType>;
  doctor?: Maybe<DoctorType>;
  doctorAssistant?: Maybe<DoctorAssistantType>;
  doctorFriend?: Maybe<DoctorFriendType>;
  providerBranchOffices: Array<ProviderBranchType>;
  firstName?: Maybe<Scalars['String']>;
  isDoctor: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  pushTokens?: Maybe<Array<Maybe<PushTokenType>>>;
  billingAddress?: Maybe<AddressType>;
  shippingAddress?: Maybe<AddressType>;
  /** @deprecated Use business_admin.business */
  adminBusiness?: Maybe<BusinessType>;
  latestHealthPlanApplication?: Maybe<HealthPlanApplicationType>;
  nextBillingDate?: Maybe<Scalars['Date']>;
  membersCreated?: Maybe<Array<Maybe<MemberType>>>;
  primaryBillingInfo?: Maybe<BillingInfoType>;
  payoutMethod?: Maybe<PaymentMethodType>;
  payinMethod?: Maybe<PaymentMethodType>;
  payinSpeiMethod?: Maybe<PaymentMethodType>;
  payinManualMethod?: Maybe<PaymentMethodType>;
  payoutManualMethod?: Maybe<PaymentMethodType>;
  payinMethods?: Maybe<Array<Maybe<PaymentMethodType>>>;
  hasManualPaymentMethods?: Maybe<Scalars['Boolean']>;
  referralCode?: Maybe<Scalars['String']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  s3ProfilePhotoKey?: Maybe<Scalars['String']>;
  firebaseCustomToken?: Maybe<Scalars['String']>;
  interestFreePaymentEnabled?: Maybe<Scalars['Boolean']>;
  payableInvoices?: Maybe<Array<Maybe<InvoiceType>>>;
  receivableInvoices?: Maybe<Array<Maybe<InvoiceType>>>;
  feedToken?: Maybe<Scalars['String']>;
  representedBeneficiaryHealthPlans?: Maybe<Array<Maybe<RepresentedBeneficiaryHealthPlanType>>>;
  ownedHealthPlans?: Maybe<Array<Maybe<OwnedHealthPlanType>>>;
  dependentMembers?: Maybe<Array<Maybe<MemberType>>>;
  isSponsor?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<GroupName>>;
  hasElectronicVouchersPendingSignature?: Maybe<Scalars['Boolean']>;
  canFreeUpEmailOrTelephone?: Maybe<Scalars['Boolean']>;
  hasDevice?: Maybe<Scalars['Int']>;
  hasConfirmedDevice?: Maybe<Scalars['Int']>;
  passwordUpdatedAt?: Maybe<Scalars['DateTime']>;
  badAttemptsCount?: Maybe<Scalars['Int']>;
};

export enum UserAppStage {
  Su = 'SU',
  Sb = 'SB',
  Co = 'CO',
  Wl = 'WL',
  Wb = 'WB',
  Wc = 'WC',
  Ac = 'AC',
  In = 'IN',
  Av = 'AV'
}

export enum UserNamePrefix {
  Dr = 'DR',
  Dra = 'DRA'
}

export enum ProductFamilies {
  Morado = 'morado',
  Mini = 'mini',
  Colectivo = 'colectivo'
}

export type HealthPlanType = {
  __typename?: 'HealthPlanType';
  id: Scalars['ID'];
  beneficiaryMember?: Maybe<MemberType>;
  relationship: HealthPlanRelationship;
  state: HealthPlanState;
  validSince?: Maybe<Scalars['DateTime']>;
  validThrough?: Maybe<Scalars['DateTime']>;
  premiumTotalCents?: Maybe<Scalars['Int']>;
  maternityAddon: Scalars['Boolean'];
  extendedHospitalsCoverage: Scalars['Boolean'];
  dentalAddon: Scalars['Boolean'];
  intlCoverageAddon: Scalars['Boolean'];
  dentalMembershipAddon?: Maybe<Scalars['Boolean']>;
  product?: Maybe<ProductType>;
  isOwnedByBusiness: Scalars['Boolean'];
  responsibleForServices?: Maybe<ResponsibleForServices>;
  uuid: Scalars['UUID'];
  cancelledAt?: Maybe<Scalars['DateTime']>;
  initialExclusions?: Maybe<Scalars['String']>;
  installmentTotalCents?: Maybe<Scalars['Int']>;
  subscriptionType: HealthPlanSubscriptionType;
  automaticRenew: Scalars['Boolean'];
  version: Scalars['Int'];
  owner?: Maybe<UserType>;
  amendments?: Maybe<Array<Maybe<HealthPlanAmendmentType>>>;
  invoices?: Maybe<Array<Maybe<InvoiceType>>>;
  gracePeriodEnd?: Maybe<Scalars['String']>;
  daysOnGracePeriod?: Maybe<Scalars['Int']>;
  isInGracePeriod?: Maybe<Scalars['Boolean']>;
  isCollective?: Maybe<Scalars['Boolean']>;
  intlCoverageValidSince?: Maybe<Scalars['DateTime']>;
  generalConditionsDoc?: Maybe<DocumentType>;
  brochureDoc?: Maybe<DocumentType>;
  coverDoc?: Maybe<DocumentType>;
  insuredAmountCents?: Maybe<Scalars['Int']>;
  currentCoinsuredAmountBalanceCents?: Maybe<Scalars['Int']>;
  currentInsuredAmountBalanceCents?: Maybe<Scalars['Int']>;
  healthPlanApplicationItem?: Maybe<HealthPlanApplicationItemType>;
  ownerFullName?: Maybe<Scalars['String']>;
  totalCents?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  premiumProvidersAddon?: Maybe<Scalars['Boolean']>;
  exclusionsPendingApproval?: Maybe<Scalars['String']>;
  productMetadata?: Maybe<Scalars['JSONString']>;
  hasActiveServices?: Maybe<Scalars['Boolean']>;
  hasOutstandingBalanceInSubscriptionOrAddons?: Maybe<Scalars['Boolean']>;
  beneficiaryRepresentativeHasInvoicesPendingPayment?: Maybe<Scalars['Boolean']>;
  coinsuranceFactor?: Maybe<Scalars['JSONString']>;
  copayInPersonConsultCents?: Maybe<Scalars['Int']>;
  medicalSpecializationGroupConsults?: Maybe<Array<MedicalSpecializationGroupProductConsultsType>>;
};

export enum HealthPlanRelationship {
  Wf = 'WF',
  Hs = 'HS',
  Dg = 'DG',
  Sl = 'SL',
  Sn = 'SN',
  Ft = 'FT',
  Mt = 'MT',
  Gp = 'GP',
  Sb = 'SB',
  Gc = 'GC',
  Np = 'NP',
  Ot = 'OT'
}

export enum HealthPlanState {
  Ac = 'AC',
  In = 'IN'
}

export type ProductType = {
  __typename?: 'ProductType';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: ProductCategory;
  productMetadataParameters?: Maybe<Scalars['JSONString']>;
  pricingModel?: Maybe<Scalars['String']>;
  config: Scalars['JSONString'];
  productFamily?: Maybe<ProductFamilies>;
  regulatoryRegistrationId?: Maybe<Scalars['String']>;
  userRightsAgencyRegistrationId?: Maybe<Scalars['String']>;
  state: ProductState;
  healthPlanTemplate?: Maybe<Scalars['String']>;
  questionnaire: Scalars['JSONString'];
  closedAt?: Maybe<Scalars['DateTime']>;
  copayInPersonConsultCents?: Maybe<Scalars['Int']>;
  pricingParameters?: Maybe<Scalars['JSONString']>;
};

export enum ProductCategory {
  In = 'IN',
  Collective = 'COLLECTIVE',
  Individuals = 'INDIVIDUALS',
  Maternity = 'MATERNITY',
  IntlCoverage = 'INTL_COVERAGE',
  DentalMembership = 'DENTAL_MEMBERSHIP',
  DentalAddon = 'DENTAL_ADDON'
}

export enum ProductState {
  Dr = 'DR',
  Av = 'AV',
  Cl = 'CL'
}

export enum ResponsibleForServices {
  Owner = 'OWNER',
  Beneficiary = 'BENEFICIARY'
}

export enum HealthPlanSubscriptionType {
  Mn = 'MN',
  Yr = 'YR',
  Qr = 'QR',
  Bn = 'BN'
}

export type HealthPlanAmendmentType = {
  __typename?: 'HealthPlanAmendmentType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  reviewedAt?: Maybe<Scalars['DateTime']>;
  generatedDoc?: Maybe<DocumentType>;
  remainingMonthsBeforeApplying?: Maybe<Scalars['Int']>;
  validSince?: Maybe<Scalars['DateTime']>;
  amendmentCategory: HealthPlanAmendmentAmendmentCategory;
  reviewResult: HealthPlanAmendmentReviewResult;
  amendmentDetails?: Maybe<Scalars['JSONString']>;
  amendmentExtras?: Maybe<Scalars['JSONString']>;
  reviewedByUser?: Maybe<UserType>;
  healthPlan?: Maybe<HealthPlanType>;
  previousHealthPlan?: Maybe<HealthPlanType>;
  attachmentDocuments?: Maybe<Array<Maybe<DocumentType>>>;
  ownerPrimaryBillingInfo?: Maybe<BillingInfoType>;
};

export enum HealthPlanAmendmentAmendmentCategory {
  Em = 'EM',
  Cn = 'CN',
  Db = 'DB',
  Md = 'MD',
  Pp = 'PP',
  Fr = 'FR',
  Bf = 'BF',
  Sa = 'SA',
  Bi = 'BI',
  Mt = 'MT',
  Dt = 'DT',
  Ic = 'IC',
  Mdt = 'MDT',
  Ac = 'AC',
  Cs = 'CS',
  As = 'AS',
  Cd = 'CD',
  Cm = 'CM',
  Cic = 'CIC',
  Cmd = 'CMD',
  Cpc = 'CPC',
  Cdc = 'CDC',
  Cgc = 'CGC',
  Ccc = 'CCC',
  Cac = 'CAC'
}

export enum HealthPlanAmendmentReviewResult {
  Pn = 'PN',
  Ap = 'AP',
  Dn = 'DN'
}

export type BillingInfoType = {
  __typename?: 'BillingInfoType';
  legalName: Scalars['String'];
  taxNumber: Scalars['String'];
  taxRegime?: Maybe<SatRegime>;
  id: Scalars['ID'];
  satUseType?: Maybe<BillingInfoSatUseType>;
  satProductServiceKey?: Maybe<BillingInfoSatProductServiceKey>;
  addressZipCode?: Maybe<Scalars['String']>;
  requiresElectronicVoucher: Scalars['Boolean'];
  taxCertificate?: Maybe<DocumentType>;
  address?: Maybe<AddressType>;
};

export enum SatRegime {
  LegalEntity = 'LEGAL_ENTITY',
  LegalEntityNonLucrative = 'LEGAL_ENTITY_NON_LUCRATIVE',
  GoodsAcquisition = 'GOODS_ACQUISITION',
  DeferredIncome = 'DEFERRED_INCOME',
  AgriculturalLivestockForestryAndFishingActivities = 'AGRICULTURAL_LIVESTOCK_FORESTRY_AND_FISHING_ACTIVITIES',
  OptionalForSocietyGroups = 'OPTIONAL_FOR_SOCIETY_GROUPS',
  Coordinated = 'COORDINATED',
  NaturalPersonSimplifiedTaxRegime = 'NATURAL_PERSON_SIMPLIFIED_TAX_REGIME',
  Hidrocarburates = 'HIDROCARBURATES',
  NaturalPersonSalariesAndSimilarsIncome = 'NATURAL_PERSON_SALARIES_AND_SIMILARS_INCOME',
  Lease = 'LEASE',
  OtherIncome = 'OTHER_INCOME',
  Consolidation = 'CONSOLIDATION',
  StockIncome = 'STOCK_INCOME',
  NaturalPerson = 'NATURAL_PERSON',
  InterestIncome = 'INTEREST_INCOME',
  PrizeIncome = 'PRIZE_INCOME',
  GenericEntity = 'GENERIC_ENTITY',
  NaturalPersonTaxIncorporation = 'NATURAL_PERSON_TAX_INCORPORATION',
  NaturalPersonWithTechPlatformIncome = 'NATURAL_PERSON_WITH_TECH_PLATFORM_INCOME',
  MultinationalBusinesses = 'MULTINATIONAL_BUSINESSES',
  StockIncomeTrading = 'STOCK_INCOME_TRADING',
  NaturalPersonForeignResidence = 'NATURAL_PERSON_FOREIGN_RESIDENCE'
}

export enum BillingInfoSatUseType {
  D07 = 'D07',
  D01 = 'D01',
  P01 = 'P01',
  G03 = 'G03',
  S01 = 'S01',
  Cp01 = 'CP01',
  Unk = 'UNK'
}

export enum BillingInfoSatProductServiceKey {
  A_42132200 = 'A_42132200',
  A_85121500 = 'A_85121500',
  A_85121501 = 'A_85121501',
  A_85121502 = 'A_85121502',
  A_85121503 = 'A_85121503',
  A_85121504 = 'A_85121504',
  A_85121600 = 'A_85121600',
  A_85121601 = 'A_85121601',
  A_85121602 = 'A_85121602',
  A_85121603 = 'A_85121603',
  A_85121604 = 'A_85121604',
  A_85121605 = 'A_85121605',
  A_85121606 = 'A_85121606',
  A_85121607 = 'A_85121607',
  A_85121608 = 'A_85121608',
  A_85121609 = 'A_85121609',
  A_85121610 = 'A_85121610',
  A_85121611 = 'A_85121611',
  A_85121612 = 'A_85121612',
  A_85121613 = 'A_85121613',
  A_85121614 = 'A_85121614',
  A_85121700 = 'A_85121700',
  A_85121701 = 'A_85121701',
  A_85121702 = 'A_85121702',
  A_85121703 = 'A_85121703',
  A_85121704 = 'A_85121704',
  A_85121705 = 'A_85121705',
  A_85121706 = 'A_85121706',
  A_85121800 = 'A_85121800',
  A_85121801 = 'A_85121801',
  A_85121802 = 'A_85121802',
  A_85121803 = 'A_85121803',
  A_85121804 = 'A_85121804',
  A_85121805 = 'A_85121805',
  A_85121806 = 'A_85121806',
  A_85121807 = 'A_85121807',
  A_85121808 = 'A_85121808',
  A_85121809 = 'A_85121809',
  A_85121810 = 'A_85121810',
  A_85121811 = 'A_85121811',
  A_85121900 = 'A_85121900',
  A_85121901 = 'A_85121901',
  A_85121902 = 'A_85121902',
  A_85122000 = 'A_85122000',
  A_85122001 = 'A_85122001',
  A_85122002 = 'A_85122002',
  A_85122003 = 'A_85122003',
  A_85122004 = 'A_85122004',
  A_85122005 = 'A_85122005',
  A_85122100 = 'A_85122100',
  A_85122101 = 'A_85122101',
  A_85122102 = 'A_85122102',
  A_85122103 = 'A_85122103',
  A_85122104 = 'A_85122104',
  A_85122105 = 'A_85122105',
  A_85122106 = 'A_85122106',
  A_85122107 = 'A_85122107',
  A_85122108 = 'A_85122108',
  A_85122109 = 'A_85122109',
  A_85122200 = 'A_85122200',
  A_85122201 = 'A_85122201',
  A_84131602 = 'A_84131602',
  A_84111506 = 'A_84111506',
  A_85101605 = 'A_85101605',
  A_85101601 = 'A_85101601',
  A_01010101 = 'A_01010101',
  A_85101501 = 'A_85101501',
  A_53131613 = 'A_53131613',
  A_85151600 = 'A_85151600',
  A_00000000 = 'A_00000000'
}

export type AddressType = {
  __typename?: 'AddressType';
  id: Scalars['ID'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  city: Scalars['String'];
  zipcode: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  stateName?: Maybe<Scalars['String']>;
  primary: Scalars['Boolean'];
  references?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  huliClinicId?: Maybe<Scalars['Int']>;
  googleMapsUrl?: Maybe<Scalars['String']>;
  temporaryVirtualOffice: Scalars['Boolean'];
  appointmentTelephone?: Maybe<Scalars['String']>;
  appointmentUrl?: Maybe<Scalars['String']>;
  doctor?: Maybe<DoctorType>;
  proofDoc?: Maybe<DocumentType>;
  telephoneCategory?: Maybe<TelephoneCategory>;
};

export enum TelephoneCategory {
  Unknown = 'UNKNOWN',
  Landline = 'LANDLINE',
  Mobile = 'MOBILE'
}

export type InvoiceType = {
  __typename?: 'InvoiceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  state?: Maybe<InvoiceState>;
  billingInfo: Scalars['JSONString'];
  dueAt?: Maybe<Scalars['Date']>;
  uuid: Scalars['UUID'];
  digitalTaxVoucherDoc?: Maybe<DocumentType>;
  items?: Maybe<Array<Maybe<InvoiceItemType>>>;
  recipient?: Maybe<UserType>;
  issuer?: Maybe<UserType>;
  hasPayInElectronicVoucher?: Maybe<Scalars['Boolean']>;
  totalAmountCents?: Maybe<Scalars['Int']>;
  sumTotalCents?: Maybe<Scalars['Int']>;
  sumVatCents?: Maybe<Scalars['Int']>;
  sumSubtotalCents?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<Maybe<TransactionType>>>;
  latestElectronicVoucher?: Maybe<ElectronicVoucherType>;
  issuedPaymentElectronicVouchers?: Maybe<Scalars['Int']>;
  paymentElectronicVouchers?: Maybe<Scalars['Int']>;
  successfulTransaction?: Maybe<TransactionType>;
  requiredElectronicVouchers?: Maybe<Array<Maybe<SatVoucherType>>>;
  signedSumTotalCents?: Maybe<Scalars['Int']>;
  successfulElectronicVoucherCount?: Maybe<Scalars['Int']>;
  expectedElectronicVouchersCount?: Maybe<Scalars['Int']>;
  servicesList?: Maybe<Array<Maybe<Scalars['String']>>>;
  canBePaid?: Maybe<Scalars['Boolean']>;
  allRelatedElectronicVouchersAreIssued?: Maybe<Scalars['Boolean']>;
  latestDispute?: Maybe<PaymentDisputeType>;
  isSubscriptionInvoice?: Maybe<Scalars['Boolean']>;
  isServiceInvoice?: Maybe<Scalars['Boolean']>;
  isAmendmentInvoice?: Maybe<Scalars['Boolean']>;
  isAutomaticPaymentServiceInvoice?: Maybe<Scalars['Boolean']>;
  isAutomaticPaymentAvailable?: Maybe<Scalars['Boolean']>;
  subscriptionHealthPlanApplication?: Maybe<HealthPlanApplicationType>;
  customerPaymentFlowType?: Maybe<CustomerPaymentFlowType>;
  scheduledPayoutEta?: Maybe<Scalars['DateTime']>;
  comments?: Maybe<Array<Maybe<CommentType>>>;
  latestCheckedDate?: Maybe<Scalars['DateTime']>;
  daysToDueDate?: Maybe<Scalars['Int']>;
  isPaymentWithinUsersPayoutMethodTier?: Maybe<Scalars['Boolean']>;
  userHasExceededPayoutTier?: Maybe<Scalars['Boolean']>;
  uploadedByDoctor?: Maybe<Scalars['Boolean']>;
};

export enum InvoiceState {
  Draft = 'DRAFT',
  NotPaid = 'NOT_PAID',
  PaymentRejected = 'PAYMENT_REJECTED',
  Refunded = 'REFUNDED',
  FundsWithdrawn = 'FUNDS_WITHDRAWN',
  Unpayable = 'UNPAYABLE',
  PaymentOrderSent = 'PAYMENT_ORDER_SENT',
  ProcessingPayment = 'PROCESSING_PAYMENT',
  InReview = 'IN_REVIEW',
  Paid = 'PAID',
  Applied = 'APPLIED',
  Cancelled = 'CANCELLED',
  Uncollectible = 'UNCOLLECTIBLE'
}

export type InvoiceItemType = {
  __typename?: 'InvoiceItemType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  objectId: Scalars['Int'];
  vatCents: Scalars['Int'];
  subtotalCents: Scalars['Int'];
  paymentFlow?: Maybe<InvoiceItemPaymentFlow>;
  description?: Maybe<Scalars['String']>;
  totalCents: Scalars['Int'];
  currency: InvoiceItemCurrency;
  installmentNumber: Scalars['Int'];
  state?: Maybe<InvoiceState>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  withRefund: Scalars['Boolean'];
  /** @deprecated Name is not representative, use subtotalCents */
  amountCents?: Maybe<Scalars['Int']>;
  contentType?: Maybe<ContentTypeType>;
  contentObject?: Maybe<ContentObjectType>;
  electronicVoucherStatus?: Maybe<Scalars['String']>;
  relatedIncome?: Maybe<IncomeType>;
  isService?: Maybe<Scalars['Boolean']>;
  healthPlanRelatedDetails?: Maybe<InvoiceItemHealthPlanRelatedDetails>;
  latestInvoice?: Maybe<InvoiceType>;
};

export enum InvoiceItemPaymentFlow {
  In = 'IN',
  Out = 'OUT'
}

export enum InvoiceItemCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export type ElectronicVoucherType = {
  __typename?: 'ElectronicVoucherType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  uuid?: Maybe<Scalars['String']>;
  voucherType: ElectronicVoucherVoucherType;
  paymentMethod?: Maybe<SatPaymentMethod>;
  xmlDocument?: Maybe<DocumentType>;
  pdfDocument?: Maybe<DocumentType>;
  recipient?: Maybe<UserType>;
  issuer?: Maybe<UserType>;
  electronicVoucherOperationState?: Maybe<ElectronicVoucherElectronicVoucherOperationState>;
  seriesValue?: Maybe<Scalars['String']>;
  folioNumber?: Maybe<Scalars['String']>;
  discountCents: Scalars['Int'];
  subtotalCents: Scalars['Int'];
  totalCents: Scalars['Int'];
  totalWithholdTaxesCents: Scalars['Int'];
  totalTransferredTaxesCents: Scalars['Int'];
  emissionZipcode?: Maybe<Scalars['String']>;
  cfdiVersion?: Maybe<Scalars['String']>;
  issuerTaxRegime?: Maybe<SatRegime>;
  issuerName?: Maybe<Scalars['String']>;
  recipientTaxRegime?: Maybe<SatRegime>;
  recipientUseCase?: Maybe<SatInvoiceUseType>;
  recipientName?: Maybe<Scalars['String']>;
  issuerTaxNumber?: Maybe<Scalars['String']>;
  recipientTaxNumber?: Maybe<Scalars['String']>;
  recipientAddressZipcode?: Maybe<Scalars['String']>;
  paymentType?: Maybe<SatPaymentForm>;
  electronicVoucherRelationType?: Maybe<SatElectronicVoucherRelationType>;
  secondaryElectronicVouchers?: Maybe<Array<Maybe<ElectronicVoucherType>>>;
  replacesElectronicVoucher?: Maybe<ElectronicVoucherType>;
  cancellationState?: Maybe<SatCancellationServiceCancellationState>;
  invoiceItems?: Maybe<Array<Maybe<InvoiceItemType>>>;
  payments?: Maybe<Array<Maybe<ElectronicVoucherPaymentType>>>;
  transactions?: Maybe<Array<Maybe<ElectronicVoucherTransactionType>>>;
  items?: Maybe<Array<Maybe<ElectronicVoucherItemsType>>>;
  taxes?: Maybe<Array<Maybe<ElectronicVoucherTaxesType>>>;
  latestTransaction?: Maybe<ElectronicVoucherTransactionType>;
  paymentsApplied?: Maybe<Array<Maybe<ElectronicVoucherPaymentItemType>>>;
  member?: Maybe<LimitedHealthPlanMemberType>;
  isSigned?: Maybe<Scalars['Boolean']>;
  relatedIncomeElectronicVouchers?: Maybe<Array<Maybe<ElectronicVoucherType>>>;
  description?: Maybe<Scalars['String']>;
  incomes?: Maybe<Array<Maybe<IncomeType>>>;
  xmlString?: Maybe<Scalars['String']>;
  recipientIsSofia?: Maybe<Scalars['Boolean']>;
  issuerOrRecipientName?: Maybe<Scalars['String']>;
};

export enum ElectronicVoucherVoucherType {
  I = 'I',
  E = 'E',
  P = 'P'
}

export enum SatPaymentMethod {
  PaymentInOneExhibition = 'PAYMENT_IN_ONE_EXHIBITION',
  PaymentInInstallmentsOrDeferred = 'PAYMENT_IN_INSTALLMENTS_OR_DEFERRED'
}

export enum ElectronicVoucherElectronicVoucherOperationState {
  Is = 'IS',
  Ca = 'CA',
  Ip = 'IP',
  Cf = 'CF',
  Ni = 'NI',
  Dr = 'DR',
  Nc = 'NC',
  Cb = 'CB',
  Rp = 'RP',
  Nd = 'ND'
}

export enum SatInvoiceUseType {
  MedicalExpensesInsurance = 'MEDICAL_EXPENSES_INSURANCE',
  MedicalDentistryAndHospitalServices = 'MEDICAL_DENTISTRY_AND_HOSPITAL_SERVICES',
  ToBeDefined = 'TO_BE_DEFINED',
  GeneralExpenses = 'GENERAL_EXPENSES',
  NoTaxApplicable = 'NO_TAX_APPLICABLE',
  PaymentsUseCase = 'PAYMENTS_USE_CASE',
  Unknown = 'UNKNOWN'
}

export enum SatPaymentForm {
  ToBeDefined = 'TO_BE_DEFINED',
  CreditCard = 'CREDIT_CARD',
  DebitCard = 'DEBIT_CARD',
  WireTransfer = 'WIRE_TRANSFER',
  PaymentGateway = 'PAYMENT_GATEWAY',
  DigitalCurrency = 'DIGITAL_CURRENCY',
  Cash = 'CASH',
  Check = 'CHECK',
  Compensation = 'COMPENSATION',
  Condonation = 'CONDONATION'
}

export enum SatElectronicVoucherRelationType {
  CreditNote = 'CREDIT_NOTE',
  DebitNote = 'DEBIT_NOTE',
  MerchandiseReturn = 'MERCHANDISE_RETURN',
  ElectronicVoucherSubstitution = 'ELECTRONIC_VOUCHER_SUBSTITUTION',
  PreviousInvoicedMerchandiseTransfer = 'PREVIOUS_INVOICED_MERCHANDISE_TRANSFER',
  ElectronicVoucherFromPreviousTransferredTax = 'ELECTRONIC_VOUCHER_FROM_PREVIOUS_TRANSFERRED_TAX',
  DownPayment = 'DOWN_PAYMENT',
  PpdGeneratedElectronicVoucher = 'PPD_GENERATED_ELECTRONIC_VOUCHER',
  PueGeneratedElectronicVoucher = 'PUE_GENERATED_ELECTRONIC_VOUCHER'
}

export enum SatCancellationServiceCancellationState {
  CancelledWithoutAcceptance = 'CANCELLED_WITHOUT_ACCEPTANCE',
  CancelledWithAcceptance = 'CANCELLED_WITH_ACCEPTANCE',
  DeadlineOverdue = 'DEADLINE_OVERDUE',
  InProgress = 'IN_PROGRESS',
  RequestDeclined = 'REQUEST_DECLINED'
}

export type ElectronicVoucherPaymentType = {
  __typename?: 'ElectronicVoucherPaymentType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  amountCents: Scalars['Int'];
  amountDecimals: Scalars['Decimal'];
  currency: ElectronicVoucherPaymentCurrency;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentType: ElectronicVoucherPaymentPaymentType;
  taxCode?: Maybe<ElectronicVoucherPaymentTaxCode>;
  items?: Maybe<Array<Maybe<ElectronicVoucherPaymentItemType>>>;
};

export enum ElectronicVoucherPaymentCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export enum ElectronicVoucherPaymentPaymentType {
  A_99 = 'A_99',
  A_04 = 'A_04',
  A_28 = 'A_28',
  A_03 = 'A_03',
  A_31 = 'A_31',
  A_06 = 'A_06',
  A_01 = 'A_01',
  A_02 = 'A_02',
  A_17 = 'A_17',
  A_15 = 'A_15'
}

export enum ElectronicVoucherPaymentTaxCode {
  A_01 = 'A_01',
  A_02 = 'A_02',
  A_03 = 'A_03',
  A_04 = 'A_04'
}

export type ElectronicVoucherPaymentItemType = {
  __typename?: 'ElectronicVoucherPaymentItemType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  electronicVoucherPayment?: Maybe<ElectronicVoucherPaymentType>;
  electronicVoucherPaid?: Maybe<ElectronicVoucherType>;
  electronicVoucherUuid?: Maybe<Scalars['String']>;
  seriesValue?: Maybe<ElectronicVoucherPaymentItemsSeriesValue>;
  folioNumber?: Maybe<Scalars['String']>;
  lastBalanceCents: Scalars['Int'];
  paidCents: Scalars['Int'];
  unpaidBalanceCents: Scalars['Int'];
  paymentMethod?: Maybe<ElectronicVoucherPaymentItemsPaymentMethod>;
  currency: ElectronicVoucherPaymentItemsCurrency;
  installmentNumber: Scalars['Int'];
  taxes?: Maybe<Array<Maybe<ElectronicVoucherTaxesType>>>;
};

export enum ElectronicVoucherPaymentItemsSeriesValue {
  Hpi = 'HPI',
  Hpp = 'HPP',
  Hpei = 'HPEI',
  Hpep = 'HPEP',
  Vc = 'VC',
  Ip = 'IP',
  Ami = 'AMI',
  Amo = 'AMO',
  SofiaOd = 'SOFIA_OD',
  SofiaIp = 'SOFIA_IP',
  Cso = 'CSO',
  Po = 'PO',
  Csi = 'CSI'
}

export enum ElectronicVoucherPaymentItemsPaymentMethod {
  Pue = 'PUE',
  Ppd = 'PPD'
}

export enum ElectronicVoucherPaymentItemsCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export type ElectronicVoucherTaxesType = {
  __typename?: 'ElectronicVoucherTaxesType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  contentType?: Maybe<ContentTypeType>;
  objectId?: Maybe<Scalars['Int']>;
  baseAmountCents?: Maybe<Scalars['Int']>;
  baseAmountDecimals?: Maybe<Scalars['Decimal']>;
  taxType?: Maybe<ElectronicVoucherTaxesTaxType>;
  taxId?: Maybe<ElectronicVoucherTaxesTaxId>;
  taxRateValue?: Maybe<Scalars['String']>;
  taxRateDecimals: Scalars['Decimal'];
  taxAmountCents: Scalars['Int'];
  taxAmountDecimals: Scalars['Decimal'];
  taxClass: ElectronicVoucherTaxesTaxClass;
};

export type ContentTypeType = {
  __typename?: 'ContentTypeType';
  id?: Maybe<Scalars['ID']>;
  appLabel?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  permissionSet: Array<PermissionType>;
  paymentdisputeSet: Array<PaymentDisputeType>;
  memberexclusionSet: Array<MemberExclusionType>;
  commentSet: Array<CommentType>;
  incomeSet: Array<IncomeType>;
  invoiceitemSet: Array<InvoiceItemType>;
  electronicvouchertaxesSet: Array<ElectronicVoucherTaxesType>;
  electronicvoucheritemsSet: Array<ElectronicVoucherItemsType>;
  serviceSet: Array<ServicesType>;
  name?: Maybe<Scalars['String']>;
};

export type PermissionType = {
  __typename?: 'PermissionType';
  id: Scalars['ID'];
  name: Scalars['String'];
  codename: Scalars['String'];
  appLabel?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
};

export type PaymentDisputeType = {
  __typename?: 'PaymentDisputeType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  transactionDisputed?: Maybe<LimitedTransactionType>;
  fundsWithdrawnTransaction?: Maybe<LimitedTransactionType>;
  state: PaymentDisputeState;
  amountCents: Scalars['Int'];
  contentType?: Maybe<ContentTypeType>;
  objectId?: Maybe<Scalars['Int']>;
  invoicePreviousState?: Maybe<InvoiceState>;
  refundType?: Maybe<PaymentDisputeRefundType>;
  capturedBy?: Maybe<UserType>;
  authorizedBy?: Maybe<UserType>;
  disputeDescription?: Maybe<Scalars['String']>;
  authorizationDescription?: Maybe<Scalars['String']>;
  rulingType?: Maybe<PaymentDisputeRulingTypes>;
};

export type LimitedTransactionType = {
  __typename?: 'LimitedTransactionType';
  createdAt: Scalars['DateTime'];
  currency: TransactionCurrency;
  amountCents: Scalars['Int'];
  transactionType: TransactionTransactionType;
  paymentGatewayResult?: Maybe<TransactionPaymentGatewayResult>;
  paymentInformation?: Maybe<PaymentInformationType>;
};

export enum TransactionCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export enum TransactionTransactionType {
  At = 'AT',
  Db = 'DB',
  Cr = 'CR',
  Rf = 'RF',
  Py = 'PY',
  Cn = 'CN',
  Ps = 'PS',
  Fw = 'FW',
  Si = 'SI',
  Cp = 'CP',
  Sr = 'SR',
  Bi = 'BI',
  Bd = 'BD',
  Po = 'PO',
  Pc = 'PC',
  Pr = 'PR',
  Uc = 'UC'
}

export enum TransactionPaymentGatewayResult {
  Ok = 'OK',
  Fl = 'FL'
}

export type PaymentInformationType = {
  __typename?: 'PaymentInformationType';
  paymentMethod?: Maybe<Scalars['String']>;
  cardDetails?: Maybe<CardDetails>;
  clabe?: Maybe<Scalars['String']>;
};

export type CardDetails = {
  __typename?: 'CardDetails';
  cardLastDigits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
};

export enum PaymentDisputeState {
  Nw = 'NW',
  Ev = 'EV',
  Wn = 'WN',
  Ls = 'LS',
  Cr = 'CR'
}

export enum PaymentDisputeRefundType {
  Tc = 'TC',
  Bg = 'BG',
  Pm = 'PM',
  Cm = 'CM',
  Cb = 'CB',
  Id = 'ID'
}

export enum PaymentDisputeRulingTypes {
  AcceptRefundSetNotPaid = 'ACCEPT_REFUND_SET_NOT_PAID',
  AcceptRefundSetUncollectible = 'ACCEPT_REFUND_SET_UNCOLLECTIBLE',
  AcceptRefundSetCancelled = 'ACCEPT_REFUND_SET_CANCELLED',
  RejectRefundSetPreviousState = 'REJECT_REFUND_SET_PREVIOUS_STATE',
  AcceptGatewayDisputeSetNotPaid = 'ACCEPT_GATEWAY_DISPUTE_SET_NOT_PAID',
  AcceptGatewayDisputeSetUncollectible = 'ACCEPT_GATEWAY_DISPUTE_SET_UNCOLLECTIBLE',
  AcceptGatewayDisputeSetCancelled = 'ACCEPT_GATEWAY_DISPUTE_SET_CANCELLED',
  AcceptUnpaidDisputeSetUncollectible = 'ACCEPT_UNPAID_DISPUTE_SET_UNCOLLECTIBLE',
  RejectUnpaidDisputeSetPreviousState = 'REJECT_UNPAID_DISPUTE_SET_PREVIOUS_STATE'
}

export type MemberExclusionType = {
  __typename?: 'MemberExclusionType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  member: MemberType;
  exclusion: ExclusionsCatalogType;
  state: MemberExclusionState;
  initialDate?: Maybe<Scalars['Date']>;
  contentType?: Maybe<ContentTypeType>;
  objectId?: Maybe<Scalars['Int']>;
  voidedMonths?: Maybe<MemberExclusionVoidedMonths>;
  coverageCapComments?: Maybe<Scalars['String']>;
  causedBy?: Maybe<MemberExclusionContentObjectType>;
};

export type ExclusionsCatalogType = {
  __typename?: 'ExclusionsCatalogType';
  id: Scalars['ID'];
  name: Scalars['String'];
  otherNames?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icds?: Maybe<Array<Maybe<KeyValueType>>>;
  blacklist: Scalars['Boolean'];
  medicalSpecializations: Array<MedicalSpecializationModelType>;
};

export type KeyValueType = {
  __typename?: 'KeyValueType';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum MemberExclusionState {
  Potential = 'POTENTIAL',
  UnderReview = 'UNDER_REVIEW',
  RuledOut = 'RULED_OUT',
  Confirmed = 'CONFIRMED',
  Exempted = 'EXEMPTED',
  Voided = 'VOIDED',
  WaitingPeriodCoverage = 'WAITING_PERIOD_COVERAGE',
  WaitingPeriodNoCoverage = 'WAITING_PERIOD_NO_COVERAGE'
}

export enum MemberExclusionVoidedMonths {
  Six = 'SIX',
  Twelve = 'TWELVE',
  Eighteen = 'EIGHTEEN',
  TwentyFour = 'TWENTY_FOUR',
  FortyEight = 'FORTY_EIGHT'
}

export type MemberExclusionContentObjectType = HealthPlanAmendmentType | HealthPlanApplicationItemType | ProfilingDataType | MedicalNoteType | ServicesType | OnDemandConsultType | InPersonConsultType | PharmacyOrderType | MedicalFeeType | HospitalServiceType | OtherServiceType | RehabilitationTherapyType | LabStudiesType | MedicinesType | MedicalEquipmentType | HomeNurseType | ProstheticsAndMechanicalEquipmentType | AmbulanceType | ExternalConsultType | ExternalVideoConsultType | UrgencyType | InPersonConsultByProviderType | LimitedIncludedConsultType;

export type HealthPlanApplicationItemType = {
  __typename?: 'HealthPlanApplicationItemType';
  id: Scalars['ID'];
  application: HealthPlanApplicationType;
  product?: Maybe<ProductType>;
  questionnaireAnswers?: Maybe<Scalars['JSONString']>;
  relationship: HealthPlanApplicationItemRelationship;
  initialExclusions?: Maybe<Scalars['String']>;
  waitingTimeSetAt?: Maybe<Scalars['DateTime']>;
  covidVaccinationStatus?: Maybe<CovidVaccinationStatus>;
  insuredAmountCents?: Maybe<InsuredAmountCents>;
  maternityAddon: Scalars['Boolean'];
  healthPlanValidSince?: Maybe<Scalars['DateTime']>;
  extendedHospitalsCoverage: Scalars['Boolean'];
  dentalAddon: Scalars['Boolean'];
  intlCoverageAddon: Scalars['Boolean'];
  dentalMembershipAddon?: Maybe<Scalars['Boolean']>;
  coinsuranceConfig?: Maybe<CoInsuranceConfigFactor>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  medicalAdditionalInformationNotes?: Maybe<Scalars['String']>;
  medicalAdditionalInformationReasons?: Maybe<Array<Maybe<MedicalAdditionalInformationReason>>>;
  waitingMemberToAcceptExclusions: Scalars['Boolean'];
  chosenForAutomaticSubscription?: Maybe<Scalars['Boolean']>;
  automaticSubscriptionEligibilityScore?: Maybe<Scalars['Float']>;
  automaticSubscriptionMedicalScore?: Maybe<Scalars['Float']>;
  automaticSubscriptionParameters?: Maybe<Scalars['JSONString']>;
  canSubscribeAutomatically?: Maybe<Scalars['Boolean']>;
  healthPlan?: Maybe<HealthPlanType>;
  questionnaireAnswersValidated?: Maybe<Scalars['JSONString']>;
  healthPlanQuote?: Maybe<HealthPlanQuoteType>;
  hasSubscriptionChecks?: Maybe<Scalars['Boolean']>;
  passesAllSubscriptionChecks?: Maybe<Scalars['Boolean']>;
  subscriptionChecks?: Maybe<Array<Maybe<HealthPlanApplicationItemCheckType>>>;
  comments?: Maybe<Array<Maybe<HealthPlanApplicationItemCommentType>>>;
  member?: Maybe<MemberType>;
  dependentDoc?: Maybe<DocumentType>;
  signatureDoc?: Maybe<DocumentType>;
  covidVaccineProofDoc?: Maybe<DocumentType>;
  rejectionReason?: Maybe<HealthPlanApplicationItemRejectionReason>;
  state: HealthPlanApplicationItemState;
  premiumTotalCents?: Maybe<Scalars['Int']>;
  installmentTotalCents?: Maybe<Scalars['Int']>;
  requiredDocuments?: Maybe<Array<Maybe<DocumentCategory>>>;
  missingDocuments?: Maybe<Array<Maybe<DocumentCategory>>>;
  medicalRecommendation?: Maybe<MedicalRecommendation>;
  medicalRecommendationComments?: Maybe<Array<Maybe<CommentType>>>;
};

export type HealthPlanApplicationType = {
  __typename?: 'HealthPlanApplicationType';
  id: Scalars['ID'];
  businessCode?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  waitingSince?: Maybe<Scalars['DateTime']>;
  acceptApplicationAt?: Maybe<Scalars['DateTime']>;
  educationAnswers?: Maybe<Scalars['JSONString']>;
  incentiveCode?: Maybe<IncentiveCodeType>;
  sponsorType: SponsorType;
  requestAntiquity?: Maybe<Scalars['Boolean']>;
  subscriptionInvoice?: Maybe<InvoiceType>;
  owner?: Maybe<UserType>;
  applicant?: Maybe<UserType>;
  subtotalCents?: Maybe<Scalars['Int']>;
  vatCents?: Maybe<Scalars['Int']>;
  grandTotalCents?: Maybe<Scalars['Int']>;
  /** @deprecated Use subscription_type in Quote */
  subscriptionType?: Maybe<Scalars['String']>;
  /** @deprecated Use currency in Quote */
  currency?: Maybe<Scalars['String']>;
  ownerRejectionReason?: Maybe<HealthPlanApplicationItemRejectionReason>;
  items?: Maybe<Array<Maybe<HealthPlanApplicationItemType>>>;
  state: HealthPlanApplicationState;
  subscriptionSource: HealthPlanApplicationSource;
  rejectionReason?: Maybe<InvoiceRejectionReason>;
  interestFreePaymentOptions?: Maybe<Scalars['JSONString']>;
  briefBusiness?: Maybe<BriefBusinessType>;
  businessMini?: Maybe<BusinessMiniType>;
  business?: Maybe<BusinessType>;
  ownedByBusiness?: Maybe<Scalars['Boolean']>;
  hasInterestFreePlan?: Maybe<Scalars['Boolean']>;
  invoicePaymentMethod?: Maybe<BriefPaymentMethodsType>;
  installmentSubtotalCents?: Maybe<Scalars['Int']>;
  installmentVatCents?: Maybe<Scalars['Int']>;
  installmentTotalCents?: Maybe<Scalars['Int']>;
  applicationDescription?: Maybe<Scalars['String']>;
  isOpsAttentionNeeded?: Maybe<Scalars['Boolean']>;
  isMedicalAttentionNeeded?: Maybe<Scalars['Boolean']>;
};

export type IncentiveCodeType = {
  __typename?: 'IncentiveCodeType';
  id: Scalars['ID'];
  referrerUser?: Maybe<UserType>;
  code: Scalars['String'];
  discountPercent?: Maybe<Scalars['Float']>;
  discountCents?: Maybe<Scalars['Int']>;
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
  isValid: Scalars['Boolean'];
  campaign?: Maybe<IncentiveCampaignsType>;
  bannerState: BannerState;
};

export type IncentiveCampaignsType = {
  __typename?: 'IncentiveCampaignsType';
  id: Scalars['ID'];
  internalName?: Maybe<Scalars['String']>;
  validFrom: Scalars['DateTime'];
  validTo: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  description: Scalars['String'];
};

export enum BannerState {
  Referred = 'REFERRED',
  Welcome = 'WELCOME',
  DoNotShow = 'DO_NOT_SHOW'
}

export enum SponsorType {
  Myself = 'MYSELF',
  Others = 'OTHERS',
  MyselfAndOthers = 'MYSELF_AND_OTHERS'
}

export enum HealthPlanApplicationItemRejectionReason {
  Documents = 'DOCUMENTS',
  Medical = 'MEDICAL',
  Kyc = 'KYC',
  OutOfArea = 'OUT_OF_AREA',
  Occupation = 'OCCUPATION',
  Covid = 'COVID',
  Obesity = 'OBESITY',
  Other = 'OTHER'
}

export enum HealthPlanApplicationState {
  Draft = 'DRAFT',
  ReviewNeeded = 'REVIEW_NEEDED',
  MoreInfoNeeded = 'MORE_INFO_NEEDED',
  PaymentFailed = 'PAYMENT_FAILED',
  ApprovedForPayment = 'APPROVED_FOR_PAYMENT',
  Rejected = 'REJECTED',
  Subscribed = 'SUBSCRIBED',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Error = 'ERROR',
  WaitingSubscriber = 'WAITING_SUBSCRIBER',
  WaitingConditions = 'WAITING_CONDITIONS',
  ToBeRenewed = 'TO_BE_RENEWED'
}

export enum HealthPlanApplicationSource {
  App = 'APP',
  Web = 'WEB',
  Hybrid = 'HYBRID',
  Renewal = 'RENEWAL',
  Reissue = 'REISSUE',
  Collective = 'COLLECTIVE',
  CollectiveAdditional = 'COLLECTIVE_ADDITIONAL',
  CollectiveMigration = 'COLLECTIVE_MIGRATION',
  CollectiveReissue = 'COLLECTIVE_REISSUE',
  CollectiveRenewal = 'COLLECTIVE_RENEWAL'
}

export enum InvoiceRejectionReason {
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  Other = 'OTHER'
}

export type BriefBusinessType = {
  __typename?: 'BriefBusinessType';
  id: Scalars['ID'];
  commonName?: Maybe<Scalars['String']>;
  maternityAddon: Scalars['Boolean'];
  subscriptionType: BusinessSubscriptionType;
  includeMaternity?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export enum BusinessSubscriptionType {
  Mn = 'MN',
  Yr = 'YR',
  Qr = 'QR',
  Bn = 'BN'
}

export type BusinessMiniType = {
  __typename?: 'BusinessMiniType';
  id: Scalars['ID'];
  commonName?: Maybe<Scalars['String']>;
  maternityAddon: Scalars['Boolean'];
  subscriptionType?: Maybe<SubscriptionType>;
  includeMaternity?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export enum SubscriptionType {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
  Quarterly = 'QUARTERLY',
  Biannually = 'BIANNUALLY'
}

export type BusinessType = {
  __typename?: 'BusinessType';
  id: Scalars['ID'];
  commonName?: Maybe<Scalars['String']>;
  maternityAddon: Scalars['Boolean'];
  legalName: Scalars['String'];
  personCategory?: Maybe<PersonCategory>;
  purpose?: Maybe<Scalars['String']>;
  referenceCode: Scalars['String'];
  nationalityCountryCode?: Maybe<CountryCode>;
  tradeRegistrationId?: Maybe<Scalars['String']>;
  incorporationDate?: Maybe<Scalars['Date']>;
  state?: Maybe<BusinessState>;
  taxRegistrationDoc?: Maybe<DocumentType>;
  digitalTaxSignatureProofDoc?: Maybe<DocumentType>;
  tradeRegistrationProofDoc?: Maybe<DocumentType>;
  registrationToCommercePublicRegistryDoc?: Maybe<DocumentType>;
  proofOfAddressDoc?: Maybe<DocumentType>;
  quoteBeneficiariesNumber: Scalars['Int'];
  complianceTier: BusinessComplianceTier;
  maternityAddonWaitingPeriodMonths?: Maybe<MaternityWaitingPeriodMonths>;
  isCollective: Scalars['Boolean'];
  subscriptionFileSent: Scalars['Boolean'];
  stakeholders: Array<BusinessStakeholderType>;
  createdAt: Scalars['DateTime'];
  /** @deprecated Use hubspot deal */
  pipedriveDeal?: Maybe<Scalars['Int']>;
  hubspotDeal?: Maybe<Scalars['BigIntScalar']>;
  administratorName?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  satUseType?: Maybe<BusinessSatUseType>;
  digitalSignatureSerialNumber?: Maybe<Scalars['String']>;
  contractDoc?: Maybe<DocumentType>;
  quoteDoc?: Maybe<DocumentType>;
  address?: Maybe<AddressType>;
  contractSentAt?: Maybe<Scalars['DateTime']>;
  contractSignedAt?: Maybe<Scalars['DateTime']>;
  originalQuoteAmountCents: Scalars['Int'];
  totalHealthplanAmountCents: Scalars['Int'];
  previousInsurance?: Maybe<Scalars['String']>;
  previousInsuranceValidSince?: Maybe<Scalars['DateTime']>;
  previousInsuranceValidThrough?: Maybe<Scalars['DateTime']>;
  draftCreatedByUser?: Maybe<UserType>;
  notionExceptionsUrl?: Maybe<Scalars['String']>;
  hasMedicalExclusions: Scalars['Boolean'];
  readyToMigrateDentalMembership?: Maybe<Scalars['Boolean']>;
  allowMultimember: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  ownerUser?: Maybe<UserType>;
  /** @deprecated Use owner_user */
  adminUser?: Maybe<UserType>;
  billingInfo?: Maybe<BillingInfoType>;
  payoutMethod?: Maybe<PaymentMethodsType>;
  payinSpeiMethod?: Maybe<PaymentMethodsType>;
  healthPlanApplications?: Maybe<Array<Maybe<HealthPlanApplicationType>>>;
  latestTransactionLogState?: Maybe<BusinessState>;
  subscriptionType?: Maybe<SubscriptionType>;
  kybChecks?: Maybe<Array<Maybe<BusinessCheckTypeType>>>;
  documentChecks?: Maybe<Array<Maybe<BusinessCheckTypeType>>>;
  contractCheck?: Maybe<BusinessCheckTypeType>;
  missingAttributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  missingChecks?: Maybe<Array<Maybe<Scalars['String']>>>;
  riskLevel?: Maybe<RiskLevel>;
  includeMaternity?: Maybe<Scalars['Boolean']>;
  businessGroupsConfigs?: Maybe<Array<BusinessGroupsConfigType>>;
  activeBusinessBeneficiaries?: Maybe<Scalars['Int']>;
  totalBusinessBeneficiaries?: Maybe<Scalars['Int']>;
  incentiveCode?: Maybe<Scalars['String']>;
  incentiveCodeObj?: Maybe<IncentiveCodeType>;
  activeOrPendingActivationConfigurations?: Maybe<Array<BusinessGroupsConfigType>>;
  legalRepresentativeStakeholder?: Maybe<BusinessStakeholderType>;
  adminSiteStepperState?: Maybe<AdminSiteStepperState>;
  registeredInAppBeneficiaries?: Maybe<Scalars['Int']>;
  notRegisteredInAppBeneficiaries?: Maybe<Scalars['Int']>;
  notRegisteredInAppBeneficiariesList?: Maybe<Array<BusinessEmployeeType>>;
  latestPendingRenewalProposal?: Maybe<BusinessPlansGeneralConfigProposalType>;
  latestLimitedPendingRenewalProposal?: Maybe<LimitedBusinessPlansGeneralConfigProposalType>;
  hasActiveCollectiveHealthPlan?: Maybe<Scalars['Boolean']>;
  dentalMembershipMigrationDate?: Maybe<Scalars['Date']>;
};

export enum PersonCategory {
  Legal = 'LEGAL',
  Natural = 'NATURAL'
}

export enum CountryCode {
  Other = 'OTHER',
  Mexico = 'MEXICO'
}

export enum BusinessState {
  Draft = 'DRAFT',
  PortalActivated = 'PORTAL_ACTIVATED',
  UploadingInfo = 'UPLOADING_INFO',
  ComplianceReview = 'COMPLIANCE_REVIEW',
  DocumentsReviewComplete = 'DOCUMENTS_REVIEW_COMPLETE',
  CorrectingInfo = 'CORRECTING_INFO',
  ContractSent = 'CONTRACT_SENT',
  ContractSigned = 'CONTRACT_SIGNED',
  ConfigsConfirmed = 'CONFIGS_CONFIRMED',
  Revoked = 'REVOKED',
  Approved = 'APPROVED',
  Activated = 'ACTIVATED'
}

export enum BusinessComplianceTier {
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3'
}

export enum MaternityWaitingPeriodMonths {
  NoWaitingTime = 'NO_WAITING_TIME',
  SixMonths = 'SIX_MONTHS'
}

export type BusinessStakeholderType = {
  __typename?: 'BusinessStakeholderType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  idDoc?: Maybe<DocumentType>;
  roleProofDoc?: Maybe<DocumentType>;
  foreignerDoc?: Maybe<DocumentType>;
  curpDoc?: Maybe<DocumentType>;
  extraRoleProofDocNeeded: Scalars['Boolean'];
  foreignerDocProofNeeded: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  curpJustifiableAbsence: Scalars['Boolean'];
  curpAbsenceJustification?: Maybe<Scalars['String']>;
  business?: Maybe<BusinessType>;
  curpIncludedInIdDoc: Scalars['Boolean'];
  stakeholderState?: Maybe<StakeHolderState>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  birthCountryCode?: Maybe<CountryCode>;
  birthCountry?: Maybe<Scalars['String']>;
  nationalityCountryCode?: Maybe<CountryCode>;
  businessControl: Scalars['Boolean'];
  legalRepresentative: Scalars['Boolean'];
  shareholder: Scalars['Boolean'];
  businessStakeholderChecks: Array<BusinessStakeholderCheckType>;
  riskLevel?: Maybe<RiskLevel>;
};

export enum StakeHolderState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type BusinessStakeholderCheckType = {
  __typename?: 'BusinessStakeholderCheckType';
  id: Scalars['ID'];
  checkType: BusinessStakeholderCheckCheckType;
  verifyDetails?: Maybe<Scalars['JSONString']>;
  state: BusinessStakeholderCheckState;
};

export enum BusinessStakeholderCheckCheckType {
  Np = 'NP',
  Nb = 'NB',
  Nr = 'NR',
  Rl = 'RL'
}

export enum BusinessStakeholderCheckState {
  Pn = 'PN',
  Ps = 'PS',
  Fl = 'FL',
  Mi = 'MI'
}

export enum RiskLevel {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
  Unknown = 'UNKNOWN'
}

export enum BusinessSatUseType {
  D07 = 'D07',
  D01 = 'D01',
  P01 = 'P01',
  G03 = 'G03',
  S01 = 'S01',
  Cp01 = 'CP01',
  Unk = 'UNK'
}

export type PaymentMethodsType = {
  __typename?: 'PaymentMethodsType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  isPrimary: Scalars['Boolean'];
  isPayoutPrimary: Scalars['Boolean'];
  paymentMethodType: PaymentMethodsPaymentMethodType;
  paymentFlowType?: Maybe<PaymentMethodsPaymentFlowType>;
  approved: Scalars['Boolean'];
  referenceName?: Maybe<Scalars['String']>;
  payoutTier?: Maybe<PayoutTiers>;
  sources?: Maybe<Array<Maybe<PaymentGatewaySourcesType>>>;
  clabe?: Maybe<Scalars['String']>;
  tiersAmountCentsEquivalences?: Maybe<Scalars['GenericScalar']>;
};

export enum PaymentMethodsPaymentMethodType {
  Cr = 'CR',
  Ox = 'OX',
  Se = 'SE',
  Pa = 'PA',
  Mn = 'MN'
}

export enum PaymentMethodsPaymentFlowType {
  In = 'IN',
  Out = 'OUT'
}

export enum PayoutTiers {
  TierOne = 'TIER_ONE',
  TierTwo = 'TIER_TWO',
  Unlimited = 'UNLIMITED'
}

export type PaymentGatewaySourcesType = {
  __typename?: 'PaymentGatewaySourcesType';
  id: Scalars['ID'];
  paymentGatewayType?: Maybe<PaymentGatewayType>;
  paymentGatewaySourceId?: Maybe<Scalars['String']>;
  paymentGatewaySourceReference?: Maybe<Scalars['JSONString']>;
};

export enum PaymentGatewayType {
  Stripe = 'STRIPE',
  StripePaymentMethod = 'STRIPE_PAYMENT_METHOD',
  Stp = 'STP',
  Offline = 'OFFLINE',
  StpPayIn = 'STP_PAY_IN',
  Paypal = 'PAYPAL',
  Manual = 'MANUAL',
  Uncollectible = 'UNCOLLECTIBLE'
}

export type BusinessCheckTypeType = {
  __typename?: 'BusinessCheckTypeType';
  id: Scalars['ID'];
  checkType: BusinessCheckCheckType;
  justifiableAbsence: Scalars['Boolean'];
  absenceJustification?: Maybe<Scalars['String']>;
  verifyDetails?: Maybe<Scalars['JSONString']>;
  state: BusinessCheckState;
  required: Scalars['Boolean'];
};

export enum BusinessCheckCheckType {
  Nb = 'NB',
  Nr = 'NR',
  Rl = 'RL',
  Ad = 'AD',
  Tr = 'TR',
  Ts = 'TS',
  Td = 'TD',
  Co = 'CO',
  Cs = 'CS'
}

export enum BusinessCheckState {
  Pn = 'PN',
  Ps = 'PS',
  Fl = 'FL',
  Mi = 'MI'
}

export type BusinessGroupsConfigType = {
  __typename?: 'BusinessGroupsConfigType';
  id: Scalars['ID'];
  allowMultimember: Scalars['Boolean'];
  dentalAddon: Scalars['Boolean'];
  intlCoverageAddon: Scalars['Boolean'];
  dentalMembershipAddon: Scalars['Boolean'];
  extendedHospitalsCoverage: Scalars['Boolean'];
  insuredAmountCentsCoverage?: Maybe<InsuredAmountCents>;
  name?: Maybe<Scalars['String']>;
  coinsuranceConfig?: Maybe<CoInsuranceConfigFactor>;
  business: BusinessType;
  default: Scalars['Boolean'];
  state?: Maybe<BusinessGroupsConfigState>;
  latestConfigProposal?: Maybe<BusinessPlansGroupConfigProposalType>;
  description?: Maybe<Scalars['String']>;
};

export enum InsuredAmountCents {
  M_1_5 = 'M_1_5',
  M_5_0 = 'M_5_0',
  M_15_0 = 'M_15_0'
}

export enum CoInsuranceConfigFactor {
  Ci_30_50 = 'CI_30_50',
  Ci_10_30 = 'CI_10_30',
  Ci_10_10 = 'CI_10_10'
}

export enum BusinessGroupsConfigState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  PendingActivation = 'PENDING_ACTIVATION'
}

export type BusinessPlansGroupConfigProposalType = {
  __typename?: 'BusinessPlansGroupConfigProposalType';
  id: Scalars['ID'];
  businessPlansGeneralConfigProposal: BusinessPlansGeneralConfigProposalType;
  businessGroupConfig?: Maybe<BriefBusinessGroupsConfigType>;
  default: Scalars['Boolean'];
  insuredAmountCentsCoverage?: Maybe<InsuredAmountCents>;
  coinsuranceConfig?: Maybe<CoInsuranceConfigFactor>;
  allowMultimember: Scalars['Boolean'];
  extendedHospitalsCoverage: Scalars['Boolean'];
  dentalAddon: Scalars['Boolean'];
  dentalMembershipAddon: Scalars['Boolean'];
  intlCoverageAddon: Scalars['Boolean'];
  name: Scalars['String'];
  differences?: Maybe<Scalars['JSONString']>;
};

export type BusinessPlansGeneralConfigProposalType = {
  __typename?: 'BusinessPlansGeneralConfigProposalType';
  id: Scalars['ID'];
  toBeAppliedOn?: Maybe<Scalars['DateTime']>;
  maternityAddon: Scalars['Boolean'];
  isCollective: Scalars['Boolean'];
  currentTotalInstallmentCents?: Maybe<Scalars['Int']>;
  incomingTotalInstallmentCents?: Maybe<Scalars['Int']>;
  incentiveCode?: Maybe<IncentiveCodeType>;
  reasonBehindProposalCreation?: Maybe<Scalars['String']>;
  cancellationsTracker?: Maybe<ChordTrackerType>;
  state?: Maybe<BusinessConfigProposalState>;
  issuanceType?: Maybe<BusinessConfigProposalIssuanceType>;
  maternityAddonWaitingPeriodMonths?: Maybe<MaternityWaitingPeriodMonths>;
  subscriptionType?: Maybe<SubscriptionType>;
  groups?: Maybe<Array<BusinessPlansGroupConfigProposalType>>;
  changes?: Maybe<BusinessPlansGeneralConfigProposalChangesQueryType>;
  beneficiaries?: Maybe<Array<Maybe<BusinessEmployeeType>>>;
  areHealthPlansCancelled?: Maybe<Scalars['Boolean']>;
  backstageUrl?: Maybe<Scalars['String']>;
  differences?: Maybe<Scalars['JSONString']>;
  application?: Maybe<HealthPlanApplicationType>;
  applicationItemsAreApproved?: Maybe<Scalars['Boolean']>;
  requestedBy?: Maybe<BusinessAdminType>;
};

export type ChordTrackerType = {
  __typename?: 'ChordTrackerType';
  id: Scalars['ID'];
  resultId: Scalars['String'];
  pending?: Maybe<Scalars['Boolean']>;
  success?: Maybe<Scalars['Boolean']>;
  failure?: Maybe<Scalars['Boolean']>;
  hasResultTask?: Maybe<Scalars['Boolean']>;
  status?: Maybe<CeleryTaskStatusType>;
};

export enum CeleryTaskStatusType {
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Failure = 'FAILURE'
}

export enum BusinessConfigProposalState {
  Draft = 'DRAFT',
  Negotiating = 'NEGOTIATING',
  Approved = 'APPROVED',
  CancellingHealthPlans = 'CANCELLING_HEALTH_PLANS',
  HealthPlansCancelled = 'HEALTH_PLANS_CANCELLED',
  Activated = 'ACTIVATED',
  CreatingApplications = 'CREATING_APPLICATIONS',
  CreatingApplicationsFailed = 'CREATING_APPLICATIONS_FAILED',
  ApplicationsCreated = 'APPLICATIONS_CREATED',
  PartiallyApplied = 'PARTIALLY_APPLIED',
  FullyApplied = 'FULLY_APPLIED',
  Rejected = 'REJECTED'
}

export enum BusinessConfigProposalIssuanceType {
  Initial = 'INITIAL',
  Renewal = 'RENEWAL',
  GlobalConfigurationChanges = 'GLOBAL_CONFIGURATION_CHANGES',
  MaternityActivation = 'MATERNITY_ACTIVATION',
  MaternityCancellation = 'MATERNITY_CANCELLATION',
  MaternityWaitingPeriodUpdate = 'MATERNITY_WAITING_PERIOD_UPDATE',
  SingleConfigurationChanges = 'SINGLE_CONFIGURATION_CHANGES',
  GroupChanges = 'GROUP_CHANGES',
  MigrationToCollective = 'MIGRATION_TO_COLLECTIVE',
  MigrationToBundle = 'MIGRATION_TO_BUNDLE',
  Reactivation = 'REACTIVATION'
}

export type BusinessPlansGeneralConfigProposalChangesQueryType = {
  __typename?: 'BusinessPlansGeneralConfigProposalChangesQueryType';
  allowMultimember?: Maybe<Scalars['Boolean']>;
  coinsuranceConfig?: Maybe<CoInsuranceConfigFactor>;
  dentalAddon?: Maybe<Scalars['Boolean']>;
  dentalMembershipAddon?: Maybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: Maybe<Scalars['Boolean']>;
  insuredAmountCentsCoverage?: Maybe<InsuredAmountCents>;
  intlCoverageAddon?: Maybe<Scalars['Boolean']>;
  maternityAddon?: Maybe<Scalars['Boolean']>;
  maternityAddonWaitingPeriodMonths?: Maybe<MaternityWaitingPeriodMonths>;
  subscriptionType?: Maybe<SubscriptionType>;
  warnings?: Maybe<Array<Maybe<ConfigModificationsWarning>>>;
};

export enum ConfigModificationsWarning {
  NoPremiumRefund = 'NO_PREMIUM_REFUND'
}

export type BusinessEmployeeType = {
  __typename?: 'BusinessEmployeeType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  beneficiaryCategory?: Maybe<BusinessBeneficiaryCategory>;
  state?: Maybe<BusinessBeneficiaryState>;
  createdAt: Scalars['DateTime'];
  business: BusinessType;
  activationDate?: Maybe<Scalars['Date']>;
  invitationDate?: Maybe<Scalars['Date']>;
  invited?: Maybe<Scalars['Boolean']>;
  employee?: Maybe<BusinessEmployeeType>;
  member?: Maybe<LimitedBusinessMemberType>;
  businessGroupConfig?: Maybe<BusinessGroupsConfigType>;
  curp?: Maybe<Scalars['String']>;
  relationship?: Maybe<CollectiveRelationshipType>;
  latestHealthPlanApplication?: Maybe<HealthPlanApplicationType>;
  familyMembers?: Maybe<Array<BusinessEmployeeType>>;
  healthPlan?: Maybe<BusinessHealthPlanType>;
  healthPlanList?: Maybe<Array<Maybe<BusinessHealthPlanType>>>;
};

export enum BusinessBeneficiaryCategory {
  Employee = 'EMPLOYEE',
  FamilyMember = 'FAMILY_MEMBER'
}

export enum BusinessBeneficiaryState {
  Active = 'ACTIVE',
  PendingActivation = 'PENDING_ACTIVATION',
  Inactive = 'INACTIVE',
  Rejected = 'REJECTED',
  Deactivated = 'DEACTIVATED'
}

export type LimitedBusinessMemberType = {
  __typename?: 'LimitedBusinessMemberType';
  id: Scalars['ID'];
  dateOfBirth?: Maybe<Scalars['Date']>;
  gender: MemberGender;
  antiquitySince?: Maybe<Scalars['Date']>;
  curp?: Maybe<Scalars['String']>;
  isRegisteredInApp?: Maybe<Scalars['Boolean']>;
};

export enum CollectiveRelationshipType {
  Wife = 'WIFE',
  Husband = 'HUSBAND',
  Daughter = 'DAUGHTER',
  Son = 'SON',
  Father = 'FATHER',
  Mother = 'MOTHER'
}

export type BusinessHealthPlanType = {
  __typename?: 'BusinessHealthPlanType';
  id: Scalars['ID'];
  state: HealthPlanState;
  validSince?: Maybe<Scalars['DateTime']>;
  validThrough?: Maybe<Scalars['DateTime']>;
  premiumTotalCents?: Maybe<Scalars['Int']>;
  maternityAddon: Scalars['Boolean'];
  maternityAddonWaitingPeriodMonths?: Maybe<MaternityWaitingPeriodMonths>;
  extendedHospitalsCoverage: Scalars['Boolean'];
  dentalAddon: Scalars['Boolean'];
  intlCoverageAddon: Scalars['Boolean'];
  dentalMembershipAddon?: Maybe<Scalars['Boolean']>;
  insuredAmountCents?: Maybe<Scalars['Int']>;
  owner?: Maybe<UserBusinessLiteType>;
};

export type UserBusinessLiteType = {
  __typename?: 'UserBusinessLiteType';
  id: Scalars['ID'];
  nickname?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
};

export type BusinessAdminType = {
  __typename?: 'BusinessAdminType';
  id: Scalars['ID'];
  business: BusinessType;
  user?: Maybe<UserType>;
  role?: Maybe<BusinessAdminRole>;
};

export enum BusinessAdminRole {
  Owner = 'OWNER',
  Admin = 'ADMIN',
  FinanceUser = 'FINANCE_USER'
}

export type BriefBusinessGroupsConfigType = {
  __typename?: 'BriefBusinessGroupsConfigType';
  id: Scalars['ID'];
  allowMultimember: Scalars['Boolean'];
  dentalAddon: Scalars['Boolean'];
  intlCoverageAddon: Scalars['Boolean'];
  dentalMembershipAddon?: Maybe<Scalars['Boolean']>;
  extendedHospitalsCoverage: Scalars['Boolean'];
  insuredAmountCentsCoverage?: Maybe<InsuredAmountCents>;
  name?: Maybe<Scalars['String']>;
  coinsuranceConfig?: Maybe<CoInsuranceConfigFactor>;
  description?: Maybe<Scalars['String']>;
};

export enum AdminSiteStepperState {
  Unknown = 'UNKNOWN',
  Draft = 'DRAFT',
  ComplianceReview = 'COMPLIANCE_REVIEW',
  CorrectingInfo = 'CORRECTING_INFO',
  ContractSent = 'CONTRACT_SENT',
  ContractSigned = 'CONTRACT_SIGNED',
  PaymentMethodConfirmed = 'PAYMENT_METHOD_CONFIRMED',
  ConfigsConfirmed = 'CONFIGS_CONFIRMED',
  PendingOnboarding = 'PENDING_ONBOARDING',
  Finished = 'FINISHED'
}

export type LimitedBusinessPlansGeneralConfigProposalType = {
  __typename?: 'LimitedBusinessPlansGeneralConfigProposalType';
  id: Scalars['ID'];
  toBeAppliedOn?: Maybe<Scalars['DateTime']>;
  maternityAddon: Scalars['Boolean'];
  isCollective: Scalars['Boolean'];
  currentTotalInstallmentCents?: Maybe<Scalars['Int']>;
  incomingTotalInstallmentCents?: Maybe<Scalars['Int']>;
  state?: Maybe<BusinessConfigProposalState>;
  maternityAddonWaitingPeriodMonths?: Maybe<MaternityWaitingPeriodMonths>;
  subscriptionType?: Maybe<SubscriptionType>;
  groups?: Maybe<Array<BusinessPlansGroupConfigProposalType>>;
  firstPaymentDateOnceApplied?: Maybe<Scalars['Date']>;
};

export type BriefPaymentMethodsType = {
  __typename?: 'BriefPaymentMethodsType';
  id: Scalars['ID'];
  paymentMethodType: PaymentMethodsPaymentMethodType;
};

export enum HealthPlanApplicationItemRelationship {
  Wf = 'WF',
  Hs = 'HS',
  Dg = 'DG',
  Sl = 'SL',
  Sn = 'SN',
  Ft = 'FT',
  Mt = 'MT',
  Gp = 'GP',
  Sb = 'SB',
  Gc = 'GC',
  Np = 'NP',
  Ot = 'OT'
}

export enum CovidVaccinationStatus {
  PartiallyVaccinated = 'PARTIALLY_VACCINATED',
  FullyVaccinated = 'FULLY_VACCINATED',
  VaccinatedWithProof = 'VACCINATED_WITH_PROOF',
  NotVaccinated = 'NOT_VACCINATED',
  NoInformationAvailable = 'NO_INFORMATION_AVAILABLE',
  WaitingToShareProof = 'WAITING_TO_SHARE_PROOF'
}

export enum MedicalAdditionalInformationReason {
  LabStudies = 'LAB_STUDIES',
  MedicalRecords = 'MEDICAL_RECORDS',
  Diagnostic = 'DIAGNOSTIC'
}

export type HealthPlanQuoteType = {
  __typename?: 'HealthPlanQuoteType';
  id: Scalars['ID'];
  expiresAt: Scalars['DateTime'];
  currency: HealthPlanQuoteCurrency;
  subscriptionType: HealthPlanQuoteSubscriptionType;
  premiumMaternityCents: Scalars['Int'];
  premiumDentalCents: Scalars['Int'];
  premiumNetBaseRgaCents: Scalars['Int'];
  installmentSubtotalCents?: Maybe<Scalars['Int']>;
  installmentVatCents?: Maybe<Scalars['Int']>;
  installmentTotalCents?: Maybe<Scalars['Int']>;
  subscriptionTypeDiscountCents?: Maybe<Scalars['Int']>;
  requestingUser?: Maybe<UserType>;
  member?: Maybe<MemberType>;
  productParametersValues?: Maybe<Scalars['JSONString']>;
  subtotalCents?: Maybe<Scalars['Int']>;
  discountAmountCents?: Maybe<Scalars['Int']>;
  vatCents?: Maybe<Scalars['Int']>;
  grandTotalCents?: Maybe<Scalars['Int']>;
  installmentTotalCentsWithoutIncentiveCode?: Maybe<Scalars['Int']>;
};

export enum HealthPlanQuoteCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export enum HealthPlanQuoteSubscriptionType {
  Mn = 'MN',
  Yr = 'YR',
  Qr = 'QR',
  Bn = 'BN'
}

export type HealthPlanApplicationItemCheckType = {
  __typename?: 'HealthPlanApplicationItemCheckType';
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  checkType: HealthPlanApplicationItemCheckCheckType;
  verifyDetails?: Maybe<Scalars['JSONString']>;
  comments?: Maybe<Scalars['String']>;
  state: HealthPlanApplicationItemCheckState;
  checkDoc?: Maybe<DocumentType>;
  passedEvidenceDoc?: Maybe<DocumentType>;
  validatedByUser?: Maybe<UserType>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export enum HealthPlanApplicationItemCheckCheckType {
  Dp = 'DP',
  Id = 'ID',
  Rf = 'RF',
  Cr = 'CR',
  Sl = 'SL',
  Ad = 'AD',
  Np = 'NP',
  Nb = 'NB',
  Nr = 'NR',
  Sw = 'SW',
  Md = 'MD',
  Ot = 'OT',
  Oc = 'OC',
  Rl = 'RL',
  Ti = 'TI'
}

export enum HealthPlanApplicationItemCheckState {
  Pn = 'PN',
  Ps = 'PS',
  Fl = 'FL',
  Mi = 'MI'
}

export type HealthPlanApplicationItemCommentType = {
  __typename?: 'HealthPlanApplicationItemCommentType';
  id: Scalars['ID'];
  comment: Scalars['String'];
  author?: Maybe<UserType>;
};

export enum HealthPlanApplicationItemState {
  Draft = 'DRAFT',
  ReviewNeeded = 'REVIEW_NEEDED',
  MoreInfoNeeded = 'MORE_INFO_NEEDED',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  WaitingConditions = 'WAITING_CONDITIONS',
  ToBeRenewed = 'TO_BE_RENEWED'
}

export enum MedicalRecommendation {
  NoExclusions = 'NO_EXCLUSIONS',
  Rejection = 'REJECTION',
  EvaluationRequired = 'EVALUATION_REQUIRED',
  DefinitiveExclusions = 'DEFINITIVE_EXCLUSIONS',
  AutomaticSubscription = 'AUTOMATIC_SUBSCRIPTION'
}

export type CommentType = {
  __typename?: 'CommentType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  objectId: Scalars['Int'];
  contentType: ContentTypeType;
  text: Scalars['String'];
  user?: Maybe<UserType>;
  replyTo?: Maybe<CommentType>;
  replies: Array<CommentType>;
};

export type ProfilingDataType = {
  __typename?: 'ProfilingDataType';
  id: Scalars['ID'];
  state?: Maybe<ProfilingDataState>;
  member?: Maybe<MemberType>;
  hospitalizations?: Maybe<Array<Maybe<ProfilingHospitalizationModelType>>>;
  chronicDiseases?: Maybe<Array<Maybe<ProfilingChronicDiseaseType>>>;
  mentalHealthDiagnoses?: Maybe<Array<Maybe<ProfilingMentalHealthDiagnosisType>>>;
  medicines?: Maybe<Array<Maybe<ProfilingMedicineType>>>;
  medicalInfo?: Maybe<ProfilingMedicalInfoType>;
  allergies?: Maybe<Array<Maybe<ProfilingAllergyType>>>;
  cancers?: Maybe<Array<Maybe<ProfilingCancerType>>>;
  gynecologyDiseases?: Maybe<Array<Maybe<ProfilingGynecologyDiseaseType>>>;
  checks?: Maybe<Array<Maybe<ProfilingDataCheckType>>>;
  medicalComments?: Maybe<Array<Maybe<CommentType>>>;
  isProfilingOpsAttentionNeeded?: Maybe<Scalars['Boolean']>;
  isProfilingMedicalAttentionNeeded?: Maybe<Scalars['Boolean']>;
};

export enum ProfilingDataState {
  Draft = 'DRAFT',
  ReviewNeeded = 'REVIEW_NEEDED',
  MoreInfoNeeded = 'MORE_INFO_NEEDED',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED'
}

export type ProfilingHospitalizationModelType = {
  __typename?: 'ProfilingHospitalizationModelType';
  id: Scalars['ID'];
  hospitalizationType?: Maybe<ProfilingHospitalizationType>;
  reason: Scalars['String'];
  date?: Maybe<Scalars['Date']>;
};

export enum ProfilingHospitalizationType {
  Urgency = 'URGENCY',
  SurgeryOrProcedure = 'SURGERY_OR_PROCEDURE',
  Other = 'OTHER'
}

export type ProfilingChronicDiseaseType = {
  __typename?: 'ProfilingChronicDiseaseType';
  id: Scalars['ID'];
  reason: Scalars['String'];
  date?: Maybe<Scalars['Date']>;
};

export type ProfilingMentalHealthDiagnosisType = {
  __typename?: 'ProfilingMentalHealthDiagnosisType';
  id: Scalars['ID'];
  diagnosis?: Maybe<ProfilingMentalDiagnosis>;
  treatment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export enum ProfilingMentalDiagnosis {
  Anxiety = 'ANXIETY',
  Depression = 'DEPRESSION'
}

export type ProfilingMedicineType = {
  __typename?: 'ProfilingMedicineType';
  id: Scalars['ID'];
  name: Scalars['String'];
  relatedDisease?: Maybe<Scalars['String']>;
};

export type ProfilingMedicalInfoType = {
  __typename?: 'ProfilingMedicalInfoType';
  id: Scalars['ID'];
  isPregnant: Scalars['Boolean'];
  otherAilments?: Maybe<Array<Maybe<ProfilingAilment>>>;
};

export enum ProfilingAilment {
  Myopia = 'MYOPIA',
  Astigmatism = 'ASTIGMATISM',
  Acne = 'ACNE',
  Migraine = 'MIGRAINE',
  Asthma = 'ASTHMA',
  Gastritis = 'GASTRITIS',
  Reflux = 'REFLUX'
}

export type ProfilingAllergyType = {
  __typename?: 'ProfilingAllergyType';
  id: Scalars['ID'];
  allergen: Scalars['String'];
  reaction?: Maybe<Scalars['String']>;
};

export type ProfilingCancerType = {
  __typename?: 'ProfilingCancerType';
  id: Scalars['ID'];
  reason: Scalars['String'];
  date?: Maybe<Scalars['Date']>;
};

export type ProfilingGynecologyDiseaseType = {
  __typename?: 'ProfilingGynecologyDiseaseType';
  id: Scalars['ID'];
  name: Scalars['String'];
  treatment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type ProfilingDataCheckType = {
  __typename?: 'ProfilingDataCheckType';
  id: Scalars['ID'];
  checkType: ProfilingDataCheckCheckType;
  comments?: Maybe<Scalars['String']>;
  state: ProfilingDataCheckState;
  validatedByUser?: Maybe<UserType>;
};

export enum ProfilingDataCheckCheckType {
  DependencyProof = 'DEPENDENCY_PROOF',
  Id = 'ID',
  Selfie = 'SELFIE',
  Signature = 'SIGNATURE',
  Medical = 'MEDICAL'
}

export enum ProfilingDataCheckState {
  Pn = 'PN',
  Ps = 'PS',
  Fl = 'FL',
  Mi = 'MI'
}

export type MedicalNoteType = {
  __typename?: 'MedicalNoteType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['UUID'];
  member: MemberType;
  doctor: DoctorType;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  service?: Maybe<LimitedServicesType>;
  huliEmrEntry?: Maybe<HuliEmrEntryType>;
  occurrenceTime: Scalars['DateTime'];
  suffering?: Maybe<Scalars['String']>;
  motive?: Maybe<Scalars['String']>;
  noteType?: Maybe<NoteType>;
  state: NoteState;
  tasks?: Maybe<Scalars['JSONString']>;
  shouldBeFreeOfCharge: Scalars['Boolean'];
  cancellationReason?: Maybe<ConsultCancellationReason>;
  cancellationReasonDetails?: Maybe<Scalars['String']>;
  isAudited: Scalars['Boolean'];
  auditedByUser?: Maybe<UserType>;
  selectedForAudit?: Maybe<Scalars['Boolean']>;
  chatEnabledDays?: Maybe<Scalars['Int']>;
  chatEnabledIndefinitely?: Maybe<Scalars['Boolean']>;
  dischargeInfo?: Maybe<DischargeInfoType>;
  noteBasicInfo: NoteBasicInfoType;
  evaluation: EvaluationType;
  physicalExam: PhysicalExamType;
  physicalActivity?: Maybe<PhysicalActivityType>;
  obstetricGynecologicalInfo?: Maybe<ObstetricGynecologicalInfoType>;
  interrogations: Array<InterrogationType>;
  performedProcedures: Array<PerformedProcedureType>;
  explorations: Array<ExplorationType>;
  procedures: Array<ProcedureType>;
  addenda: Array<AddendumType>;
  exams: Array<ExamType>;
  scheduledProcedures: Array<ScheduledProcedureType>;
  prescriptions?: Maybe<Array<PrescriptionType>>;
  huliId?: Maybe<Scalars['ID']>;
  isDischarge?: Maybe<Scalars['Boolean']>;
  matchingMemberExclusions?: Maybe<Array<MemberExclusionType>>;
  isChatEnabled?: Maybe<Scalars['Boolean']>;
  hasProcedures?: Maybe<Scalars['Boolean']>;
  signedPrescriptions?: Maybe<Array<PrescriptionType>>;
};

export type LimitedDiseaseCaseType = {
  __typename?: 'LimitedDiseaseCaseType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
};

export type LimitedServicesType = {
  __typename?: 'LimitedServicesType';
  id: Scalars['ID'];
  doctor?: Maybe<DoctorProfileType>;
  doctorFriend?: Maybe<DoctorFriendType>;
  provider?: Maybe<ProviderBranchType>;
  ondemandconsult?: Maybe<OnDemandConsultType>;
  inPersonConsult?: Maybe<LimitedInPersonConsultType>;
  includedConsult?: Maybe<LimitedIncludedConsultType>;
  inPersonConsultByProvider?: Maybe<InPersonConsultByProviderType>;
  rehabilitationTherapy?: Maybe<LimitedRehabilitationTherapyType>;
  isChatEnabled?: Maybe<Scalars['Boolean']>;
  evidenceTokens: Array<LimitedEvidenceType>;
};

export type DoctorProfileType = {
  __typename?: 'DoctorProfileType';
  id: Scalars['ID'];
  licenseNumber?: Maybe<Scalars['String']>;
  licenseInstitution?: Maybe<Scalars['String']>;
  isAuthorizedForOnDemand?: Maybe<Scalars['Boolean']>;
  directAppointment?: Maybe<Scalars['Boolean']>;
  appointmentTelephone?: Maybe<Scalars['String']>;
  autoEmitEnabled: Scalars['Boolean'];
  isPrimaryCareDoctor?: Maybe<Scalars['Boolean']>;
  calendlyUrl?: Maybe<Scalars['String']>;
  welcomeCalendlyUrl?: Maybe<Scalars['String']>;
  onLeaveFrom?: Maybe<Scalars['Date']>;
  onLeaveUntil?: Maybe<Scalars['Date']>;
  leaveReason?: Maybe<DoctorLeaveReason>;
  isPediatricsCareDoctor?: Maybe<Scalars['Boolean']>;
  isPediatric?: Maybe<Scalars['Boolean']>;
  doctoraliaUrl?: Maybe<Scalars['String']>;
  photo?: Maybe<DocumentType>;
  introVideo?: Maybe<DocumentType>;
  extraInfo?: Maybe<Scalars['String']>;
  triviaInfo?: Maybe<Scalars['String']>;
  priorityLevel?: Maybe<DoctorPriorityLevel>;
  officeAddress?: Maybe<AddressType>;
  secondaryOfficeAddress?: Maybe<AddressType>;
  officeAddresses?: Maybe<Array<AddressType>>;
  doctorSpecialistDetails?: Maybe<Array<DoctorSpecialistDetailType>>;
  availableDoctorSpecialistDetails?: Maybe<Array<DoctorSpecialistDetailType>>;
  telephone?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  prefixedFullName?: Maybe<Scalars['String']>;
  isAllowedForScheduledConsults?: Maybe<Scalars['Boolean']>;
  isOnLeave?: Maybe<Scalars['Boolean']>;
};

export enum DoctorPriorityLevel {
  Lvl_1 = 'LVL_1',
  Lvl_2 = 'LVL_2',
  Lvl_3 = 'LVL_3'
}

export type DoctorSpecialistDetailType = {
  __typename?: 'DoctorSpecialistDetailType';
  id: Scalars['ID'];
  specialistNumber?: Maybe<Scalars['String']>;
  medicalSpecialization: MedicalSpecializationModelType;
  specialistInstitution?: Maybe<Scalars['String']>;
  available: Scalars['Boolean'];
  certificateValidUntil?: Maybe<Scalars['Date']>;
  certificate?: Maybe<DocumentType>;
  specializationName?: Maybe<Scalars['String']>;
};

export type DoctorFriendType = {
  __typename?: 'DoctorFriendType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  user?: Maybe<UserType>;
  tier: DoctorFriendTier;
  caseEventQuotes: Array<CaseEventQuoteType>;
  billingInfo?: Maybe<BillingInfoType>;
  invoiceIssuer?: Maybe<UserType>;
};

export enum DoctorFriendTier {
  Regular = 'REGULAR',
  Premium = 'PREMIUM'
}

export type CaseEventQuoteType = {
  __typename?: 'CaseEventQuoteType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  member?: Maybe<MemberType>;
  healthPlan?: Maybe<HealthPlanType>;
  cieCode?: Maybe<Scalars['String']>;
  event?: Maybe<LimitedCaseEventType>;
  cptList?: Maybe<Array<Scalars['String']>>;
  careType?: Maybe<Scalars['String']>;
  admissionType?: Maybe<Scalars['String']>;
  admissionArea?: Maybe<Scalars['String']>;
  document?: Maybe<DocumentType>;
  validSince?: Maybe<Scalars['Date']>;
  validThrough?: Maybe<Scalars['Date']>;
  provisionalDoctor?: Maybe<DoctorType>;
  provisionalDoctorOrSurgeon?: Maybe<DoctorFriendType>;
  provisionalProviderBranchOffice?: Maybe<ProviderBranchOfficeType>;
  provisionalLetters?: Maybe<Array<CaseEventQuoteLetterType>>;
  items: Array<QuoteItemType>;
  quoteTotal?: Maybe<Scalars['Int']>;
  treatingDoctorSubtotal?: Maybe<Scalars['Int']>;
  graprovisionalDoctorOrSurgeon?: Maybe<ProviderBranchOfficeType>;
};

export type LimitedCaseEventType = {
  __typename?: 'LimitedCaseEventType';
  id: Scalars['ID'];
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  description: Scalars['String'];
  originDiagnosisIcd?: Maybe<Array<Scalars['String']>>;
  occurrenceDate?: Maybe<Scalars['Date']>;
  createdAt: Scalars['DateTime'];
  requestingDoctor?: Maybe<DoctorProfileType>;
  procedureMedicalSpecialization?: Maybe<MedicalSpecializationModelType>;
  latestEventQuote?: Maybe<CaseEventQuoteType>;
};

export type CaseEventQuoteLetterType = {
  __typename?: 'CaseEventQuoteLetterType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  quote: CaseEventQuoteType;
  letterType?: Maybe<CaseEventQuoteLetterLetterType>;
  document?: Maybe<DocumentType>;
  sent?: Maybe<Scalars['Boolean']>;
};

export enum CaseEventQuoteLetterLetterType {
  DoctorsOfficeProcedureApprovalLetter = 'DOCTORS_OFFICE_PROCEDURE_APPROVAL_LETTER',
  ProvisionalDoctorsEventLetter = 'PROVISIONAL_DOCTORS_EVENT_LETTER',
  ProvisionalDoctorsOfficeProcedureLetter = 'PROVISIONAL_DOCTORS_OFFICE_PROCEDURE_LETTER',
  ProvisionalHospitalEventLetter = 'PROVISIONAL_HOSPITAL_EVENT_LETTER',
  ProvisionalMemberEventLetter = 'PROVISIONAL_MEMBER_EVENT_LETTER',
  ProvisionalMemberUrgencyLetter = 'PROVISIONAL_MEMBER_URGENCY_LETTER',
  ProvisionalMembersOfficeProcedureLetter = 'PROVISIONAL_MEMBERS_OFFICE_PROCEDURE_LETTER',
  ProvisionalUrgencyHospitalLetter = 'PROVISIONAL_URGENCY_HOSPITAL_LETTER'
}

export type QuoteItemType = {
  __typename?: 'QuoteItemType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  baseCents: Scalars['Int'];
  subtotalCents: Scalars['Int'];
  totalCents: Scalars['Int'];
  vatCents: Scalars['Int'];
  providerName?: Maybe<Scalars['String']>;
  quote?: Maybe<CaseEventQuoteType>;
  cptCode?: Maybe<Scalars['String']>;
  providerClass?: Maybe<CptProviderClassification>;
  referenceSource?: Maybe<CptPriceSource>;
  comment?: Maybe<Scalars['String']>;
  friendlyDescription?: Maybe<Scalars['String']>;
  percentage: Scalars['Decimal'];
};

export enum CptProviderClassification {
  TreatingDoctor = 'TREATING_DOCTOR',
  Anesthesiologist = 'ANESTHESIOLOGIST',
  DoctorFirstAssistant = 'DOCTOR_FIRST_ASSISTANT',
  DoctorSecondAssistant = 'DOCTOR_SECOND_ASSISTANT',
  Hospital = 'HOSPITAL',
  Other = 'OTHER'
}

export enum CptPriceSource {
  Sofia_2023 = 'SOFIA_2023',
  CaobaAverage_25_50 = 'CAOBA_AVERAGE_25_50'
}

export type ProviderBranchType = {
  __typename?: 'ProviderBranchType';
  id: Scalars['ID'];
  name: Scalars['String'];
  telephones?: Maybe<Array<Scalars['String']>>;
  website?: Maybe<Scalars['String']>;
  deferInvoice: Scalars['Boolean'];
  tier?: Maybe<ProviderTier>;
  provider: ProvidersType;
  address?: Maybe<AddressType>;
  providerType?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  emitMonthlyInvoice: Scalars['Boolean'];
};

export type OnDemandConsultType = {
  __typename?: 'OnDemandConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  state: OnDemandConsultState;
  reason: Scalars['String'];
  classification?: Maybe<Scalars['String']>;
  zoomCall?: Maybe<ZoomCall>;
  member?: Maybe<MemberType>;
  callState?: Maybe<CallStates>;
  opentokSession?: Maybe<OpentokSessionType>;
  emrEntry?: Maybe<EmrEntryType>;
  healthPlan?: Maybe<HealthPlanType>;
  billedAmount?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<Array<Maybe<PrescriptionType>>>;
  messages?: Maybe<Array<Maybe<MessageType>>>;
  lastConsultMessage?: Maybe<MessageType>;
  welcomeConsultId?: Maybe<Scalars['Int']>;
  doctorName?: Maybe<Scalars['String']>;
  doctor?: Maybe<DoctorType>;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  serviceCoverageRulingState?: Maybe<RulingState>;
  medicalNotes?: Maybe<Array<Maybe<MedicalNoteType>>>;
};

export enum OnDemandConsultState {
  Hn = 'HN',
  Mn = 'MN',
  Re = 'RE',
  Cl = 'CL',
  Ip = 'IP',
  Ir = 'IR',
  Io = 'IO',
  Cs = 'CS',
  Ca = 'CA'
}

export type ZoomCall = {
  __typename?: 'ZoomCall';
  hostEmail?: Maybe<Scalars['String']>;
  joinUrl: Scalars['String'];
  password: Scalars['String'];
  globalDialInNumbers?: Maybe<Scalars['JSONString']>;
  startTime?: Maybe<Scalars['DateTime']>;
  startUrl: Scalars['String'];
  zak?: Maybe<Scalars['String']>;
  clientToken: Scalars['String'];
  callState?: Maybe<ZoomCallCallState>;
  doctorInCall: Scalars['Boolean'];
  doctorLeaveReason?: Maybe<ZoomCallDoctorLeaveReason>;
  patientInCall: Scalars['Boolean'];
  patientLeaveReason?: Maybe<ZoomCallPatientLeaveReason>;
  id?: Maybe<Scalars['String']>;
};

export enum ZoomCallCallState {
  Act = 'ACT',
  Cbd = 'CBD',
  Cbp = 'CBP',
  Ebd = 'EBD',
  Ebp = 'EBP',
  Ina = 'INA'
}

export enum ZoomCallDoctorLeaveReason {
  Lft = 'LFT',
  Dsc = 'DSC',
  Hed = 'HED',
  Hcd = 'HCD',
  Hsn = 'HSN',
  Nte = 'NTE',
  Hnj = 'HNJ',
  Tle = 'TLE',
  Rbh = 'RBH',
  Unr = 'UNR',
  Lwr = 'LWR',
  Rwr = 'RWR'
}

export enum ZoomCallPatientLeaveReason {
  Lft = 'LFT',
  Dsc = 'DSC',
  Hed = 'HED',
  Hcd = 'HCD',
  Hsn = 'HSN',
  Nte = 'NTE',
  Hnj = 'HNJ',
  Tle = 'TLE',
  Rbh = 'RBH',
  Unr = 'UNR',
  Lwr = 'LWR',
  Rwr = 'RWR'
}

export enum CallStates {
  Active = 'ACTIVE',
  CancelledByDoctor = 'CANCELLED_BY_DOCTOR',
  CancelledByPatient = 'CANCELLED_BY_PATIENT',
  EndedByDoctor = 'ENDED_BY_DOCTOR',
  EndedByPatient = 'ENDED_BY_PATIENT',
  Inactive = 'INACTIVE'
}

export type OpentokSessionType = {
  __typename?: 'OpentokSessionType';
  apiKey?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type EmrEntryType = {
  __typename?: 'EmrEntryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  signedAt?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Scalars['JSONString']>;
  member?: Maybe<MemberType>;
  doctor?: Maybe<DoctorType>;
  diagnosis?: Maybe<Scalars['String']>;
  otherRecommendations?: Maybe<Scalars['String']>;
  labs?: Maybe<Scalars['String']>;
  medicines?: Maybe<Scalars['JSONString']>;
  notes?: Maybe<Scalars['String']>;
};

export type PrescriptionType = {
  __typename?: 'PrescriptionType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  member?: Maybe<MemberType>;
  memberName: Scalars['String'];
  memberAge: Scalars['Int'];
  doctorName: Scalars['String'];
  licenseNumber?: Maybe<Scalars['String']>;
  licenseInstitution?: Maybe<Scalars['String']>;
  specialization?: Maybe<Scalars['String']>;
  specialistNumber?: Maybe<Scalars['String']>;
  specialistInstitution?: Maybe<Scalars['String']>;
  doctorOfficeAddress: Scalars['String'];
  doctorTelephone: Scalars['String'];
  diagnosis?: Maybe<Scalars['String']>;
  recommendations?: Maybe<Scalars['String']>;
  hasAntibiotics?: Maybe<Scalars['Boolean']>;
  prescriptionItemsMemberChecks?: Maybe<Scalars['JSONString']>;
  labsCheck?: Maybe<Scalars['Boolean']>;
  medicinesCheck?: Maybe<Scalars['Boolean']>;
  referencesCheck?: Maybe<Scalars['Boolean']>;
  pdfDocument?: Maybe<DocumentType>;
  signed: Scalars['Boolean'];
  prescriptionItems?: Maybe<Scalars['JSONString']>;
  doctor?: Maybe<DoctorType>;
  items?: Maybe<Array<Maybe<PrescriptionItemType>>>;
};

export type PrescriptionItemType = {
  __typename?: 'PrescriptionItemType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  group?: Maybe<PrescriptionItemGroup>;
  service?: Maybe<LimitedServicesType>;
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  checked: Scalars['Boolean'];
  prescriptionItemDetails?: Maybe<PrescriptionItemDetailsType>;
};

export enum PrescriptionItemGroup {
  Labs = 'LABS',
  Medicines = 'MEDICINES',
  References = 'REFERENCES',
  Checkups = 'CHECKUPS',
  Urgencies = 'URGENCIES'
}

export type PrescriptionItemDetailsType = {
  __typename?: 'PrescriptionItemDetailsType';
  id: Scalars['ID'];
  medicalSpecialization?: Maybe<MedicalSpecializationModelType>;
  time?: Maybe<Scalars['String']>;
  dose?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  administration?: Maybe<Scalars['String']>;
  preparation?: Maybe<Scalars['String']>;
};

export type MessageType = {
  __typename?: 'MessageType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  fromUser?: Maybe<BriefUserType>;
  toUser?: Maybe<BriefUserType>;
  content?: Maybe<Scalars['String']>;
  read: Scalars['Boolean'];
  published: Scalars['Boolean'];
  consult?: Maybe<OnDemandConsultType>;
  attachmentUrl?: Maybe<Scalars['String']>;
};

export enum RulingState {
  Pending = 'PENDING',
  InReview = 'IN_REVIEW',
  Accepted = 'ACCEPTED',
  PartiallyAccepted = 'PARTIALLY_ACCEPTED',
  Declined = 'DECLINED',
  Cancelled = 'CANCELLED'
}

export type LimitedInPersonConsultType = {
  __typename?: 'LimitedInPersonConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member?: Maybe<MemberType>;
  updatedAt: Scalars['DateTime'];
  healthPlan?: Maybe<HealthPlanType>;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
  consultPtr: LimitedConsultType;
  huliEmrEntry?: Maybe<HuliEmrEntryType>;
  doctorSpecializationFare?: Maybe<SpecializationFareType>;
  doctorMedicalSpecializations: Array<MedicalSpecializationModelType>;
  emrEntry?: Maybe<EmrEntryType>;
  scheduledconsultSet: Array<LimitedScheduledConsultType>;
  doctor?: Maybe<DoctorType>;
  consultDate?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  serviceCoverageRulingState?: Maybe<RulingState>;
  prescriptions?: Maybe<Array<Maybe<PrescriptionType>>>;
  medicalNotes?: Maybe<Array<Maybe<MedicalNoteType>>>;
  hasPayInAndPayOut?: Maybe<Scalars['Boolean']>;
};

export enum ServiceServiceTypeValue {
  MedicalFees = 'MEDICAL_FEES',
  HospitalService = 'HOSPITAL_SERVICE',
  OtherService = 'OTHER_SERVICE',
  RehabilitationTherapy = 'REHABILITATION_THERAPY',
  LabStudies = 'LAB_STUDIES',
  Medicines = 'MEDICINES',
  MedicalEquipment = 'MEDICAL_EQUIPMENT',
  HomeNurse = 'HOME_NURSE',
  ProstheticsAndMechanicalEquipment = 'PROSTHETICS_AND_MECHANICAL_EQUIPMENT',
  Ambulance = 'AMBULANCE',
  InPersonConsult = 'IN_PERSON_CONSULT',
  OnDemandConsult = 'ON_DEMAND_CONSULT',
  PharmacyOrder = 'PHARMACY_ORDER',
  ExternalConsult = 'EXTERNAL_CONSULT',
  Urgency = 'URGENCY',
  ExtVideoConsult = 'EXT_VIDEO_CONSULT',
  SurgicalMaterials = 'SURGICAL_MATERIALS',
  IncludedConsult = 'INCLUDED_CONSULT',
  InPersonConsultByProvider = 'IN_PERSON_CONSULT_BY_PROVIDER'
}

export enum ServiceProcessState {
  ProcessStarted = 'PROCESS_STARTED',
  ReviewingEvidences = 'REVIEWING_EVIDENCES',
  Ruling = 'RULING',
  RegisteringAmounts = 'REGISTERING_AMOUNTS',
  ProcessingPayments = 'PROCESSING_PAYMENTS',
  ProcessFinalized = 'PROCESS_FINALIZED',
  Cancelled = 'CANCELLED'
}

export enum ServiceSpecification {
  EmergencyAmbulance = 'EMERGENCY_AMBULANCE',
  TransferAmbulance = 'TRANSFER_AMBULANCE',
  HospitalEmergency = 'HOSPITAL_EMERGENCY',
  HospitalAdmission = 'HOSPITAL_ADMISSION',
  TreatingPhysician = 'TREATING_PHYSICIAN',
  Surgeon = 'SURGEON',
  FirstHelper = 'FIRST_HELPER',
  SecondHelper = 'SECOND_HELPER',
  Anesthesiologist = 'ANESTHESIOLOGIST',
  ConsultingPhysician = 'CONSULTING_PHYSICIAN'
}

export type ServicesType = {
  __typename?: 'ServicesType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan?: Maybe<HealthPlanType>;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Maybe<KeyValueType>>>;
  cpt?: Maybe<Array<Maybe<KeyValueType>>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceTypeSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  assignmentHistory: Array<ServiceAssignmentHistoryType>;
  electronicVoucherItems: Array<ServiceElectronicVoucherItemType>;
  medicalNotes: Array<MedicalNoteType>;
  ondemandconsult?: Maybe<OnDemandConsultType>;
  evidences?: Maybe<Array<Maybe<EvidenceType>>>;
  state?: Maybe<RulingState>;
  serviceCoverageRuling?: Maybe<ServicesCoverageRulingType>;
  payoutInvoice?: Maybe<InvoiceType>;
  payinInvoice?: Maybe<InvoiceType>;
  isFreeOfCharge?: Maybe<Scalars['Boolean']>;
  serviceType?: Maybe<ServiceTypeType>;
  doctor?: Maybe<DoctorProfileType>;
  doctorFriend?: Maybe<DoctorFriendType>;
  provider?: Maybe<ProviderBranchType>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  inPersonConsult?: Maybe<InPersonConsultType>;
  hospitalizationDays?: Maybe<Scalars['Int']>;
  dischargeDate?: Maybe<Scalars['Date']>;
  isCancellable?: Maybe<Scalars['Boolean']>;
  isValidForAutomaticRuling?: Maybe<Scalars['Boolean']>;
  commentHistory?: Maybe<Array<Maybe<ServiceCommentType>>>;
  editableReserveAmount?: Maybe<Scalars['Boolean']>;
  matchingMemberExclusions?: Maybe<Array<MemberExclusionType>>;
  lastPayoutState?: Maybe<ServicePayoutLogStates>;
  medicalFees?: Maybe<MedicalFeeType>;
};

export type DiseaseCaseType = {
  __typename?: 'DiseaseCaseType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  member: MemberType;
  originDiagnosisIcd?: Maybe<Array<Maybe<KeyValueType>>>;
  medicalDescription?: Maybe<Scalars['String']>;
  services?: Maybe<Array<Maybe<ServicesType>>>;
  sumServicesTotalCents?: Maybe<Scalars['Int']>;
  servicesCount?: Maybe<Scalars['Int']>;
};

export enum ServiceTypeSpecification {
  EmergencyAmbulance = 'EMERGENCY_AMBULANCE',
  TransferAmbulance = 'TRANSFER_AMBULANCE',
  HospitalEmergency = 'HOSPITAL_EMERGENCY',
  HospitalAdmission = 'HOSPITAL_ADMISSION',
  TreatingPhysician = 'TREATING_PHYSICIAN',
  Surgeon = 'SURGEON',
  FirstHelper = 'FIRST_HELPER',
  SecondHelper = 'SECOND_HELPER',
  Anesthesiologist = 'ANESTHESIOLOGIST',
  ConsultingPhysician = 'CONSULTING_PHYSICIAN'
}

export enum ServiceRulingDifficulty {
  Red = 'RED',
  Green = 'GREEN',
  Yellow = 'YELLOW'
}

export enum ServiceSuggestedRulingState {
  Pn = 'PN',
  Ir = 'IR',
  Ac = 'AC',
  Pa = 'PA',
  Dc = 'DC',
  Cn = 'CN'
}

export enum ServiceCoverageTag {
  CheckUp = 'CHECK_UP',
  MajorMedicalExpense = 'MAJOR_MEDICAL_EXPENSE',
  InPersonConsult = 'IN_PERSON_CONSULT',
  Medicine = 'MEDICINE',
  Vaccine = 'VACCINE',
  VideoConsultation = 'VIDEO_CONSULTATION',
  OfficeProcedure = 'OFFICE_PROCEDURE',
  Maternity = 'MATERNITY',
  ClinicalLaboratoryExamsDiagnostic = 'CLINICAL_LABORATORY_EXAMS_DIAGNOSTIC',
  ClinicalLaboratoryExamsPrevention = 'CLINICAL_LABORATORY_EXAMS_PREVENTION',
  ImagingAndCabinetExamsDiagnostic = 'IMAGING_AND_CABINET_EXAMS_DIAGNOSTIC',
  ImagingAndCabinetExamsPrevention = 'IMAGING_AND_CABINET_EXAMS_PREVENTION'
}

export type ServiceAssignmentHistoryType = {
  __typename?: 'ServiceAssignmentHistoryType';
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  fromUser?: Maybe<UserType>;
  toUser: UserType;
};

export type ServiceElectronicVoucherItemType = {
  __typename?: 'ServiceElectronicVoucherItemType';
  id: Scalars['ID'];
  accepted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  service: ServicesType;
  electronicVoucherItem: ElectronicVoucherItemsType;
  unitTotalAuthorizedDecimals: Scalars['Decimal'];
  quantityAuthorizedDecimals: Scalars['Decimal'];
};

export type ElectronicVoucherItemsType = {
  __typename?: 'ElectronicVoucherItemsType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  contentType?: Maybe<ContentTypeType>;
  objectId?: Maybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  quantityDecimals: Scalars['Decimal'];
  productServiceKey?: Maybe<ElectronicVoucherItemsProductServiceKey>;
  parsedProductServiceKey?: Maybe<Scalars['String']>;
  unitKey?: Maybe<ElectronicVoucherItemsUnitKey>;
  taxCode?: Maybe<ElectronicVoucherItemsTaxCode>;
  description?: Maybe<Scalars['String']>;
  amountCents: Scalars['Int'];
  amountDecimals: Scalars['Decimal'];
  discountCents: Scalars['Int'];
  discountDecimals: Scalars['Decimal'];
  unitValueCents: Scalars['Int'];
  unitValueDecimals: Scalars['Decimal'];
  totalWithholdTaxesCents: Scalars['Int'];
  totalTransferredTaxesCents: Scalars['Int'];
  relatesToElectronicVoucher?: Maybe<ElectronicVoucherType>;
  electronicVoucherRelationType?: Maybe<ElectronicVoucherItemsElectronicVoucherRelationType>;
  electronicVoucherItems: Array<ServiceElectronicVoucherItemType>;
  taxes?: Maybe<Array<Maybe<ElectronicVoucherTaxesType>>>;
};

export enum ElectronicVoucherItemsProductServiceKey {
  A_42132200 = 'A_42132200',
  A_85121500 = 'A_85121500',
  A_85121501 = 'A_85121501',
  A_85121502 = 'A_85121502',
  A_85121503 = 'A_85121503',
  A_85121504 = 'A_85121504',
  A_85121600 = 'A_85121600',
  A_85121601 = 'A_85121601',
  A_85121602 = 'A_85121602',
  A_85121603 = 'A_85121603',
  A_85121604 = 'A_85121604',
  A_85121605 = 'A_85121605',
  A_85121606 = 'A_85121606',
  A_85121607 = 'A_85121607',
  A_85121608 = 'A_85121608',
  A_85121609 = 'A_85121609',
  A_85121610 = 'A_85121610',
  A_85121611 = 'A_85121611',
  A_85121612 = 'A_85121612',
  A_85121613 = 'A_85121613',
  A_85121614 = 'A_85121614',
  A_85121700 = 'A_85121700',
  A_85121701 = 'A_85121701',
  A_85121702 = 'A_85121702',
  A_85121703 = 'A_85121703',
  A_85121704 = 'A_85121704',
  A_85121705 = 'A_85121705',
  A_85121706 = 'A_85121706',
  A_85121800 = 'A_85121800',
  A_85121801 = 'A_85121801',
  A_85121802 = 'A_85121802',
  A_85121803 = 'A_85121803',
  A_85121804 = 'A_85121804',
  A_85121805 = 'A_85121805',
  A_85121806 = 'A_85121806',
  A_85121807 = 'A_85121807',
  A_85121808 = 'A_85121808',
  A_85121809 = 'A_85121809',
  A_85121810 = 'A_85121810',
  A_85121811 = 'A_85121811',
  A_85121900 = 'A_85121900',
  A_85121901 = 'A_85121901',
  A_85121902 = 'A_85121902',
  A_85122000 = 'A_85122000',
  A_85122001 = 'A_85122001',
  A_85122002 = 'A_85122002',
  A_85122003 = 'A_85122003',
  A_85122004 = 'A_85122004',
  A_85122005 = 'A_85122005',
  A_85122100 = 'A_85122100',
  A_85122101 = 'A_85122101',
  A_85122102 = 'A_85122102',
  A_85122103 = 'A_85122103',
  A_85122104 = 'A_85122104',
  A_85122105 = 'A_85122105',
  A_85122106 = 'A_85122106',
  A_85122107 = 'A_85122107',
  A_85122108 = 'A_85122108',
  A_85122109 = 'A_85122109',
  A_85122200 = 'A_85122200',
  A_85122201 = 'A_85122201',
  A_84131602 = 'A_84131602',
  A_84111506 = 'A_84111506',
  A_85101605 = 'A_85101605',
  A_85101601 = 'A_85101601',
  A_01010101 = 'A_01010101',
  A_85101501 = 'A_85101501',
  A_53131613 = 'A_53131613',
  A_85151600 = 'A_85151600',
  A_00000000 = 'A_00000000'
}

export enum ElectronicVoucherItemsUnitKey {
  Ip = 'IP',
  Act = 'ACT',
  E48 = 'E48',
  H87 = 'H87',
  C62 = 'C62',
  Ea = 'EA',
  Kgm = 'KGM',
  E51 = 'E51',
  A9 = 'A9',
  Mtr = 'MTR',
  Ab = 'AB',
  Bb = 'BB',
  Kt = 'KT',
  Set = 'SET',
  Ltr = 'LTR',
  Xbx = 'XBX',
  Mon = 'MON',
  Hur = 'HUR',
  Mtk = 'MTK',
  A_11 = 'A_11',
  Mgm = 'MGM',
  Xpk = 'XPK',
  Xki = 'XKI',
  As = 'AS',
  Grm = 'GRM',
  Pr = 'PR',
  Dpc = 'DPC',
  Xun = 'XUN',
  Xun_28 = 'XUN_28',
  Day = 'DAY',
  Xlt = 'XLT',
  A_10 = 'A_10',
  Mlt = 'MLT',
  E54 = 'E54',
  S6 = 'S6',
  Xci = 'XCI',
  Zz = 'ZZ',
  Xap = 'XAP',
  Lo = 'LO',
  X4G = 'X4G',
  Mmt = 'MMT',
  E49 = 'E49',
  A_05 = 'A_05',
  M36 = 'M36',
  A_48 = 'A_48',
  Xvi = 'XVI',
  Xna = 'XNA',
  T0 = 'T0',
  Xen = 'XEN',
  A_29 = 'A_29'
}

export enum ElectronicVoucherItemsTaxCode {
  A_01 = 'A_01',
  A_02 = 'A_02',
  A_03 = 'A_03',
  A_04 = 'A_04'
}

export enum ElectronicVoucherItemsElectronicVoucherRelationType {
  A_01 = 'A_01',
  A_02 = 'A_02',
  A_03 = 'A_03',
  A_04 = 'A_04',
  A_05 = 'A_05',
  A_06 = 'A_06',
  A_07 = 'A_07',
  A_08 = 'A_08',
  A_09 = 'A_09'
}

export type EvidenceType = {
  __typename?: 'EvidenceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['UUID'];
  reportType: TypeformTokenReportType;
  typeformEventId?: Maybe<Scalars['String']>;
  typeformResponse?: Maybe<Scalars['JSONString']>;
  createdBy: UserType;
  member: MemberType;
  doctor?: Maybe<DoctorProfileType>;
  provider?: Maybe<ProviderBranchType>;
  state: TypeformTokenState;
  generatedFormLink: Scalars['String'];
  evidenceState: TypeformTokenEvidenceState;
  consumed?: Maybe<Scalars['Boolean']>;
  formLink?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['JSONString']>;
  validatedBy?: Maybe<UserType>;
  attachments?: Maybe<Array<Maybe<DocumentType>>>;
  typeformAttachments?: Maybe<Array<Maybe<TypeFormAttachmentType>>>;
};

export enum TypeformTokenReportType {
  Memberdisease = 'MEMBERDISEASE',
  Memberpostservice = 'MEMBERPOSTSERVICE',
  Memberpostserviceshort = 'MEMBERPOSTSERVICESHORT',
  Memberpostserviceshortsofiaform = 'MEMBERPOSTSERVICESHORTSOFIAFORM',
  Otherprovider = 'OTHERPROVIDER',
  Hospitalservice = 'HOSPITALSERVICE',
  Doctorfullprocedure = 'DOCTORFULLPROCEDURE',
  Doctorofficeservice = 'DOCTOROFFICESERVICE',
  Doctorpostservice = 'DOCTORPOSTSERVICE',
  Doctorfullhospitalservice = 'DOCTORFULLHOSPITALSERVICE',
  Doctorpreservice = 'DOCTORPRESERVICE',
  Doctorposthospitalservice = 'DOCTORPOSTHOSPITALSERVICE',
  Doctorinterconsultant = 'DOCTORINTERCONSULTANT',
  Outofnetworkinpersonconsultsofiaform = 'OUTOFNETWORKINPERSONCONSULTSOFIAFORM',
  Memberdiseasedev = 'MEMBERDISEASEDEV',
  Memberpostservicedev = 'MEMBERPOSTSERVICEDEV',
  Memberpostserviceshortdev = 'MEMBERPOSTSERVICESHORTDEV',
  Memberpostserviceshortsofiaformdev = 'MEMBERPOSTSERVICESHORTSOFIAFORMDEV',
  Otherproviderdev = 'OTHERPROVIDERDEV',
  Hospitalservicedev = 'HOSPITALSERVICEDEV',
  Doctorfullproceduredev = 'DOCTORFULLPROCEDUREDEV',
  Doctorofficeservicedev = 'DOCTOROFFICESERVICEDEV',
  Doctorpostservicedev = 'DOCTORPOSTSERVICEDEV',
  Doctorfullhospitalservicedev = 'DOCTORFULLHOSPITALSERVICEDEV',
  Doctorpreservicedev = 'DOCTORPRESERVICEDEV',
  Doctorposthospitalservicedev = 'DOCTORPOSTHOSPITALSERVICEDEV',
  Doctorinterconsultantdev = 'DOCTORINTERCONSULTANTDEV',
  Prescription = 'PRESCRIPTION',
  Outofnetworkinpersonconsultsofiaformdev = 'OUTOFNETWORKINPERSONCONSULTSOFIAFORMDEV'
}

export enum TypeformTokenState {
  In = 'IN',
  Co = 'CO'
}

export enum TypeformTokenEvidenceState {
  Pending = 'PENDING',
  Received = 'RECEIVED',
  Wrong = 'WRONG',
  CorrectionRequested = 'CORRECTION_REQUESTED',
  AwaitingAnswer = 'AWAITING_ANSWER',
  ReviewedAndAccepted = 'REVIEWED_AND_ACCEPTED'
}

export type TypeFormAttachmentType = {
  __typename?: 'TypeFormAttachmentType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  document?: Maybe<DocumentType>;
  state?: Maybe<AttachmentState>;
  typeformToken?: Maybe<LimitedEvidenceType>;
  updatedBy?: Maybe<UserType>;
};

export enum AttachmentState {
  Wrong = 'WRONG',
  CorrectionRequested = 'CORRECTION_REQUESTED',
  AwaitingAnswer = 'AWAITING_ANSWER',
  ReviewedAndAccepted = 'REVIEWED_AND_ACCEPTED'
}

export type LimitedEvidenceType = {
  __typename?: 'LimitedEvidenceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['UUID'];
  reportType: TypeformTokenReportType;
  typeformEventId?: Maybe<Scalars['String']>;
  typeformResponse?: Maybe<Scalars['JSONString']>;
  createdBy: UserType;
  member: MemberType;
  doctor?: Maybe<DoctorProfileType>;
  provider?: Maybe<ProviderBranchType>;
  state: TypeformTokenState;
  generatedFormLink: Scalars['String'];
  evidenceState: TypeformTokenEvidenceState;
  formLink?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['JSONString']>;
  attachments?: Maybe<Array<Maybe<DocumentType>>>;
  serviceTypeValue?: Maybe<Scalars['String']>;
  typeformAttachments?: Maybe<Array<Maybe<TypeFormAttachmentType>>>;
};

export type ServicesCoverageRulingType = {
  __typename?: 'ServicesCoverageRulingType';
  id: Scalars['ID'];
  state: ServiceCoverageRulingState;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  healthPlan?: Maybe<HealthPlanType>;
  declinedReason?: Maybe<ServiceCoverageRulingDeclinedReason>;
  ruledByUser?: Maybe<UserType>;
  initialCostCents: Scalars['Int'];
  preauthorizationDoc?: Maybe<DocumentType>;
  postauthorizationDoc?: Maybe<DocumentType>;
  totalCostCents: Scalars['Int'];
  rejectionComment?: Maybe<Scalars['String']>;
  rejectionMessageForUser: Scalars['JSONString'];
  allowPayoutOnRejection: Scalars['Boolean'];
  service?: Maybe<ServicesType>;
  checks?: Maybe<Array<Maybe<ClaimRulingChecksType>>>;
  preapprovedAmounts: Array<PreapprovedAmountHistoryType>;
  /** @deprecated Use payout field */
  payouts?: Maybe<Array<Maybe<ServiceCoveragePayoutType>>>;
  payout?: Maybe<ServiceCoveragePayoutType>;
  payInRequest?: Maybe<ServicePayInRequestType>;
};

export enum ServiceCoverageRulingState {
  Pn = 'PN',
  Ir = 'IR',
  Ac = 'AC',
  Pa = 'PA',
  Dc = 'DC',
  Cn = 'CN'
}

export enum ServiceCoverageRulingDeclinedReason {
  Mi = 'MI',
  Hp = 'HP',
  Pr = 'PR',
  Ex = 'EX',
  Wt = 'WT',
  Mn = 'MN',
  On = 'ON',
  Sc = 'SC',
  Pi = 'PI'
}

export type ClaimRulingChecksType = {
  __typename?: 'ClaimRulingChecksType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceCoverageRuling: ServicesCoverageRulingType;
  declinedReason?: Maybe<ClaimRulingChecksDeclinedReason>;
  state?: Maybe<RulingCheckState>;
};

export enum ClaimRulingChecksDeclinedReason {
  Mi = 'MI',
  Hp = 'HP',
  Pr = 'PR',
  Ex = 'EX',
  Wt = 'WT',
  Mn = 'MN',
  On = 'ON',
  Sc = 'SC',
  Pi = 'PI'
}

export enum RulingCheckState {
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED'
}

export type PreapprovedAmountHistoryType = {
  __typename?: 'PreapprovedAmountHistoryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  totalAmountCents: Scalars['Int'];
  vatAmountCents: Scalars['Int'];
  subtotalAmountCents: Scalars['Int'];
  currency: PreapprovedAmountHistoryCurrency;
  coveredSubtotalCents: Scalars['Int'];
  coveredVatCents: Scalars['Int'];
  coveredTotalCents: Scalars['Int'];
  coinsuredSubtotalCents: Scalars['Int'];
  coinsuredVatCents: Scalars['Int'];
  coinsuredTotalCents: Scalars['Int'];
  serviceCoverageRuling: ServicesCoverageRulingType;
  description: Scalars['String'];
  approvedBy?: Maybe<UserType>;
  movementType: PreapprovedAmountHistoryMovementType;
  remarks?: Maybe<Scalars['String']>;
};

export enum PreapprovedAmountHistoryCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export enum PreapprovedAmountHistoryMovementType {
  Ini = 'INI',
  Inc = 'INC',
  Dec = 'DEC',
  Can = 'CAN',
  Pay = 'PAY'
}

export type ServiceCoveragePayoutType = {
  __typename?: 'ServiceCoveragePayoutType';
  id: Scalars['ID'];
  subtotalAmountCents: Scalars['Int'];
  invoiceItem?: Maybe<InvoiceItemType>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  totalAmountCents: Scalars['Int'];
  vatAmountCents: Scalars['Int'];
  currency: ServiceCoveragePayoutCurrency;
  coveredSubtotalCents: Scalars['Int'];
  coveredVatCents: Scalars['Int'];
  coveredTotalCents: Scalars['Int'];
  coinsuredSubtotalCents: Scalars['Int'];
  coinsuredVatCents: Scalars['Int'];
  coinsuredTotalCents: Scalars['Int'];
  serviceCoverageRuling: ServicesCoverageRulingType;
  invoice?: Maybe<ClaimPayoutInvoiceType>;
  approvedBy?: Maybe<UserType>;
  amountAnalysisDocument?: Maybe<DocumentType>;
  paidCoinsuranceSubtotalCents: Scalars['Int'];
  paidCoinsuranceVatCents: Scalars['Int'];
  paidCoinsuranceTotalCents: Scalars['Int'];
  rejectedAmountSubtotalCents: Scalars['Int'];
  rejectedAmountVatCents: Scalars['Int'];
  rejectedAmountTotalCents: Scalars['Int'];
  rejectedAmountCentsComment?: Maybe<Scalars['String']>;
  rejectedAmountCentsMessageForMember?: Maybe<Scalars['String']>;
  payoutAmountSubtotalCents: Scalars['Int'];
  payoutAmountVatCents: Scalars['Int'];
  payoutAmountTotalCents: Scalars['Int'];
  payeeUser?: Maybe<UserType>;
  payeeProvider?: Maybe<ProviderBranchType>;
  payeeMember?: Maybe<MemberType>;
  state: ServiceCoveragePayoutState;
  payoutMode: ServiceCoveragePayoutPayoutMode;
  withholdTaxesCents: Scalars['Int'];
  coinsuredPercent?: Maybe<Scalars['Int']>;
  coveredPercent?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  claimsInvoiceDocuments?: Maybe<ClaimPayoutInvoiceType>;
};

export enum ServiceCoveragePayoutCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export type ClaimPayoutInvoiceType = {
  __typename?: 'ClaimPayoutInvoiceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
  xmlDocument?: Maybe<DocumentType>;
  pdfDocument?: Maybe<DocumentType>;
  isValid?: Maybe<Scalars['Boolean']>;
  payout: Array<ServiceCoveragePayoutType>;
  relatedElectronicVoucher?: Maybe<ElectronicVoucherType>;
};

export enum ServiceCoveragePayoutState {
  Draft = 'DRAFT',
  Requested = 'REQUESTED',
  Failed = 'FAILED',
  Confirmed = 'CONFIRMED',
  InSitu = 'IN_SITU',
  Uncollectible = 'UNCOLLECTIBLE',
  Refunded = 'REFUNDED',
  Staging = 'STAGING'
}

export enum ServiceCoveragePayoutPayoutMode {
  Direct = 'DIRECT',
  DirectHospital = 'DIRECT_HOSPITAL',
  DirectConsultingRoom = 'DIRECT_CONSULTING_ROOM',
  Reimbursement = 'REIMBURSEMENT',
  ReimbursementCfdiRecipientMember = 'REIMBURSEMENT_CFDI_RECIPIENT_MEMBER',
  Compensation = 'COMPENSATION'
}

export type ServicePayInRequestType = {
  __typename?: 'ServicePayInRequestType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  totalAmountCents: Scalars['Int'];
  vatAmountCents: Scalars['Int'];
  subtotalAmountCents: Scalars['Int'];
  serviceCoverageRuling?: Maybe<ServicesCoverageRulingType>;
  method?: Maybe<PayInMethods>;
  state: ServicePayInRequestState;
};

export enum PayInMethods {
  Card = 'CARD',
  InSitu = 'IN_SITU'
}

export enum ServicePayInRequestState {
  Draft = 'DRAFT',
  Requested = 'REQUESTED',
  Failed = 'FAILED',
  Confirmed = 'CONFIRMED',
  InSitu = 'IN_SITU',
  Uncollectible = 'UNCOLLECTIBLE',
  Refunded = 'REFUNDED',
  Staging = 'STAGING'
}

export type ServiceTypeType = {
  __typename?: 'ServiceTypeType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  scheduling?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  rulingType?: Maybe<Scalars['String']>;
  causesVat?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  isOneFreeOfCharge?: Maybe<Scalars['Boolean']>;
  isAssistance?: Maybe<Scalars['Boolean']>;
  specifications?: Maybe<Array<Maybe<ServiceTypeSpecification>>>;
  availablePayoutModes: Array<ServiceCoveragePayoutMode>;
  defaultPayoutMode: ServiceCoveragePayoutMode;
  availablePayInMethods?: Maybe<Array<PayInMethods>>;
};

export enum ServiceCoveragePayoutMode {
  Direct = 'DIRECT',
  /** @deprecated Deprecated use DIRECT or REIMBURSEMENT */
  DirectHospital = 'DIRECT_HOSPITAL',
  /** @deprecated Deprecated use DIRECT or REIMBURSEMENT */
  DirectConsultingRoom = 'DIRECT_CONSULTING_ROOM',
  Reimbursement = 'REIMBURSEMENT',
  /** @deprecated Deprecated use DIRECT or REIMBURSEMENT */
  ReimbursementCfdiRecipientMember = 'REIMBURSEMENT_CFDI_RECIPIENT_MEMBER',
  Compensation = 'COMPENSATION'
}

export type InPersonConsultType = {
  __typename?: 'InPersonConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member?: Maybe<MemberType>;
  updatedAt: Scalars['DateTime'];
  healthPlan?: Maybe<HealthPlanType>;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Maybe<KeyValueType>>>;
  cpt?: Maybe<Array<Maybe<KeyValueType>>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
  consultPtr: LimitedConsultType;
  huliEmrEntry?: Maybe<HuliEmrEntryType>;
  doctorSpecializationFare?: Maybe<SpecializationFareType>;
  doctorMedicalSpecializations: Array<MedicalSpecializationModelType>;
  emrEntry?: Maybe<EmrEntryType>;
  scheduledconsultSet: Array<LimitedScheduledConsultType>;
  provider?: Maybe<ProviderBranchType>;
  serviceType?: Maybe<ServiceTypeType>;
  medicalNotes?: Maybe<Array<Maybe<MedicalNoteType>>>;
  prescriptions?: Maybe<Array<PrescriptionType>>;
  note?: Maybe<Scalars['String']>;
  invoiceItem?: Maybe<InvoiceType>;
  consultDate?: Maybe<Scalars['DateTime']>;
  doctor?: Maybe<DoctorType>;
  hasDoctorBeenPaid?: Maybe<Scalars['Boolean']>;
  hasElectronicVoucher?: Maybe<Scalars['Boolean']>;
  doctorFeeCents?: Maybe<Scalars['Int']>;
  doctorPayoutFeeCents?: Maybe<Scalars['Int']>;
  hasBeenPaidIn?: Maybe<Scalars['Boolean']>;
  hasPayInAndPayOut?: Maybe<Scalars['Boolean']>;
};

export enum ServiceAutomaticPaymentApprovalFlagLastCheck {
  R = 'R',
  G = 'G',
  Y = 'Y'
}

export type LimitedConsultType = {
  __typename?: 'LimitedConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member?: Maybe<MemberType>;
  updatedAt: Scalars['DateTime'];
  healthPlan?: Maybe<HealthPlanType>;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
  inpersonconsultbyprovider?: Maybe<InPersonConsultByProviderType>;
  includedconsult?: Maybe<LimitedIncludedConsultType>;
  inpersonconsult?: Maybe<InPersonConsultType>;
  rehabilitationtherapy?: Maybe<LimitedRehabilitationTherapyType>;
  ondemandconsult?: Maybe<WatchOnDemandConsultType>;
  prescriptions?: Maybe<Array<PrescriptionType>>;
  scheduledConsults: Array<LimitedScheduledConsultType>;
  billedAmount?: Maybe<Scalars['Int']>;
  callState?: Maybe<CallStates>;
  consultDate?: Maybe<Scalars['DateTime']>;
  doctor?: Maybe<DoctorType>;
  doctorName?: Maybe<Scalars['String']>;
  emrEntry?: Maybe<EmrEntryType>;
  hasPayInAndPayOut?: Maybe<Scalars['Boolean']>;
  medicalNotes?: Maybe<Array<Maybe<MedicalNoteType>>>;
  reason?: Maybe<Scalars['String']>;
  serviceCoverageRulingState?: Maybe<RulingState>;
  welcomeConsultId?: Maybe<Scalars['Int']>;
};

export type InPersonConsultByProviderType = {
  __typename?: 'InPersonConsultByProviderType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member?: Maybe<MemberType>;
  updatedAt: Scalars['DateTime'];
  healthPlan?: Maybe<HealthPlanType>;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Maybe<KeyValueType>>>;
  cpt?: Maybe<Array<Maybe<KeyValueType>>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
  consultPtr: LimitedConsultType;
  doctorMedicalSpecializations: Array<MedicalSpecializationModelType>;
  scheduledconsultSet: Array<LimitedScheduledConsultType>;
  provider?: Maybe<ProviderBranchType>;
  serviceType?: Maybe<ServiceTypeType>;
  medicalNotes?: Maybe<Array<Maybe<MedicalNoteType>>>;
  prescriptions?: Maybe<Array<PrescriptionType>>;
  note?: Maybe<Scalars['String']>;
  invoiceItem?: Maybe<InvoiceType>;
  consultDate?: Maybe<Scalars['DateTime']>;
  huliEmrEntry?: Maybe<HuliEmrEntryType>;
  doctor?: Maybe<DoctorType>;
  hasDoctorBeenPaid?: Maybe<Scalars['Boolean']>;
  hasElectronicVoucher?: Maybe<Scalars['Boolean']>;
  doctorFeeCents?: Maybe<Scalars['Int']>;
  doctorPayoutFeeCents?: Maybe<Scalars['Int']>;
  hasBeenPaidIn?: Maybe<Scalars['Boolean']>;
  hasPayInAndPayOut?: Maybe<Scalars['Boolean']>;
};

export type LimitedScheduledConsultType = {
  __typename?: 'LimitedScheduledConsultType';
  id: Scalars['ID'];
  scheduledConsultClassification?: Maybe<GrapheneScheduledConsultClassification>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  shouldEndAt?: Maybe<Scalars['DateTime']>;
  symptoms?: Maybe<Scalars['String']>;
  extraNotes?: Maybe<Scalars['String']>;
  onDemandConsultClassification?: Maybe<OnDemandConsultClassificationType>;
  category?: Maybe<ConsultCategory>;
  claimed?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressType>;
  secondaryAddress?: Maybe<AddressType>;
  confirmedByUser?: Maybe<UserType>;
  medicalSpecialization?: Maybe<MedicalSpecializationModelType>;
  specialInstructions?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<ConsultCancellationReason>;
  cancellationReasonDetails?: Maybe<Scalars['String']>;
  member?: Maybe<LimitedMemberType>;
  doctor?: Maybe<DoctorProfileType>;
  state: ScheduledConsultState;
};

export enum GrapheneScheduledConsultClassification {
  None = 'None',
  InPersonConsult = 'IN_PERSON_CONSULT',
  OnDemandConsult = 'ON_DEMAND_CONSULT'
}

export enum OnDemandConsultClassificationType {
  NotApplicable = 'NOT_APPLICABLE',
  Welcome = 'WELCOME',
  AnnualCheckup = 'ANNUAL_CHECKUP',
  PrimaryCare = 'PRIMARY_CARE',
  NextAvailable = 'NEXT_AVAILABLE',
  OnDemand = 'ON_DEMAND',
  NightCall = 'NIGHT_CALL',
  FirstTime = 'FIRST_TIME',
  FollowUp = 'FOLLOW_UP'
}

export enum ConsultCategory {
  Preventive = 'PREVENTIVE',
  General = 'GENERAL',
  InPerson = 'IN_PERSON',
  Remote = 'REMOTE'
}

export enum ConsultCancellationReason {
  MemberInsufficientTime = 'MEMBER_INSUFFICIENT_TIME',
  MemberInadequateLocation = 'MEMBER_INADEQUATE_LOCATION',
  MemberBadInternet = 'MEMBER_BAD_INTERNET',
  DoctorUrgency = 'DOCTOR_URGENCY',
  StuckConsultCard = 'STUCK_CONSULT_CARD',
  MemberNoShow = 'MEMBER_NO_SHOW',
  CancelledByMember = 'CANCELLED_BY_MEMBER',
  NonReferenceMedical = 'NON_REFERENCE_MEDICAL',
  NoMemberResponse = 'NO_MEMBER_RESPONSE',
  Other = 'OTHER'
}

export type LimitedMemberType = {
  __typename?: 'LimitedMemberType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
  dateOfBirth?: Maybe<Scalars['Date']>;
  nickname: Scalars['String'];
  pronouns?: Maybe<Scalars['String']>;
  actualGender?: Maybe<Scalars['String']>;
  gender: MemberGender;
  assignedPrimaryCareDoctor?: Maybe<DoctorType>;
  antiquitySince?: Maybe<Scalars['Date']>;
  hasActiveHealthPlan?: Maybe<Scalars['Boolean']>;
  healthPlanUuid?: Maybe<Scalars['String']>;
  healthPlanProductFamily?: Maybe<Scalars['String']>;
  callToAction?: Maybe<Scalars['String']>;
  briefBusinessBeneficiary?: Maybe<BriefBusinessBeneficiaryType>;
};

export type BriefBusinessBeneficiaryType = {
  __typename?: 'BriefBusinessBeneficiaryType';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  beneficiaryCategory?: Maybe<BusinessBeneficiaryCategory>;
  briefBusiness?: Maybe<BusinessLiteType>;
  briefBusinessGroupConfig?: Maybe<BriefBusinessGroupsConfigType>;
  briefEmployee?: Maybe<BriefBusinessBeneficiaryType>;
  business?: Maybe<BusinessMiniType>;
};

export type BusinessLiteType = {
  __typename?: 'BusinessLiteType';
  id: Scalars['ID'];
  commonName?: Maybe<Scalars['String']>;
  maternityAddon: Scalars['Boolean'];
  subscriptionType?: Maybe<SubscriptionType>;
  legalName: Scalars['String'];
  personCategory?: Maybe<PersonCategory>;
  purpose?: Maybe<Scalars['String']>;
  referenceCode: Scalars['String'];
  nationalityCountryCode?: Maybe<CountryCode>;
  tradeRegistrationId?: Maybe<Scalars['String']>;
  incorporationDate?: Maybe<Scalars['Date']>;
  state?: Maybe<BusinessState>;
  taxRegistrationDoc?: Maybe<DocumentBusinessLiteType>;
  digitalTaxSignatureProofDoc?: Maybe<DocumentBusinessLiteType>;
  tradeRegistrationProofDoc?: Maybe<DocumentBusinessLiteType>;
  registrationToCommercePublicRegistryDoc?: Maybe<DocumentBusinessLiteType>;
  proofOfAddressDoc?: Maybe<DocumentBusinessLiteType>;
  quoteBeneficiariesNumber: Scalars['Int'];
  complianceTier: BusinessComplianceTier;
  maternityAddonWaitingPeriodMonths?: Maybe<MaternityWaitingPeriodMonths>;
  isCollective: Scalars['Boolean'];
  subscriptionFileSent: Scalars['Boolean'];
  stakeholders?: Maybe<Array<BusinessStakeholderLiteType>>;
  name?: Maybe<Scalars['String']>;
  registeredInAppBeneficiaries?: Maybe<Scalars['Int']>;
  notRegisteredInAppBeneficiaries?: Maybe<Scalars['Int']>;
  satisfactionSurveyIsAvailable?: Maybe<Scalars['Boolean']>;
  daysSinceFirstBusinessSignature?: Maybe<Scalars['Int']>;
  legalRepresentativeStakeholder?: Maybe<BusinessStakeholderLiteType>;
  ownerUser?: Maybe<UserBusinessLiteType>;
  payoutMethod?: Maybe<PaymentMethodBusinessLiteType>;
  address?: Maybe<AddressBusinessLiteType>;
  billingInfo?: Maybe<BillingInfoBusinessLiteType>;
  latestPendingRenewalProposal?: Maybe<BusinessPlansGeneralConfigProposalLiteType>;
  documentChecks?: Maybe<Array<BusinessCheckLiteType>>;
  businessGroupsConfigs?: Maybe<Array<BusinessGroupConfigLiteType>>;
  notRegisteredInAppBeneficiariesList?: Maybe<Array<BusinessBeneficiaryLiteType>>;
  adminSiteStepperState?: Maybe<AdminSiteStepperState>;
};

export type DocumentBusinessLiteType = {
  __typename?: 'DocumentBusinessLiteType';
  id: Scalars['ID'];
  category?: Maybe<DocumentCategory>;
  s3Key?: Maybe<Scalars['String']>;
  isValidated: Scalars['Boolean'];
  mimeType?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  latestVersion?: Maybe<Scalars['Int']>;
  documentVersions?: Maybe<Array<DocumentVersionBusinessLiteType>>;
};

export type DocumentVersionBusinessLiteType = {
  __typename?: 'DocumentVersionBusinessLiteType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  selfDocument?: Maybe<DocumentBusinessLiteType>;
};

export type BusinessStakeholderLiteType = {
  __typename?: 'BusinessStakeholderLiteType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  idDoc?: Maybe<DocumentBusinessLiteType>;
  roleProofDoc?: Maybe<DocumentBusinessLiteType>;
  foreignerDoc?: Maybe<DocumentBusinessLiteType>;
  curpDoc?: Maybe<DocumentBusinessLiteType>;
  extraRoleProofDocNeeded: Scalars['Boolean'];
  foreignerDocProofNeeded: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  curpJustifiableAbsence: Scalars['Boolean'];
  curpAbsenceJustification?: Maybe<Scalars['String']>;
};

export type PaymentMethodBusinessLiteType = {
  __typename?: 'PaymentMethodBusinessLiteType';
  id: Scalars['ID'];
  clabe?: Maybe<Scalars['String']>;
};

export type AddressBusinessLiteType = {
  __typename?: 'AddressBusinessLiteType';
  id: Scalars['ID'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  city: Scalars['String'];
  zipcode: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
};

export type BillingInfoBusinessLiteType = {
  __typename?: 'BillingInfoBusinessLiteType';
  legalName: Scalars['String'];
  taxNumber: Scalars['String'];
  taxRegime?: Maybe<SatRegime>;
  address?: Maybe<AddressBusinessLiteType>;
};

export type BusinessPlansGeneralConfigProposalLiteType = {
  __typename?: 'BusinessPlansGeneralConfigProposalLiteType';
  id: Scalars['ID'];
};

export type BusinessCheckLiteType = {
  __typename?: 'BusinessCheckLiteType';
  id: Scalars['ID'];
  checkType: BusinessCheckCheckType;
  justifiableAbsence: Scalars['Boolean'];
  absenceJustification?: Maybe<Scalars['String']>;
};

export type BusinessGroupConfigLiteType = {
  __typename?: 'BusinessGroupConfigLiteType';
  id: Scalars['ID'];
  allowMultimember: Scalars['Boolean'];
  dentalAddon: Scalars['Boolean'];
  intlCoverageAddon: Scalars['Boolean'];
  dentalMembershipAddon: Scalars['Boolean'];
  extendedHospitalsCoverage: Scalars['Boolean'];
  insuredAmountCentsCoverage?: Maybe<InsuredAmountCents>;
  name?: Maybe<Scalars['String']>;
  coinsuranceConfig?: Maybe<CoInsuranceConfigFactor>;
  description?: Maybe<Scalars['String']>;
};

export type BusinessBeneficiaryLiteType = {
  __typename?: 'BusinessBeneficiaryLiteType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  beneficiaryCategory?: Maybe<BusinessBeneficiaryCategory>;
  state?: Maybe<BusinessBeneficiaryState>;
};

export enum ScheduledConsultState {
  ToBeRequested = 'TO_BE_REQUESTED',
  Requested = 'REQUESTED',
  Scheduled = 'SCHEDULED',
  Confirmed = 'CONFIRMED',
  DueSoon = 'DUE_SOON',
  Done = 'DONE',
  Cancelled = 'CANCELLED'
}

export type HuliEmrEntryType = {
  __typename?: 'HuliEmrEntryType';
  id: Scalars['ID'];
  huliConsultId: Scalars['Int'];
  patientUuid?: Maybe<Scalars['String']>;
  doctorHuliId?: Maybe<Scalars['Int']>;
  doctorName?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  healthPlanUuid?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  gender?: Maybe<HuliEmrEntryGender>;
  bmi?: Maybe<Scalars['Float']>;
  reason?: Maybe<Scalars['String']>;
  initialConsult?: Maybe<Scalars['Boolean']>;
  followUpConsult?: Maybe<Scalars['Boolean']>;
  numberOfLaboratoryTests: Scalars['Int'];
  laboratoryTests?: Maybe<Scalars['String']>;
  numberOfMedicines: Scalars['Int'];
  medicines?: Maybe<Scalars['String']>;
  suffering?: Maybe<Scalars['String']>;
  physicalNote?: Maybe<Scalars['String']>;
  planNote?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  appointmentDatetime?: Maybe<Scalars['DateTime']>;
  consultStartsAt?: Maybe<Scalars['DateTime']>;
  emrType?: Maybe<EmrType>;
  reviewedAt?: Maybe<Scalars['DateTime']>;
  completedSync: Scalars['Boolean'];
  classificationNotes?: Maybe<Scalars['String']>;
  huliClinicId?: Maybe<Scalars['Int']>;
  inPersonConsult?: Maybe<InPersonConsultType>;
  reviewedBy?: Maybe<UserType>;
  tobacco?: Maybe<Scalars['Boolean']>;
  patientId?: Maybe<Scalars['String']>;
  healthPlanId?: Maybe<Scalars['String']>;
  doctorIdUser?: Maybe<Scalars['Int']>;
  huliIdEvent?: Maybe<Scalars['Int']>;
  onDemandConsult?: Maybe<OnDemandConsultType>;
  service?: Maybe<ServicesType>;
  doctor?: Maybe<DoctorType>;
  diagnosticImpression?: Maybe<Scalars['String']>;
  huliPatientFileId?: Maybe<Scalars['Int']>;
  consultAddress?: Maybe<AddressType>;
  healthPlan?: Maybe<HealthPlanType>;
};

export enum HuliEmrEntryGender {
  U = 'U',
  M = 'M',
  F = 'F'
}

export enum EmrType {
  PreventiveVc = 'PREVENTIVE_VC',
  OnDemandConsult = 'ON_DEMAND_CONSULT',
  InPersonConsult = 'IN_PERSON_CONSULT',
  DuplicatedConsult = 'DUPLICATED_CONSULT',
  UnclassifiedConsult = 'UNCLASSIFIED_CONSULT',
  RehabilitationTherapy = 'REHABILITATION_THERAPY',
  FolowupNotes = 'FOLOWUP_NOTES',
  IgnoreRecord = 'IGNORE_RECORD'
}

export type LimitedIncludedConsultType = {
  __typename?: 'LimitedIncludedConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member?: Maybe<MemberType>;
  updatedAt: Scalars['DateTime'];
  healthPlan?: Maybe<HealthPlanType>;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
  consultPtr: LimitedConsultType;
  scheduledconsultSet: Array<LimitedScheduledConsultType>;
  doctor?: Maybe<DoctorType>;
  consultDate?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  serviceCoverageRulingState?: Maybe<RulingState>;
  prescriptions?: Maybe<Array<Maybe<PrescriptionType>>>;
  medicalNotes?: Maybe<Array<Maybe<MedicalNoteType>>>;
  hasPayInAndPayOut?: Maybe<Scalars['Boolean']>;
};

export type LimitedRehabilitationTherapyType = {
  __typename?: 'LimitedRehabilitationTherapyType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member?: Maybe<MemberType>;
  updatedAt: Scalars['DateTime'];
  healthPlan?: Maybe<HealthPlanType>;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  consultPtr: LimitedConsultType;
  servicePtr: ServicesType;
  huliEmrEntry?: Maybe<HuliEmrEntryType>;
  scheduledconsultSet: Array<LimitedScheduledConsultType>;
  doctor?: Maybe<DoctorType>;
  consultDate?: Maybe<Scalars['DateTime']>;
  serviceCoverageRulingState?: Maybe<RulingState>;
  prescriptions?: Maybe<Array<Maybe<PrescriptionType>>>;
  medicalNotes?: Maybe<Array<Maybe<MedicalNoteType>>>;
};

export type WatchOnDemandConsultType = {
  __typename?: 'WatchOnDemandConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  state: OnDemandConsultState;
  reason: Scalars['String'];
  classification?: Maybe<Scalars['String']>;
  zoomCall?: Maybe<ZoomCall>;
  patientId?: Maybe<Scalars['Int']>;
  doctorId?: Maybe<Scalars['Int']>;
  patientFirstName?: Maybe<Scalars['String']>;
  patientFirstLastName?: Maybe<Scalars['String']>;
  patientSecondLastName?: Maybe<Scalars['String']>;
  patientDateOfBirth?: Maybe<Scalars['String']>;
  patientGender?: Maybe<GenderType>;
  memberUuid?: Maybe<Scalars['String']>;
  welcomeConsultId?: Maybe<Scalars['Int']>;
  doctorName?: Maybe<Scalars['String']>;
};

export enum GenderType {
  Unknown = 'UNKNOWN',
  Male = 'MALE',
  Female = 'FEMALE'
}

export type ServiceCommentType = {
  __typename?: 'ServiceCommentType';
  createdAt: Scalars['DateTime'];
  service: ServicesType;
  author: UserType;
  comment: Scalars['String'];
};

export enum ServicePayoutLogStates {
  InReview = 'IN_REVIEW',
  PendingInvoice = 'PENDING_INVOICE',
  BillSent = 'BILL_SENT',
  ProcessingPayment = 'PROCESSING_PAYMENT',
  Paid = 'PAID',
  FailedPayment = 'FAILED_PAYMENT'
}

export type MedicalFeeType = {
  __typename?: 'MedicalFeeType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  servicePtr: ServicesType;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  performedProcedure?: Maybe<PerformedProcedureType>;
  doctor?: Maybe<DoctorType>;
};

export type PerformedProcedureType = {
  __typename?: 'PerformedProcedureType';
  id: Scalars['ID'];
  medicalNote: LimitedMedicalNoteType;
  procedureCategory?: Maybe<ProcedureCategoryType>;
  medicalReason?: Maybe<Scalars['String']>;
  medicalEvidences: Array<DocumentType>;
  medicalFees?: Maybe<MedicalFeeType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
};

export type LimitedMedicalNoteType = {
  __typename?: 'LimitedMedicalNoteType';
  id: Scalars['ID'];
  member: LimitedMemberType;
  doctor: DoctorProfileType;
  occurrenceTime: Scalars['DateTime'];
  noteType?: Maybe<NoteType>;
  state: NoteState;
  cancellationReason?: Maybe<ConsultCancellationReason>;
  cancellationReasonDetails?: Maybe<Scalars['String']>;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  service?: Maybe<LimitedServicesType>;
  prescriptions?: Maybe<Array<PrescriptionType>>;
  isDischarge?: Maybe<Scalars['Boolean']>;
};

export enum NoteType {
  InPerson = 'IN_PERSON',
  Video = 'VIDEO',
  Included = 'INCLUDED',
  Hospitalization = 'HOSPITALIZATION',
  Urgency = 'URGENCY',
  RehabilitationTherapy = 'REHABILITATION_THERAPY'
}

export enum NoteState {
  Open = 'OPEN',
  Completed = 'COMPLETED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export type ProcedureCategoryType = {
  __typename?: 'ProcedureCategoryType';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  cptKey?: Maybe<Scalars['String']>;
  evidenceNeededToSchedule: Scalars['Boolean'];
  evidenceToScheduleDescription?: Maybe<Scalars['String']>;
  evidenceNeededToPerform: Scalars['Boolean'];
  evidenceToPerformDescription?: Maybe<Scalars['String']>;
  type?: Maybe<ProcedureCategoryClassification>;
  isScheduled: Scalars['Boolean'];
  medicalSpecialization?: Maybe<MedicalSpecializationModelType>;
  cptPriceReferenceCents?: Maybe<Scalars['Int']>;
};

export enum ProcedureCategoryClassification {
  InOffice = 'IN_OFFICE',
  Hospital = 'HOSPITAL'
}

export type DischargeInfoType = {
  __typename?: 'DischargeInfoType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: LimitedMedicalNoteType;
  entryDate?: Maybe<Scalars['Date']>;
  dischargeDate?: Maybe<Scalars['Date']>;
  visitsAmount?: Maybe<Scalars['Int']>;
  dischargeReason?: Maybe<DischargeReason>;
  evolution?: Maybe<Scalars['String']>;
  staySummary?: Maybe<Scalars['String']>;
};

export enum DischargeReason {
  Improvement = 'IMPROVEMENT',
  Death = 'DEATH',
  Voluntary = 'VOLUNTARY'
}

export type NoteBasicInfoType = {
  __typename?: 'NoteBasicInfoType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: LimitedMedicalNoteType;
  weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  bloodType?: Maybe<BloodType>;
  percentile?: Maybe<Scalars['Int']>;
  zScore?: Maybe<Scalars['Float']>;
  cephalicPerimeter?: Maybe<Scalars['Float']>;
};

export enum BloodType {
  ONegative = 'O_NEGATIVE',
  OPositive = 'O_POSITIVE',
  ANegative = 'A_NEGATIVE',
  APositive = 'A_POSITIVE',
  BNegative = 'B_NEGATIVE',
  BPositive = 'B_POSITIVE',
  AbNegative = 'AB_NEGATIVE',
  AbPositive = 'AB_POSITIVE'
}

export type EvaluationType = {
  __typename?: 'EvaluationType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: LimitedMedicalNoteType;
  diagnosticImpression?: Maybe<Scalars['String']>;
  cieKeys: Array<Scalars['String']>;
  diagnostics: Array<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type PhysicalExamType = {
  __typename?: 'PhysicalExamType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: LimitedMedicalNoteType;
  systolicBloodPressure?: Maybe<Scalars['Int']>;
  diastolicBloodPressure?: Maybe<Scalars['Int']>;
  heartRate?: Maybe<Scalars['Int']>;
  oxygenation?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  breathingFrequency?: Maybe<Scalars['Int']>;
  bodyFat?: Maybe<Scalars['Float']>;
  muscleMass?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
};

export type PhysicalActivityType = {
  __typename?: 'PhysicalActivityType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: LimitedMedicalNoteType;
  hasExercise?: Maybe<Scalars['Boolean']>;
  exerciseActivities?: Maybe<Scalars['String']>;
  exerciseWeeklyFrequency?: Maybe<Scalars['Int']>;
  exerciseNotes?: Maybe<Scalars['String']>;
};

export type ObstetricGynecologicalInfoType = {
  __typename?: 'ObstetricGynecologicalInfoType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: LimitedMedicalNoteType;
  hasBirthControl?: Maybe<Scalars['Boolean']>;
  hasMenstrualCycle?: Maybe<Scalars['Boolean']>;
  hasBeenPregnant?: Maybe<Scalars['Boolean']>;
  hasGynecologicalExams?: Maybe<Scalars['Boolean']>;
};

export type InterrogationType = {
  __typename?: 'InterrogationType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: LimitedMedicalNoteType;
  system?: Maybe<InterrogationSystem>;
  systemName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export enum InterrogationSystem {
  Articulate = 'ARTICULATE',
  Cardiovascular = 'CARDIOVASCULAR',
  Circulatory = 'CIRCULATORY',
  Digestive = 'DIGESTIVE',
  Endocrine = 'ENDOCRINE',
  Skeletal = 'SKELETAL',
  Excretory = 'EXCRETORY',
  Immune = 'IMMUNE',
  Integumentary = 'INTEGUMENTARY',
  Lymphatic = 'LYMPHATIC',
  Locomotive = 'LOCOMOTIVE',
  Muscular = 'MUSCULAR',
  Nervous = 'NERVOUS',
  Reproductive = 'REPRODUCTIVE',
  Respiratory = 'RESPIRATORY',
  Other = 'OTHER'
}

export type ExplorationType = {
  __typename?: 'ExplorationType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: LimitedMedicalNoteType;
  segment?: Maybe<ExplorationSegment>;
  segmentName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export enum ExplorationSegment {
  Head = 'HEAD',
  Neck = 'NECK',
  Chest = 'CHEST',
  Abdomen = 'ABDOMEN',
  Spine = 'SPINE',
  UpperLimbs = 'UPPER_LIMBS',
  Genitals = 'GENITALS',
  LowerLimbs = 'LOWER_LIMBS',
  Other = 'OTHER'
}

export type ProcedureType = {
  __typename?: 'ProcedureType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: LimitedMedicalNoteType;
  document?: Maybe<DocumentType>;
  procedure?: Maybe<ProcedureName>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  pathologySendingRequired: Scalars['Boolean'];
  scheduled: Scalars['Boolean'];
};

export enum ProcedureName {
  Ultrasound = 'ULTRASOUND',
  Electrocardiogram = 'ELECTROCARDIOGRAM',
  Papanicolaou = 'PAPANICOLAOU',
  BandagesSplintPlacementPlaster = 'BANDAGES_SPLINT_PLACEMENT_PLASTER',
  Sutures = 'SUTURES',
  AbscessDrainage = 'ABSCESS_DRAINAGE',
  NasalBleedingControl = 'NASAL_BLEEDING_CONTROL',
  AudiometrySpeechAudiometryImpedanciometryVideonystagmography = 'AUDIOMETRY_SPEECH_AUDIOMETRY_IMPEDANCIOMETRY_VIDEONYSTAGMOGRAPHY',
  Other = 'OTHER'
}

export type AddendumType = {
  __typename?: 'AddendumType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: LimitedMedicalNoteType;
  document?: Maybe<DocumentType>;
  notes?: Maybe<Scalars['String']>;
};

export type ExamType = {
  __typename?: 'ExamType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  medicalNote?: Maybe<LimitedMedicalNoteType>;
  document?: Maybe<DocumentType>;
  doctor?: Maybe<DoctorProfileType>;
  uploadedAt: Scalars['Date'];
  notes?: Maybe<Scalars['String']>;
};

export type ScheduledProcedureType = {
  __typename?: 'ScheduledProcedureType';
  id: Scalars['ID'];
  medicalNote: LimitedMedicalNoteType;
  procedureCategory?: Maybe<ProcedureCategoryType>;
  procedureName?: Maybe<Scalars['String']>;
  medicalReason?: Maybe<Scalars['String']>;
  tentativeDate?: Maybe<Scalars['Date']>;
  inHospital: Scalars['Boolean'];
  isUrgent: Scalars['Boolean'];
  durationHours?: Maybe<Scalars['Int']>;
  stayDays?: Maybe<Scalars['Int']>;
  hasExtraRequirements: Scalars['Boolean'];
  extraRequirements?: Maybe<Scalars['String']>;
  medicalEvidences: Array<DocumentType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
};

export type PharmacyOrderType = {
  __typename?: 'PharmacyOrderType';
  id: Scalars['ID'];
  state: PharmacyOrderState;
  handledBy?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  providerConfirmationId?: Maybe<Scalars['String']>;
  xmlProvided: Scalars['Boolean'];
  prescription?: Maybe<PrescriptionType>;
  user?: Maybe<UserType>;
  items?: Maybe<Array<Maybe<PharmacyOrderItemType>>>;
  amount?: Maybe<Scalars['Int']>;
  xmlDocumentId?: Maybe<Scalars['Int']>;
  provider?: Maybe<ProviderBranchType>;
};

export enum PharmacyOrderState {
  Dr = 'DR',
  Ip = 'IP',
  Cm = 'CM',
  Cl = 'CL'
}

export type PharmacyOrderItemType = {
  __typename?: 'PharmacyOrderItemType';
  id: Scalars['ID'];
  description: Scalars['String'];
  subtotal: Scalars['Int'];
  vat: Scalars['Int'];
  amount: Scalars['Int'];
};

export type HospitalServiceType = {
  __typename?: 'HospitalServiceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
  hospitalizationDays: Scalars['Int'];
  dischargeDate?: Maybe<Scalars['Date']>;
};

export type OtherServiceType = {
  __typename?: 'OtherServiceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
};

export type RehabilitationTherapyType = {
  __typename?: 'RehabilitationTherapyType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  consultPtr: LimitedConsultType;
  servicePtr: ServicesType;
  huliEmrEntry?: Maybe<HuliEmrEntryType>;
  scheduledconsultSet: Array<LimitedScheduledConsultType>;
};

export type LabStudiesType = {
  __typename?: 'LabStudiesType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
};

export type MedicinesType = {
  __typename?: 'MedicinesType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
};

export type MedicalEquipmentType = {
  __typename?: 'MedicalEquipmentType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
};

export type HomeNurseType = {
  __typename?: 'HomeNurseType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
};

export type ProstheticsAndMechanicalEquipmentType = {
  __typename?: 'ProstheticsAndMechanicalEquipmentType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
};

export type AmbulanceType = {
  __typename?: 'AmbulanceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
};

export type ExternalConsultType = {
  __typename?: 'ExternalConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
};

export type ExternalVideoConsultType = {
  __typename?: 'ExternalVideoConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
};

export type UrgencyType = {
  __typename?: 'UrgencyType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
};

export type IncomeType = {
  __typename?: 'IncomeType';
  id: Scalars['ID'];
  contentType?: Maybe<ContentTypeType>;
  objectId: Scalars['Int'];
  subtotalCents: Scalars['Int'];
  paymentFlow?: Maybe<IncomePaymentFlow>;
  description?: Maybe<Scalars['String']>;
  totalCents: Scalars['Int'];
  currency: IncomeCurrency;
  relatedIncome?: Maybe<IncomeType>;
  satPaymentMethod?: Maybe<SatPaymentMethod>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  contentObject?: Maybe<ContentObjectType>;
  electronicVoucherStatus?: Maybe<Scalars['String']>;
  recipient?: Maybe<UserType>;
  issuer?: Maybe<UserType>;
};

export enum IncomePaymentFlow {
  In = 'IN',
  Out = 'OUT'
}

export enum IncomeCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export type ContentObjectType = HealthPlanType | OnDemandConsultTimeLogType | HealthPlanAmendmentType | ServicesType | OnDemandConsultType | InPersonConsultType | PharmacyOrderType | MedicalFeeType | HospitalServiceType | OtherServiceType | RehabilitationTherapyType | LabStudiesType | MedicinesType | MedicalEquipmentType | HomeNurseType | ProstheticsAndMechanicalEquipmentType | AmbulanceType | IncentiveType | ExternalConsultType | ExternalVideoConsultType | UrgencyType | InPersonConsultByProviderType | SurgicalMaterialsType;

export type OnDemandConsultTimeLogType = {
  __typename?: 'OnDemandConsultTimeLogType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  rateType?: Maybe<OnDemandConsultTimeLogRateType>;
  subtotalCents: Scalars['Int'];
  vatCents: Scalars['Int'];
  withholdTaxesCents: Scalars['Int'];
  totalCents: Scalars['Int'];
  paymentPeriodStartsAt?: Maybe<Scalars['DateTime']>;
  paymentPeriodEndsAt?: Maybe<Scalars['DateTime']>;
  hoursLogged: Scalars['Int'];
  doctor: DoctorProfileType;
};

export enum OnDemandConsultTimeLogRateType {
  Wd = 'WD',
  We = 'WE'
}

export type IncentiveType = {
  __typename?: 'IncentiveType';
  id: Scalars['ID'];
  state: IncentiveState;
};

export enum IncentiveState {
  Cr = 'CR',
  Gr = 'GR',
  Ca = 'CA'
}

export type SurgicalMaterialsType = {
  __typename?: 'SurgicalMaterialsType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member: MemberType;
  updatedAt: Scalars['DateTime'];
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
};

export enum ElectronicVoucherTaxesTaxType {
  Tasa = 'TASA',
  Cuota = 'CUOTA',
  Exento = 'EXENTO'
}

export enum ElectronicVoucherTaxesTaxId {
  A_002 = 'A_002',
  A_001 = 'A_001',
  A_003 = 'A_003'
}

export enum ElectronicVoucherTaxesTaxClass {
  Wt = 'WT',
  Tt = 'TT'
}

export type ElectronicVoucherTransactionType = {
  __typename?: 'ElectronicVoucherTransactionType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  electronicVoucherGatewayRequest: Scalars['String'];
  electronicVoucherGatewayConfirmation: Scalars['String'];
  electronicVoucherGatewayResult?: Maybe<ElectronicVoucherTransactionElectronicVoucherGatewayResult>;
  isAutoEmitted: Scalars['Boolean'];
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  electronicVoucherGatewayCancellationResult?: Maybe<Scalars['String']>;
  signingErrorCategory?: Maybe<ElectronicVoucherTransactionSigningErrorCategory>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failureReminderSentAt?: Maybe<Scalars['DateTime']>;
  electronicVoucherGatewayConfirmationErrors?: Maybe<Scalars['JSONString']>;
};

export enum ElectronicVoucherTransactionElectronicVoucherGatewayResult {
  Ok = 'OK',
  Fl = 'FL'
}

export enum ElectronicVoucherTransactionSigningErrorCategory {
  CfdiUseError = 'CFDI_USE_ERROR',
  CsdNotAuthorized = 'CSD_NOT_AUTHORIZED',
  DuplicatedVoucher = 'DUPLICATED_VOUCHER',
  GeneralPublicInfoError = 'GENERAL_PUBLIC_INFO_ERROR',
  InvalidVoucherStructure = 'INVALID_VOUCHER_STRUCTURE',
  IssuerRfcNotRegisteredInReachcore = 'ISSUER_RFC_NOT_REGISTERED_IN_REACHCORE',
  IssuerRfcNotValid = 'ISSUER_RFC_NOT_VALID',
  RecipientNameError = 'RECIPIENT_NAME_ERROR',
  RecipientRfcNotRegisteredInSat = 'RECIPIENT_RFC_NOT_REGISTERED_IN_SAT',
  RecipientRfcNotValid = 'RECIPIENT_RFC_NOT_VALID',
  RecipientTaxAddressExpeditionPlaceError = 'RECIPIENT_TAX_ADDRESS_EXPEDITION_PLACE_ERROR',
  RecipientTaxAddressMatchError = 'RECIPIENT_TAX_ADDRESS_MATCH_ERROR',
  RegimeError = 'REGIME_ERROR',
  WithholdTaxesError = 'WITHHOLD_TAXES_ERROR',
  TotalFieldSumError = 'TOTAL_FIELD_SUM_ERROR',
  TotalTransferredTaxesSumError = 'TOTAL_TRANSFERRED_TAXES_SUM_ERROR',
  TransferAmountError = 'TRANSFER_AMOUNT_ERROR',
  ZipcodeError = 'ZIPCODE_ERROR',
  UnknownError = 'UNKNOWN_ERROR'
}

export type LimitedHealthPlanMemberType = {
  __typename?: 'LimitedHealthPlanMemberType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  nickname: Scalars['String'];
  pronouns?: Maybe<Scalars['String']>;
  actualGender?: Maybe<Scalars['String']>;
  gender: MemberGender;
  user?: Maybe<UserType>;
  healthPlan?: Maybe<HealthPlanType>;
  residenceAddress?: Maybe<AddressType>;
  onDemandConsults?: Maybe<Array<Maybe<OnDemandConsultType>>>;
  welcomeConsultStatus?: Maybe<WelcomeConsultStatusCategory>;
  services?: Maybe<Array<Maybe<LimitedServicesType>>>;
  briefBusinessBeneficiary?: Maybe<BriefBusinessBeneficiaryType>;
};

export enum WelcomeConsultStatusCategory {
  ToBeScheduled = 'TO_BE_SCHEDULED',
  Scheduled = 'SCHEDULED',
  OptedOut = 'OPTED_OUT',
  Taken = 'TAKEN'
}

export type InvoiceItemHealthPlanRelatedDetails = {
  __typename?: 'InvoiceItemHealthPlanRelatedDetails';
  member?: Maybe<LimitedMemberType>;
  totalInstallments?: Maybe<Scalars['Int']>;
  concept?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  renewalDatetime?: Maybe<Scalars['DateTime']>;
  maximumCoverageCents?: Maybe<Scalars['Int']>;
  addOns?: Maybe<Array<Maybe<Scalars['String']>>>;
  annualTotalCents?: Maybe<Scalars['Int']>;
};

export type TransactionType = {
  __typename?: 'TransactionType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  paymentMethodInformation: Scalars['JSONString'];
  paymentGatewaySource?: Maybe<PaymentGatewaySourcesType>;
  currency: TransactionCurrency;
  amountCents: Scalars['Int'];
  transactionType: TransactionTransactionType;
  paymentGatewayConfirmation: Scalars['JSONString'];
  paymentGatewayResult?: Maybe<TransactionPaymentGatewayResult>;
  uuid: Scalars['UUID'];
  invoice?: Maybe<InvoiceType>;
  evidenceDoc?: Maybe<DocumentType>;
  cepAvailable?: Maybe<Scalars['Boolean']>;
};

export enum SatVoucherType {
  Income = 'INCOME',
  Expenditure = 'EXPENDITURE',
  Payment = 'PAYMENT'
}

export enum CustomerPaymentFlowType {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING'
}

export type MedicalSpecializationGroupProductConsultsType = {
  __typename?: 'MedicalSpecializationGroupProductConsultsType';
  medicalSpecializationGroup: MedicalSpecializationGroupType;
  product: ProductType;
  coveredYearlyConsults?: Maybe<Scalars['Int']>;
};

export type DoctorAssistantType = {
  __typename?: 'DoctorAssistantType';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Date']>;
  user?: Maybe<UserType>;
  doctor?: Maybe<DoctorProfileType>;
  firstName?: Maybe<Scalars['String']>;
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  fullName?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  canScheduleOfficeAddress?: Maybe<Scalars['Boolean']>;
  canScheduleSecondaryOfficeAddress?: Maybe<Scalars['Boolean']>;
  allowedDoctorOfficeAddresses?: Maybe<Array<AddressType>>;
};

export type PushTokenType = {
  __typename?: 'PushTokenType';
  token: Scalars['String'];
  platform: Scalars['String'];
  platformVersion: Scalars['String'];
};

export type PaymentMethodType = {
  __typename?: 'PaymentMethodType';
  id: Scalars['ID'];
  payoutTier?: Maybe<PayoutTiers>;
  sources?: Maybe<Array<Maybe<PaymentGatewaySourcesType>>>;
  primary?: Maybe<Scalars['Boolean']>;
  source?: Maybe<PaymentGatewaySourcesType>;
  notAppliedSources?: Maybe<Array<Maybe<PaymentGatewaySourcesType>>>;
};

export type RepresentedBeneficiaryHealthPlanType = {
  __typename?: 'RepresentedBeneficiaryHealthPlanType';
  id: Scalars['ID'];
  beneficiaryMember?: Maybe<MemberType>;
};

export type OwnedHealthPlanType = {
  __typename?: 'OwnedHealthPlanType';
  id: Scalars['ID'];
  beneficiaryMember?: Maybe<MemberType>;
  relationship: HealthPlanRelationship;
  state: HealthPlanState;
  owner?: Maybe<UserType>;
};

export enum GroupName {
  Adjusters = 'ADJUSTERS',
  SuperAdmin = 'SUPER_ADMIN',
  AdminEdit = 'ADMIN_EDIT',
  AdminRead = 'ADMIN_READ',
  Chiefs = 'CHIEFS',
  OpsHeads = 'OPS_HEADS',
  ManualPayments = 'MANUAL_PAYMENTS',
  Developers = 'DEVELOPERS',
  Doctors = 'DOCTORS',
  DoctorLeads = 'DOCTOR_LEADS',
  InternalDoctors = 'INTERNAL_DOCTORS',
  ExternalDoctors = 'EXTERNAL_DOCTORS',
  VcDoctors = 'VC_DOCTORS',
  IpcDoctors = 'IPC_DOCTORS',
  NightCallDoctors = 'NIGHT_CALL_DOCTORS',
  ChatDoctors = 'CHAT_DOCTORS',
  MedicalAssigners = 'MEDICAL_ASSIGNERS',
  MedicalOps = 'MEDICAL_OPS',
  Operations = 'OPERATIONS',
  Providers = 'PROVIDERS',
  Medical = 'MEDICAL',
  MedicalSabio = 'MEDICAL_SABIO',
  SalesManagers = 'SALES_MANAGERS',
  Impersonators = 'IMPERSONATORS',
  SuperDoctor = 'SUPER_DOCTOR',
  OpsFinance = 'OPS_FINANCE',
  DoctorAssistant = 'DOCTOR_ASSISTANT',
  IncentiveCodeAdmins = 'INCENTIVE_CODE_ADMINS',
  CompanyExperienceUnit = 'COMPANY_EXPERIENCE_UNIT',
  PaymentsSabio = 'PAYMENTS_SABIO',
  PaymentsHead = 'PAYMENTS_HEAD',
  Accounting = 'ACCOUNTING',
  PayoutTierTwo = 'PAYOUT_TIER_TWO',
  PayoutUnlimited = 'PAYOUT_UNLIMITED'
}

export type BranchOfficeHospitalInfoType = {
  __typename?: 'BranchOfficeHospitalInfoType';
  providerBranchOffice?: Maybe<ProviderBranchOfficeType>;
  id?: Maybe<Scalars['ID']>;
  laboratory?: Maybe<Scalars['Boolean']>;
  cabinet?: Maybe<Scalars['Boolean']>;
  bloodBank?: Maybe<Scalars['Boolean']>;
  inhalationTherapy?: Maybe<Scalars['Boolean']>;
  rehabilitation?: Maybe<Scalars['Boolean']>;
  hemodialysisUnit?: Maybe<Scalars['Boolean']>;
  chemotherapyUnit?: Maybe<Scalars['Boolean']>;
  catheterizationRoom?: Maybe<Scalars['Boolean']>;
  adultIcu?: Maybe<Scalars['Boolean']>;
  pediatricIcu?: Maybe<Scalars['Boolean']>;
  neonatalIcu?: Maybe<Scalars['Boolean']>;
  urgencies?: Maybe<Scalars['Boolean']>;
  operatingRoom?: Maybe<Scalars['Boolean']>;
  maternalAndChildArea?: Maybe<Scalars['Boolean']>;
  shortStayUnit?: Maybe<Scalars['Boolean']>;
  endoscopyUnit?: Maybe<Scalars['Boolean']>;
  transfusionService?: Maybe<Scalars['Boolean']>;
  computedAxialTomography?: Maybe<Scalars['Boolean']>;
  magneticResonance?: Maybe<Scalars['Boolean']>;
  mammograph?: Maybe<Scalars['Boolean']>;
  xRays?: Maybe<Scalars['Boolean']>;
  densitometer?: Maybe<Scalars['Boolean']>;
  electroencephalogram?: Maybe<Scalars['Boolean']>;
  echocardiogram?: Maybe<Scalars['Boolean']>;
  ultrasound?: Maybe<Scalars['Boolean']>;
  pet?: Maybe<Scalars['Boolean']>;
  countableBeds?: Maybe<Scalars['Int']>;
  uncountableBeds?: Maybe<Scalars['Int']>;
  standardRooms?: Maybe<Scalars['Int']>;
  totalRooms?: Maybe<Scalars['Int']>;
  emergencyOffices?: Maybe<Scalars['Int']>;
  emergencyCubicles?: Maybe<Scalars['Int']>;
  operatingRooms?: Maybe<Scalars['Int']>;
  offices?: Maybe<Scalars['Int']>;
};

export type BranchOfficeContactInfoType = {
  __typename?: 'BranchOfficeContactInfoType';
  providerBranchOffice?: Maybe<ProviderBranchOfficeType>;
  category?: Maybe<BranchOfficeContactInfoCategory>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export enum BranchOfficeContactInfoCategory {
  AgreementsAndInsurers = 'AGREEMENTS_AND_INSURERS',
  MedicalDirector = 'MEDICAL_DIRECTOR',
  Admission = 'ADMISSION',
  Collections = 'COLLECTIONS'
}

export enum DoctorTrainingStep {
  Consults = 'CONSULTS',
  Payments = 'PAYMENTS',
  Scheduling = 'SCHEDULING',
  ClinicalNotes = 'CLINICAL_NOTES',
  Procedures = 'PROCEDURES',
  Finished = 'FINISHED'
}

export type ZapsignContractUrlsType = {
  __typename?: 'ZapsignContractUrlsType';
  signerUrl?: Maybe<Scalars['String']>;
  doctorSignerUrl?: Maybe<Scalars['String']>;
};

export enum MemberBirthCountryCode {
  Ot = 'OT',
  Mx = 'MX'
}

export enum MemberNationalityCountryCode {
  Ot = 'OT',
  Mx = 'MX'
}

export type ComplianceAlertType = {
  __typename?: 'ComplianceAlertType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  subject?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['JSONString']>;
  comment?: Maybe<Scalars['String']>;
  reviewedByOfficer: Scalars['Boolean'];
  reviewedByOfficerAt?: Maybe<Scalars['DateTime']>;
  officerReported: Scalars['Boolean'];
  internalNotes?: Maybe<Scalars['String']>;
  reviewedByCommittee: Scalars['Boolean'];
  reviewedByCommitteeAt?: Maybe<Scalars['DateTime']>;
  reportToAuthority: Scalars['Boolean'];
  reportedAt?: Maybe<Scalars['DateTime']>;
  alertCategory?: Maybe<ComplianceAlertCategory>;
  member?: Maybe<MemberType>;
  applicationState?: Maybe<HealthPlanApplicationState>;
  reviewedByUser?: Maybe<UserType>;
};

export enum ComplianceAlertCategory {
  Unknown = 'UNKNOWN',
  PepCheckFailed = 'PEP_CHECK_FAILED',
  RestrictedCheckFailed = 'RESTRICTED_CHECK_FAILED',
  BannedCheckFailed = 'BANNED_CHECK_FAILED',
  IdCheckFailed = 'ID_CHECK_FAILED',
  DuplicatedAddress = 'DUPLICATED_ADDRESS',
  AddressUpdated = 'ADDRESS_UPDATED',
  Cancellation = 'CANCELLATION',
  HighRiskLevel = 'HIGH_RISK_LEVEL',
  OccupationCheckFailed = 'OCCUPATION_CHECK_FAILED',
  BirthCountryCheckFailed = 'BIRTH_COUNTRY_CHECK_FAILED',
  ComplianceSurpassedRefundLimit = 'COMPLIANCE_SURPASSED_REFUND_LIMIT',
  ComplianceSurpassedMonthlyLimit = 'COMPLIANCE_SURPASSED_MONTHLY_LIMIT',
  Location = 'LOCATION',
  HighAmountTransaction = 'HIGH_AMOUNT_TRANSACTION',
  InternalOperations = 'INTERNAL_OPERATIONS',
  GeneralUnusualOperations = 'GENERAL_UNUSUAL_OPERATIONS',
  UnusualCustomerBehaviorContractingProducts = 'UNUSUAL_CUSTOMER_BEHAVIOR_CONTRACTING_PRODUCTS',
  UnusualCustomerBehaviorPerformingOperations = 'UNUSUAL_CUSTOMER_BEHAVIOR_PERFORMING_OPERATIONS',
  UnusualCustomerBehaviorPaymentAmount = 'UNUSUAL_CUSTOMER_BEHAVIOR_PAYMENT_AMOUNT',
  UnusualNumberOfAmendmentsInAYear = 'UNUSUAL_NUMBER_OF_AMENDMENTS_IN_A_YEAR'
}

export type EmergencyContactType = {
  __typename?: 'EmergencyContactType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  member?: Maybe<MemberType>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  relationship?: Maybe<Relationship>;
  primary: Scalars['Boolean'];
};

export enum Relationship {
  Wife = 'WIFE',
  Husband = 'HUSBAND',
  Daughter = 'DAUGHTER',
  Self = 'SELF',
  Son = 'SON',
  Father = 'FATHER',
  Mother = 'MOTHER',
  Grandparent = 'GRANDPARENT',
  Sibling = 'SIBLING',
  Grandchild = 'GRANDCHILD',
  Nephew = 'NEPHEW',
  Other = 'OTHER'
}

export type AntiquityRequestType = {
  __typename?: 'AntiquityRequestType';
  id: Scalars['ID'];
  member?: Maybe<MemberType>;
  antiquitySince?: Maybe<Scalars['Date']>;
  insurer?: Maybe<Scalars['String']>;
  state?: Maybe<AntiquityRequestState>;
  uploadedDocuments?: Maybe<Array<Maybe<AntiquityDocuments>>>;
  hasRequiredDocuments: Scalars['Boolean'];
};

export enum AntiquityRequestState {
  Draft = 'DRAFT',
  InAmendment = 'IN_AMENDMENT',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export enum AntiquityDocuments {
  AntiquityCertificate = 'ANTIQUITY_CERTIFICATE',
  AntiquityPreviousPolicy = 'ANTIQUITY_PREVIOUS_POLICY',
  AntiquityProofOfPayment = 'ANTIQUITY_PROOF_OF_PAYMENT'
}

export type ActivationCampaignType = {
  __typename?: 'ActivationCampaignType';
  id: Scalars['ID'];
  state?: Maybe<ActivationCampaignState>;
  category?: Maybe<ActivationCampaignCategory>;
};

export enum ActivationCampaignState {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Visited = 'VISITED'
}

export enum ActivationCampaignCategory {
  BreastCancerOct_2023 = 'BREAST_CANCER_OCT_2023',
  CervicalCancerMar_2024 = 'CERVICAL_CANCER_MAR_2024'
}

export type NonPathologicalHistoryType = {
  __typename?: 'NonPathologicalHistoryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  filledByMember: Scalars['Boolean'];
  lifestyleNotes?: Maybe<Scalars['String']>;
  sleepHours?: Maybe<Scalars['Int']>;
  sleepNotes?: Maybe<Scalars['String']>;
  nutritionNotes?: Maybe<Scalars['String']>;
  hasExercise?: Maybe<Scalars['Boolean']>;
  exerciseActivities?: Maybe<Scalars['String']>;
  exerciseWeeklyFrequency?: Maybe<Scalars['Int']>;
  exerciseNotes?: Maybe<Scalars['String']>;
  smokingConsumption?: Maybe<NonPathologicalHistoryHabit>;
  smokingCigarettes?: Maybe<Scalars['Int']>;
  smokingYears?: Maybe<Scalars['Int']>;
  smokingSuspensionTime?: Maybe<NonPathologicalHistoryHabitYears>;
  smokingNotes?: Maybe<Scalars['String']>;
  alcoholConsumption?: Maybe<NonPathologicalHistoryHabit>;
  alcoholFrequency?: Maybe<Scalars['String']>;
  alcoholConsumptionTime?: Maybe<NonPathologicalHistoryHabitYears>;
  alcoholSuspensionTime?: Maybe<NonPathologicalHistoryHabitYears>;
  alcoholNotes?: Maybe<Scalars['String']>;
  drugsConsumption?: Maybe<NonPathologicalHistoryHabit>;
  drugsType?: Maybe<Drug>;
  drugsName?: Maybe<Scalars['String']>;
  drugsSuspensionTime?: Maybe<NonPathologicalHistoryHabitYears>;
  drugsNotes?: Maybe<Scalars['String']>;
  medicalHistory?: Maybe<MedicalHistoryType>;
};

export enum NonPathologicalHistoryHabit {
  Ongoing = 'ONGOING',
  Suspended = 'SUSPENDED',
  NotPresent = 'NOT_PRESENT'
}

export enum NonPathologicalHistoryHabitYears {
  LessThanOne = 'LESS_THAN_ONE',
  OneToFive = 'ONE_TO_FIVE',
  SixToTen = 'SIX_TO_TEN',
  TenToTwenty = 'TEN_TO_TWENTY',
  MoreThanTwenty = 'MORE_THAN_TWENTY'
}

export enum Drug {
  Amphetamine = 'AMPHETAMINE',
  Caffeine = 'CAFFEINE',
  Cannabis = 'CANNABIS',
  Cocaine = 'COCAINE',
  Mushrooms = 'MUSHROOMS',
  Mdma = 'MDMA',
  Lsd = 'LSD',
  OpiumAndDerivatives = 'OPIUM_AND_DERIVATIVES',
  Other = 'OTHER'
}

export enum MedicalHistorySectionState {
  Present = 'PRESENT',
  Denied = 'DENIED'
}

export enum MedicalHistoryMentalHealthPsychologyState {
  Present = 'PRESENT',
  Denied = 'DENIED'
}

export enum MedicalHistoryMentalHealthPsychiatryState {
  Present = 'PRESENT',
  Denied = 'DENIED'
}

export type PerinatalHistoryType = {
  __typename?: 'PerinatalHistoryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  isPresent?: Maybe<MedicalHistorySectionState>;
  gestationalAgeWeeks?: Maybe<Scalars['Int']>;
  gestationalAgeDays?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  headCircumference?: Maybe<Scalars['Float']>;
  gestationalAgeClassification?: Maybe<PerinatalHistoryGestationalAgeClassification>;
  gestationalWeightClassification?: Maybe<PerinatalHistoryGestationalWeightClassification>;
  apgar1MinScore?: Maybe<Scalars['Int']>;
  apgar5MinScore?: Maybe<Scalars['Int']>;
  reanimationRequired?: Maybe<Scalars['Boolean']>;
  reanimationNotes?: Maybe<Scalars['String']>;
  hadComplications?: Maybe<Scalars['Boolean']>;
  complicationsNotes?: Maybe<Scalars['String']>;
  developmentAlteration?: Maybe<Scalars['Boolean']>;
  developmentAlterationNotes?: Maybe<Scalars['String']>;
  incubationRequired?: Maybe<Scalars['Boolean']>;
  other?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  normalCardiologyScreening?: Maybe<Scalars['Boolean']>;
  cardiologyScreeningNotes?: Maybe<Scalars['String']>;
  normalHipScreening?: Maybe<Scalars['Boolean']>;
  hipScreeningNotes?: Maybe<Scalars['String']>;
  normalHearingScreening?: Maybe<Scalars['Boolean']>;
  hearingScreeningNotes?: Maybe<Scalars['String']>;
  normalVisualScreening?: Maybe<Scalars['Boolean']>;
  visualScreeningNotes?: Maybe<Scalars['String']>;
  normalMetabolicScreening?: Maybe<Scalars['Boolean']>;
  metabolicScreeningNotes?: Maybe<Scalars['String']>;
};

export enum PerinatalHistoryGestationalAgeClassification {
  Rnpr = 'RNPR',
  Rnt = 'RNT',
  Rnpt = 'RNPT'
}

export enum PerinatalHistoryGestationalWeightClassification {
  Peg = 'PEG',
  Aeg = 'AEG',
  Geg = 'GEG'
}

export type ParentPerinatalHistoryType = {
  __typename?: 'ParentPerinatalHistoryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  isPresent?: Maybe<MedicalHistorySectionState>;
  motherPregnancies?: Maybe<Scalars['Int']>;
  motherAbortions?: Maybe<Scalars['Int']>;
  motherDeliveries?: Maybe<Scalars['Int']>;
  motherCaesareanSections?: Maybe<Scalars['Int']>;
  motherPregnancyAge?: Maybe<Scalars['Int']>;
  motherHeight?: Maybe<Scalars['Float']>;
  fatherHeight?: Maybe<Scalars['Float']>;
  expectedHeight?: Maybe<Scalars['Float']>;
  pregnancyEvolution?: Maybe<ParentPerinatalHistoryPregnancyEvolution>;
  obstetrician?: Maybe<Scalars['String']>;
  deliveryType?: Maybe<ParentPerinatalHistoryDeliveryType>;
  notes?: Maybe<Scalars['String']>;
  pregnancyComplications?: Maybe<Scalars['Boolean']>;
  pregnancyComplicationsNotes?: Maybe<Scalars['String']>;
};

export enum ParentPerinatalHistoryPregnancyEvolution {
  Normal = 'NORMAL',
  Abnormal = 'ABNORMAL',
  HighRisk = 'HIGH_RISK'
}

export enum ParentPerinatalHistoryDeliveryType {
  Spontaneous = 'SPONTANEOUS',
  Conducted = 'CONDUCTED',
  Instrumented = 'INSTRUMENTED',
  IntrapartumCaesarean = 'INTRAPARTUM_CAESAREAN',
  ElectiveCaesarean = 'ELECTIVE_CAESAREAN'
}

export type BasicInfoType = {
  __typename?: 'BasicInfoType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  date?: Maybe<Scalars['Date']>;
  weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  bloodType?: Maybe<BloodType>;
};

export type ObstetricGynecologicalHistoryType = {
  __typename?: 'ObstetricGynecologicalHistoryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  hasBirthControl?: Maybe<Scalars['Boolean']>;
  birthControlName?: Maybe<ObstetricGynecologicalHistoryBirthControlName>;
  birthControlTimeUsed?: Maybe<Scalars['String']>;
  birthControlNotes?: Maybe<Scalars['String']>;
  hasMenstrualCycle?: Maybe<Scalars['Boolean']>;
  isMenstrualCycleRegular?: Maybe<Scalars['Boolean']>;
  hasDysmenorrhea?: Maybe<Scalars['Boolean']>;
  menstrualCycleNotes?: Maybe<Scalars['String']>;
  hasBeenPregnant?: Maybe<Scalars['Boolean']>;
  numPregnancies: Scalars['Int'];
  numDeliveries: Scalars['Int'];
  numAbortions: Scalars['Int'];
  numStillBirths: Scalars['Int'];
  numCesareans: Scalars['Int'];
  numLiveBirths: Scalars['Int'];
  numDeaths: Scalars['Int'];
  pregnancyNotes?: Maybe<Scalars['String']>;
  gynecologicalExamsNotes?: Maybe<Scalars['String']>;
  gynecologicalExams: Array<GynecologicalExamType>;
  pregnancies: Array<PregnancyType>;
};

export enum ObstetricGynecologicalHistoryBirthControlName {
  Intrauterine = 'INTRAUTERINE',
  Injectable = 'INJECTABLE',
  BarrierMethod = 'BARRIER_METHOD',
  NaturalMethod = 'NATURAL_METHOD',
  SurgicalMethod = 'SURGICAL_METHOD',
  Oral = 'ORAL',
  Subdermal = 'SUBDERMAL'
}

export type GynecologicalExamType = {
  __typename?: 'GynecologicalExamType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  obstetricGynecologicalHistory: ObstetricGynecologicalHistoryType;
  type?: Maybe<GynecologicalExamClassification>;
  date: Scalars['Date'];
  isNormal: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
};

export enum GynecologicalExamClassification {
  Cytology = 'CYTOLOGY',
  Mammogram = 'MAMMOGRAM'
}

export type PregnancyType = {
  __typename?: 'PregnancyType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  obstetricGynecologicalHistory: ObstetricGynecologicalHistoryType;
  date?: Maybe<Scalars['Date']>;
  laborHours?: Maybe<Scalars['Int']>;
  gestationAge?: Maybe<PregnancyGestationAge>;
  notes?: Maybe<Scalars['String']>;
  deliveries: Array<PregnancyDeliveryType>;
};

export enum PregnancyGestationAge {
  Preterm = 'PRETERM',
  Term = 'TERM',
  Postterm = 'POSTTERM'
}

export type PregnancyDeliveryType = {
  __typename?: 'PregnancyDeliveryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  pregnancy: PregnancyType;
  weight?: Maybe<Scalars['Float']>;
  sexAssignedAtBirth?: Maybe<PregnancySex>;
  isCesarean?: Maybe<Scalars['Boolean']>;
  status?: Maybe<PregnancyStatus>;
};

export enum PregnancySex {
  M = 'M',
  F = 'F'
}

export enum PregnancyStatus {
  Live = 'LIVE',
  Aborted = 'ABORTED',
  Dead = 'DEAD'
}

export type AllergyType = {
  __typename?: 'AllergyType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  allergen: Scalars['String'];
  reaction?: Maybe<Scalars['String']>;
  severity?: Maybe<Severity>;
};

export enum Severity {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH'
}

export type FamilyPathologyType = {
  __typename?: 'FamilyPathologyType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  pathology: Scalars['String'];
  cieNotes?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  relatives: Array<Scalars['String']>;
  relative?: Maybe<Relative>;
};

export enum Relative {
  Mother = 'MOTHER',
  Father = 'FATHER',
  PaternalGrandfather = 'PATERNAL_GRANDFATHER',
  MaternalGrandfather = 'MATERNAL_GRANDFATHER',
  PaternalGrandmother = 'PATERNAL_GRANDMOTHER',
  MaternalGrandmother = 'MATERNAL_GRANDMOTHER',
  PaternalUncle = 'PATERNAL_UNCLE',
  MaternalUncle = 'MATERNAL_UNCLE',
  PaternalAunt = 'PATERNAL_AUNT',
  MaternalAunt = 'MATERNAL_AUNT',
  Sibling = 'SIBLING'
}

export type HospitalizationType = {
  __typename?: 'HospitalizationType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  reason?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  notes?: Maybe<Scalars['String']>;
};

export type MalformationType = {
  __typename?: 'MalformationType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  name: Scalars['String'];
  treatment?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type MedicineType = {
  __typename?: 'MedicineType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  drugKey: Scalars['String'];
  ailment?: Maybe<Scalars['String']>;
};

export type PathologyType = {
  __typename?: 'PathologyType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  cieKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  diagnosisDate?: Maybe<Scalars['Date']>;
  treatment?: Maybe<Scalars['String']>;
  cieNotes?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
};

export type SurgeryType = {
  __typename?: 'SurgeryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  name: Scalars['String'];
  cptKey?: Maybe<Scalars['String']>;
  cptNotes?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type VaccineType = {
  __typename?: 'VaccineType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  name: Scalars['String'];
  date?: Maybe<Scalars['Date']>;
  notes?: Maybe<Scalars['String']>;
};

export type MentalHealthDiagnosisType = {
  __typename?: 'MentalHealthDiagnosisType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  mentalHealthDiagnosisClassification?: Maybe<MentalHealthDiagnosisClassification>;
  diagnosis: Scalars['String'];
  duration?: Maybe<Scalars['String']>;
  treatment?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export enum MentalHealthDiagnosisClassification {
  Psychology = 'PSYCHOLOGY',
  Psychiatry = 'PSYCHIATRY'
}

export type GeneralObservationType = {
  __typename?: 'GeneralObservationType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  doctor?: Maybe<DoctorProfileType>;
  addedAt?: Maybe<Scalars['DateTime']>;
  notes: Scalars['String'];
};

export type MedicalProgramType = {
  __typename?: 'MedicalProgramType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  category?: Maybe<MedicalProgramCategory>;
  state?: Maybe<MedicalProgramState>;
  invitedByDoctor?: Maybe<DoctorType>;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  endReason?: Maybe<MedicalProgramEndReason>;
  endReasonDetails?: Maybe<Scalars['String']>;
  bariatricsprogram?: Maybe<BariatricsProgramType>;
  maternityprogram?: Maybe<MaternityProgramType>;
  medicalNotes?: Maybe<Array<MedicalNoteType>>;
};

export enum MedicalProgramCategory {
  Bariatrics = 'BARIATRICS',
  Maternity = 'MATERNITY'
}

export enum MedicalProgramState {
  Invited = 'INVITED',
  Ongoing = 'ONGOING',
  Ended = 'ENDED'
}

export enum MedicalProgramEndReason {
  ObjectiveReached = 'OBJECTIVE_REACHED',
  EndedByPatient = 'ENDED_BY_PATIENT',
  InvitedByMistake = 'INVITED_BY_MISTAKE',
  Other = 'OTHER'
}

export type BariatricsProgramType = {
  __typename?: 'BariatricsProgramType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  category?: Maybe<MedicalProgramCategory>;
  state?: Maybe<MedicalProgramState>;
  invitedByDoctor?: Maybe<DoctorType>;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  endReason?: Maybe<MedicalProgramEndReason>;
  endReasonDetails?: Maybe<Scalars['String']>;
  medicalprogramPtr: MedicalProgramType;
  startingBmi: Scalars['Float'];
  hasPreviousSurgery: Scalars['Boolean'];
  relatedDiseases?: Maybe<Scalars['String']>;
  medicalNotes?: Maybe<Array<MedicalNoteType>>;
};

export type MaternityProgramType = {
  __typename?: 'MaternityProgramType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  category?: Maybe<MedicalProgramCategory>;
  state?: Maybe<MedicalProgramState>;
  invitedByDoctor?: Maybe<DoctorType>;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  endReason?: Maybe<MedicalProgramEndReason>;
  endReasonDetails?: Maybe<Scalars['String']>;
  medicalprogramPtr: MedicalProgramType;
  lastMenstruationDate: Scalars['Date'];
  designatedDoctor?: Maybe<DoctorProfileType>;
  medicalNotes?: Maybe<Array<MedicalNoteType>>;
};

export type PaginatedMedicalNoteType = {
  __typename?: 'PaginatedMedicalNoteType';
  totalCount?: Maybe<Scalars['Int']>;
  medicalNotes?: Maybe<Array<Maybe<MedicalNoteType>>>;
};

export type PaginatedLimitedMedicalNoteType = {
  __typename?: 'PaginatedLimitedMedicalNoteType';
  totalCount?: Maybe<Scalars['Int']>;
  medicalNotes?: Maybe<Array<Maybe<LimitedMedicalNoteType>>>;
};

export type MemberMedicalHistorySummaryType = {
  __typename?: 'MemberMedicalHistorySummaryType';
  summary: Scalars['String'];
};

export type MedicalNotePossibleCiesType = {
  __typename?: 'MedicalNotePossibleCiesType';
  cies: Scalars['String'];
};

export type MedicalNoteRecommendationsType = {
  __typename?: 'MedicalNoteRecommendationsType';
  recommendations: Scalars['String'];
};

export enum PrescriptionTypeEnum {
  Medicines = 'MEDICINES',
  LabStudies = 'LAB_STUDIES'
}

export type PaginatedProcedureCategoryType = {
  __typename?: 'PaginatedProcedureCategoryType';
  totalCount?: Maybe<Scalars['Int']>;
  procedureCategories?: Maybe<Array<Maybe<ProcedureCategoryType>>>;
};

export type PaginatedServices = {
  __typename?: 'PaginatedServices';
  totalCount?: Maybe<Scalars['Int']>;
  services?: Maybe<Array<Maybe<ServicesType>>>;
};

export enum ServiceType {
  MedicalFees = 'MEDICAL_FEES',
  HospitalService = 'HOSPITAL_SERVICE',
  OtherService = 'OTHER_SERVICE',
  RehabilitationTherapy = 'REHABILITATION_THERAPY',
  LabStudies = 'LAB_STUDIES',
  Medicines = 'MEDICINES',
  MedicalEquipment = 'MEDICAL_EQUIPMENT',
  HomeNurse = 'HOME_NURSE',
  ProstheticsAndMechanicalEquipment = 'PROSTHETICS_AND_MECHANICAL_EQUIPMENT',
  Ambulance = 'AMBULANCE',
  InPersonConsult = 'IN_PERSON_CONSULT',
  OnDemandConsult = 'ON_DEMAND_CONSULT',
  PharmacyOrder = 'PHARMACY_ORDER',
  ExternalConsult = 'EXTERNAL_CONSULT',
  Urgency = 'URGENCY',
  ExtVideoConsult = 'EXT_VIDEO_CONSULT',
  SurgicalMaterials = 'SURGICAL_MATERIALS',
  IncludedConsult = 'INCLUDED_CONSULT',
  InPersonConsultByProvider = 'IN_PERSON_CONSULT_BY_PROVIDER'
}

export enum ServiceProcessStateTypeEnum {
  ProcessStarted = 'PROCESS_STARTED',
  ReviewingEvidences = 'REVIEWING_EVIDENCES',
  Ruling = 'RULING',
  RegisteringAmounts = 'REGISTERING_AMOUNTS',
  ProcessingPayments = 'PROCESSING_PAYMENTS',
  ProcessFinalized = 'PROCESS_FINALIZED',
  Cancelled = 'CANCELLED'
}

export enum QuerySortBy {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type MemberYearServiceSummary = {
  __typename?: 'MemberYearServiceSummary';
  memberId: Scalars['Int'];
  year: Scalars['Int'];
  labStudiesCount: Scalars['Int'];
  medicinesCount: Scalars['Int'];
  urgenciesCount: Scalars['Int'];
  hospitalServicesCount: Scalars['Int'];
  pharmacyOrdersCount: Scalars['Int'];
  totalNonConsultServicesCount: Scalars['Int'];
  psychologyInPersonConsultsCount: Scalars['Int'];
  nonPsychologyInPersonConsultsCount: Scalars['Int'];
  totalInPersonConsultsCount: Scalars['Int'];
  primaryCareDoctorOnDemandConsultsCount: Scalars['Int'];
  otherDoctorOnDemandConsultsCount: Scalars['Int'];
  totalOnDemandConsultsCount: Scalars['Int'];
  totalServicesCount: Scalars['Int'];
};

export type ReinbursementServiceInfo = {
  __typename?: 'ReinbursementServiceInfo';
  id: Scalars['ID'];
  memberName: Scalars['String'];
  relatedConsultType?: Maybe<Scalars['String']>;
  doctorName?: Maybe<Scalars['String']>;
  doctorSpecialization?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<Scalars['String']>;
};

export type CaseEventType = {
  __typename?: 'CaseEventType';
  id: Scalars['ID'];
  invoice?: Maybe<InvoiceType>;
  diseaseCase?: Maybe<DiseaseCaseType>;
  assignedTo?: Maybe<UserType>;
  description: Scalars['String'];
  originDiagnosisIcd?: Maybe<Array<Scalars['String']>>;
  category?: Maybe<CaseEventCategory>;
  state?: Maybe<CaseEventState>;
  occurrenceDate?: Maybe<Scalars['Date']>;
  dischargeStartDate?: Maybe<Scalars['DateTime']>;
  dischargeEndDate?: Maybe<Scalars['DateTime']>;
  administrativeRulingNotes?: Maybe<Scalars['String']>;
  medicalPreDictaminationNotes?: Maybe<Scalars['String']>;
  quoteAndSchedulingRulingNotes?: Maybe<Scalars['String']>;
  medicalProcedureRulingNotes?: Maybe<Scalars['String']>;
  dischargeRulingNotes?: Maybe<Scalars['String']>;
  paymentStatusSection?: Maybe<Scalars['String']>;
  pendingMemberAction?: Maybe<Scalars['Boolean']>;
  pendingDoctorAction?: Maybe<Scalars['Boolean']>;
  eventClass?: Maybe<CaseEventClassOptions>;
  admissionSource?: Maybe<CaseEventAdmissionSource>;
  dischargeReason?: Maybe<CaseEventDischargeReason>;
  deferredSince?: Maybe<Scalars['Date']>;
  medicallyJustified?: Maybe<Scalars['Boolean']>;
  internalNotes?: Maybe<Scalars['String']>;
  backgroundCheck?: Maybe<Scalars['Boolean']>;
  informedHospital?: Maybe<Scalars['Boolean']>;
  requestedPartnerDoctorInfo?: Maybe<Scalars['Boolean']>;
  scheduledHospitalDate?: Maybe<Scalars['DateTime']>;
  relevantHistory?: Maybe<Scalars['String']>;
  preAdmissionDoctors: Array<DoctorProfileType>;
  finalAmountsState?: Maybe<CaseEventFinalAmountsState>;
  bedNumber?: Maybe<Scalars['String']>;
  evidenceLinkSent?: Maybe<Scalars['Boolean']>;
  attendingDoctors: Array<DoctorProfileType>;
  invoiceReceived?: Maybe<Scalars['Boolean']>;
  memberStage?: Maybe<CaseEventMemberStage>;
  stage?: Maybe<CaseEventStage>;
  administrativeRulingTasks?: Maybe<AdministrativeRulingTasksType>;
  paymentStatusTasks?: Maybe<CaseEventPaymentStatusTasksType>;
  dischargeTasks?: Maybe<DischargeTasksType>;
  medicalPreRulingTasks?: Maybe<MedicalPreRulingTasksType>;
  medicalProcedureOccurrenceTasks?: Maybe<MedicalProcedureOccurrenceTasksType>;
  quotingAndSchedulingTasks?: Maybe<QuotingAndSchedulingTasksType>;
  finalLettersData?: Maybe<CaseEventFinalLetterDataType>;
  budgetDocuments: Array<Maybe<BudgetDocumentType>>;
  caseEventQuotes: Array<CaseEventQuoteType>;
  scheduledProcedure?: Maybe<ScheduledProcedureType>;
  services?: Maybe<Array<Maybe<ServicesType>>>;
  preapprovedCents?: Maybe<Scalars['JSONString']>;
  approvedCents?: Maybe<Scalars['JSONString']>;
  memberBrief?: Maybe<MemberType>;
  providerName?: Maybe<Scalars['String']>;
  commentHistory?: Maybe<Array<Maybe<EventCommentType>>>;
  assignmentHistory?: Maybe<Array<Maybe<EventAssignmentHistoryType>>>;
  latestEventQuote?: Maybe<CaseEventQuoteType>;
  summaryExcellentList: Array<Maybe<CaseEventSummaryType>>;
  summaryBadList: Array<Maybe<CaseEventSummaryType>>;
  requestingDoctor?: Maybe<DoctorType>;
  channelUrl?: Maybe<Scalars['String']>;
};

export enum CaseEventCategory {
  Urgency = 'URGENCY',
  Admission = 'ADMISSION',
  Surgery = 'SURGERY',
  SurgeryAdmission = 'SURGERY_ADMISSION',
  AmbulatorySurgery = 'AMBULATORY_SURGERY',
  UnscheduledSurgery = 'UNSCHEDULED_SURGERY',
  OfficeProcedure = 'OFFICE_PROCEDURE',
  OutpatientProcedure = 'OUTPATIENT_PROCEDURE'
}

export enum CaseEventState {
  Created = 'CREATED',
  InAdministrativeRuling = 'IN_ADMINISTRATIVE_RULING',
  InMedicalPreRuling = 'IN_MEDICAL_PRE_RULING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Quoting = 'QUOTING',
  LettersSent = 'LETTERS_SENT',
  Scheduled = 'SCHEDULED',
  Active = 'ACTIVE',
  DischargeInitiated = 'DISCHARGE_INITIATED',
  DischargeCompleted = 'DISCHARGE_COMPLETED',
  Paused = 'PAUSED',
  MedicalMonitoring = 'MEDICAL_MONITORING',
  ProcessFinalized = 'PROCESS_FINALIZED',
  PaymentsPending = 'PAYMENTS_PENDING'
}

export enum CaseEventClassOptions {
  Outpatient = 'OUTPATIENT',
  Inpatient = 'INPATIENT'
}

export enum CaseEventAdmissionSource {
  Referral = 'REFERRAL',
  Transfer = 'TRANSFER',
  Emergency = 'EMERGENCY'
}

export enum CaseEventDischargeReason {
  Improvement = 'IMPROVEMENT',
  PalliativeCare = 'PALLIATIVE_CARE',
  VoluntaryDischarge = 'VOLUNTARY_DISCHARGE',
  Transfer = 'TRANSFER'
}

export enum CaseEventFinalAmountsState {
  Pending = 'PENDING',
  InReview = 'IN_REVIEW',
  Approved = 'APPROVED'
}

export enum CaseEventMemberStage {
  Informed = 'INFORMED',
  OnTheWay = 'ON_THE_WAY',
  Hospitalized = 'HOSPITALIZED',
  Discharged = 'DISCHARGED'
}

export enum CaseEventStage {
  PreAdmission = 'PRE_ADMISSION',
  Hospitalized = 'HOSPITALIZED',
  Discharged = 'DISCHARGED'
}

export type AdministrativeRulingTasksType = {
  __typename?: 'AdministrativeRulingTasksType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  antiquityCheckTask: AdministrativeRulingTasksAntiquityCheckTask;
  validityCheckTask: AdministrativeRulingTasksValidityCheckTask;
  balanceCheckTask: AdministrativeRulingTasksBalanceCheckTask;
  rulingInfoSnapshot?: Maybe<Scalars['JSONString']>;
  isCompleted: Scalars['Boolean'];
  pendingTasks: Array<Maybe<TaskType>>;
};

export enum AdministrativeRulingTasksAntiquityCheckTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum AdministrativeRulingTasksValidityCheckTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum AdministrativeRulingTasksBalanceCheckTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export type TaskType = {
  __typename?: 'TaskType';
  name: Scalars['String'];
  state: TaskState;
};

export enum TaskState {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export type CaseEventPaymentStatusTasksType = {
  __typename?: 'CaseEventPaymentStatusTasksType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  memberChargesConfirmedTask: CaseEventPaymentStatusTasksMemberChargesConfirmedTask;
  providersPaymentsTask: CaseEventPaymentStatusTasksProvidersPaymentsTask;
  isCompleted: Scalars['Boolean'];
  pendingTasks: Array<Maybe<TaskType>>;
};

export enum CaseEventPaymentStatusTasksMemberChargesConfirmedTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum CaseEventPaymentStatusTasksProvidersPaymentsTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export type DischargeTasksType = {
  __typename?: 'DischargeTasksType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  dischargeEndDateTask: DischargeTasksDischargeEndDateTask;
  isCompleted: Scalars['Boolean'];
  pendingTasks: Array<Maybe<TaskType>>;
};

export enum DischargeTasksDischargeEndDateTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export type MedicalPreRulingTasksType = {
  __typename?: 'MedicalPreRulingTasksType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  waitingPeriodCheckTask: MedicalPreRulingTasksWaitingPeriodCheckTask;
  preexistenceCheckTask: MedicalPreRulingTasksPreexistenceCheckTask;
  medicalNeedCheckTask: MedicalPreRulingTasksMedicalNeedCheckTask;
  preexistenceInvestigationCheckTask: MedicalPreRulingTasksPreexistenceInvestigationCheckTask;
  rulingInfoSnapshot?: Maybe<Scalars['JSONString']>;
  isCompleted: Scalars['Boolean'];
  pendingTasks: Array<Maybe<TaskType>>;
};

export enum MedicalPreRulingTasksWaitingPeriodCheckTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum MedicalPreRulingTasksPreexistenceCheckTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum MedicalPreRulingTasksMedicalNeedCheckTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum MedicalPreRulingTasksPreexistenceInvestigationCheckTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export type MedicalProcedureOccurrenceTasksType = {
  __typename?: 'MedicalProcedureOccurrenceTasksType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  memberHospitalizedTask: MedicalProcedureOccurrenceTasksMemberHospitalizedTask;
  occurrenceDateRegisteredTask: MedicalProcedureOccurrenceTasksOccurrenceDateRegisteredTask;
  dischargeIndicatedTask: MedicalProcedureOccurrenceTasksDischargeIndicatedTask;
  dischargeDateRegisteredTask: MedicalProcedureOccurrenceTasksDischargeDateRegisteredTask;
  isCompleted: Scalars['Boolean'];
  pendingTasks: Array<Maybe<TaskType>>;
};

export enum MedicalProcedureOccurrenceTasksMemberHospitalizedTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum MedicalProcedureOccurrenceTasksOccurrenceDateRegisteredTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum MedicalProcedureOccurrenceTasksDischargeIndicatedTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum MedicalProcedureOccurrenceTasksDischargeDateRegisteredTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export type QuotingAndSchedulingTasksType = {
  __typename?: 'QuotingAndSchedulingTasksType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  cptVsCostReferencesTask: QuotingAndSchedulingTasksCptVsCostReferencesTask;
  providersQuotingTask: QuotingAndSchedulingTasksProvidersQuotingTask;
  managementApprovalTask: QuotingAndSchedulingTasksManagementApprovalTask;
  provisionalLettersCreationTask: QuotingAndSchedulingTasksProvisionalLettersCreationTask;
  provisionalLettersSentTask: QuotingAndSchedulingTasksProvisionalLettersSentTask;
  mainServiceCreatedTask: QuotingAndSchedulingTasksMainServiceCreatedTask;
  vcVpoScheduledTask: QuotingAndSchedulingTasksVcVpoScheduledTask;
  scheduledDateRegisteredTask: QuotingAndSchedulingTasksScheduledDateRegisteredTask;
  isCompleted: Scalars['Boolean'];
  pendingTasks: Array<Maybe<TaskType>>;
};

export enum QuotingAndSchedulingTasksCptVsCostReferencesTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum QuotingAndSchedulingTasksProvidersQuotingTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum QuotingAndSchedulingTasksManagementApprovalTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum QuotingAndSchedulingTasksProvisionalLettersCreationTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum QuotingAndSchedulingTasksProvisionalLettersSentTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum QuotingAndSchedulingTasksMainServiceCreatedTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum QuotingAndSchedulingTasksVcVpoScheduledTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export enum QuotingAndSchedulingTasksScheduledDateRegisteredTask {
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export type CaseEventFinalLetterDataType = {
  __typename?: 'CaseEventFinalLetterDataType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  caseEvent: LimitedCaseEventType;
  cieCodes?: Maybe<Scalars['String']>;
  cieDescriptions?: Maybe<Scalars['String']>;
  cptCodes?: Maybe<Scalars['String']>;
  cptDescriptions?: Maybe<Scalars['String']>;
  validSince?: Maybe<Scalars['Date']>;
  validThrough?: Maybe<Scalars['Date']>;
  observations?: Maybe<Scalars['String']>;
  letters: Array<CaseEventFinalLetterType>;
  amountsSummary?: Maybe<CaseEventFinalLetterDataAmountsSummary>;
};

export type CaseEventFinalLetterType = {
  __typename?: 'CaseEventFinalLetterType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  caseEventFinalLetterData: CaseEventFinalLetterDataType;
  letterType?: Maybe<CaseEventFinalLetterLetterType>;
  document?: Maybe<DocumentType>;
  sent?: Maybe<Scalars['Boolean']>;
};

export enum CaseEventFinalLetterLetterType {
  HospitalFinalLetter = 'HOSPITAL_FINAL_LETTER',
  DoctorFinalLetter = 'DOCTOR_FINAL_LETTER',
  MemberFinalLetter = 'MEMBER_FINAL_LETTER'
}

export type CaseEventFinalLetterDataAmountsSummary = {
  __typename?: 'CaseEventFinalLetterDataAmountsSummary';
  weCoverSum?: Maybe<Scalars['Int']>;
  eventExpensesSum?: Maybe<Scalars['Int']>;
  memberPaysSum?: Maybe<Scalars['Int']>;
  exceedsTheCoinsuranceLimit?: Maybe<Scalars['Boolean']>;
};

export type BudgetDocumentType = {
  __typename?: 'BudgetDocumentType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  caseEvent: LimitedCaseEventType;
  document?: Maybe<DocumentType>;
};

export type EventCommentType = {
  __typename?: 'EventCommentType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  event: LimitedCaseEventType;
  author: UserType;
  comment: Scalars['String'];
};

export type EventAssignmentHistoryType = {
  __typename?: 'EventAssignmentHistoryType';
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  fromUser?: Maybe<UserType>;
  toUser: UserType;
};

export type CaseEventSummaryType = {
  __typename?: 'CaseEventSummaryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  event: LimitedCaseEventType;
  author: UserType;
  category: CaseEventSummaryCategory;
  assessment: CaseEventSummaryAssessment;
};

export enum CaseEventSummaryCategory {
  AttentionDuringHospitalization = 'ATTENTION_DURING_HOSPITALIZATION',
  AttentionFromSofia = 'ATTENTION_FROM_SOFIA',
  Diagnosis = 'DIAGNOSIS',
  DoctorsFriends = 'DOCTORS_FRIENDS',
  DoctorsFromSofiamed = 'DOCTORS_FROM_SOFIAMED',
  HospitalAccount = 'HOSPITAL_ACCOUNT',
  MedicalFollowup = 'MEDICAL_FOLLOWUP',
  Payments = 'PAYMENTS',
  ProviderResponse = 'PROVIDER_RESPONSE',
  Quote = 'QUOTE'
}

export enum CaseEventSummaryAssessment {
  Excellent = 'EXCELLENT',
  Bad = 'BAD'
}

export type PaginatedCaseEvent = {
  __typename?: 'PaginatedCaseEvent';
  totalCount?: Maybe<Scalars['Int']>;
  events?: Maybe<Array<Maybe<CaseEventType>>>;
};

export type EventDoctorSelectorType = {
  __typename?: 'EventDoctorSelectorType';
  doctorId: Scalars['ID'];
  doctorName: Scalars['String'];
  doctorRole: Scalars['String'];
};

export type PaginatedExclusionsCatalogType = {
  __typename?: 'PaginatedExclusionsCatalogType';
  totalCount?: Maybe<Scalars['Int']>;
  exclusionsCatalog?: Maybe<Array<ExclusionsCatalogType>>;
};

export type PaginatedCptPriceReferenceType = {
  __typename?: 'PaginatedCptPriceReferenceType';
  totalCount?: Maybe<Scalars['Int']>;
  cptPrices: Array<CptPriceReferenceType>;
};

export type CptPriceReferenceType = {
  __typename?: 'CptPriceReferenceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  baseAmountCents?: Maybe<Scalars['Int']>;
  providerClass?: Maybe<CptProviderClassification>;
  referenceSource?: Maybe<CptPriceSource>;
  cptCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type PaginatedCptPriceReferenceGroupedType = {
  __typename?: 'PaginatedCptPriceReferenceGroupedType';
  totalCount?: Maybe<Scalars['Int']>;
  cptPrices: Array<CptPriceReferenceGroupedType>;
};

export type CptPriceReferenceGroupedType = {
  __typename?: 'CptPriceReferenceGroupedType';
  cptCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  providers?: Maybe<Array<Maybe<ProviderType>>>;
};

export type ProviderType = {
  __typename?: 'ProviderType';
  cptPriceReferenceId?: Maybe<Scalars['String']>;
  cptCode?: Maybe<Scalars['String']>;
  providerClass?: Maybe<CptProviderClassification>;
  baseCents?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type PaginatedQuoteItemType = {
  __typename?: 'PaginatedQuoteItemType';
  totalCount?: Maybe<Scalars['Int']>;
  quoteItems?: Maybe<Array<QuoteItemType>>;
};

export type PaginatedDoctorLimitedServices = {
  __typename?: 'PaginatedDoctorLimitedServices';
  totalCount?: Maybe<Scalars['Int']>;
  services?: Maybe<Array<DoctorLimitedServicesType>>;
};

export type DoctorLimitedServicesType = {
  __typename?: 'DoctorLimitedServicesType';
  id: Scalars['ID'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  serviceTypeValue?: Maybe<ServiceType>;
  member: MemberType;
  lastPayoutState?: Maybe<ServicePayoutLogStates>;
  serviceCoverageRuling?: Maybe<DoctorLimitedServiceCoverageRulingType>;
  medicalNotes: Array<MedicalNoteType>;
  payoutLog?: Maybe<Array<ServicePayoutLogType>>;
};

export type DoctorLimitedServiceCoverageRulingType = {
  __typename?: 'DoctorLimitedServiceCoverageRulingType';
  id: Scalars['ID'];
  state?: Maybe<RulingState>;
  payout?: Maybe<DoctorLimitedServiceCoveragePayoutType>;
};

export type DoctorLimitedServiceCoveragePayoutType = {
  __typename?: 'DoctorLimitedServiceCoveragePayoutType';
  id: Scalars['ID'];
  subtotalAmountCents: Scalars['Int'];
  invoiceItem?: Maybe<DoctorLimitedInvoiceItemType>;
  invoice?: Maybe<DoctorLimitedInvoiceType>;
};

export type DoctorLimitedInvoiceItemType = {
  __typename?: 'DoctorLimitedInvoiceItemType';
  id: Scalars['ID'];
};

export type DoctorLimitedInvoiceType = {
  __typename?: 'DoctorLimitedInvoiceType';
  id: Scalars['ID'];
};

export type ServicePayoutLogType = {
  __typename?: 'ServicePayoutLogType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: UserType;
  service: ServicesType;
  state: ServicePayoutLogState;
};

export enum ServicePayoutLogState {
  InReview = 'IN_REVIEW',
  PendingInvoice = 'PENDING_INVOICE',
  BillSent = 'BILL_SENT',
  ProcessingPayment = 'PROCESSING_PAYMENT',
  Paid = 'PAID',
  FailedPayment = 'FAILED_PAYMENT'
}

export type PaginatedLimitedCaseEvent = {
  __typename?: 'PaginatedLimitedCaseEvent';
  totalCount?: Maybe<Scalars['Int']>;
  events?: Maybe<Array<Maybe<LimitedCaseEventType>>>;
};

export type PaginatedCptCatalogType = {
  __typename?: 'PaginatedCptCatalogType';
  totalCount?: Maybe<Scalars['Int']>;
  cpts?: Maybe<Array<CptCatalogType>>;
};

export type CptCatalogType = {
  __typename?: 'CptCatalogType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  cptCode: Scalars['String'];
  description: Scalars['String'];
};

export type PageBasedAccessHealthPlanApplicationType = {
  __typename?: 'PageBasedAccessHealthPlanApplicationType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<HealthPlanApplicationType>>;
};

export type PageBasedAccessProductType = {
  __typename?: 'PageBasedAccessProductType';
  totalCount?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<ProductType>>>;
};

export type NubariumIneType = {
  __typename?: 'NubariumIneType';
  names?: Maybe<Scalars['String']>;
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  curp?: Maybe<Scalars['String']>;
  electorKey?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  subcategory?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  registry?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['String']>;
  qr?: Maybe<Scalars['String']>;
  mrz?: Maybe<Scalars['String']>;
  cic?: Maybe<Scalars['String']>;
  ocr?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  citizenId?: Maybe<Scalars['String']>;
  validationCode?: Maybe<Scalars['String']>;
  mrzValidation?: Maybe<NubariumIneMrzValidationType>;
};

export type NubariumIneMrzValidationType = {
  __typename?: 'NubariumIneMrzValidationType';
  names?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  cic?: Maybe<Scalars['String']>;
};

export type NubariumSatInfoValidationType = {
  __typename?: 'NubariumSatInfoValidationType';
  messageCode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  validationCode?: Maybe<Scalars['String']>;
};

export type InAppRejectionType = {
  __typename?: 'InAppRejectionType';
  rejection?: Maybe<InAppRejection>;
  ok?: Maybe<Scalars['Boolean']>;
};

export enum InAppRejection {
  Pregnancy = 'PREGNANCY',
  Athlete = 'ATHLETE',
  Nationality = 'NATIONALITY',
  Elder = 'ELDER',
  Underage = 'UNDERAGE',
  Infant = 'INFANT',
  OutOfArea = 'OUT_OF_AREA',
  Obesity = 'OBESITY'
}

export type PublicProductType = {
  __typename?: 'PublicProductType';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: ProductCategory;
  productMetadataParameters?: Maybe<Scalars['JSONString']>;
  pricingModel?: Maybe<Scalars['String']>;
  config: Scalars['JSONString'];
  productFamily: ProductProductFamily;
  pricingParameters?: Maybe<Scalars['JSONString']>;
};

export enum ProductProductFamily {
  Morado = 'MORADO',
  Mini = 'MINI',
  Colectivo = 'COLECTIVO'
}

export type PublicKeyValueType = {
  __typename?: 'PublicKeyValueType';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MultipleSubscriptionsPriceType = {
  __typename?: 'MultipleSubscriptionsPriceType';
  monthlyTotalCents?: Maybe<Scalars['Int']>;
  yearlyTotalCents?: Maybe<Scalars['Int']>;
  pricingBreakdown: Array<SubscriptionPriceType>;
  breakdown: Array<SubscriptionPriceType>;
  monthlyTotalCentsList?: Maybe<Array<Scalars['Int']>>;
  yearlyTotalCentsList?: Maybe<Array<Scalars['Int']>>;
};

export type SubscriptionPriceType = {
  __typename?: 'SubscriptionPriceType';
  gender: GenderType;
  age?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  vaccinatedAgainstCovid?: Maybe<Scalars['Boolean']>;
  monthlyTotalCents?: Maybe<Scalars['Int']>;
  yearlyTotalCents?: Maybe<Scalars['Int']>;
  baseTotalCents?: Maybe<Scalars['Int']>;
  dentalAddonValue?: Maybe<Scalars['Int']>;
  maternityAddonValue?: Maybe<Scalars['Int']>;
  intlCoverageAddonValue?: Maybe<Scalars['Int']>;
  extendedHospitalsCoverageAddonValue?: Maybe<Scalars['Int']>;
  insuredAmountCents?: Maybe<InsuredAmountCents>;
  name?: Maybe<Scalars['String']>;
  relationship?: Maybe<Relationship>;
  error?: Maybe<SubscriptionPriceError>;
};

export type SubscriptionPriceError = {
  __typename?: 'SubscriptionPriceError';
  type: SubscriptionPriceErrorType;
  message: Scalars['String'];
};

export enum SubscriptionPriceErrorType {
  Bmi = 'BMI',
  Age = 'AGE'
}

export type SubscriptionPriceInputType = {
  gender: GenderType;
  age: Scalars['Int'];
  weight?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  vaccinatedAgainstCovid?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  coinsuranceConfig?: InputMaybe<CoInsuranceConfigFactor>;
  maternityAddonWaitingPeriodMonths?: InputMaybe<Scalars['Int']>;
  coinsurancePercent?: InputMaybe<Scalars['Int']>;
  relationship?: InputMaybe<Relationship>;
};

export enum QuoteType {
  B2C = 'B2C',
  B2BBundle = 'B2B_BUNDLE',
  B2BCollective = 'B2B_COLLECTIVE'
}

export type ScheduledApprovalCallType = {
  __typename?: 'ScheduledApprovalCallType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['UUID'];
  user?: Maybe<UserType>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  shouldEndAt?: Maybe<Scalars['DateTime']>;
  calendlyId?: Maybe<Scalars['String']>;
  confirmed: Scalars['Boolean'];
  specialInstructions?: Maybe<Scalars['String']>;
  status?: Maybe<ScheduledApprovalCallStatus>;
  assignedTo?: Maybe<UserType>;
  callUrl?: Maybe<Scalars['String']>;
  rescheduleUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export enum ScheduledApprovalCallStatus {
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED',
  Cancelled = 'CANCELLED',
  Finished = 'FINISHED',
  Rescheduled = 'RESCHEDULED',
  NoShow = 'NO_SHOW'
}

export type PagedBasedScheduledApprovalCallType = {
  __typename?: 'PagedBasedScheduledApprovalCallType';
  totalCount?: Maybe<Scalars['Int']>;
  scheduledApprovalCalls?: Maybe<Array<Maybe<ScheduledApprovalCallType>>>;
};

export type LeadInformationType = {
  __typename?: 'LeadInformationType';
  firstName?: Maybe<Scalars['String']>;
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  monthlyTotalCents?: Maybe<Scalars['String']>;
  yearlyTotalCents?: Maybe<Scalars['String']>;
  weightKg?: Maybe<Scalars['Int']>;
  heightCm?: Maybe<Scalars['Int']>;
  dentalMembershipAddon?: Maybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: Maybe<Scalars['Boolean']>;
  discountPercent?: Maybe<Scalars['Int']>;
  incentiveCode?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  insuredAmountCents?: Maybe<InsuredAmountCents>;
  leadBeneficiaries?: Maybe<Array<LeadBeneficiaryType>>;
};

export type LeadBeneficiaryType = {
  __typename?: 'LeadBeneficiaryType';
  id?: Maybe<Scalars['ID']>;
  gender?: Maybe<GenderType>;
  weightKg?: Maybe<Scalars['Int']>;
  heightCm?: Maybe<Scalars['Int']>;
  relationship?: Maybe<Relationship>;
  dateOfBirth?: Maybe<Scalars['Date']>;
};

export type ProductDiscountConfigurationType = {
  __typename?: 'ProductDiscountConfigurationType';
  discountPercent?: Maybe<Scalars['Decimal']>;
  group: ProductDiscountPercentageConfigurationGroup;
};

export enum ProductDiscountPercentageConfigurationGroup {
  NoDiscountGroup = 'NO_DISCOUNT_GROUP',
  DiscountGroup_2021 = 'DISCOUNT_GROUP_2021',
  DiscountGroup_2023_01 = 'DISCOUNT_GROUP_2023_01',
  IntlCoverageGroup_01 = 'INTL_COVERAGE_GROUP_01',
  DentalAddonGroup_01 = 'DENTAL_ADDON_GROUP_01',
  DentalMembAddonGroup_01 = 'DENTAL_MEMB_ADDON_GROUP_01',
  SofiaPopGroup_01 = 'SOFIA_POP_GROUP_01'
}

export type PageBasedAccessUserType = {
  __typename?: 'PageBasedAccessUserType';
  totalCount?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<UserType>>>;
};

export type PageBasedAccessDocumentType = {
  __typename?: 'PageBasedAccessDocumentType';
  totalCount?: Maybe<Scalars['Int']>;
  documents?: Maybe<Array<Maybe<DocumentType>>>;
};

export type PageBasedAccessMemberType = {
  __typename?: 'PageBasedAccessMemberType';
  totalCount?: Maybe<Scalars['Int']>;
  members?: Maybe<Array<Maybe<MemberType>>>;
};

export type PageBasedProfilingDataType = {
  __typename?: 'PageBasedProfilingDataType';
  totalCount?: Maybe<Scalars['Int']>;
  profilingData?: Maybe<Array<Maybe<PagedProfilingDataType>>>;
};

export type PagedProfilingDataType = {
  __typename?: 'PagedProfilingDataType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  member?: Maybe<MemberType>;
  state?: Maybe<ProfilingDataState>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  isProfilingOpsAttentionNeeded?: Maybe<Scalars['Boolean']>;
  isProfilingMedicalAttentionNeeded?: Maybe<Scalars['Boolean']>;
};

export type MemberSearchResultsType = {
  __typename?: 'MemberSearchResultsType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<LimitedMemberType>>;
};

export type SequentialAccessMemberType = {
  __typename?: 'SequentialAccessMemberType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  members?: Maybe<Array<Maybe<LimitedHealthPlanMemberType>>>;
};

export type PageInfoType = {
  __typename?: 'PageInfoType';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type GrantAccessRepresentedMembersType = {
  __typename?: 'GrantAccessRepresentedMembersType';
  members: Array<Maybe<MemberType>>;
};

export type RepresentedMembersByIdType = {
  __typename?: 'RepresentedMembersByIdType';
  members: Array<Maybe<MemberType>>;
};

export type VerifyPasswordType = {
  __typename?: 'VerifyPasswordType';
  ok?: Maybe<Scalars['Boolean']>;
};

export type MinSupportedVersionType = {
  __typename?: 'MinSupportedVersionType';
  android?: Maybe<Scalars['String']>;
  ios?: Maybe<Scalars['String']>;
};

export type CoronaidActiveStatus = {
  __typename?: 'CoronaidActiveStatus';
  isActive?: Maybe<Scalars['Boolean']>;
};

export type AuthenticatedVars = {
  __typename?: 'AuthenticatedVars';
  onDemandConsultPrimaryDoctorName?: Maybe<Scalars['String']>;
  onDemandConsultPrimaryWidgetId?: Maybe<Scalars['String']>;
  incentiveCodeEnabled?: Maybe<Scalars['Boolean']>;
  currentFamilyIncentiveCode?: Maybe<Scalars['String']>;
  currentFamilyIncentiveCodeWithOneChild?: Maybe<Scalars['String']>;
  currentFamilyIncentiveCodeWithTwoChildren?: Maybe<Scalars['String']>;
  currentFamilyIncentiveCodeWithThreeOrMoreChildren?: Maybe<Scalars['String']>;
};

export type WaitingRoomEnabledStatus = {
  __typename?: 'WaitingRoomEnabledStatus';
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntrospectionQueryResult = {
  __typename?: 'IntrospectionQueryResult';
  result?: Maybe<Scalars['JSONString']>;
};

export type IsTelephoneAlreadyOnUseType = {
  __typename?: 'IsTelephoneAlreadyOnUseType';
  onUse?: Maybe<Scalars['Boolean']>;
};

export type IpAddressType = {
  __typename?: 'IpAddressType';
  ipAddress?: Maybe<Scalars['String']>;
};

export type CheckEmailType = {
  __typename?: 'CheckEmailType';
  ok?: Maybe<Scalars['Boolean']>;
};

export type InAppNotificationsType = {
  __typename?: 'InAppNotificationsType';
  mainNotification?: Maybe<Array<Maybe<NotificationType>>>;
  detailNotification?: Maybe<Array<Maybe<NotificationType>>>;
  paymentsNotification?: Maybe<Array<Maybe<NotificationType>>>;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  type: InAppNotificationType;
  urgency: InAppNotificationUrgency;
  title: Scalars['String'];
  message: Scalars['String'];
};

export enum InAppNotificationType {
  GracePeriod = 'GRACE_PERIOD'
}

export enum InAppNotificationUrgency {
  Urgent = 'URGENT',
  Warning = 'WARNING',
  Information = 'INFORMATION'
}

export type CheckExpertsWorkingHoursType = {
  __typename?: 'CheckExpertsWorkingHoursType';
  ok: Scalars['Boolean'];
};

export type CheckUserHasPaymentsPending = {
  __typename?: 'CheckUserHasPaymentsPending';
  ok: Scalars['Boolean'];
};

export type VerifyPasswordTokenType = {
  __typename?: 'VerifyPasswordTokenType';
  ok: Scalars['Boolean'];
};

export type PageBasedMemberExclusionType = {
  __typename?: 'PageBasedMemberExclusionType';
  totalCount?: Maybe<Scalars['Int']>;
  exclusions?: Maybe<Array<Maybe<MemberExclusionType>>>;
};

export type SequentialAccessMemberExclusionType = {
  __typename?: 'SequentialAccessMemberExclusionType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  exclusions?: Maybe<Array<Maybe<MemberExclusionType>>>;
};

export type PaginatedDoctorProfileType = {
  __typename?: 'PaginatedDoctorProfileType';
  totalCount?: Maybe<Scalars['Int']>;
  doctors?: Maybe<Array<Maybe<DoctorProfileType>>>;
};

export type PaginatedProviderBranchesType = {
  __typename?: 'PaginatedProviderBranchesType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<ProviderBranchType>>;
};

export type CervicalCancerType = {
  __typename?: 'CervicalCancerType';
  category: CervicalCancerCategory;
};

export enum CervicalCancerCategory {
  Infant = 'INFANT',
  Pap_12M = 'PAP_12M',
  NoPapInsuredLess_1Y = 'NO_PAP_INSURED_LESS_1Y',
  NoPapInsuredMore_1Y = 'NO_PAP_INSURED_MORE_1Y',
  NoShow = 'NO_SHOW'
}

export type ReferralCodeType = {
  __typename?: 'ReferralCodeType';
  user?: Maybe<UserType>;
  code?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  remainingInvitations?: Maybe<Scalars['Int']>;
  referrerName?: Maybe<Scalars['String']>;
};

export type ReferralCodeValidationType = {
  __typename?: 'ReferralCodeValidationType';
  isValid?: Maybe<Scalars['Boolean']>;
  referrerName?: Maybe<Scalars['String']>;
};

export type PaginatedDoctorFriendsType = {
  __typename?: 'PaginatedDoctorFriendsType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<DoctorFriendType>>;
};

export type PaginatedDoctorPatientsType = {
  __typename?: 'PaginatedDoctorPatientsType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  members?: Maybe<Array<MemberType>>;
};

export type PublicMedicalSpecializationModelType = {
  __typename?: 'PublicMedicalSpecializationModelType';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<SpecializationType>;
};

export type PaginatedMedicalSpecializationsType = {
  __typename?: 'PaginatedMedicalSpecializationsType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<MedicalSpecializationModelType>>;
};

export type PaginatedProviderBranchOfficesType = {
  __typename?: 'PaginatedProviderBranchOfficesType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<ProviderBranchOfficeType>>;
};

export enum States {
  Aguascalientes = 'AGUASCALIENTES',
  BajaCalifornia = 'BAJA_CALIFORNIA',
  BajaCaliforniaSur = 'BAJA_CALIFORNIA_SUR',
  Campeche = 'CAMPECHE',
  Chiapas = 'CHIAPAS',
  Chihuahua = 'CHIHUAHUA',
  CiudadDeMexico = 'CIUDAD_DE_MEXICO',
  CoahuilaDeZaragoza = 'COAHUILA_DE_ZARAGOZA',
  Colima = 'COLIMA',
  Durango = 'DURANGO',
  Guanajuato = 'GUANAJUATO',
  Guerrero = 'GUERRERO',
  Hidalgo = 'HIDALGO',
  Jalisco = 'JALISCO',
  Mexico = 'MEXICO',
  MichoacanDeOcampo = 'MICHOACAN_DE_OCAMPO',
  Morelos = 'MORELOS',
  Nayarit = 'NAYARIT',
  NuevoLeon = 'NUEVO_LEON',
  Oaxaca = 'OAXACA',
  Puebla = 'PUEBLA',
  Queretaro = 'QUERETARO',
  QuintanaRoo = 'QUINTANA_ROO',
  SanLuisPotosi = 'SAN_LUIS_POTOSI',
  Sinaloa = 'SINALOA',
  Sonora = 'SONORA',
  Tabasco = 'TABASCO',
  Tamaulipas = 'TAMAULIPAS',
  Tlaxcala = 'TLAXCALA',
  VeracruzDeIgnacioDeLaLlave = 'VERACRUZ_DE_IGNACIO_DE_LA_LLAVE',
  Yucatan = 'YUCATAN',
  Zacatecas = 'ZACATECAS'
}

export type SequentialAccessDirectoryListType = {
  __typename?: 'SequentialAccessDirectoryListType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  directory?: Maybe<Array<Maybe<CareDirectoryType>>>;
};

export type CareDirectoryType = DoctorProfileType | ProviderBranchType;

export type PaginatedProvidersType = {
  __typename?: 'PaginatedProvidersType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<ProvidersType>>;
};

export type SequentialAccessProviderListType = {
  __typename?: 'SequentialAccessProviderListType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  directory?: Maybe<Array<Maybe<CareProviderType>>>;
};

export type CareProviderType = DoctorProfileType | DoctorFriendType | ProviderBranchType;

export type PageBasedAccessDoctorType = {
  __typename?: 'PageBasedAccessDoctorType';
  totalCount?: Maybe<Scalars['Int']>;
  doctors?: Maybe<Array<Maybe<DoctorType>>>;
};

export type HuliDoctorDataType = {
  __typename?: 'HuliDoctorDataType';
  huliId?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  specializations?: Maybe<Array<Maybe<HuliSpecialtyType>>>;
  clinics?: Maybe<Array<Maybe<HuliClinicType>>>;
};

export type HuliSpecialtyType = {
  __typename?: 'HuliSpecialtyType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type HuliClinicType = {
  __typename?: 'HuliClinicType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type SequentialAccessDoctorReminderListType = {
  __typename?: 'SequentialAccessDoctorReminderListType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  reminders?: Maybe<Array<Maybe<DoctorReminderType>>>;
};

export type DoctorReminderType = {
  __typename?: 'DoctorReminderType';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  remindAt?: Maybe<Scalars['DateTime']>;
  status: DoctorReminderStatus;
  member?: Maybe<MemberType>;
  doctor?: Maybe<DoctorType>;
  consult?: Maybe<OnDemandConsultType>;
};

export enum DoctorReminderStatus {
  Pending = 'PENDING',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Overdue = 'OVERDUE'
}

export type PaginatedDoctorAssistantType = {
  __typename?: 'PaginatedDoctorAssistantType';
  totalCount?: Maybe<Scalars['Int']>;
  doctorAssistants?: Maybe<Array<Maybe<DoctorAssistantType>>>;
};

export type PublicDoctorType = {
  __typename?: 'PublicDoctorType';
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
  nickname: Scalars['String'];
  officeAddress?: Maybe<PublicAddressType>;
  secondaryOfficeAddress?: Maybe<PublicAddressType>;
  officeAddresses?: Maybe<Array<PublicAddressType>>;
  doctorSpecialistDetails?: Maybe<Array<PublicDoctorSpecialistDetailType>>;
  prefixedFullName?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
};

export type PublicAddressType = {
  __typename?: 'PublicAddressType';
  id?: Maybe<Scalars['ID']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateName?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  references?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  googleMapsUrl?: Maybe<Scalars['String']>;
  temporaryVirtualOffice?: Maybe<Scalars['Boolean']>;
};

export type PublicDoctorSpecialistDetailType = {
  __typename?: 'PublicDoctorSpecialistDetailType';
  id: Scalars['ID'];
  specialistNumber?: Maybe<Scalars['String']>;
  medicalSpecialization: PublicMedicalSpecializationModelType;
  specialistInstitution?: Maybe<Scalars['String']>;
  specializationName?: Maybe<Scalars['String']>;
};

export type PaginatedDoctorType = {
  __typename?: 'PaginatedDoctorType';
  totalCount?: Maybe<Scalars['Int']>;
  doctors?: Maybe<Array<Maybe<FilteredAddressDoctorProfileType>>>;
};

export type FilteredAddressDoctorProfileType = {
  __typename?: 'FilteredAddressDoctorProfileType';
  doctor?: Maybe<DoctorProfileType>;
  filteredAddresses?: Maybe<Array<AddressType>>;
};

export type LimitedLocationInputType = {
  stateName: Scalars['String'];
  city?: InputMaybe<Scalars['String']>;
  municipality?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
};

export type LimitedDoctorOfficeAddress = {
  __typename?: 'LimitedDoctorOfficeAddress';
  id?: Maybe<Scalars['ID']>;
  doctorId?: Maybe<Scalars['ID']>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type LimitedProviderBranchOfficeAddress = {
  __typename?: 'LimitedProviderBranchOfficeAddress';
  id?: Maybe<Scalars['ID']>;
  providerBranchOfficeId?: Maybe<Scalars['ID']>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type PaginatedPharmacyOrdersType = {
  __typename?: 'PaginatedPharmacyOrdersType';
  totalCount?: Maybe<Scalars['Int']>;
  pharmacyOrders?: Maybe<Array<PharmacyOrderType>>;
};

export type GroupType = {
  __typename?: 'GroupType';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<PermissionType>;
};

export type PaginatedGroupType = {
  __typename?: 'PaginatedGroupType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<GroupType>>;
};

export type PaginatedPermissionType = {
  __typename?: 'PaginatedPermissionType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<PermissionType>>;
};

export type SequentialAccessPaymentMethodsType = {
  __typename?: 'SequentialAccessPaymentMethodsType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethodsType>>>;
};

export enum PaymentFlowTypes {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING'
}

export type SequentialAccessStripeSourceType = {
  __typename?: 'SequentialAccessStripeSourceType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  stripeSources?: Maybe<Array<Maybe<StripeSourceType>>>;
};

export type StripeSourceType = {
  __typename?: 'StripeSourceType';
  id: Scalars['ID'];
  primary?: Maybe<Scalars['Boolean']>;
  remoteStripeSourceId?: Maybe<Scalars['String']>;
  lastFourDigits?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
};

export enum TransactionResult {
  Ok = 'OK',
  Fail = 'FAIL'
}

export type PageBasedAccessTransactionType = {
  __typename?: 'PageBasedAccessTransactionType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<TransactionType>>>;
};

export type LocationType = {
  __typename?: 'LocationType';
  id: Scalars['ID'];
  zipcode: Scalars['String'];
  neighborhood?: Maybe<Scalars['String']>;
  neighborhoodType?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  stateName: Scalars['String'];
  city: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isCore: Scalars['Boolean'];
  isAvailableUpdatedAt: Scalars['DateTime'];
  isCoreUpdatedAt: Scalars['DateTime'];
};

export type PageBasedAccessLocationType = {
  __typename?: 'PageBasedAccessLocationType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<LocationType>>>;
};

export type LimitedLocationType = {
  __typename?: 'LimitedLocationType';
  stateName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
};

export type IssuerType = {
  __typename?: 'IssuerType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type PaginatedInvoiceItemsType = {
  __typename?: 'PaginatedInvoiceItemsType';
  totalCount?: Maybe<Scalars['Int']>;
  summarySubtotalCents?: Maybe<Scalars['Int']>;
  summaryVatCents?: Maybe<Scalars['Int']>;
  summaryTotalCents?: Maybe<Scalars['Int']>;
  invoiceItems?: Maybe<Array<Maybe<InvoiceItemType>>>;
  allInvoiceItemIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type PageBasedInvoiceType = {
  __typename?: 'PageBasedInvoiceType';
  totalCount?: Maybe<Scalars['Int']>;
  electronicVoucherErrors?: Maybe<Scalars['Int']>;
  notPaidInvoices?: Maybe<Scalars['Int']>;
  cancelledInvoices?: Maybe<Scalars['Int']>;
  failedInvoices?: Maybe<Scalars['Int']>;
  invoices?: Maybe<Array<Maybe<InvoiceType>>>;
};

export enum InvoiceCounterpartyType {
  Members = 'MEMBERS',
  Doctors = 'DOCTORS',
  Businesses = 'BUSINESSES',
  Providers = 'PROVIDERS',
  DoctorFriends = 'DOCTOR_FRIENDS'
}

export enum InvoicesQueryServiceType {
  AmendmentCancelation = 'AMENDMENT_CANCELATION',
  AmendmentCorrection = 'AMENDMENT_CORRECTION',
  AmendmentSubscription = 'AMENDMENT_SUBSCRIPTION',
  ClaimsAmbulance = 'CLAIMS_AMBULANCE',
  ClaimsHospitalService = 'CLAIMS_HOSPITAL_SERVICE',
  ClaimsInPersonConsult = 'CLAIMS_IN_PERSON_CONSULT',
  ClaimsLabStudies = 'CLAIMS_LAB_STUDIES',
  ClaimsMedicalEquipment = 'CLAIMS_MEDICAL_EQUIPMENT',
  ClaimsMedicalFees = 'CLAIMS_MEDICAL_FEES',
  ClaimsMedicines = 'CLAIMS_MEDICINES',
  ClaimsOnDemandConsult = 'CLAIMS_ON_DEMAND_CONSULT',
  ClaimsOtherServices = 'CLAIMS_OTHER_SERVICES',
  ClaimsProstheticAndMechanicalEquipment = 'CLAIMS_PROSTHETIC_AND_MECHANICAL_EQUIPMENT',
  ClaimsRehabilitationTherapy = 'CLAIMS_REHABILITATION_THERAPY',
  PharmarcyOrder = 'PHARMARCY_ORDER',
  Incentive = 'INCENTIVE'
}

export type PageBasedElectronicVoucherType = {
  __typename?: 'PageBasedElectronicVoucherType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  results?: Maybe<Array<Maybe<ElectronicVoucherType>>>;
};

export enum SatOperationTypeStatus {
  Issued = 'ISSUED',
  Cancelled = 'CANCELLED',
  CancellationInProgress = 'CANCELLATION_IN_PROGRESS',
  CancellationFailed = 'CANCELLATION_FAILED',
  NotIssued = 'NOT_ISSUED',
  Draft = 'DRAFT',
  NonCollectibleVoucher = 'NON_COLLECTIBLE_VOUCHER',
  CancelledBeforeIssued = 'CANCELLED_BEFORE_ISSUED',
  Replaced = 'REPLACED',
  NonDeductible = 'NON_DEDUCTIBLE'
}

export type PageBasedIncomeType = {
  __typename?: 'PageBasedIncomeType';
  totalCount?: Maybe<Scalars['Int']>;
  incomes?: Maybe<Array<Maybe<IncomeType>>>;
};

export type AutomaticPaymentChecksResultType = {
  __typename?: 'AutomaticPaymentChecksResultType';
  yellowChecks: Array<Maybe<Scalars['String']>>;
  redChecks: Array<Maybe<Scalars['String']>>;
};

export type PublicIncentiveCodeType = {
  __typename?: 'PublicIncentiveCodeType';
  discountPercent?: Maybe<Scalars['Int']>;
  valid?: Maybe<Scalars['Boolean']>;
  validFamilyIncentiveCode?: Maybe<Scalars['Boolean']>;
};

export type ReferredOnDatesType = {
  __typename?: 'ReferredOnDatesType';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ReferralIncentiveStageType = {
  __typename?: 'ReferralIncentiveStageType';
  stage?: Maybe<Scalars['Int']>;
  totalCents?: Maybe<Scalars['Int']>;
  sumAmountCents?: Maybe<Scalars['Int']>;
};

export type PageBasedAccessIncentiveCodeType = {
  __typename?: 'PageBasedAccessIncentiveCodeType';
  totalCount?: Maybe<Scalars['Int']>;
  incentiveCodes?: Maybe<Array<Maybe<IncentiveCodeType>>>;
};

export type ListIncentiveCampaignsType = {
  __typename?: 'ListIncentiveCampaignsType';
  incentiveCampaigns?: Maybe<Array<IncentiveCampaignsType>>;
};

export type PageBasedAccessHealthPlanType = {
  __typename?: 'PageBasedAccessHealthPlanType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<HealthPlanType>>;
};

export type PageBasedAccessHealthPlanAmendmentType = {
  __typename?: 'PageBasedAccessHealthPlanAmendmentType';
  totalCount?: Maybe<Scalars['Int']>;
  healthPlanAmendments?: Maybe<Array<Maybe<HealthPlanAmendmentType>>>;
};

export type HealthPlanQuoteDifferenceType = {
  __typename?: 'HealthPlanQuoteDifferenceType';
  installmentSubtotalCents?: Maybe<Scalars['Int']>;
  installmentSubscriptionTypeSurchargeCents?: Maybe<Scalars['Int']>;
  installmentVatCents?: Maybe<Scalars['Int']>;
  installmentTotalCents?: Maybe<Scalars['Int']>;
  installmentNetPremiumCents?: Maybe<Scalars['Int']>;
  installmentIssuanceCents?: Maybe<Scalars['Int']>;
  premiumSubtotalCents?: Maybe<Scalars['Int']>;
  premiumSubscriptionTypeSurchargeCents?: Maybe<Scalars['Int']>;
  premiumVatCents?: Maybe<Scalars['Int']>;
  premiumTotalCents?: Maybe<Scalars['Int']>;
  premiumNetPremiumCents?: Maybe<Scalars['Int']>;
  premiumIssuanceCents?: Maybe<Scalars['Int']>;
  subscriptionTypeDiscountCents?: Maybe<Scalars['Int']>;
  installmentSubtotalDifferenceCents?: Maybe<Scalars['Int']>;
  installmentVatDifferenceCents?: Maybe<Scalars['Int']>;
  installmentSubscriptionTypeSurchargeDifferenceCents?: Maybe<Scalars['Int']>;
  installmentTotalDifferenceCents?: Maybe<Scalars['Int']>;
  installmentNetPremiumDifferenceCents?: Maybe<Scalars['Int']>;
  installmentIssuanceDifferenceCents?: Maybe<Scalars['Int']>;
  premiumSubtotalDifferenceCents?: Maybe<Scalars['Int']>;
  premiumVatDifferenceCents?: Maybe<Scalars['Int']>;
  premiumSubscriptionTypeSurchargeDifferenceCents?: Maybe<Scalars['Int']>;
  premiumTotalDifferenceCents?: Maybe<Scalars['Int']>;
  premiumNetPremiumDifferenceCents?: Maybe<Scalars['Int']>;
  premiumIssuanceDifferenceCents?: Maybe<Scalars['Int']>;
  adjustFeeCents?: Maybe<Scalars['Int']>;
};

export type SequentialAccessMessageType = {
  __typename?: 'SequentialAccessMessageType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  messages?: Maybe<Array<Maybe<MessageType>>>;
};

export type PageBasedAccessHuliEmrEntryType = {
  __typename?: 'PageBasedAccessHuliEmrEntryType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<HuliEmrEntryType>>>;
};

export type SequentialAccessOnDemandConsultType = {
  __typename?: 'SequentialAccessOnDemandConsultType';
  totalCount: Scalars['Int'];
  pageInfo: PageInfoType;
  onDemandConsults: Array<OnDemandConsultType>;
};

export type PaginatedScheduledConsultType = {
  __typename?: 'PaginatedScheduledConsultType';
  totalCount?: Maybe<Scalars['Int']>;
  scheduledConsults?: Maybe<Array<ScheduledConsultType>>;
};

export type ScheduledConsultType = {
  __typename?: 'ScheduledConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  scheduledConsultClassification?: Maybe<GrapheneScheduledConsultClassification>;
  user?: Maybe<UserType>;
  member?: Maybe<MemberType>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  shouldEndAt?: Maybe<Scalars['DateTime']>;
  symptoms?: Maybe<Scalars['String']>;
  extraNotes?: Maybe<Scalars['String']>;
  onDemandConsultClassification?: Maybe<OnDemandConsultClassificationType>;
  inPersonConsultClassification?: Maybe<InPersonConsultClassificationType>;
  /** @deprecated Not used */
  scheduledConsultKind?: Maybe<ScheduledConsultKind>;
  category?: Maybe<ConsultCategory>;
  tasks?: Maybe<Scalars['JSONString']>;
  consultTasks?: Maybe<Scalars['JSONString']>;
  claimed?: Maybe<Scalars['Boolean']>;
  claimedAt?: Maybe<Scalars['DateTime']>;
  huliId?: Maybe<Scalars['Int']>;
  calendlyId?: Maybe<Scalars['String']>;
  googleEventId?: Maybe<Scalars['String']>;
  consult?: Maybe<LimitedConsultType>;
  onDemandConsult?: Maybe<WatchOnDemandConsultType>;
  inPersonConsult?: Maybe<InPersonConsultType>;
  includedConsult?: Maybe<LimitedIncludedConsultType>;
  inPersonConsultByProvider?: Maybe<InPersonConsultByProviderType>;
  rehabilitationTherapy?: Maybe<LimitedRehabilitationTherapyType>;
  doctor?: Maybe<DoctorProfileType>;
  address?: Maybe<AddressType>;
  secondaryAddress?: Maybe<AddressType>;
  confirmed: Scalars['Boolean'];
  confirmedByUser?: Maybe<UserType>;
  medicalSpecialization?: Maybe<MedicalSpecializationModelType>;
  urgency?: Maybe<AppointmentUrgency>;
  urgencyDescription?: Maybe<Scalars['String']>;
  timeWindow?: Maybe<AppointmentTimeWindow>;
  timeWindowDescription?: Maybe<Scalars['String']>;
  dayWindow?: Maybe<AppointmentDayWindow>;
  dayWindowDescription?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
  latestState?: Maybe<ScheduledConsultLatestState>;
  cancellationReason?: Maybe<ConsultCancellationReason>;
  cancellationReasonDetails?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserType>;
  locationState?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  assignmentHistory: Array<ScheduledConsultAssignmentHistoryType>;
  deprecatedScheduledConsultClassification?: Maybe<GrapheneScheduledConsultClassificationDeprecated>;
  state: ScheduledConsultState;
  comments?: Maybe<Array<Maybe<CommentType>>>;
  city?: Maybe<Scalars['String']>;
};

export enum InPersonConsultClassificationType {
  NotApplicable = 'NOT_APPLICABLE',
  InNetwork = 'IN_NETWORK',
  OutOfNetwork = 'OUT_OF_NETWORK',
  RehabilitationTherapy = 'REHABILITATION_THERAPY',
  Calendar = 'CALENDAR'
}

export enum ScheduledConsultKind {
  FirstTime = 'FIRST_TIME',
  FollowUp = 'FOLLOW_UP'
}

export enum AppointmentUrgency {
  Asap = 'ASAP',
  NextWeek = 'NEXT_WEEK',
  NextMonth = 'NEXT_MONTH',
  Other = 'OTHER'
}

export enum AppointmentTimeWindow {
  Morning = 'MORNING',
  Afternoon = 'AFTERNOON',
  Other = 'OTHER'
}

export enum AppointmentDayWindow {
  Weekday = 'WEEKDAY',
  WeekEnd = 'WEEK_END',
  Other = 'OTHER'
}

export enum ScheduledConsultLatestState {
  Tb = 'TB',
  Rq = 'RQ',
  Sd = 'SD',
  Cf = 'CF',
  Ds = 'DS',
  Dn = 'DN',
  Cn = 'CN'
}

export type ScheduledConsultAssignmentHistoryType = {
  __typename?: 'ScheduledConsultAssignmentHistoryType';
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  fromUser?: Maybe<UserType>;
  toUser: UserType;
};

export enum GrapheneScheduledConsultClassificationDeprecated {
  OnDemandConsultPrimary = 'ON_DEMAND_CONSULT_PRIMARY',
  OnDemandConsultWelcome = 'ON_DEMAND_CONSULT_WELCOME',
  OnDemandConsultMain = 'ON_DEMAND_CONSULT_MAIN',
  PediatricsPrimary = 'PEDIATRICS_PRIMARY',
  PediatricsWelcome = 'PEDIATRICS_WELCOME',
  RehabilitationTherapy = 'REHABILITATION_THERAPY'
}

export type PaginatedLimitedScheduledConsultType = {
  __typename?: 'PaginatedLimitedScheduledConsultType';
  totalCount?: Maybe<Scalars['Int']>;
  scheduledConsults?: Maybe<Array<LimitedScheduledConsultType>>;
};

export type ActualConsultType = {
  __typename?: 'ActualConsultType';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  member?: Maybe<MemberType>;
  opentokSession?: Maybe<OpentokSessionType>;
  emrEntry?: Maybe<EmrEntryType>;
  healthPlan?: Maybe<HealthPlanType>;
  billedAmount?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<Array<Maybe<PrescriptionType>>>;
  messages?: Maybe<Array<Maybe<MessageType>>>;
  lastConsultMessage?: Maybe<MessageType>;
  welcomeConsultId?: Maybe<Scalars['Int']>;
  doctorName?: Maybe<Scalars['String']>;
  doctor?: Maybe<DoctorType>;
  consultDate?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  consultType?: Maybe<Scalars['String']>;
};

export type ConsultUnionType = OnDemandConsultType | LimitedInPersonConsultType | LimitedIncludedConsultType | LimitedInPersonConsultByProviderType | LimitedRehabilitationTherapyType;

export type LimitedInPersonConsultByProviderType = {
  __typename?: 'LimitedInPersonConsultByProviderType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  member?: Maybe<MemberType>;
  updatedAt: Scalars['DateTime'];
  healthPlan?: Maybe<HealthPlanType>;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  caseEvent?: Maybe<LimitedCaseEventType>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  isAutomaticApprovalWorkflow?: Maybe<Scalars['Boolean']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  rulingDifficulty?: Maybe<ServiceRulingDifficulty>;
  assignedTo?: Maybe<UserType>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  suggestedRulingState?: Maybe<ServiceSuggestedRulingState>;
  evidenceStateFlags: Scalars['JSONString'];
  coverageTag?: Maybe<ServiceCoverageTag>;
  deleted?: Maybe<Scalars['DateTime']>;
  isPendingObligation: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  automaticRulingPassed?: Maybe<Scalars['Boolean']>;
  automaticRulingScore?: Maybe<Scalars['Float']>;
  automaticRulingAbusePreventionTriggered?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
  payoutIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePtr: ServicesType;
  consultPtr: LimitedConsultType;
  doctorMedicalSpecializations: Array<MedicalSpecializationModelType>;
  scheduledconsultSet: Array<LimitedScheduledConsultType>;
  doctor?: Maybe<DoctorType>;
  consultDate?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  serviceCoverageRulingState?: Maybe<RulingState>;
  prescriptions?: Maybe<Array<Maybe<PrescriptionType>>>;
  medicalNotes?: Maybe<Array<Maybe<MedicalNoteType>>>;
  hasPayInAndPayOut?: Maybe<Scalars['Boolean']>;
};

export type OnDemandConsultHourlyFeesType = {
  __typename?: 'OnDemandConsultHourlyFeesType';
  weekdayCents?: Maybe<Scalars['Int']>;
  weekendCents?: Maybe<Scalars['Int']>;
};

export type OnDemandConsultPriceType = {
  __typename?: 'OnDemandConsultPriceType';
  consultCurrentPriceCents?: Maybe<Scalars['Int']>;
};

export type InPersonConsultFeeType = {
  __typename?: 'InPersonConsultFeeType';
  amountCents?: Maybe<Scalars['Int']>;
};

export type TaxesForInPersonConsults = {
  __typename?: 'TaxesForInPersonConsults';
  vatPercentage?: Maybe<Scalars['Float']>;
  withholdTaxesPercentage?: Maybe<Scalars['Float']>;
};

export type WelcomeScheduledConsultType = {
  __typename?: 'WelcomeScheduledConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  scheduledConsultClassification?: Maybe<ScheduledConsultScheduledConsultClassification>;
  user?: Maybe<Scalars['String']>;
  member?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  shouldEndAt?: Maybe<Scalars['DateTime']>;
  symptoms?: Maybe<Scalars['String']>;
  extraNotes?: Maybe<Scalars['String']>;
  onDemandConsultClassification?: Maybe<Scalars['String']>;
  inPersonConsultClassification: ScheduledConsultInPersonConsultClassification;
  scheduledConsultKind?: Maybe<ScheduledConsultScheduledConsultKind>;
  category?: Maybe<ScheduledConsultCategory>;
  tasks?: Maybe<Scalars['JSONString']>;
  consultTasks?: Maybe<Scalars['JSONString']>;
  claimed?: Maybe<Scalars['Boolean']>;
  claimedAt?: Maybe<Scalars['DateTime']>;
  huliId?: Maybe<Scalars['Int']>;
  calendlyId?: Maybe<Scalars['String']>;
  googleEventId?: Maybe<Scalars['String']>;
  consult?: Maybe<LimitedConsultType>;
  onDemandConsult?: Maybe<WatchOnDemandConsultType>;
  inPersonConsult?: Maybe<InPersonConsultType>;
  includedConsult?: Maybe<LimitedIncludedConsultType>;
  inPersonConsultByProvider?: Maybe<InPersonConsultByProviderType>;
  rehabilitationTherapy?: Maybe<LimitedRehabilitationTherapyType>;
  doctor?: Maybe<DoctorProfileType>;
  address?: Maybe<AddressType>;
  secondaryAddress?: Maybe<AddressType>;
  confirmed: Scalars['Boolean'];
  confirmedByUser?: Maybe<UserType>;
  medicalSpecialization?: Maybe<MedicalSpecializationModelType>;
  urgency?: Maybe<ScheduledConsultUrgency>;
  urgencyDescription?: Maybe<Scalars['String']>;
  timeWindow?: Maybe<ScheduledConsultTimeWindow>;
  timeWindowDescription?: Maybe<Scalars['String']>;
  dayWindow?: Maybe<ScheduledConsultDayWindow>;
  dayWindowDescription?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
  latestState?: Maybe<ScheduledConsultLatestState>;
  cancellationReason?: Maybe<ScheduledConsultCancellationReason>;
  cancellationReasonDetails?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<UserType>;
  locationState?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  assignmentHistory: Array<ScheduledConsultAssignmentHistoryType>;
};

export enum ScheduledConsultScheduledConsultClassification {
  InPersonConsult = 'IN_PERSON_CONSULT',
  Videoconsult = 'VIDEOCONSULT',
  PrimaryCareDr = 'PRIMARY_CARE_DR',
  Welcome = 'WELCOME',
  General = 'GENERAL',
  PediatricsPrimary = 'PEDIATRICS_PRIMARY',
  PediatricsWelcome = 'PEDIATRICS_WELCOME',
  RehabilitationTherapy = 'REHABILITATION_THERAPY'
}

export enum ScheduledConsultInPersonConsultClassification {
  NotApplicable = 'NOT_APPLICABLE',
  InNetwork = 'IN_NETWORK',
  OutOfNetwork = 'OUT_OF_NETWORK',
  RehabilitationTherapy = 'REHABILITATION_THERAPY',
  Calendar = 'CALENDAR'
}

export enum ScheduledConsultScheduledConsultKind {
  FirstTime = 'FIRST_TIME',
  FollowUp = 'FOLLOW_UP'
}

export enum ScheduledConsultCategory {
  Preventive = 'PREVENTIVE',
  General = 'GENERAL',
  InPerson = 'IN_PERSON',
  Remote = 'REMOTE'
}

export enum ScheduledConsultUrgency {
  As = 'AS',
  Nw = 'NW',
  Nm = 'NM',
  Ot = 'OT'
}

export enum ScheduledConsultTimeWindow {
  Mn = 'MN',
  At = 'AT',
  Ot = 'OT'
}

export enum ScheduledConsultDayWindow {
  Wd = 'WD',
  We = 'WE',
  Ot = 'OT'
}

export enum ScheduledConsultCancellationReason {
  MemberInsufficientTime = 'MEMBER_INSUFFICIENT_TIME',
  MemberInadequateLocation = 'MEMBER_INADEQUATE_LOCATION',
  MemberBadInternet = 'MEMBER_BAD_INTERNET',
  DoctorUrgency = 'DOCTOR_URGENCY',
  StuckConsultCard = 'STUCK_CONSULT_CARD',
  MemberNoShow = 'MEMBER_NO_SHOW',
  CancelledByMember = 'CANCELLED_BY_MEMBER',
  NonReferenceMedical = 'NON_REFERENCE_MEDICAL',
  NoMemberResponse = 'NO_MEMBER_RESPONSE',
  Other = 'OTHER'
}

export type SequentialAccessScheduledConsultType = {
  __typename?: 'SequentialAccessScheduledConsultType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  scheduledConsults?: Maybe<Array<Maybe<ScheduledConsultType>>>;
};

export type CalendarSlotType = {
  __typename?: 'CalendarSlotType';
  start?: Maybe<Scalars['Time']>;
  end?: Maybe<Scalars['Time']>;
};

export type CalendarDatesAvailabilityType = {
  __typename?: 'CalendarDatesAvailabilityType';
  date?: Maybe<Scalars['Date']>;
  available?: Maybe<Scalars['Boolean']>;
};

export enum CalendarSlotClassification {
  Welcome = 'WELCOME',
  PrimaryCare = 'PRIMARY_CARE',
  Internist = 'INTERNIST',
  InPerson = 'IN_PERSON'
}

export type CalendarDatesDoctorsAvailabilityType = {
  __typename?: 'CalendarDatesDoctorsAvailabilityType';
  date?: Maybe<Scalars['Date']>;
  doctorIds?: Maybe<Array<Scalars['ID']>>;
};

export type CalendarDatesSlotsAvailability = {
  __typename?: 'CalendarDatesSlotsAvailability';
  date?: Maybe<Scalars['Date']>;
  slots?: Maybe<Array<CalendarSlotType>>;
};

export type CalendarDatesDoctorsSlotsAvailability = {
  __typename?: 'CalendarDatesDoctorsSlotsAvailability';
  date?: Maybe<Scalars['Date']>;
  slots?: Maybe<Array<CalendarDoctorSlotType>>;
};

export type CalendarDoctorSlotType = {
  __typename?: 'CalendarDoctorSlotType';
  start?: Maybe<Scalars['Time']>;
  end?: Maybe<Scalars['Time']>;
  doctorIds?: Maybe<Array<Scalars['ID']>>;
};

export type CalendarDateNextSlotAvailability = {
  __typename?: 'CalendarDateNextSlotAvailability';
  date?: Maybe<Scalars['Date']>;
  doctorId?: Maybe<Scalars['ID']>;
  slot?: Maybe<CalendarSlotType>;
};

export type SubscriptionConsultType = {
  __typename?: 'SubscriptionConsultType';
  birthday?: Maybe<Scalars['String']>;
  callState?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  consultId?: Maybe<Scalars['Int']>;
  consultReason?: Maybe<Scalars['String']>;
  consultState?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  actualGender?: Maybe<Scalars['String']>;
  patientFirstName?: Maybe<Scalars['String']>;
  patientFirstLastName?: Maybe<Scalars['String']>;
  patientSecondLastName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['ID']>;
  memberUuid?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Int']>;
  patientPrefix?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['ID']>;
  zoomMeetingPassword?: Maybe<Scalars['String']>;
  zoomAccessToken?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['ID']>;
  doctorFirstName?: Maybe<Scalars['String']>;
  doctorFirstLastName?: Maybe<Scalars['String']>;
  doctorSecondLastName?: Maybe<Scalars['String']>;
  doctorId?: Maybe<Scalars['ID']>;
  doctorPrefix?: Maybe<Scalars['String']>;
  doctorSpecialty?: Maybe<Scalars['String']>;
  doctorIsPrimaryCare?: Maybe<Scalars['String']>;
};

export type PaginatedChronologicalLimitedConsultsType = {
  __typename?: 'PaginatedChronologicalLimitedConsultsType';
  totalCount?: Maybe<Scalars['Int']>;
  chronologicalConsults?: Maybe<Array<AnnualLimitedConsultsType>>;
};

export type AnnualLimitedConsultsType = {
  __typename?: 'AnnualLimitedConsultsType';
  year: Scalars['Int'];
  months?: Maybe<Array<MonthlyLimitedConsultsType>>;
};

export type MonthlyLimitedConsultsType = {
  __typename?: 'MonthlyLimitedConsultsType';
  month: Scalars['Int'];
  consults?: Maybe<Array<LimitedConsultType>>;
};

export enum OnDemandConsultStateEnum {
  ActiveHealthPlanNeeded = 'ACTIVE_HEALTH_PLAN_NEEDED',
  MedicalDetailsNeeded = 'MEDICAL_DETAILS_NEEDED',
  Ready = 'READY',
  Claimed = 'CLAIMED',
  InProgress = 'IN_PROGRESS',
  InReview = 'IN_REVIEW',
  InObservation = 'IN_OBSERVATION',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export type SpecializationGroupConsultCountType = {
  __typename?: 'SpecializationGroupConsultCountType';
  consultCount?: Maybe<Scalars['Int']>;
  coveredYearlyConsults?: Maybe<Scalars['Int']>;
};

export type PageBasedComplianceAlertType = {
  __typename?: 'PageBasedComplianceAlertType';
  totalCount?: Maybe<Scalars['Int']>;
  alerts?: Maybe<Array<Maybe<ComplianceAlertType>>>;
};

export enum ComplianceAlertAnnotation {
  ReviewedByAnybody = 'REVIEWED_BY_ANYBODY',
  NotReviewed = 'NOT_REVIEWED',
  ReviewedOnlyByOfficer = 'REVIEWED_ONLY_BY_OFFICER',
  ReviewedOnlyByCommittee = 'REVIEWED_ONLY_BY_COMMITTEE'
}

export type PaginatedServiceType = {
  __typename?: 'PaginatedServiceType';
  totalCount?: Maybe<Scalars['Int']>;
  serviceTypes?: Maybe<Array<Maybe<ServiceTypeType>>>;
};

export type AppLimitedServicesType = {
  __typename?: 'AppLimitedServicesType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceType>;
  processState?: Maybe<ServiceProcessStateTypeEnum>;
  serviceTypeName?: Maybe<Scalars['String']>;
  state?: Maybe<RulingState>;
  relatedServicePrescriptions: Array<AppLimitedPrescriptionType>;
  relatedServiceConsult?: Maybe<AppLimitedConsultUnionType>;
  payoutInvoice?: Maybe<LimitedInvoiceType>;
  payInMethod?: Maybe<PayInMethods>;
  providerName?: Maybe<Scalars['String']>;
  serviceIsReimbursement?: Maybe<Scalars['Boolean']>;
  servicePayoutMode?: Maybe<ServiceCoveragePayoutMode>;
  servicePayoutTotalAmount?: Maybe<Scalars['Int']>;
  serviceTotalAmountWithTaxes?: Maybe<Scalars['Int']>;
  serviceSofiaCoversSubtotalAmount?: Maybe<Scalars['Int']>;
  serviceSofiaCoversVatAmount?: Maybe<Scalars['Int']>;
  serviceSofiaCoversTotalAmount?: Maybe<Scalars['Int']>;
  serviceMemberCoinsuredSubtotalAmount?: Maybe<Scalars['Int']>;
  serviceMemberCoinsuredVatAmount?: Maybe<Scalars['Int']>;
  serviceMemberCoinsuredTotalAmount?: Maybe<Scalars['Int']>;
  serviceMemberCoinsuredPercent?: Maybe<Scalars['Int']>;
  serviceMemberCoveredPercent?: Maybe<Scalars['Int']>;
  serviceElectronicVoucherTotalAmount?: Maybe<Scalars['Int']>;
  rejectionMessageForUser?: Maybe<Scalars['JSONString']>;
  rejectedAmountCentsMessageForMember?: Maybe<Scalars['String']>;
  limitedElectronicVoucherItems?: Maybe<Array<ElectronicVoucherItemLimitedType>>;
  evidenceTokens: Array<LimitedEvidenceType>;
  doctor?: Maybe<DoctorProfileType>;
  doctorFriend?: Maybe<DoctorFriendType>;
  provider?: Maybe<ProviderBranchType>;
  latestEvidenceToken?: Maybe<LimitedEvidenceType>;
};

export type AppLimitedPrescriptionType = {
  __typename?: 'AppLimitedPrescriptionType';
  id: Scalars['ID'];
};

export type AppLimitedConsultUnionType = AppLimitedOnDemandConsultType | AppLimitedInPersonConsultType | AppLimitedIncludedConsultType | AppLimitedInPersonConsultByProviderType | AppLimitedRehabilitationTherapyType;

export type AppLimitedOnDemandConsultType = {
  __typename?: 'AppLimitedOnDemandConsultType';
  id: Scalars['ID'];
};

export type AppLimitedInPersonConsultType = {
  __typename?: 'AppLimitedInPersonConsultType';
  id: Scalars['ID'];
};

export type AppLimitedIncludedConsultType = {
  __typename?: 'AppLimitedIncludedConsultType';
  id: Scalars['ID'];
};

export type AppLimitedInPersonConsultByProviderType = {
  __typename?: 'AppLimitedInPersonConsultByProviderType';
  id: Scalars['ID'];
};

export type AppLimitedRehabilitationTherapyType = {
  __typename?: 'AppLimitedRehabilitationTherapyType';
  id: Scalars['ID'];
};

export type LimitedInvoiceType = {
  __typename?: 'LimitedInvoiceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  state?: Maybe<InvoiceState>;
  billingInfo: Scalars['JSONString'];
  dueAt?: Maybe<Scalars['Date']>;
  electronicVouchersPdfZipDoc?: Maybe<DocumentType>;
  electronicVouchersXmlZipDoc?: Maybe<DocumentType>;
  invoiceSnapshotXlsxDoc?: Maybe<DocumentType>;
  invoiceSnapshotPdfDoc?: Maybe<DocumentType>;
  sumTotalCents?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<InvoiceItemType>>>;
  transactions?: Maybe<Array<Maybe<LimitedTransactionType>>>;
  successfulTransaction?: Maybe<LimitedTransactionType>;
  successfulElectronicVoucherCount?: Maybe<Scalars['Int']>;
  expectedElectronicVouchersCount?: Maybe<Scalars['Int']>;
  customerPaymentFlowType?: Maybe<CustomerPaymentFlowType>;
  description?: Maybe<Scalars['String']>;
};

export type ElectronicVoucherItemLimitedType = {
  __typename?: 'ElectronicVoucherItemLimitedType';
  id: Scalars['ID'];
  accepted: Scalars['Boolean'];
  electronicVoucherItemDescription?: Maybe<Scalars['String']>;
};

export type AppLimitedServicesInfoType = {
  __typename?: 'AppLimitedServicesInfoType';
  hasServices?: Maybe<Scalars['Boolean']>;
  hasRecentProcessFinalized?: Maybe<Scalars['Boolean']>;
};

export type PaginatedChronologicalAppLimitedServices = {
  __typename?: 'PaginatedChronologicalAppLimitedServices';
  totalCount?: Maybe<Scalars['Int']>;
  chronologicalServices?: Maybe<Array<Maybe<AnnualLimitedServicesType>>>;
};

export type AnnualLimitedServicesType = {
  __typename?: 'AnnualLimitedServicesType';
  year: Scalars['Int'];
  months?: Maybe<Array<MonthlyLimitedServicesType>>;
};

export type MonthlyLimitedServicesType = {
  __typename?: 'MonthlyLimitedServicesType';
  month: Scalars['Int'];
  services?: Maybe<Array<AppLimitedServicesType>>;
};

export type PaginatedChecksLogType = {
  __typename?: 'PaginatedChecksLogType';
  totalCount: Scalars['Int'];
  checksLog: Array<ChecksLogType>;
};

export type ChecksLogType = {
  __typename?: 'ChecksLogType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  service: ServiceTypeType;
  rule: Scalars['String'];
  checkResult: Scalars['Boolean'];
};

export type PaginatedProviderServiceCoveragePayoutType = {
  __typename?: 'PaginatedProviderServiceCoveragePayoutType';
  totalCount: Scalars['Int'];
  payouts: Array<ServiceCoveragePayoutType>;
  summarySubtotalCents?: Maybe<Scalars['Int']>;
  summaryVatCents?: Maybe<Scalars['Int']>;
  summaryTotalCents?: Maybe<Scalars['Int']>;
  allPayoutIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type PaginatedServiceCoveragePayoutType = {
  __typename?: 'PaginatedServiceCoveragePayoutType';
  totalCount: Scalars['Int'];
  payouts: Array<ServiceCoveragePayoutType>;
};

export enum ServiceTransactionState {
  Draft = 'DRAFT',
  Requested = 'REQUESTED',
  Failed = 'FAILED',
  Confirmed = 'CONFIRMED',
  InSitu = 'IN_SITU',
  Uncollectible = 'UNCOLLECTIBLE',
  Refunded = 'REFUNDED',
  Staging = 'STAGING'
}

export type PaginatedTypeFormAttachmentType = {
  __typename?: 'PaginatedTypeFormAttachmentType';
  totalCount?: Maybe<Scalars['Int']>;
  typeformAttachments?: Maybe<Array<TypeFormAttachmentType>>;
};

export type PaginatedInvoiceItemType = {
  __typename?: 'PaginatedInvoiceItemType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<InvoiceItemType>>;
};

export type PageBasedAccessBusinessHealthPlanType = {
  __typename?: 'PageBasedAccessBusinessHealthPlanType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<HealthPlanType>>;
};

export type BusinessServicesStatisticsType = {
  __typename?: 'BusinessServicesStatisticsType';
  servicesList?: Maybe<Array<Maybe<ServiceUseCountType>>>;
  welcomeOnDemandConsultsTakenHuliIdeal?: Maybe<Scalars['Int']>;
  welcomeOnDemandConsultsTakenHuliApproximation?: Maybe<Scalars['Int']>;
  welcomeOnDemandConsultsTakenService?: Maybe<Scalars['Int']>;
  membersWhoHaveUsedServices?: Maybe<Scalars['Int']>;
};

export type ServiceUseCountType = {
  __typename?: 'ServiceUseCountType';
  serviceType?: Maybe<Scalars['String']>;
  serviceCount?: Maybe<Scalars['Int']>;
};

export type PageBasedBusinessType = {
  __typename?: 'PageBasedBusinessType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<BusinessType>>>;
};

export type BusinessPaymentStatusType = {
  __typename?: 'BusinessPaymentStatusType';
  businessId?: Maybe<Scalars['ID']>;
  commonName?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['ID']>;
  paymentStatus?: Maybe<Scalars['String']>;
  invoiceTotalCents?: Maybe<Scalars['Int']>;
  activeHealthPlans?: Maybe<Scalars['Int']>;
  businessType?: Maybe<Scalars['String']>;
  invoiceDueAt?: Maybe<Scalars['Date']>;
  overdueDays?: Maybe<Scalars['Int']>;
  gracePeriodEndsAt?: Maybe<Scalars['Date']>;
  daysRemaining?: Maybe<Scalars['Int']>;
};

export type BusinessGroupsConfigEditCategoryQueryType = {
  __typename?: 'BusinessGroupsConfigEditCategoryQueryType';
  editCategory?: Maybe<BusinessGroupsConfigEditCategory>;
};

export enum BusinessGroupsConfigEditCategory {
  Simple = 'SIMPLE',
  NewAmendments = 'NEW_AMENDMENTS',
  GroupReissue = 'GROUP_REISSUE'
}

export type BusinessMaternityAddonChangesCategoryQueryType = {
  __typename?: 'BusinessMaternityAddonChangesCategoryQueryType';
  maternityChangesCategory?: Maybe<BusinessMaternityChangesCategory>;
};

export enum BusinessMaternityChangesCategory {
  AddMaternity_6M = 'ADD_MATERNITY_6M',
  RemoveMaternity_6M = 'REMOVE_MATERNITY_6M',
  Reissue = 'REISSUE'
}

export type PageBasedBusinessEmployeeType = {
  __typename?: 'PageBasedBusinessEmployeeType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<BusinessEmployeeType>>;
};

export type BusinessEmployeeStatisticsType = {
  __typename?: 'BusinessEmployeeStatisticsType';
  total?: Maybe<Scalars['Int']>;
  pending?: Maybe<Scalars['Int']>;
  initiated?: Maybe<Scalars['Int']>;
  subscribed?: Maybe<Scalars['Int']>;
  cancelled?: Maybe<Scalars['Int']>;
  rejected?: Maybe<Scalars['Int']>;
};

export type SearchBusinessType = {
  __typename?: 'SearchBusinessType';
  results?: Maybe<Array<BusinessType>>;
};

export type SearchBusinessEmployeesType = {
  __typename?: 'SearchBusinessEmployeesType';
  results?: Maybe<Array<BusinessEmployeeType>>;
};

export type BusinessBillInfoType = {
  __typename?: 'BusinessBillInfoType';
  paymentTotalCents?: Maybe<Scalars['Int']>;
  paymentTotalCentsEstimation?: Maybe<Scalars['Int']>;
  paymentDueDate?: Maybe<Scalars['DateTime']>;
  statementDueDate?: Maybe<Scalars['DateTime']>;
  paymentExpirationState?: Maybe<BusinessPaymentExpirationState>;
  customerPaymentFlowType?: Maybe<CustomerPaymentFlowType>;
  paymentExpirationFriendlyPeriodDate?: Maybe<Scalars['Date']>;
};

export enum BusinessPaymentExpirationState {
  BeforeStatementDueDate = 'BEFORE_STATEMENT_DUE_DATE',
  OnTimePeriod = 'ON_TIME_PERIOD',
  CloseToDueDate = 'CLOSE_TO_DUE_DATE',
  LastChance = 'LAST_CHANCE',
  AfterPaymentDueDate = 'AFTER_PAYMENT_DUE_DATE',
  Reimbursement = 'REIMBURSEMENT'
}

export type BusinessLastPaidBillInfoType = {
  __typename?: 'BusinessLastPaidBillInfoType';
  paymentTotalCents?: Maybe<Scalars['Int']>;
  paidAtDate?: Maybe<Scalars['DateTime']>;
};

export type PageBasedBusinessInvoiceType = {
  __typename?: 'PageBasedBusinessInvoiceType';
  totalCount?: Maybe<Scalars['Int']>;
  /** @deprecated Not used */
  notPaidInvoices?: Maybe<Scalars['Int']>;
  /** @deprecated Not used */
  failedInvoices?: Maybe<Scalars['Int']>;
  invoices?: Maybe<Array<Maybe<LimitedInvoiceType>>>;
};

export type PageBasedBusinessBeneficiaryConfigProposalType = {
  __typename?: 'PageBasedBusinessBeneficiaryConfigProposalType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<BusinessBeneficiaryConfigProposalType>>;
};

export type BusinessBeneficiaryConfigProposalType = {
  __typename?: 'BusinessBeneficiaryConfigProposalType';
  id: Scalars['ID'];
  businessPlansGroupConfigProposal?: Maybe<BusinessPlansGroupConfigProposalType>;
  businessBeneficiary?: Maybe<BusinessEmployeeType>;
  currentTotalInstallmentCents?: Maybe<Scalars['Int']>;
  incomingTotalInstallmentCents?: Maybe<Scalars['Int']>;
};

export type PageBasedBusinessPlansGeneralConfigProposalType = {
  __typename?: 'PageBasedBusinessPlansGeneralConfigProposalType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<BusinessPlansGeneralConfigProposalType>>;
};

export type BusinessMaternityEligibleBeneficiariesType = {
  __typename?: 'BusinessMaternityEligibleBeneficiariesType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<BusinessEmployeeType>>;
};

export type BusinessBeneficiariesToCancelForReissueType = {
  __typename?: 'BusinessBeneficiariesToCancelForReissueType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<BusinessEmployeeType>>;
};

export type CurrentBalanceType = {
  __typename?: 'CurrentBalanceType';
  actual?: Maybe<ActualBalanceType>;
  provisional?: Maybe<ProvisionalBalanceType>;
};

export type ActualBalanceType = {
  __typename?: 'ActualBalanceType';
  insuredAmountBalanceCents?: Maybe<Scalars['Int']>;
  coinsuredAmountBalanceCents?: Maybe<Scalars['Int']>;
  hospitalService?: Maybe<ServiceBalanceDetailType>;
  labStudies?: Maybe<ServiceBalanceDetailType>;
  medicines?: Maybe<ServiceBalanceDetailType>;
  ambulance?: Maybe<ServiceBalanceDetailType>;
  externalConsult?: Maybe<ServiceBalanceDetailType>;
  urgency?: Maybe<ServiceBalanceDetailType>;
  extVideoConsult?: Maybe<ServiceBalanceDetailType>;
};

export type ServiceBalanceDetailType = {
  __typename?: 'ServiceBalanceDetailType';
  quantity?: Maybe<Scalars['Int']>;
  amountCents?: Maybe<Scalars['Int']>;
  availableQuantity?: Maybe<Scalars['Int']>;
  availableAmountCents?: Maybe<Scalars['Int']>;
};

export type ProvisionalBalanceType = {
  __typename?: 'ProvisionalBalanceType';
  insuredAmountBalanceCents?: Maybe<Scalars['Int']>;
  coinsuredAmountBalanceCents?: Maybe<Scalars['Int']>;
  hospitalService?: Maybe<ServiceBalanceDetailType>;
  labStudies?: Maybe<ServiceBalanceDetailType>;
  medicines?: Maybe<ServiceBalanceDetailType>;
  ambulance?: Maybe<ServiceBalanceDetailType>;
  externalConsult?: Maybe<ServiceBalanceDetailType>;
  urgency?: Maybe<ServiceBalanceDetailType>;
  extVideoConsult?: Maybe<ServiceBalanceDetailType>;
};

export type ServiceParameters = {
  __typename?: 'ServiceParameters';
  service: ServiceType;
  quantity: Scalars['Int'];
  amountCents: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createDoctorLead?: Maybe<CreateDoctorLead>;
  requestDoctorLeadCv?: Maybe<RequestDoctorLeadCv>;
  requestDoctorLeadUserCreation?: Maybe<RequestDoctorLeadUserCreation>;
  updateDoctorLead?: Maybe<UpdateDoctorLead>;
  createUserFromLead?: Maybe<CreateUserFromLead>;
  uploadPublicDoctorLeadDocument?: Maybe<UploadPublicDoctorLeadDocument>;
  consumeAuthTokenAndPullContactDataFromWebQuotation?: Maybe<ConsumeAuthTokenAndPullContactDataFromWebQuotation>;
  createMedicalHistoryForMember?: Maybe<CreateMedicalHistoryForMember>;
  updateMedicalHistoryForMember?: Maybe<UpdateMedicalHistoryForMember>;
  addMedicalHistoryAllergy?: Maybe<AddMedicalHistoryAllergy>;
  updateMedicalHistoryAllergy?: Maybe<UpdateMedicalHistoryAllergy>;
  deleteMedicalHistoryAllergy?: Maybe<DeleteMedicalHistoryAllergy>;
  uploadMedicalHistoryExamDocument?: Maybe<UploadMedicalHistoryExamDocument>;
  uploadExamDocumentToMedicalNote?: Maybe<UploadExamDocumentToMedicalNote>;
  assignMedicalHistoryExamToMedicalNote?: Maybe<AssignMedicalHistoryExamToMedicalNote>;
  deleteMedicalHistoryExam?: Maybe<DeleteMedicalHistoryExam>;
  updateMedicalHistoryExam?: Maybe<UpdateMedicalHistoryExam>;
  doctorDeleteMedicalNoteExam?: Maybe<DoctorDeleteMedicalNoteExam>;
  addFamilyPathology?: Maybe<AddFamilyPathology>;
  updateFamilyPathology?: Maybe<UpdateFamilyPathology>;
  deleteFamilyPathology?: Maybe<DeleteFamilyPathology>;
  addHospitalization?: Maybe<AddHospitalization>;
  updateHospitalization?: Maybe<UpdateHospitalization>;
  deleteHospitalization?: Maybe<DeleteHospitalization>;
  addMalformation?: Maybe<AddMalformation>;
  updateMalformation?: Maybe<UpdateMalformation>;
  deleteMalformation?: Maybe<DeleteMalformation>;
  addMedicalHistoryMedicine?: Maybe<AddMedicalHistoryMedicine>;
  updateMedicalHistoryMedicine?: Maybe<UpdateMedicalHistoryMedicine>;
  deleteMedicalHistoryMedicine?: Maybe<DeleteMedicalHistoryMedicine>;
  updateNonPathologicalHistory?: Maybe<UpdateNonPathologicalHistory>;
  memberUpdateNonPathologicalHistory?: Maybe<MemberUpdateNonPathologicalHistory>;
  updateObstetricGynecologicalHistory?: Maybe<UpdateObstetricGynecologicalHistory>;
  addPregnancyDelivery?: Maybe<AddPregnancyDelivery>;
  updatePregnancyDelivery?: Maybe<UpdatePregnancyDelivery>;
  deletePregnancyDelivery?: Maybe<DeletePregnancyDelivery>;
  addGynecologicalExam?: Maybe<AddGynecologicalExam>;
  updateGynecologicalExam?: Maybe<UpdateGynecologicalExam>;
  deleteGynecologicalExam?: Maybe<DeleteGynecologicalExam>;
  addPathology?: Maybe<AddPathology>;
  updatePathology?: Maybe<UpdatePathology>;
  deletePathology?: Maybe<DeletePathology>;
  addPregnancy?: Maybe<AddPregnancy>;
  updatePregnancy?: Maybe<UpdatePregnancy>;
  deletePregnancy?: Maybe<DeletePregnancy>;
  addSurgery?: Maybe<AddSurgery>;
  updateSurgery?: Maybe<UpdateSurgery>;
  deleteSurgery?: Maybe<DeleteSurgery>;
  addVaccine?: Maybe<AddVaccine>;
  updateVaccine?: Maybe<UpdateVaccine>;
  deleteVaccine?: Maybe<DeleteVaccine>;
  updateMedicalHistoryBasicInfo?: Maybe<UpdateMedicalHistoryBasicInfo>;
  addMentalHealthDiagnosis?: Maybe<AddMentalHealthDiagnosis>;
  updateMentalHealthDiagnosis?: Maybe<UpdateMentalHealthDiagnosis>;
  deleteMentalHealthDiagnosis?: Maybe<DeleteMentalHealthDiagnosis>;
  updatePerinatalHistory?: Maybe<UpdatePerinatalHistory>;
  updateParentPerinatalHistory?: Maybe<UpdateParentPerinatalHistory>;
  addGeneralObservation?: Maybe<AddGeneralObservation>;
  updateGeneralObservation?: Maybe<UpdateGeneralObservation>;
  deleteGeneralObservation?: Maybe<DeleteGeneralObservation>;
  updateServiceMedicalNote?: Maybe<UpdateServiceMedicalNote>;
  createNewMedicalNote?: Maybe<CreateNewMedicalNote>;
  createConsultMedicalNote?: Maybe<CreateConsultMedicalNote>;
  createScheduledConsultMedicalNote?: Maybe<CreateScheduledConsultMedicalNote>;
  updateMedicalNote?: Maybe<UpdateMedicalNote>;
  updateMedicalNoteIsAudited?: Maybe<UpdateMedicalNoteIsAudited>;
  deleteMedicalNote?: Maybe<DeleteMedicalNote>;
  cancelMedicalNote?: Maybe<CancelMedicalNote>;
  completeMedicalNote?: Maybe<CompleteMedicalNote>;
  addPrescriptionToMedicalNote?: Maybe<AddPrescriptionToMedicalNote>;
  reclassifyMedicalNote?: Maybe<ReclassifyMedicalNote>;
  updateEvaluation?: Maybe<UpdateEvaluation>;
  addInterrogation?: Maybe<AddInterrogation>;
  updateInterrogation?: Maybe<UpdateInterrogation>;
  deleteInterrogation?: Maybe<DeleteInterrogation>;
  addExploration?: Maybe<AddExploration>;
  updateExploration?: Maybe<UpdateExploration>;
  deleteExploration?: Maybe<DeleteExploration>;
  addProcedure?: Maybe<AddProcedure>;
  updateProcedure?: Maybe<UpdateProcedure>;
  uploadProcedureDocument?: Maybe<UploadProcedureDocument>;
  deleteProcedure?: Maybe<DeleteProcedure>;
  addAddendum?: Maybe<AddAddendum>;
  updateAddendum?: Maybe<UpdateAddendum>;
  uploadAddendumDocument?: Maybe<UploadAddendumDocument>;
  deleteAddendum?: Maybe<DeleteAddendum>;
  updatePhysicalExam?: Maybe<UpdatePhysicalExam>;
  updateNoteBasicInfo?: Maybe<UpdateNoteBasicInfo>;
  updateDischargeInfo?: Maybe<UpdateDischargeInfo>;
  updatePhysicalActivity?: Maybe<UpdatePhysicalActivity>;
  updateObstetricGynecologicalInfo?: Maybe<UpdateObstetricGynecologicalInfo>;
  endMedicalProgram?: Maybe<EndMedicalProgram>;
  createMaternityProgram?: Maybe<CreateMaternityProgram>;
  updateMaternityProgram?: Maybe<UpdateMaternityProgram>;
  createBariatricsProgram?: Maybe<CreateBariatricsProgram>;
  updateBariatricsProgram?: Maybe<UpdateBariatricsProgram>;
  createProcedureCategory?: Maybe<CreateProcedureCategory>;
  updateProcedureCategory?: Maybe<UpdateProcedureCategory>;
  addPerformedProcedure?: Maybe<AddPerformedProcedure>;
  updatePerformedProcedure?: Maybe<UpdatePerformedProcedure>;
  uploadPerformedProcedureEvidence?: Maybe<UploadPerformedProcedureEvidence>;
  deletePerformedProcedureEvidence?: Maybe<DeletePerformedProcedureEvidence>;
  deletePerformedProcedure?: Maybe<DeletePerformedProcedure>;
  addScheduledProcedure?: Maybe<AddScheduledProcedure>;
  updateScheduledProcedure?: Maybe<UpdateScheduledProcedure>;
  uploadScheduledProcedureEvidence?: Maybe<UploadScheduledProcedureEvidence>;
  deleteScheduledProcedureEvidence?: Maybe<DeleteScheduledProcedureEvidence>;
  deleteScheduledProcedure?: Maybe<DeleteScheduledProcedure>;
  createMedicalNotePrescription?: Maybe<CreateMedicalNotePrescription>;
  updateMedicalNotePrescription?: Maybe<UpdateMedicalNotePrescription>;
  deleteMedicalNotePrescription?: Maybe<DeleteMedicalNotePrescription>;
  createPrescriptionItem?: Maybe<CreatePrescriptionItem>;
  updatePrescriptionItem?: Maybe<UpdatePrescriptionItem>;
  deletePrescriptionItem?: Maybe<DeletePrescriptionItem>;
  signMedicalNotePrescription?: Maybe<SignMedicalNotePrescription>;
  createExclusion?: Maybe<CreateExclusion>;
  updateExclusion?: Maybe<UpdateExclusion>;
  createCaseEvent?: Maybe<CreateCaseEvent>;
  deleteCaseEvent?: Maybe<DeleteCaseEvent>;
  /** @deprecated Please use create service providing a case event id */
  groupServicesOnCaseEvent?: Maybe<GroupServicesOnCaseEvent>;
  assignUserToCaseEvent?: Maybe<AssignUserToCaseEvent>;
  updateCaseEvent?: Maybe<UpdateCaseEvent>;
  removeServicesFromCaseEvent?: Maybe<RemoveServicesFromCaseEvent>;
  addEventComment?: Maybe<AddEventComment>;
  addCaseEventSummary?: Maybe<AddCaseEventSummary>;
  createEventQuote?: Maybe<CreateEventQuote>;
  deleteEventQuote?: Maybe<DeleteEventQuote>;
  buildEventQuoteLetter?: Maybe<BuildEventQuoteLetter>;
  updateAdministrativeRulingTasks?: Maybe<UpdateAdministrativeRulingTasks>;
  updateMedicalPreRulingTasks?: Maybe<UpdateMedicalPreRulingTasks>;
  updateQuotingAndSchedulingTasks?: Maybe<UpdateQuotingAndSchedulingTasks>;
  updateMedicalProcedureOccurrenceTasks?: Maybe<UpdateMedicalProcedureOccurrenceTasks>;
  updateDischargeTasks?: Maybe<UpdateDischargeTasks>;
  updateCaseEventPaymentStatusTasks?: Maybe<UpdateCaseEventPaymentStatusTasks>;
  updateEventQuote?: Maybe<UpdateEventQuote>;
  deleteEventQuoteItems?: Maybe<DeleteEventQuoteItems>;
  sendEventQuoteLetter?: Maybe<SendEventQuoteLetter>;
  createCptPriceReference?: Maybe<CreateCptPriceReference>;
  updateCptPriceReference?: Maybe<UpdateCptPriceReference>;
  uploadDataForEventBudget?: Maybe<UploadDataForEventBudget>;
  deleteEventBudget?: Maybe<DeleteEventBudget>;
  buildDefaultEventQuoteLetters?: Maybe<BuildDefaultEventQuoteLetters>;
  changeQuoteLetterSentState?: Maybe<ChangeQuoteLetterSentState>;
  deleteQuoteLetter?: Maybe<DeleteQuoteLetter>;
  validateServiceCoveragePayoutElectronicVoucherAmounts?: Maybe<ValidateServiceCoveragePayoutElectronicVoucherAmounts>;
  setDocumentXmlElectronicVoucher?: Maybe<SetDocumentXmlElectronicVoucher>;
  getServiceCoveragePayoutDocumentXmlUploadData?: Maybe<GetServiceCoveragePayoutDocumentXmlUploadData>;
  cancelCaseEvent?: Maybe<CancelCaseEvent>;
  pauseCaseEvent?: Maybe<PauseCaseEvent>;
  buildDefaultCaseEventQuoteAndQuoteLetters?: Maybe<BuildDefaultCaseEventQuoteAndQuoteLetters>;
  createCaseEventFromScheduledProcedure?: Maybe<CreateCaseEventFromScheduledProcedure>;
  createFinalLetterData?: Maybe<CreateFinalLetterData>;
  updateFinalLetterData?: Maybe<UpdateFinalLetterData>;
  changeFinalLetterSentState?: Maybe<ChangeFinalLetterSentState>;
  deleteFinalLetter?: Maybe<DeleteFinalLetter>;
  sendFinalLetter?: Maybe<SendFinalLetter>;
  createCaseEventChannel?: Maybe<CreateCaseEventChannel>;
  inviteUserToEventChannel?: Maybe<InviteUserToEventChannel>;
  notifyToCaseEventChannel?: Maybe<NotifyToCaseEventChannel>;
  createCpt?: Maybe<CreateCpt>;
  updateCpt?: Maybe<UpdateCpt>;
  initUserHybridSubscription?: Maybe<InitUserHybridSubscriptionContext>;
  setupUserHealthplanApplication?: Maybe<SetUpUserHealthPlanApplication>;
  checkBusinessAccount?: Maybe<CheckBusinessAccount>;
  createHealthPlanApplication?: Maybe<CreateHealthPlanApplication>;
  updateHealthPlanApplication?: Maybe<UpdateHealthPlanApplication>;
  updateHealthPlanApplicationAnswers?: Maybe<UpdateHealthPlanApplicationAnswers>;
  updateHealthPlanApplicationIncentiveCode?: Maybe<UpdateHealthPlanApplicationIncentiveCode>;
  deleteHealthPlanApplicationIncentiveCode?: Maybe<DeleteHealthPlanApplicationIncentiveCode>;
  submitHealthPlanApplication?: Maybe<SubmitHealthPlanApplication>;
  submitHealthPlanApplicationWithToken?: Maybe<SubmitHealthPlanApplicationWithToken>;
  acceptHealthPlanApplication?: Maybe<AcceptHealthPlanApplication>;
  attemptPreApprovedSubscriptionByUser?: Maybe<AttemptPreApprovedSubscriptionByUser>;
  closeHealthPlanApplication?: Maybe<CloseHealthPlanApplication>;
  setWaitingHealthPlanApplication?: Maybe<SetWaitingHealthPlanApplication>;
  notifyHealthPlanApplicationState?: Maybe<NotifyHealthPlanApplicationState>;
  sendHealthPlanAcceptDocuments?: Maybe<SendHealthPlanAcceptDocuments>;
  resendWelcomeEmailsToCollective?: Maybe<ResendCollectiveWelcomeEmail>;
  updateHealthPlanApplicationItem?: Maybe<UpdateHealthPlanApplicationItem>;
  forceHealthPlanApplicationItemCheck?: Maybe<ForceHealthPlanApplicationItemCheck>;
  createHealthPlanApplicationItem?: Maybe<CreateHealthPlanApplicationItem>;
  createHealthPlanApplicationItemV2?: Maybe<CreateHealthPlanApplicationItemV2>;
  duplicateHealthPlanApplicationItem?: Maybe<DuplicateHealthPlanApplicationItem>;
  cancelHealthPlanApplicationItem?: Maybe<CancelHealthPlanApplicationItem>;
  deleteHealthPlanApplicationItem?: Maybe<DeleteHealthPlanApplicationItem>;
  uploadPhotoHealthPlanApplicationItem?: Maybe<UploadPhotoHealthPlanApplicationItem>;
  uploadHealthPlanApplicationItemDocument?: Maybe<UploadHealthPlanApplicationItemDocument>;
  uploadSignatureForWeb?: Maybe<UploadSignatureForWeb>;
  commentOnHealthPlanApplicationItem?: Maybe<CommentOnHealthPlanApplicationItem>;
  setWaitingHealthPlanApplicationItem?: Maybe<SetWaitingHealthPlanApplicationItem>;
  createHealthPlanQuote?: Maybe<CreateHealthPlanQuote>;
  updateHealthPlanQuote?: Maybe<UpdateHealthPlanQuote>;
  updateHealthPlanApplicationItemAnswers?: Maybe<UpdateHealthPlanApplicationItemAnswers>;
  setSubscriptionCheckState?: Maybe<SetSubscriptionCheckState>;
  setProfilingDataCheckState?: Maybe<SetProfilingDataCheckState>;
  refreshProfilingDataState?: Maybe<RefreshProfilingDataState>;
  approveCollectiveHealthPlanApplicationItemChecksAfterProfiling?: Maybe<ApproveCollectiveHealthPlanApplicationItemChecksAfterProfiling>;
  retriggerAutoSubscriptionChecks?: Maybe<RetriggerAutoSubscriptionChecks>;
  checkProductParameters?: Maybe<CheckProductParameters>;
  getInstallmentsAvailableForPaymentMethod?: Maybe<GetInstallmentsAvailableForPaymentMethod>;
  updateHealthPlanApplicationInterestFreePlan?: Maybe<UpdateHealthplanApplicationInterestFreePlan>;
  sendReminder?: Maybe<SendReminder>;
  setBusinessAsHealthPlanApplicationOwner?: Maybe<SetBusinessAsHealthPlanApplicationOwner>;
  sendSubscriptionPriceEstimationEmail?: Maybe<SendSubscriptionPriceEstimationEmail>;
  generateSubscriptionPriceEstimationDocument?: Maybe<GenerateSubscriptionPriceEstimationDocument>;
  generateSubscriptionPriceEstimationDocumentForCollective?: Maybe<GenerateSubscriptionPriceEstimationDocumentForCollective>;
  updateHealthPlanQuoteSubscriptionType?: Maybe<UpdateHealthPlanQuoteSubscriptionType>;
  startMiniSubscription?: Maybe<StartMiniSubscription>;
  updateMedicalRecommendation?: Maybe<UpdateMedicalRecommendation>;
  addMedicalRecommendationComment?: Maybe<AddMedicalRecommendationComment>;
  updateMedicalAdditionalInformation?: Maybe<UpdateMedicalAdditionalInformation>;
  toggleWaitingMemberToAcceptExclusions?: Maybe<ToggleWaitingMemberToAcceptExclusions>;
  submitActivationScores?: Maybe<SubmitActivationScores>;
  updateScheduledApprovalCall?: Maybe<UpdateScheduledApprovalCall>;
  setupActivationHealthPlanApplication?: Maybe<SetupActivationHealthPlanApplication>;
  addProfilingHospitalization?: Maybe<AddProfilingHospitalization>;
  addProfilingCancer?: Maybe<AddProfilingCancer>;
  addProfilingChronicDisease?: Maybe<AddProfilingChronicDisease>;
  addProfilingMentalHealthDiagnosis?: Maybe<AddProfilingMentalHealthDiagnosis>;
  addProfilingAllergy?: Maybe<AddProfilingAllergy>;
  addProfilingMedicine?: Maybe<AddProfilingMedicine>;
  addProfilingGynecologyDisease?: Maybe<AddProfilingGynecologyDisease>;
  updateProfilingHospitalization?: Maybe<UpdateProfilingHospitalization>;
  updateProfilingCancer?: Maybe<UpdateProfilingCancer>;
  updateProfilingChronicDisease?: Maybe<UpdateProfilingChronicDisease>;
  updateProfilingMentalHealthDiagnosis?: Maybe<UpdateProfilingMentalHealthDiagnosis>;
  updateProfilingAllergy?: Maybe<UpdateProfilingAllergy>;
  updateProfilingMedicine?: Maybe<UpdateProfilingMedicine>;
  updateProfilingGynecologyDisease?: Maybe<UpdateProfilingGynecologyDisease>;
  updateProfilingMedicalInfo?: Maybe<UpdateProfilingMedicalInfo>;
  deleteProfilingHospitalization?: Maybe<DeleteProfilingHospitalization>;
  deleteProfilingCancer?: Maybe<DeleteProfilingCancer>;
  deleteProfilingChronicDisease?: Maybe<DeleteProfilingChronicDisease>;
  deleteProfilingMentalHealthDiagnosis?: Maybe<DeleteProfilingMentalHealthDiagnosis>;
  deleteProfilingAllergy?: Maybe<DeleteProfilingAllergy>;
  deleteProfilingMedicine?: Maybe<DeleteProfilingMedicine>;
  deleteProfilingGynecologyDisease?: Maybe<DeleteProfilingGynecologyDisease>;
  addProfilingMedicalComment?: Maybe<AddProfilingMedicalComment>;
  submitCollectiveProfiling?: Maybe<SubmitCollectiveProfiling>;
  finishCollectiveOnboardingFlow?: Maybe<FinishCollectiveOnboardingFlow>;
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  refreshToken?: Maybe<Refresh>;
  createInternalUser?: Maybe<CreateInternalUser>;
  updateInternalUser?: Maybe<UpdateInternalUser>;
  createUserForMember?: Maybe<CreateUserForMember>;
  updateUser?: Maybe<UpdateUser>;
  setUserGroups?: Maybe<SetUserGroups>;
  requestUserEmailUpdate?: Maybe<RequestUserEmailUpdate>;
  confirmUserEmailUpdate?: Maybe<ConfirmUserEmailUpdate>;
  requestUserPasswordUpdate?: Maybe<RequestUserPasswordUpdate>;
  confirmEmailOtpAuth?: Maybe<ConfirmEmailOtpAuth>;
  requestEmailChange?: Maybe<RequestEmailChange>;
  uploadPhotoMember?: Maybe<UploadPhotoMember>;
  appForgetPasswordUser?: Maybe<AppForgetPasswordUser>;
  forgetPasswordUser?: Maybe<ForgetPasswordUser>;
  resetPasswordUser?: Maybe<ResetPasswordUser>;
  logoutUserFromAllSessions?: Maybe<LogoutUserFromAllSessions>;
  createMember?: Maybe<CreateMember>;
  updateMember?: Maybe<UpdateMember>;
  addCommentToMember?: Maybe<AddCommentToMember>;
  requestUpdateMember?: Maybe<RequestUpdateMember>;
  createEmergencyContact?: Maybe<CreateEmergencyContact>;
  requestSosCall?: Maybe<RequestSosCall>;
  updateEmergencyContact?: Maybe<UpdateEmergencyContact>;
  deleteEmergencyContact?: Maybe<DeleteEmergencyContact>;
  createStripeSource?: Maybe<CreateStripeSource>;
  setPrimaryStripeSource?: Maybe<SetPrimaryStripeSource>;
  deleteStripeSource?: Maybe<DeleteStripeSource>;
  deleteMember?: Maybe<DeleteMember>;
  addPushTokenToUser?: Maybe<AddPushTokenToUser>;
  deletePushTokenFromUser?: Maybe<DeletePushTokenFromUser>;
  sendTelephoneConfirm?: Maybe<SendTelephoneConfirm>;
  confirmTelephone?: Maybe<ConfirmTelephone>;
  phoneTokenAuth?: Maybe<PhoneTokenAuth>;
  updateUserTelephone?: Maybe<UpdateUserTelephone>;
  grantRepresentedUserAccess?: Maybe<GrantRepresentedUserAccess>;
  replaceDocument?: Maybe<ReplaceDocument>;
  uploadMemberDocument?: Maybe<UploadMemberDocument>;
  uploadAddressDocument?: Maybe<UploadAddressDocument>;
  uploadClaimsInvoiceDocument?: Maybe<UploadClaimsInvoiceDocument>;
  uploadAmendmentAttachmentDocument?: Maybe<UploadAmendmentAttachmentDocument>;
  createAuthorizationToken?: Maybe<CreateAuthorizationToken>;
  createAuthorizationCode?: Maybe<CreateAuthorizationCode>;
  consumeAuthorizationToken?: Maybe<ConsumeAuthorizationToken>;
  resetCollectiveUserCredentials?: Maybe<ResetCollectiveUserCredentials>;
  resetB2bAdminUserCredentials?: Maybe<ResetB2BAdminUserCredentials>;
  requestForgotDoctorPassword?: Maybe<RequestForgotDoctorPassword>;
  setCollectiveUserCredentials?: Maybe<SetCollectiveUserCredentials>;
  confirmCollectiveUserCredentials?: Maybe<ConfirmCollectiveUserCredentials>;
  setSubscriptionState?: Maybe<SetSubscriptionState>;
  setSubscriptionStateV2?: Maybe<SetSubscriptionStateV2>;
  toggleAutomaticRenew?: Maybe<ToggleAutomaticRenew>;
  updateFeatureFlagValue?: Maybe<UpdateFeatureFlagValue>;
  userIntercomTag?: Maybe<UserIntercomTag>;
  runCommand?: Maybe<RunCommand>;
  updateHealthPlanApplicationItemMemberData?: Maybe<UpdateHealthPlanApplicationItemMemberData>;
  impersonateUser?: Maybe<ImpersonateUser>;
  logoutFromImpersonation?: Maybe<LogoutFromImpersonation>;
  magicLinkAuth?: Maybe<MagicLinkAuth>;
  otpTokenAuth?: Maybe<OtpTokenAuth>;
  freeUpUserTelephoneOrEmail?: Maybe<FreeUpUserTelephoneOrEmail>;
  otpVerification?: Maybe<OtpVerification>;
  createOtpDevice?: Maybe<CreateOtpDevice>;
  confirmOtpDevice?: Maybe<ConfirmOtpDevice>;
  deleteConfirmedOtpDevice?: Maybe<DeleteConfirmedOtpDevice>;
  deleteUnconfirmedOtpDevices?: Maybe<DeleteUnconfirmedOtpDevices>;
  generateOtpChallenge?: Maybe<GenerateOtpChallenge>;
  createMemberExclusion?: Maybe<CreateMemberExclusion>;
  doctorCreateMemberExclusion?: Maybe<DoctorCreateMemberExclusion>;
  updateMemberExclusion?: Maybe<UpdateMemberExclusion>;
  deleteMemberExclusion?: Maybe<DeleteMemberExclusion>;
  updateActivationCampaignState?: Maybe<UpdateActivationCampaignState>;
  addProviderToMemberFavourites?: Maybe<AddProviderToMemberFavourites>;
  removeProviderFromMemberFavourites?: Maybe<RemoveProviderFromMemberFavourites>;
  changeRepresentationOnDependentUser?: Maybe<ChangeRepresentationOnDependentUser>;
  resetMemberRepresentationToSponsorUser?: Maybe<ResetMemberRepresentationToSponsorUser>;
  sendReferralInvitationCode?: Maybe<SendReferralInvitationCode>;
  providersToken?: Maybe<ProvidersToken>;
  createDoctorFriend?: Maybe<CreateDoctorFriend>;
  updateDoctorFriend?: Maybe<UpdateDoctorFriend>;
  createProviderCategory?: Maybe<CreateProviderCategory>;
  updateProviderCategory?: Maybe<UpdateProviderCategory>;
  deleteProviderCategory?: Maybe<DeleteProviderCategory>;
  createProvider?: Maybe<CreateProvider>;
  updateProvider?: Maybe<UpdateProvider>;
  deleteProvider?: Maybe<DeleteProvider>;
  createProviderBranchOffice?: Maybe<CreateProviderBranchOffice>;
  updateProviderBranchOffice?: Maybe<UpdateProviderBranchOffice>;
  deleteProviderBranchOffice?: Maybe<DeleteProviderBranchOffice>;
  updateBranchOfficeContactInfo?: Maybe<UpdateBranchOfficeContactInfo>;
  updateBranchOfficeHospitalInfo?: Maybe<UpdateBranchOfficeHospitalInfo>;
  createDoctorSpecialistDetail?: Maybe<CreateDoctorSpecialistDetail>;
  updateDoctorSpecialistDetail?: Maybe<UpdateDoctorSpecialistDetail>;
  deleteDoctorSpecialistDetail?: Maybe<DeleteDoctorSpecialistDetail>;
  uploadDoctorSpecialistDetailDocument?: Maybe<UploadDoctorSpecialistDetailDocument>;
  addDoctorSpecializationCertificateValidity?: Maybe<AddDoctorSpecializationCertificateValidity>;
  createDoctor?: Maybe<CreateDoctor>;
  updateDoctor?: Maybe<UpdateDoctor>;
  updateDoctorById?: Maybe<UpdateDoctorById>;
  uploadDoctorSignatureImage?: Maybe<UploadDoctorSignatureImage>;
  uploadDoctorDocument?: Maybe<UploadDoctorDocument>;
  updateDoctorGeneralInfo?: Maybe<UpdateDoctorGeneralInfo>;
  updateDoctorPaymentInfo?: Maybe<UpdateDoctorPaymentInfo>;
  setDoctorLeaveOfAbsense?: Maybe<SetDoctorLeaveOfAbsense>;
  removeDoctorLeaveOfAbsense?: Maybe<RemoveDoctorLeaveOfAbsense>;
  updateDoctorStatusInfo?: Maybe<UpdateDoctorStatusInfo>;
  updateDoctorServicesInfo?: Maybe<UpdateDoctorServicesInfo>;
  createDoctorOfficeAddress?: Maybe<CreateDoctorOfficeAddress>;
  updateDoctorOfficeAddress?: Maybe<UpdateDoctorOfficeAddress>;
  deleteDoctorOfficeAddress?: Maybe<DeleteDoctorOfficeAddress>;
  createSpecializationFare?: Maybe<CreateSpecializationFare>;
  updateSpecializationFare?: Maybe<UpdateSpecializationFare>;
  createDoctorReminder?: Maybe<CreateDoctorReminder>;
  updateDoctorReminder?: Maybe<UpdateDoctorReminder>;
  scheduleAppointmentAttemptNotification?: Maybe<ScheduleAppointmentAttemptNotification>;
  importDoctorDraftFromAirtable?: Maybe<ImportDoctorDraftFromAirtable>;
  createDoctorAssistant?: Maybe<CreateDoctorAssistant>;
  updateDoctorAssistant?: Maybe<UpdateDoctorAssistant>;
  createMedicalSpecialization?: Maybe<CreateMedicalSpecialization>;
  updateMedicalSpecialization?: Maybe<UpdateMedicalSpecialization>;
  createMedicalSpecializationGroup?: Maybe<CreateMedicalSpecializationGroup>;
  updateDoctorLeadInfo?: Maybe<UpdateDoctorLeadInfo>;
  updateDoctorLeadInfoState?: Maybe<UpdateDoctorLeadInfoState>;
  updateDoctorLeadInfoStateById?: Maybe<UpdateDoctorLeadInfoStateById>;
  sendDoctorContractById?: Maybe<SendDoctorContractById>;
  uploadDoctorLeadDocument?: Maybe<UploadDoctorLeadDocument>;
  createDoctorSpecialistDetailForLead?: Maybe<CreateDoctorSpecialistDetailForLead>;
  updateDoctorSpecialistDetailForLead?: Maybe<UpdateDoctorSpecialistDetailForLead>;
  deleteDoctorSpecialistDetailForLead?: Maybe<DeleteDoctorSpecialistDetailForLead>;
  setDoctorUserGroups?: Maybe<SetDoctorUserGroups>;
  createPharmacyOrder?: Maybe<CreatePharmacyOrder>;
  updatePharmacyOrder?: Maybe<UpdatePharmacyOrder>;
  cancelPharmacyOrder?: Maybe<CancelPharmacyOrder>;
  confirmCoinsuredPharmacyOrder?: Maybe<ConfirmCoinsuredPharmacyOrder>;
  uploadPharmacyInvoiceDocument?: Maybe<UploadPharmacyInvoiceDocument>;
  sendPharmacyOrderEmail?: Maybe<SendPharmacyOrderEmail>;
  createGroup?: Maybe<CreateGroup>;
  updateGroup?: Maybe<UpdateGroup>;
  deleteGroup?: Maybe<DeleteGroup>;
  createPaymentMethod?: Maybe<CreatePaymentMethod>;
  makePaymentMethodPrimary?: Maybe<MakePaymentMethodPrimary>;
  deletePaymentMethod?: Maybe<DeletePaymentMethod>;
  makePayoutPaymentMethodPrimary?: Maybe<MakePayoutPaymentMethodPrimary>;
  makePayinPaymentMethodPrimary?: Maybe<MakePayinPaymentMethodPrimary>;
  createBillingInfo?: Maybe<CreateBillingInfo>;
  updateBillingInfo?: Maybe<UpdateBillingInfo>;
  createPaymentMethodForUser?: Maybe<CreatePaymentMethodForUser>;
  createManualPaymentMethodForUser?: Maybe<CreateManualPaymentMethodForUser>;
  editManualPaymentGatewaySource?: Maybe<EditManualPaymentGatewaySource>;
  addSpeiPaymentMethodForUser?: Maybe<AddSpeiPaymentMethodForUser>;
  createPaymentMethodWithMultipleSources?: Maybe<CreatePaymentMethodWithMultipleSources>;
  requestStripeSetupIntent?: Maybe<RequestStripeSetupIntent>;
  createSetupIntents?: Maybe<CreateSetupIntents>;
  createInvoiceDispute?: Maybe<CreateInvoiceDispute>;
  ruleInvoiceDispute?: Maybe<RuleInvoiceDispute>;
  fetchTransactionCep?: Maybe<FetchTransactionCep>;
  updateUserBillingInfo?: Maybe<UpdateUserBillingInfo>;
  updatePayoutMethodTier?: Maybe<UpdatePayoutMethodTier>;
  createLocation?: Maybe<CreateLocation>;
  updateLocation?: Maybe<UpdateLocation>;
  updateInBatchLocation?: Maybe<UpdateInBatchLocation>;
  retryChargeInvoice?: Maybe<RetryChargeInvoice>;
  generateElectronicVoucherForInvoiceItem?: Maybe<GenerateElectronicVoucherForInvoiceItem>;
  confirmManualInvoicePayment?: Maybe<ConfirmManualInvoicePayment>;
  refundInvoice?: Maybe<RefundInvoice>;
  requestElectronicVoucherDocumentData?: Maybe<RequestElectronicVoucherDocumentData>;
  validateElectronicVoucherXml?: Maybe<ValidateElectronicVoucherXml>;
  validateDraftElectronicVoucherXml?: Maybe<ValidateDraftElectronicVoucherXml>;
  replaceInvoiceDocument?: Maybe<ReplaceInvoiceDocument>;
  uploadElectronicVoucher?: Maybe<UploadElectronicVoucher>;
  uploadDraftElectronicVoucher?: Maybe<UploadDraftElectronicVoucher>;
  payoutInvoice?: Maybe<PayoutInvoice>;
  bundleInvoiceItems?: Maybe<BundleInvoiceItems>;
  payoutInvoiceOffline?: Maybe<PayoutInvoiceOffline>;
  downloadInvoicePayoutCep?: Maybe<DownloadInvoicePayoutCep>;
  updateElectronicVoucher?: Maybe<UpdateElectronicVoucher>;
  retrySignElectronicVoucher?: Maybe<RetrySignElectronicVoucher>;
  autoEmitMissingElectronicVoucher?: Maybe<AutoEmitMissingElectronicVoucher>;
  manualElectronicVoucherCancellation?: Maybe<ManualElectronicVoucherCancellation>;
  cancelScheduledPayout?: Maybe<CancelScheduledPayout>;
  addCommentToInvoice?: Maybe<AddCommentToInvoice>;
  attemptToSignUsersElectronicVouchers?: Maybe<AttemptToSignUsersElectronicVouchers>;
  setCopayInvoiceAsUncollectible?: Maybe<SetCopayInvoiceAsUncollectible>;
  removeInvoiceItemElectronicVoucher?: Maybe<RemoveInvoiceItemElectronicVoucher>;
  updateInvoiceItemsWithElectronicVoucherAmounts?: Maybe<UpdateInvoiceItemsWithElectronicVoucherAmounts>;
  setInvoiceUnpayable?: Maybe<SetInvoiceUnpayable>;
  cancelPayoutInvoice?: Maybe<CancelPayoutInvoice>;
  setPayinInvoiceAsUncollectible?: Maybe<SetPayinInvoiceAsUncollectible>;
  cancelServicePayinInvoice?: Maybe<CancelServicePayinInvoice>;
  updateIncentiveCodeBanner?: Maybe<UpdateIncentiveCodeBanner>;
  editIncentiveCode?: Maybe<EditIncentiveCode>;
  createIncentiveCode?: Maybe<CreateIncentiveCode>;
  createHealthPlanAmendment?: Maybe<CreateHealthPlanAmendment>;
  updateHealthPlanAmendment?: Maybe<UpdateHealthPlanAmendment>;
  createReissueApplication?: Maybe<CreateReissueApplication>;
  cancelHealthPlan?: Maybe<CancelHealthPlan>;
  cancelEmployeeAndFamilyHealthplans?: Maybe<CancelEmployeeAndFamilyHealthplans>;
  updateAntiquityRequest?: Maybe<UpdateAntiquityRequest>;
  submitAntiquityRequest?: Maybe<SubmitAntiquityRequest>;
  cancelAntiquityRequest?: Maybe<CancelAntiquityRequest>;
  getAntiquityRequestDocumentS3UploadData?: Maybe<GetAntiquityRequestDocumentS3UploadData>;
  createAntiquityRequestDocument?: Maybe<CreateAntiquityRequestDocument>;
  addDentalMembershipHealthPlan?: Maybe<AddDentalMembershipHealthPlan>;
  cancelDentalMembershipHealthPlan?: Maybe<CancelDentalMembershipHealthPlan>;
  setConsultType?: Maybe<SetConsultType>;
  setClassificationNotes?: Maybe<SetClassificationNotes>;
  authorizeInPersonConsults?: Maybe<AuthorizeInPersonConsult>;
  createPrescription?: Maybe<CreatePrescription>;
  createOnDemandConsult?: Maybe<CreateOnDemandConsult>;
  createOnDemandConsultNew?: Maybe<CreateOnDemandConsultNew>;
  addReasonOnDemandConsult?: Maybe<AddReasonOnDemandConsult>;
  claimOnDemandConsult?: Maybe<ClaimOnDemandConsult>;
  startOnDemandConsult?: Maybe<StartOnDemandConsult>;
  stopOnDemandConsult?: Maybe<StopOnDemandConsult>;
  cancelOnDemandConsult?: Maybe<CancelOnDemandConsult>;
  closeOnDemandConsult?: Maybe<CloseOnDemandConsult>;
  setReadyAgainOnDemandConsult?: Maybe<SetReadyAgainOnDemandConsult>;
  startCallConsult?: Maybe<StartCallConsult>;
  endCallConsult?: Maybe<EndCallConsult>;
  wrapUpConsult?: Maybe<WrapUpConsult>;
  updateEmr?: Maybe<UpdateEmr>;
  sendTextMessage?: Maybe<SendTextMessage>;
  setMessagesRead?: Maybe<SetMessagesRead>;
  startAttachmentMessage?: Maybe<StartAttachmentMessage>;
  completeAttachmentMessage?: Maybe<CompleteAttachmentMessage>;
  requestMedicinePostOnDemandConsult?: Maybe<RequestMedicinePostOnDemandConsult>;
  updatePrescriptionMemberChecks?: Maybe<UpdatePrescriptionMemberChecks>;
  chargeInPersonConsult?: Maybe<ChargeInPersonConsult>;
  chargeInPersonConsultByProvider?: Maybe<ChargeInPersonConsultByProvider>;
  createCalendarEvent?: Maybe<CreateCalendarEvent>;
  updateCalendarEvent?: Maybe<UpdateCalendarEvent>;
  deleteCalendarEvent?: Maybe<DeleteCalendarEvent>;
  assignUserToConsult?: Maybe<AssignUserToConsult>;
  addCommentToConsult?: Maybe<AddCommentToConsult>;
  createEmptyScheduledConsultRequest?: Maybe<CreateEmptyScheduledConsultRequest>;
  scheduleOutOfNetworkConsult?: Maybe<ScheduleOutOfNetworkConsult>;
  createScheduledConsultRequest?: Maybe<CreateScheduledConsultRequest>;
  createScheduledConsult?: Maybe<CreateScheduledConsult>;
  scheduleConsult?: Maybe<ScheduleConsult>;
  doctorAssistantScheduleConsult?: Maybe<DoctorAssistantScheduleConsult>;
  confirmScheduledConsult?: Maybe<ConfirmScheduledConsult>;
  cancelScheduledConsult?: Maybe<CancelScheduledConsult>;
  doctorAssistantCancelScheduledConsult?: Maybe<DoctorAssistantCancelScheduledConsult>;
  updateScheduledConsultRequest?: Maybe<UpdateScheduledConsultRequest>;
  memberCancelScheduledConsult?: Maybe<MemberCancelScheduledConsult>;
  memberUpdateScheduledConsult?: Maybe<MemberUpdateScheduledConsult>;
  memberUpdateScheduledConsultRequest?: Maybe<MemberUpdateScheduledConsultRequest>;
  reviewComplianceAlert?: Maybe<ReviewComplianceAlert>;
  sendAmlReport?: Maybe<SendAmlReport>;
  doctorCreateDiseaseCase?: Maybe<DoctorCreateDiseaseCase>;
  createDiseaseCase?: Maybe<CreateDiseaseCase>;
  updateDiseaseCase?: Maybe<UpdateDiseaseCase>;
  createService?: Maybe<CreateService>;
  memberCreateService?: Maybe<MemberCreateService>;
  updateService?: Maybe<UpdateService>;
  createPrescriptionService?: Maybe<CreatePrescriptionService>;
  updateServiceDoctor?: Maybe<UpdateServiceDoctor>;
  updateServiceDoctorFriend?: Maybe<UpdateServiceDoctorFriend>;
  deleteService?: Maybe<DeleteService>;
  updateServiceCoverageRuling?: Maybe<UpdateServiceCoverageRuling>;
  updateRejectionMessageForUser?: Maybe<UpdateRejectionMessageForUser>;
  updateServiceCoverageRulingState?: Maybe<UpdateServiceCoverageRulingState>;
  updateClaimRulingCheck?: Maybe<UpdateClaimRulingCheck>;
  acceptAllRulingChecks?: Maybe<AcceptAllRulingChecks>;
  groupServicesOnDiseaseCase?: Maybe<GroupServicesOnDiseaseCase>;
  createEvidence?: Maybe<CreateEvidence>;
  completeShortReimbursementSofiaForm?: Maybe<CompleteShortReimbursementSofiaForm>;
  evidencesReviewed?: Maybe<EvidencesReviewed>;
  requestMoreEvidences?: Maybe<RequestMoreEvidences>;
  createMemberReimbursement?: Maybe<CreateMemberReimbursement>;
  createOutOfNetworkConsultMemberReimbursement?: Maybe<CreateOutOfNetworkConsultMemberReimbursement>;
  createPreapprovedAmount?: Maybe<CreatePreapprovedAmount>;
  updateOrCreateServiceCoveragePayout?: Maybe<UpdateOrCreateServiceCoveragePayout>;
  createServiceCoveragePayoutInvoice?: Maybe<CreateServiceCoveragePayoutInvoice>;
  setAmountsOnServiceCoveragePayout?: Maybe<SetAmountsOnServiceCoveragePayout>;
  approveServiceCoveragePayout?: Maybe<ApproveServiceCoveragePayout>;
  requestServicePayIn?: Maybe<RequestServicePayIn>;
  uploadDataForClaimDocument?: Maybe<UploadDataForClaimDocument>;
  createServiceElectronicVoucherItems?: Maybe<CreateServiceElectronicVoucherItems>;
  setAcceptedElectronicVoucherItems?: Maybe<SetAcceptedElectronicVoucherItems>;
  bundleEventInvoiceItems?: Maybe<BundleEventInvoiceItems>;
  cancelService?: Maybe<CancelService>;
  assignUserToService?: Maybe<AssignUserToService>;
  updateServiceMedicalRecommendationNotes?: Maybe<UpdateServiceMedicalRecommendationNotes>;
  addServiceComment?: Maybe<AddServiceComment>;
  updateServiceEvidenceFlags?: Maybe<UpdateServiceEvicenceFlagsMutation>;
  processServiceCoveragePayout?: Maybe<ProcessServiceCoveragePayout>;
  validateCoveragePayoutAmounts?: Maybe<ValidateCoveragePayoutAmounts>;
  updateServiceCoveragePayout?: Maybe<UpdateServiceCoveragePayout>;
  confirmServicePayInAmounts?: Maybe<ConfirmServicePayInAmounts>;
  bundleServicePayInRequests?: Maybe<BundleServicePayInRequests>;
  uploadDataForPayoutCfdi?: Maybe<UploadDataForPayoutCfdi>;
  validatePayoutCfdi?: Maybe<ValidatePayoutCfdi>;
  markEvidenceAsWrong?: Maybe<MarkEvidenceAsWrong>;
  requestEvidenceCorrection?: Maybe<RequestEvidenceCorrection>;
  acceptEvidence?: Maybe<AcceptEvidence>;
  createMedicalFeesFromMedicalNoteProcedure?: Maybe<CreateMedicalFeesFromMedicalNoteProcedure>;
  updateClaimsEvidenceResponseAttachmentState?: Maybe<UpdateClaimsEvidenceResponseAttachmentState>;
  requestClaimsEvidenceAttachmentUpload?: Maybe<RequestClaimsEvidenceAttachmentUpload>;
  createTypeformEvidence?: Maybe<CreateTypeformEvidence>;
  createServiceAndTypeformEvidence?: Maybe<CreateServiceAndTypeformEvidence>;
  createOutOfNetworkConsultMemberReimbursementForInAppEvidence?: Maybe<CreateOutOfNetworkConsultMemberReimbursementForInAppEvidence>;
  bundleServiceCoveragePayouts?: Maybe<BundleServiceCoveragePayouts>;
  businessToken?: Maybe<BusinessToken>;
  createBusinessDraft?: Maybe<CreateBusinessDraft>;
  updateBusinessInformation?: Maybe<UpdateBusinessInformation>;
  updateBusinessGlobalConfiguration?: Maybe<UpdateBusinessGlobalConfiguration>;
  updateBusinessIncentiveCode?: Maybe<UpdateBusinessIncentiveCode>;
  updateBusiness?: Maybe<UpdateBusiness>;
  updateOrCreateBillingInfo?: Maybe<UpdateOrCreateBillingInfo>;
  updateBusinessReadyToMigrateDentalMembership?: Maybe<UpdateBusinessReadyToMigrateDentalMembership>;
  updateBusinessPaymentInformation?: Maybe<UpdateBusinessPaymentInformation>;
  updateOrCreateBusinessPayoutClabe?: Maybe<UpdateOrCreateBusinessPayoutClabe>;
  updateOrCreateBusinessOwner?: Maybe<UpdateOrCreateBusinessOwner>;
  retriggerBusinessChecks?: Maybe<RetriggerBusinessChecks>;
  requestBusinessDocumentUploadData?: Maybe<RequestBusinessDocumentUploadData>;
  getBusinessS3UploadData?: Maybe<GetBusinessS3UploadData>;
  createBusinessDocument?: Maybe<CreateBusinessDocument>;
  approveBusinessDocumentCheck?: Maybe<ApproveBusinessDocumentCheck>;
  toggleJustifiableAbsenceForBusinessDocumentCheck?: Maybe<ToggleJustifiableAbsenceForBusinessDocumentCheck>;
  setAbsenceJustificationForBusinessDocumentCheck?: Maybe<SetAbsenceJustificationForBusinessDocumentCheck>;
  transitionBusinessState?: Maybe<TransitionBusinessState>;
  handleCorrectingInfoBusinessStateTransitions?: Maybe<HandleCorrectingInfoBusinessStateTransitions>;
  handleDocumentsReviewCompleteBusinessStateTransition?: Maybe<HandleDocumentsReviewCompleteBusinessStateTransition>;
  handleContractSentBusinessStateTransition?: Maybe<HandleContractSentBusinessStateTransition>;
  handleContractSignedBusinessStateTransition?: Maybe<HandleContractSignedBusinessStateTransition>;
  handleApprovedBusinessStateTransition?: Maybe<HandleApprovedBusinessStateTransition>;
  handleRevokedBusinessStateTransition?: Maybe<HandleRevokedBusinessStateTransition>;
  contractUpdateStateDate?: Maybe<ContractUpdateStateDate>;
  createBusinessStakeholder?: Maybe<CreateBusinessStakeholder>;
  updateBusinessStakeholder?: Maybe<UpdateBusinessStakeholder>;
  updateLegalRepresentative?: Maybe<UpdateLegalRepresentative>;
  retriggerBusinessStakeholderChecks?: Maybe<RetriggerBusinessStakeholderChecks>;
  requestBusinessStakeholderDocumentUploadData?: Maybe<RequestBusinessStakeholderDocumentUploadData>;
  getBusinessStakeholderS3UploadData?: Maybe<GetBusinessStakeholderS3UploadData>;
  createBusinessStakeholderDocument?: Maybe<CreateBusinessStakeholderDocument>;
  createBusinessEmployee?: Maybe<CreateBusinessEmployee>;
  createApplicationsForBusinessBeneficiary?: Maybe<CreateApplicationsForBusinessBeneficiary>;
  createBusinessBeneficiary?: Maybe<CreateBusinessBeneficiary>;
  createBusinessEmployees?: Maybe<CreateBusinessEmployees>;
  createCollectiveBusinessBeneficiaries?: Maybe<CreateCollectiveBusinessBeneficiaries>;
  createCollectiveBusinessBeneficiaryRelatives?: Maybe<CreateCollectiveBusinessBeneficiaryRelatives>;
  sendBeneficiariesDataToOps?: Maybe<SendBeneficiariesDataToOps>;
  editBusinessEmployee?: Maybe<EditBusinessEmployee>;
  deleteBusinessEmployee?: Maybe<DeleteBusinessEmployee>;
  sendBusinessEmployeeWelcomeEmail?: Maybe<SendBusinessEmployeeWelcomeEmail>;
  sendBusinessAdminWelcomeEmail?: Maybe<SendBusinessAdminWelcomeEmail>;
  createBusinessPaymentMethod?: Maybe<CreateBusinessPaymentMethod>;
  makeBusinessPayinPaymentMethodPrimary?: Maybe<MakeBusinessPayinPaymentMethodPrimary>;
  deleteBusinessPaymentMethod?: Maybe<DeleteBusinessPaymentMethod>;
  businessEarlyCutoffProcess?: Maybe<BusinessEarlyCutoffProcess>;
  createBusinessAdmin?: Maybe<CreateBusinessAdmin>;
  updateBusinessAdmin?: Maybe<UpdateBusinessAdmin>;
  deleteBusinessAdmin?: Maybe<DeleteBusinessAdmin>;
  makeBusinessAdminOwner?: Maybe<MakeBusinessAdminOwner>;
  createBusinessGroupsConfig?: Maybe<CreateBusinessGroupsConfig>;
  updateBusinessGroupsConfig?: Maybe<UpdateBusinessGroupsConfig>;
  asyncExecuteChangesForActiveBusinessGroupsConfig?: Maybe<AsyncExecuteChangesForActiveBusinessGroupsConfig>;
  executeChangesForActiveBusinessGroupsConfig?: Maybe<ExecuteChangesForActiveBusinessGroupsConfig>;
  sendBusinessNotRegisteredBeneficiaryReminderEmail?: Maybe<SendBusinessNotRegisteredBeneficiaryReminderEmail>;
  updateBusinessPlansGeneralConfigProposal?: Maybe<UpdateBusinessPlansGeneralConfigProposal>;
  acceptBusinessPlansGeneralConfigProposal?: Maybe<AcceptBusinessPlansGeneralConfigProposal>;
  updateBusinessPlansGroupConfigProposal?: Maybe<UpdateBusinessPlansGroupConfigProposal>;
  updateBusinessBeneficiariesProposalsGroup?: Maybe<UpdateBusinessBeneficiariesProposalsGroup>;
  updateDefaultBusinessPlansGroupConfigProposal?: Maybe<UpdateDefaultBusinessPlansGroupConfigProposal>;
  generateConciliationFile?: Maybe<GenerateConciliationFile>;
  cancelBusinessCollectiveHealthPlan?: Maybe<CancelBusinessCollectiveHealthPlan>;
  confirmBusinessConfigurations?: Maybe<ConfirmBusinessConfigurations>;
  reactivateBusiness?: Maybe<ReactivateBusiness>;
  createCollectiveBusinessRelative?: Maybe<CreateCollectiveBusinessRelative>;
  updateCollectiveBusinessRelative?: Maybe<UpdateCollectiveBusinessRelative>;
  createBundleBusinessRelative?: Maybe<CreateBundleBusinessRelative>;
  updateBundleBusinessRelative?: Maybe<UpdateBundleBusinessRelative>;
  asyncExecuteMaternityAddonChangesForActiveBusiness?: Maybe<AsyncExecuteMaternityAddonChangesForActiveBusiness>;
  executeMaternityAddonChangesForActiveBusiness?: Maybe<ExecuteMaternityAddonChangesForActiveBusiness>;
  cancelActiveHealthPlansForReissue?: Maybe<CancelActiveHealthPlansForReissue>;
  createProposalToChangeBusinessSubscriptionType?: Maybe<CreateProposalToChangeBusinessSubscriptionType>;
  activateConfigurationProposal?: Maybe<ActivateConfigurationProposal>;
  createHealthPlanApplicationsFromProposalAndSubmitThem?: Maybe<CreateHealthPlanApplicationsFromProposalAndSubmitThem>;
  moveBusinessBeneficiaryToBundle?: Maybe<MoveBusinessBeneficiaryToBundle>;
  updateBusinessBillingDay?: Maybe<UpdateBusinessBillingDay>;
  deactivateBusinessGroupConfig?: Maybe<DeactivateBusinessGroupConfig>;
  migrateYearlyBusinessToDentalMembership?: Maybe<MigrateYearlyBusinessToDentalMembership>;
  acceptBusinessProposal?: Maybe<AcceptBusinessProposal>;
  createBusinessMaternityReissueProposal?: Maybe<CreateBusinessMaternityReissueProposal>;
  createBusinessGroupConfigReissueProposal?: Maybe<CreateBusinessGroupConfigReissueProposal>;
  cancelBusinessReissueProposalBeneficiaries?: Maybe<CancelBusinessReissueProposalBeneficiaries>;
  applyBusinessMaternityReissueProposalChanges?: Maybe<ApplyBusinessMaternityReissueProposalChanges>;
  applyBusinessGroupConfigReissueProposalChanges?: Maybe<ApplyBusinessGroupConfigReissueProposalChanges>;
  createBusinessReissueProposalApplication?: Maybe<CreateBusinessReissueProposalApplication>;
  rejectBusinessReissueProposal?: Maybe<RejectBusinessReissueProposal>;
  approveBusinessProposalApplicationItems?: Maybe<ApproveBusinessProposalApplicationItems>;
  createBusinessBeneficiariesReportDocument?: Maybe<CreateBusinessBeneficiariesReportDocument>;
  verifyToken?: Maybe<Verify>;
  revokeToken?: Maybe<Revoke>;
};


export type MutationCreateDoctorLeadArgs = {
  category?: InputMaybe<DoctorDocuments>;
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  mimeType?: InputMaybe<Scalars['String']>;
  otherMedicalSpecialization?: InputMaybe<Scalars['String']>;
  otherMedicalSubspecialization?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  specializationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  waTelephone: Scalars['String'];
  zipcode?: InputMaybe<Scalars['String']>;
};


export type MutationRequestDoctorLeadCvArgs = {
  doctorLeadId: Scalars['ID'];
};


export type MutationRequestDoctorLeadUserCreationArgs = {
  doctorLeadId: Scalars['ID'];
};


export type MutationUpdateDoctorLeadArgs = {
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isDuplicate?: InputMaybe<Scalars['Boolean']>;
  lostReason?: InputMaybe<DoctorLeadLostReason>;
  secondLastName?: InputMaybe<Scalars['String']>;
  specializationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  state?: InputMaybe<DoctorLeadState>;
  waTelephone?: InputMaybe<Scalars['String']>;
};


export type MutationCreateUserFromLeadArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  telephone: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUploadPublicDoctorLeadDocumentArgs = {
  category: DoctorDocuments;
  doctorLeadToken: Scalars['String'];
  mimeType: Scalars['String'];
};


export type MutationConsumeAuthTokenAndPullContactDataFromWebQuotationArgs = {
  capability: AuthorizationCodeCapability;
  email: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCreateMedicalHistoryForMemberArgs = {
  allergies?: InputMaybe<Array<InputMaybe<AllergyInputType>>>;
  allergiesNotes?: InputMaybe<Scalars['String']>;
  allergiesState?: InputMaybe<MedicalHistorySectionState>;
  familyPathologies?: InputMaybe<Array<InputMaybe<FamilyPathologyInputType>>>;
  familyPathologiesNotes?: InputMaybe<Scalars['String']>;
  familyPathologiesState?: InputMaybe<MedicalHistorySectionState>;
  hospitalizations?: InputMaybe<Array<InputMaybe<HospitalizationInputType>>>;
  hospitalizationsNotes?: InputMaybe<Scalars['String']>;
  hospitalizationsState?: InputMaybe<MedicalHistorySectionState>;
  malformations?: InputMaybe<Array<InputMaybe<MalformationInputType>>>;
  malformationsNotes?: InputMaybe<Scalars['String']>;
  malformationsState?: InputMaybe<MedicalHistorySectionState>;
  medicines?: InputMaybe<Array<InputMaybe<MedicineInputType>>>;
  medicinesNotes?: InputMaybe<Scalars['String']>;
  memberId: Scalars['ID'];
  mentalHealthDiagnoses?: InputMaybe<Array<InputMaybe<MentalHealthDiagnosisInputType>>>;
  mentalHealthPsychiatryNotes?: InputMaybe<Scalars['String']>;
  mentalHealthPsychiatryState?: InputMaybe<MedicalHistorySectionState>;
  mentalHealthPsychologyNotes?: InputMaybe<Scalars['String']>;
  mentalHealthPsychologyState?: InputMaybe<MedicalHistorySectionState>;
  nonPathologicalHistory?: InputMaybe<NonPathologicalHistoryInputType>;
  obstetricGynecologicalHistory?: InputMaybe<ObstetricGynecologicalHistoryInputType>;
  parentPerinatalHistory?: InputMaybe<ParentPerinatalHistoryInputType>;
  pathologies?: InputMaybe<Array<InputMaybe<PathologyInputType>>>;
  pathologiesNotes?: InputMaybe<Scalars['String']>;
  pathologiesState?: InputMaybe<MedicalHistorySectionState>;
  perinatalHistory?: InputMaybe<PerinatalHistoryInputType>;
  surgeries?: InputMaybe<Array<InputMaybe<SurgeriesInputType>>>;
  surgeriesNotes?: InputMaybe<Scalars['String']>;
  surgeriesState?: InputMaybe<MedicalHistorySectionState>;
  vaccines?: InputMaybe<Array<InputMaybe<VaccineInputType>>>;
  vaccinesNotes?: InputMaybe<Scalars['String']>;
  vaccinesState?: InputMaybe<MedicalHistorySectionState>;
};


export type MutationUpdateMedicalHistoryForMemberArgs = {
  allergiesNotes?: InputMaybe<Scalars['String']>;
  allergiesState?: InputMaybe<MedicalHistorySectionState>;
  familyPathologiesNotes?: InputMaybe<Scalars['String']>;
  familyPathologiesState?: InputMaybe<MedicalHistorySectionState>;
  hasTherapy?: InputMaybe<Scalars['Boolean']>;
  hospitalizationsNotes?: InputMaybe<Scalars['String']>;
  hospitalizationsState?: InputMaybe<MedicalHistorySectionState>;
  id: Scalars['ID'];
  malformationsNotes?: InputMaybe<Scalars['String']>;
  malformationsState?: InputMaybe<MedicalHistorySectionState>;
  medicinesNotes?: InputMaybe<Scalars['String']>;
  mentalHealthPsychiatryNotes?: InputMaybe<Scalars['String']>;
  mentalHealthPsychiatryState?: InputMaybe<MedicalHistorySectionState>;
  mentalHealthPsychologyNotes?: InputMaybe<Scalars['String']>;
  mentalHealthPsychologyState?: InputMaybe<MedicalHistorySectionState>;
  pathologiesNotes?: InputMaybe<Scalars['String']>;
  pathologiesState?: InputMaybe<MedicalHistorySectionState>;
  surgeriesNotes?: InputMaybe<Scalars['String']>;
  surgeriesState?: InputMaybe<MedicalHistorySectionState>;
  therapyNotes?: InputMaybe<Scalars['String']>;
  vaccinesNotes?: InputMaybe<Scalars['String']>;
  vaccinesState?: InputMaybe<MedicalHistorySectionState>;
};


export type MutationAddMedicalHistoryAllergyArgs = {
  allergen: Scalars['String'];
  medicalHistoryId: Scalars['ID'];
  reaction?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Severity>;
};


export type MutationUpdateMedicalHistoryAllergyArgs = {
  allergen?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  reaction?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Severity>;
};


export type MutationDeleteMedicalHistoryAllergyArgs = {
  id: Scalars['ID'];
};


export type MutationUploadMedicalHistoryExamDocumentArgs = {
  fileName: Scalars['String'];
  medicalHistoryId: Scalars['ID'];
  mimeType: Scalars['String'];
};


export type MutationUploadExamDocumentToMedicalNoteArgs = {
  fileName: Scalars['String'];
  medicalNoteId: Scalars['ID'];
  mimeType: Scalars['String'];
};


export type MutationAssignMedicalHistoryExamToMedicalNoteArgs = {
  id: Scalars['ID'];
  medicalNoteId: Scalars['ID'];
};


export type MutationDeleteMedicalHistoryExamArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateMedicalHistoryExamArgs = {
  id: Scalars['ID'];
  notes: Scalars['String'];
};


export type MutationDoctorDeleteMedicalNoteExamArgs = {
  id: Scalars['ID'];
};


export type MutationAddFamilyPathologyArgs = {
  cieNotes?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  medicalHistoryId: Scalars['ID'];
  pathology: Scalars['String'];
  relatives: Array<InputMaybe<Relative>>;
};


export type MutationUpdateFamilyPathologyArgs = {
  cieNotes?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  pathology?: InputMaybe<Scalars['String']>;
  relatives?: InputMaybe<Array<InputMaybe<Relative>>>;
};


export type MutationDeleteFamilyPathologyArgs = {
  id: Scalars['ID'];
};


export type MutationAddHospitalizationArgs = {
  date: Scalars['Date'];
  medicalHistoryId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateHospitalizationArgs = {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteHospitalizationArgs = {
  id: Scalars['ID'];
};


export type MutationAddMalformationArgs = {
  medicalHistoryId: Scalars['ID'];
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  treatment?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMalformationArgs = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  treatment?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteMalformationArgs = {
  id: Scalars['ID'];
};


export type MutationAddMedicalHistoryMedicineArgs = {
  ailment: Scalars['String'];
  drugKey: Scalars['String'];
  medicalHistoryId: Scalars['ID'];
};


export type MutationUpdateMedicalHistoryMedicineArgs = {
  ailment?: InputMaybe<Scalars['String']>;
  drugKey?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationDeleteMedicalHistoryMedicineArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateNonPathologicalHistoryArgs = {
  alcoholConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  alcoholConsumptionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  alcoholFrequency?: InputMaybe<Scalars['String']>;
  alcoholNotes?: InputMaybe<Scalars['String']>;
  alcoholSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  drugsConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  drugsName?: InputMaybe<Scalars['String']>;
  drugsNotes?: InputMaybe<Scalars['String']>;
  drugsSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  drugsType?: InputMaybe<Drug>;
  exerciseActivities?: InputMaybe<Scalars['String']>;
  exerciseNotes?: InputMaybe<Scalars['String']>;
  exerciseWeeklyFrequency?: InputMaybe<Scalars['Int']>;
  hasExercise?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lifestyleNotes?: InputMaybe<Scalars['String']>;
  nutritionNotes?: InputMaybe<Scalars['String']>;
  sleepHours?: InputMaybe<Scalars['Int']>;
  sleepNotes?: InputMaybe<Scalars['String']>;
  smokingCigarettes?: InputMaybe<Scalars['Int']>;
  smokingConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  smokingNotes?: InputMaybe<Scalars['String']>;
  smokingSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  smokingYears?: InputMaybe<Scalars['Int']>;
};


export type MutationMemberUpdateNonPathologicalHistoryArgs = {
  alcoholConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  alcoholConsumptionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  alcoholFrequency?: InputMaybe<Scalars['String']>;
  alcoholNotes?: InputMaybe<Scalars['String']>;
  alcoholSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  drugsConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  drugsName?: InputMaybe<Scalars['String']>;
  drugsNotes?: InputMaybe<Scalars['String']>;
  drugsSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  drugsType?: InputMaybe<Drug>;
  exerciseActivities?: InputMaybe<Scalars['String']>;
  exerciseNotes?: InputMaybe<Scalars['String']>;
  exerciseWeeklyFrequency?: InputMaybe<Scalars['Int']>;
  hasExercise?: InputMaybe<Scalars['Boolean']>;
  lifestyleNotes?: InputMaybe<Scalars['String']>;
  memberId: Scalars['ID'];
  nutritionNotes?: InputMaybe<Scalars['String']>;
  sleepHours?: InputMaybe<Scalars['Int']>;
  sleepNotes?: InputMaybe<Scalars['String']>;
  smokingCigarettes?: InputMaybe<Scalars['Int']>;
  smokingConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  smokingNotes?: InputMaybe<Scalars['String']>;
  smokingSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  smokingYears?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateObstetricGynecologicalHistoryArgs = {
  birthControlName?: InputMaybe<BirthControlName>;
  birthControlNotes?: InputMaybe<Scalars['String']>;
  birthControlTimeUsed?: InputMaybe<Scalars['String']>;
  gynecologicalExamsNotes?: InputMaybe<Scalars['String']>;
  hasBeenPregnant?: InputMaybe<Scalars['Boolean']>;
  hasBirthControl?: InputMaybe<Scalars['Boolean']>;
  hasDysmenorrhea?: InputMaybe<Scalars['Boolean']>;
  hasMenstrualCycle?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isMenstrualCycleRegular?: InputMaybe<Scalars['Boolean']>;
  menstrualCycleNotes?: InputMaybe<Scalars['String']>;
  numAbortions?: InputMaybe<Scalars['Int']>;
  numCesareans?: InputMaybe<Scalars['Int']>;
  numDeaths?: InputMaybe<Scalars['Int']>;
  numDeliveries?: InputMaybe<Scalars['Int']>;
  numLiveBirths?: InputMaybe<Scalars['Int']>;
  numPregnancies?: InputMaybe<Scalars['Int']>;
  numStillBirths?: InputMaybe<Scalars['Int']>;
};


export type MutationAddPregnancyDeliveryArgs = {
  isCesarean: Scalars['Boolean'];
  pregnancyId: Scalars['ID'];
  sexAssignedAtBirth: PregnancySex;
  status: PregnancyStatus;
  weight: Scalars['Float'];
};


export type MutationUpdatePregnancyDeliveryArgs = {
  id: Scalars['ID'];
  isCesarean: Scalars['Boolean'];
  sexAssignedAtBirth: PregnancySex;
  status: PregnancyStatus;
  weight: Scalars['Float'];
};


export type MutationDeletePregnancyDeliveryArgs = {
  id: Scalars['ID'];
};


export type MutationAddGynecologicalExamArgs = {
  date: Scalars['Date'];
  isNormal: Scalars['Boolean'];
  notes?: InputMaybe<Scalars['String']>;
  obstetricGynecologicalHistoryId: Scalars['ID'];
  type: GynecologicalExamClassification;
};


export type MutationUpdateGynecologicalExamArgs = {
  date: Scalars['Date'];
  id: Scalars['ID'];
  isNormal: Scalars['Boolean'];
  notes?: InputMaybe<Scalars['String']>;
  type: GynecologicalExamClassification;
};


export type MutationDeleteGynecologicalExamArgs = {
  id: Scalars['ID'];
};


export type MutationAddPathologyArgs = {
  cieKey?: InputMaybe<Scalars['String']>;
  cieNotes?: InputMaybe<Scalars['String']>;
  diagnosisDate: Scalars['Date'];
  isActive: Scalars['Boolean'];
  medicalHistoryId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  treatment?: InputMaybe<Scalars['String']>;
};


export type MutationUpdatePathologyArgs = {
  cieKey?: InputMaybe<Scalars['String']>;
  cieNotes?: InputMaybe<Scalars['String']>;
  diagnosisDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  treatment?: InputMaybe<Scalars['String']>;
};


export type MutationDeletePathologyArgs = {
  id: Scalars['ID'];
};


export type MutationAddPregnancyArgs = {
  date: Scalars['Date'];
  gestationAge?: InputMaybe<PregnancyGestationAge>;
  laborHours?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  obstetricGynecologicalHistoryId: Scalars['ID'];
};


export type MutationUpdatePregnancyArgs = {
  date?: InputMaybe<Scalars['Date']>;
  gestationAge?: InputMaybe<PregnancyGestationAge>;
  id: Scalars['ID'];
  laborHours?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationDeletePregnancyArgs = {
  id: Scalars['ID'];
};


export type MutationAddSurgeryArgs = {
  cptKey?: InputMaybe<Scalars['String']>;
  cptNotes?: InputMaybe<Scalars['String']>;
  date: Scalars['Date'];
  medicalHistoryId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateSurgeryArgs = {
  cptKey?: InputMaybe<Scalars['String']>;
  cptNotes?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteSurgeryArgs = {
  id: Scalars['ID'];
};


export type MutationAddVaccineArgs = {
  date: Scalars['Date'];
  medicalHistoryId: Scalars['ID'];
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateVaccineArgs = {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteVaccineArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateMedicalHistoryBasicInfoArgs = {
  bloodType?: InputMaybe<BloodType>;
  date?: InputMaybe<Scalars['Date']>;
  height?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  weight?: InputMaybe<Scalars['Float']>;
};


export type MutationAddMentalHealthDiagnosisArgs = {
  diagnosis: Scalars['String'];
  duration?: InputMaybe<Scalars['String']>;
  medicalHistoryId: Scalars['ID'];
  mentalHealthDiagnosisClassification: MentalHealthDiagnosisClassification;
  notes?: InputMaybe<Scalars['String']>;
  treatment: Scalars['String'];
};


export type MutationUpdateMentalHealthDiagnosisArgs = {
  diagnosis: Scalars['String'];
  duration?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  mentalHealthDiagnosisClassification: MentalHealthDiagnosisClassification;
  notes?: InputMaybe<Scalars['String']>;
  treatment: Scalars['String'];
};


export type MutationDeleteMentalHealthDiagnosisArgs = {
  id: Scalars['ID'];
};


export type MutationUpdatePerinatalHistoryArgs = {
  apgar1MinScore?: InputMaybe<Scalars['Int']>;
  apgar5MinScore?: InputMaybe<Scalars['Int']>;
  cardiologyScreeningNotes?: InputMaybe<Scalars['String']>;
  complicationsNotes?: InputMaybe<Scalars['String']>;
  developmentAlteration?: InputMaybe<Scalars['Boolean']>;
  developmentAlterationNotes?: InputMaybe<Scalars['String']>;
  gestationalAgeClassification?: InputMaybe<GestationalAgeClassification>;
  gestationalAgeDays?: InputMaybe<Scalars['Int']>;
  gestationalAgeWeeks?: InputMaybe<Scalars['Int']>;
  gestationalWeightClassification?: InputMaybe<GestationalWeightClassification>;
  hadComplications?: InputMaybe<Scalars['Boolean']>;
  headCircumference?: InputMaybe<Scalars['Float']>;
  hearingScreeningNotes?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  hipScreeningNotes?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  incubationRequired?: InputMaybe<Scalars['Boolean']>;
  isPresent?: InputMaybe<MedicalHistorySectionState>;
  metabolicScreeningNotes?: InputMaybe<Scalars['String']>;
  normalCardiologyScreening?: InputMaybe<Scalars['Boolean']>;
  normalHearingScreening?: InputMaybe<Scalars['Boolean']>;
  normalHipScreening?: InputMaybe<Scalars['Boolean']>;
  normalMetabolicScreening?: InputMaybe<Scalars['Boolean']>;
  normalVisualScreening?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  reanimationNotes?: InputMaybe<Scalars['String']>;
  reanimationRequired?: InputMaybe<Scalars['Boolean']>;
  visualScreeningNotes?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
};


export type MutationUpdateParentPerinatalHistoryArgs = {
  deliveryType?: InputMaybe<DeliveryType>;
  expectedHeight?: InputMaybe<Scalars['Int']>;
  fatherHeight?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isPresent?: InputMaybe<MedicalHistorySectionState>;
  motherAbortions?: InputMaybe<Scalars['Int']>;
  motherCaesareanSections?: InputMaybe<Scalars['Int']>;
  motherDeliveries?: InputMaybe<Scalars['Int']>;
  motherHeight?: InputMaybe<Scalars['Float']>;
  motherPregnancies?: InputMaybe<Scalars['Int']>;
  motherPregnancyAge?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  obstetrician?: InputMaybe<Scalars['String']>;
  pregnancyComplications?: InputMaybe<Scalars['Boolean']>;
  pregnancyComplicationsNotes?: InputMaybe<Scalars['String']>;
  pregnancyEvolution?: InputMaybe<PregnancyEvolutionType>;
};


export type MutationAddGeneralObservationArgs = {
  medicalHistoryId: Scalars['ID'];
  notes: Scalars['String'];
};


export type MutationUpdateGeneralObservationArgs = {
  id: Scalars['ID'];
  notes: Scalars['String'];
};


export type MutationDeleteGeneralObservationArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateServiceMedicalNoteArgs = {
  noteId: Scalars['ID'];
  serviceId: Scalars['ID'];
};


export type MutationCreateNewMedicalNoteArgs = {
  memberId: Scalars['ID'];
  motive?: InputMaybe<Scalars['String']>;
  noteType: NoteType;
  occurrenceTime?: InputMaybe<Scalars['DateTime']>;
  shouldBeFreeOfCharge?: InputMaybe<Scalars['Boolean']>;
  suffering?: InputMaybe<Scalars['String']>;
};


export type MutationCreateConsultMedicalNoteArgs = {
  consultId: Scalars['ID'];
  motive?: InputMaybe<Scalars['String']>;
  noteType: NoteType;
  occurrenceTime?: InputMaybe<Scalars['DateTime']>;
  shouldBeFreeOfCharge?: InputMaybe<Scalars['Boolean']>;
  suffering?: InputMaybe<Scalars['String']>;
};


export type MutationCreateScheduledConsultMedicalNoteArgs = {
  motive?: InputMaybe<Scalars['String']>;
  occurrenceTime?: InputMaybe<Scalars['DateTime']>;
  scheduledConsultId: Scalars['ID'];
  shouldBeFreeOfCharge?: InputMaybe<Scalars['Boolean']>;
  suffering?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMedicalNoteArgs = {
  chatEnabledDays?: InputMaybe<Scalars['Int']>;
  chatEnabledIndefinitely?: InputMaybe<Scalars['Boolean']>;
  diseaseCaseId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  motive?: InputMaybe<Scalars['String']>;
  occurrenceTime?: InputMaybe<Scalars['DateTime']>;
  shouldBeFreeOfCharge?: InputMaybe<Scalars['Boolean']>;
  suffering?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMedicalNoteIsAuditedArgs = {
  id: Scalars['ID'];
  isAudited: Scalars['Boolean'];
};


export type MutationDeleteMedicalNoteArgs = {
  id: Scalars['ID'];
};


export type MutationCancelMedicalNoteArgs = {
  cancellationReason?: InputMaybe<ConsultCancellationReason>;
  cancellationReasonDetails?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationCompleteMedicalNoteArgs = {
  diagnosis: Scalars['String'];
  hasAntibiotics?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  prescriptionItems?: InputMaybe<Array<PrescriptionItemInputType>>;
  recommendations?: InputMaybe<Scalars['String']>;
};


export type MutationAddPrescriptionToMedicalNoteArgs = {
  diagnosis: Scalars['String'];
  hasAntibiotics?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  prescriptionItems?: InputMaybe<Array<PrescriptionItemInputType>>;
  recommendations?: InputMaybe<Scalars['String']>;
};


export type MutationReclassifyMedicalNoteArgs = {
  id: Scalars['ID'];
  noteType: NoteType;
};


export type MutationUpdateEvaluationArgs = {
  cieKeys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  diagnosticImpression?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationAddInterrogationArgs = {
  medicalNoteId: Scalars['ID'];
  notes: Scalars['String'];
  system: InterrogationSystem;
  systemName?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateInterrogationArgs = {
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  system?: InputMaybe<InterrogationSystem>;
  systemName?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteInterrogationArgs = {
  id: Scalars['ID'];
};


export type MutationAddExplorationArgs = {
  medicalNoteId: Scalars['ID'];
  notes: Scalars['String'];
  segment: ExplorationSegment;
  segmentName?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateExplorationArgs = {
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  segment?: InputMaybe<ExplorationSegment>;
  segmentName?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteExplorationArgs = {
  id: Scalars['ID'];
};


export type MutationAddProcedureArgs = {
  description?: InputMaybe<Scalars['String']>;
  medicalNoteId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  pathologySendingRequired?: InputMaybe<Scalars['Boolean']>;
  procedure: ProcedureName;
  scheduled: Scalars['Boolean'];
};


export type MutationUpdateProcedureArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  pathologySendingRequired?: InputMaybe<Scalars['Boolean']>;
  procedure?: InputMaybe<ProcedureName>;
};


export type MutationUploadProcedureDocumentArgs = {
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
  procedureId: Scalars['ID'];
};


export type MutationDeleteProcedureArgs = {
  id: Scalars['ID'];
};


export type MutationAddAddendumArgs = {
  medicalNoteId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAddendumArgs = {
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationUploadAddendumDocumentArgs = {
  addendumId: Scalars['ID'];
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
};


export type MutationDeleteAddendumArgs = {
  id: Scalars['ID'];
};


export type MutationUpdatePhysicalExamArgs = {
  bodyFat?: InputMaybe<Scalars['Float']>;
  breathingFrequency?: InputMaybe<Scalars['Int']>;
  diastolicBloodPressure?: InputMaybe<Scalars['Int']>;
  heartRate?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  muscleMass?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  oxygenation?: InputMaybe<Scalars['Int']>;
  systolicBloodPressure?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Float']>;
};


export type MutationUpdateNoteBasicInfoArgs = {
  bloodType?: InputMaybe<BloodType>;
  cephalicPerimeter?: InputMaybe<Scalars['Float']>;
  height?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  percentile?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Float']>;
  zScore?: InputMaybe<Scalars['Float']>;
};


export type MutationUpdateDischargeInfoArgs = {
  dischargeDate?: InputMaybe<Scalars['Date']>;
  dischargeReason?: InputMaybe<DischargeReason>;
  entryDate?: InputMaybe<Scalars['Date']>;
  evolution?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  staySummary?: InputMaybe<Scalars['String']>;
  visitsAmount?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdatePhysicalActivityArgs = {
  exerciseActivities?: InputMaybe<Scalars['String']>;
  exerciseNotes?: InputMaybe<Scalars['String']>;
  exerciseWeeklyFrequency?: InputMaybe<Scalars['Int']>;
  hasExercise?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type MutationUpdateObstetricGynecologicalInfoArgs = {
  hasBeenPregnant?: InputMaybe<Scalars['Boolean']>;
  hasBirthControl?: InputMaybe<Scalars['Boolean']>;
  hasGynecologicalExams?: InputMaybe<Scalars['Boolean']>;
  hasMenstrualCycle?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type MutationEndMedicalProgramArgs = {
  endReason: MedicalProgramEndReason;
  endReasonDetails?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationCreateMaternityProgramArgs = {
  designatedDoctorId?: InputMaybe<Scalars['ID']>;
  lastMenstruationDate: Scalars['Date'];
  medicalHistoryId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type MutationUpdateMaternityProgramArgs = {
  designatedDoctorId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  lastMenstruationDate?: InputMaybe<Scalars['Date']>;
};


export type MutationCreateBariatricsProgramArgs = {
  hasPreviousSurgery: Scalars['Boolean'];
  medicalHistoryId: Scalars['ID'];
  relatedDiseases?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startingBmi: Scalars['Float'];
};


export type MutationUpdateBariatricsProgramArgs = {
  hasPreviousSurgery?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  relatedDiseases?: InputMaybe<Scalars['String']>;
  startingBmi?: InputMaybe<Scalars['Float']>;
};


export type MutationCreateProcedureCategoryArgs = {
  cptKey?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  evidenceNeededToPerform?: InputMaybe<Scalars['Boolean']>;
  evidenceNeededToSchedule?: InputMaybe<Scalars['Boolean']>;
  evidenceToPerformDescription?: InputMaybe<Scalars['String']>;
  evidenceToScheduleDescription?: InputMaybe<Scalars['String']>;
  isScheduled?: InputMaybe<Scalars['Boolean']>;
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  type?: InputMaybe<ProcedureCategoryClassification>;
};


export type MutationUpdateProcedureCategoryArgs = {
  cptKey?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  evidenceNeededToPerform?: InputMaybe<Scalars['Boolean']>;
  evidenceNeededToSchedule?: InputMaybe<Scalars['Boolean']>;
  evidenceToPerformDescription?: InputMaybe<Scalars['String']>;
  evidenceToScheduleDescription?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isScheduled?: InputMaybe<Scalars['Boolean']>;
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<ProcedureCategoryClassification>;
};


export type MutationAddPerformedProcedureArgs = {
  caseEventId?: InputMaybe<Scalars['ID']>;
  medicalNoteId: Scalars['ID'];
  medicalReason?: InputMaybe<Scalars['String']>;
  procedureCategoryId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdatePerformedProcedureArgs = {
  id: Scalars['ID'];
  medicalReason?: InputMaybe<Scalars['String']>;
};


export type MutationUploadPerformedProcedureEvidenceArgs = {
  description: Scalars['String'];
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
  performedProcedureId: Scalars['ID'];
};


export type MutationDeletePerformedProcedureEvidenceArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePerformedProcedureArgs = {
  id: Scalars['ID'];
};


export type MutationAddScheduledProcedureArgs = {
  durationHours?: InputMaybe<Scalars['Int']>;
  extraRequirements?: InputMaybe<Scalars['String']>;
  hasExtraRequirements?: InputMaybe<Scalars['Boolean']>;
  inHospital?: InputMaybe<Scalars['Boolean']>;
  isUrgent?: InputMaybe<Scalars['Boolean']>;
  medicalNoteId: Scalars['ID'];
  medicalReason?: InputMaybe<Scalars['String']>;
  procedureCategoryId?: InputMaybe<Scalars['ID']>;
  procedureName?: InputMaybe<Scalars['String']>;
  stayDays?: InputMaybe<Scalars['Int']>;
  tentativeDate?: InputMaybe<Scalars['Date']>;
};


export type MutationUpdateScheduledProcedureArgs = {
  durationHours?: InputMaybe<Scalars['Int']>;
  extraRequirements?: InputMaybe<Scalars['String']>;
  hasExtraRequirements?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inHospital?: InputMaybe<Scalars['Boolean']>;
  isUrgent?: InputMaybe<Scalars['Boolean']>;
  medicalReason?: InputMaybe<Scalars['String']>;
  procedureCategoryId?: InputMaybe<Scalars['ID']>;
  procedureName?: InputMaybe<Scalars['String']>;
  stayDays?: InputMaybe<Scalars['Int']>;
  tentativeDate?: InputMaybe<Scalars['Date']>;
};


export type MutationUploadScheduledProcedureEvidenceArgs = {
  description: Scalars['String'];
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
  scheduledProcedureId: Scalars['ID'];
};


export type MutationDeleteScheduledProcedureEvidenceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteScheduledProcedureArgs = {
  id: Scalars['ID'];
};


export type MutationCreateMedicalNotePrescriptionArgs = {
  diagnosis: Scalars['String'];
  hasAntibiotics?: InputMaybe<Scalars['Boolean']>;
  medicalNoteId: Scalars['ID'];
  recommendations?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMedicalNotePrescriptionArgs = {
  diagnosis?: InputMaybe<Scalars['String']>;
  hasAntibiotics?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  recommendations?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteMedicalNotePrescriptionArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePrescriptionItemArgs = {
  prescriptionId: Scalars['ID'];
  prescriptionItemInput: PrescriptionItemInputType;
};


export type MutationUpdatePrescriptionItemArgs = {
  id: Scalars['ID'];
  prescriptionItemInput: PrescriptionItemInputType;
};


export type MutationDeletePrescriptionItemArgs = {
  id: Scalars['ID'];
};


export type MutationSignMedicalNotePrescriptionArgs = {
  id: Scalars['ID'];
};


export type MutationCreateExclusionArgs = {
  exclusionDetail: ExclusionsCatalogInputType;
};


export type MutationUpdateExclusionArgs = {
  exclusionDetail: ExclusionsCatalogInputType;
  id: Scalars['ID'];
};


export type MutationCreateCaseEventArgs = {
  category: CaseEventCategory;
  description: Scalars['String'];
  diseaseCaseId?: InputMaybe<Scalars['ID']>;
  memberId: Scalars['ID'];
};


export type MutationDeleteCaseEventArgs = {
  id: Scalars['Int'];
};


export type MutationGroupServicesOnCaseEventArgs = {
  eventCategory?: InputMaybe<CaseEventCategory>;
  eventDescription?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['Int']>;
  servicesIds: Array<InputMaybe<Scalars['Int']>>;
};


export type MutationAssignUserToCaseEventArgs = {
  caseEventId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationUpdateCaseEventArgs = {
  administrativeRulingNotes?: InputMaybe<Scalars['String']>;
  admissionSource?: InputMaybe<CaseEventAdmissionSource>;
  attendingDoctorsIds?: InputMaybe<Array<Scalars['Int']>>;
  backgroundCheck?: InputMaybe<Scalars['Boolean']>;
  bedNumber?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CaseEventCategory>;
  deferredSince?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  dischargeEndDate?: InputMaybe<Scalars['DateTime']>;
  dischargeReason?: InputMaybe<CaseEventDischargeReason>;
  dischargeRulingNotes?: InputMaybe<Scalars['String']>;
  dischargeStartDate?: InputMaybe<Scalars['DateTime']>;
  diseaseCaseId?: InputMaybe<Scalars['ID']>;
  eventClass?: InputMaybe<CaseEventClassOptions>;
  eventId: Scalars['Int'];
  evidenceLinkSent?: InputMaybe<Scalars['Boolean']>;
  finalAmountsState?: InputMaybe<CaseEventFinalAmountsState>;
  informedHospital?: InputMaybe<Scalars['Boolean']>;
  internalNotes?: InputMaybe<Scalars['String']>;
  invoiceReceived?: InputMaybe<Scalars['Boolean']>;
  medicalPreDictaminationNotes?: InputMaybe<Scalars['String']>;
  medicalProcedureRulingNotes?: InputMaybe<Scalars['String']>;
  medicallyJustified?: InputMaybe<Scalars['Boolean']>;
  memberStage?: InputMaybe<CaseEventMemberStage>;
  occurrenceDate?: InputMaybe<Scalars['Date']>;
  originDiagnosisIcd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  paymentStatusSection?: InputMaybe<Scalars['String']>;
  pendingDoctorAction?: InputMaybe<Scalars['Boolean']>;
  pendingMemberAction?: InputMaybe<Scalars['Boolean']>;
  preAdmissionDoctorsIds?: InputMaybe<Array<Scalars['Int']>>;
  quoteAndSchedulingRulingNotes?: InputMaybe<Scalars['String']>;
  relevantHistory?: InputMaybe<Scalars['String']>;
  requestedPartnerDoctorInfo?: InputMaybe<Scalars['Boolean']>;
  scheduledHospitalDate?: InputMaybe<Scalars['DateTime']>;
};


export type MutationRemoveServicesFromCaseEventArgs = {
  servicesIds: Array<InputMaybe<Scalars['Int']>>;
};


export type MutationAddEventCommentArgs = {
  comment: Scalars['String'];
  eventId: Scalars['ID'];
};


export type MutationAddCaseEventSummaryArgs = {
  assessment: CaseEventSummaryAssessment;
  category: CaseEventSummaryCategory;
  eventId: Scalars['ID'];
};


export type MutationCreateEventQuoteArgs = {
  cieCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['ID']>;
  quoteItemData?: InputMaybe<Array<QuoteItemInputType>>;
};


export type MutationDeleteEventQuoteArgs = {
  id: Scalars['Int'];
};


export type MutationBuildEventQuoteLetterArgs = {
  provisionalLetterType: EventQuoteLetters;
  quoteId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateAdministrativeRulingTasksArgs = {
  antiquityCheckTask?: InputMaybe<TaskState>;
  balanceCheckTask?: InputMaybe<TaskState>;
  eventId: Scalars['ID'];
  validityCheckTask?: InputMaybe<TaskState>;
};


export type MutationUpdateMedicalPreRulingTasksArgs = {
  eventId: Scalars['ID'];
  medicalNeedCheckTask?: InputMaybe<TaskState>;
  preexistenceCheckTask?: InputMaybe<TaskState>;
  preexistenceInvestigationCheckTask?: InputMaybe<TaskState>;
  waitingPeriodCheckTask?: InputMaybe<TaskState>;
};


export type MutationUpdateQuotingAndSchedulingTasksArgs = {
  cptVsCostReferencesTask?: InputMaybe<TaskState>;
  eventId: Scalars['ID'];
  mainServiceCreatedTask?: InputMaybe<TaskState>;
  managementApprovalTask?: InputMaybe<TaskState>;
  providersQuotingTask?: InputMaybe<TaskState>;
  provisionalLettersCreationTask?: InputMaybe<TaskState>;
  provisionalLettersSentTask?: InputMaybe<TaskState>;
  scheduledDateRegisteredTask?: InputMaybe<TaskState>;
  vcVpoScheduledTask?: InputMaybe<TaskState>;
};


export type MutationUpdateMedicalProcedureOccurrenceTasksArgs = {
  dischargeDateRegisteredTask?: InputMaybe<TaskState>;
  dischargeIndicatedTask?: InputMaybe<TaskState>;
  eventId: Scalars['ID'];
  memberHospitalizedTask?: InputMaybe<TaskState>;
  occurrenceDateRegisteredTask?: InputMaybe<TaskState>;
};


export type MutationUpdateDischargeTasksArgs = {
  dischargeEndDateTask?: InputMaybe<TaskState>;
  eventId: Scalars['ID'];
};


export type MutationUpdateCaseEventPaymentStatusTasksArgs = {
  eventId: Scalars['ID'];
  memberChargesConfirmedTask?: InputMaybe<TaskState>;
  providersPaymentsTask?: InputMaybe<TaskState>;
};


export type MutationUpdateEventQuoteArgs = {
  cieCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  provisionalDoctorId?: InputMaybe<Scalars['ID']>;
  provisionalDoctorOrSurgeonId?: InputMaybe<Scalars['ID']>;
  provisionalProviderBranchOfficeId?: InputMaybe<Scalars['ID']>;
  quoteId?: InputMaybe<Scalars['ID']>;
  quoteItemData?: InputMaybe<Array<QuoteItemInputType>>;
};


export type MutationDeleteEventQuoteItemsArgs = {
  cptCodes?: InputMaybe<Array<Scalars['String']>>;
  quoteId?: InputMaybe<Scalars['ID']>;
  quoteItemId?: InputMaybe<Scalars['ID']>;
};


export type MutationSendEventQuoteLetterArgs = {
  provisionalLetterType: EventQuoteLetters;
  quoteId?: InputMaybe<Scalars['ID']>;
  recipientEmail: Scalars['String'];
};


export type MutationCreateCptPriceReferenceArgs = {
  baseAmountCents: Scalars['Int'];
  cptCode: Scalars['String'];
  description: Scalars['String'];
  providerClass: CptProviderClassification;
  referenceSource: CptPriceSource;
};


export type MutationUpdateCptPriceReferenceArgs = {
  id: Scalars['ID'];
  inputData: PriceReferencePriceInputType;
};


export type MutationUploadDataForEventBudgetArgs = {
  eventId: Scalars['ID'];
  fileName: Scalars['String'];
};


export type MutationDeleteEventBudgetArgs = {
  budgetId: Scalars['ID'];
};


export type MutationBuildDefaultEventQuoteLettersArgs = {
  quoteId?: InputMaybe<Scalars['ID']>;
};


export type MutationChangeQuoteLetterSentStateArgs = {
  letterId?: InputMaybe<Scalars['ID']>;
  sent: Scalars['Boolean'];
};


export type MutationDeleteQuoteLetterArgs = {
  letterId?: InputMaybe<Scalars['ID']>;
};


export type MutationValidateServiceCoveragePayoutElectronicVoucherAmountsArgs = {
  serviceCoveragePayoutId: Scalars['ID'];
};


export type MutationSetDocumentXmlElectronicVoucherArgs = {
  documentId: Scalars['ID'];
  serviceCoveragePayoutId: Scalars['ID'];
};


export type MutationGetServiceCoveragePayoutDocumentXmlUploadDataArgs = {
  serviceCoveragePayoutId: Scalars['ID'];
};


export type MutationCancelCaseEventArgs = {
  caseEventId: Scalars['ID'];
  comments?: InputMaybe<Scalars['String']>;
};


export type MutationPauseCaseEventArgs = {
  caseEventId: Scalars['ID'];
  comments?: InputMaybe<Scalars['String']>;
};


export type MutationBuildDefaultCaseEventQuoteAndQuoteLettersArgs = {
  caseEventId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateCaseEventFromScheduledProcedureArgs = {
  scheduledProcedureId: Scalars['ID'];
};


export type MutationCreateFinalLetterDataArgs = {
  caseEventId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateFinalLetterDataArgs = {
  cieCodes?: InputMaybe<Scalars['String']>;
  cieDescriptions?: InputMaybe<Scalars['String']>;
  cptCodes?: InputMaybe<Scalars['String']>;
  cptDescriptions?: InputMaybe<Scalars['String']>;
  finalLetterDataId?: InputMaybe<Scalars['ID']>;
  observations?: InputMaybe<Scalars['String']>;
};


export type MutationChangeFinalLetterSentStateArgs = {
  letterId?: InputMaybe<Scalars['ID']>;
  sent: Scalars['Boolean'];
};


export type MutationDeleteFinalLetterArgs = {
  letterId?: InputMaybe<Scalars['ID']>;
};


export type MutationSendFinalLetterArgs = {
  finalLetterId?: InputMaybe<Scalars['ID']>;
  finalLetterType: EventFinalLetters;
  recipientEmail: Scalars['String'];
};


export type MutationCreateCaseEventChannelArgs = {
  caseEventId: Scalars['ID'];
};


export type MutationInviteUserToEventChannelArgs = {
  caseEventId: Scalars['ID'];
  userEmails: Array<InputMaybe<Scalars['String']>>;
};


export type MutationNotifyToCaseEventChannelArgs = {
  caseEventId: Scalars['ID'];
};


export type MutationCreateCptArgs = {
  cptDetail: CptCatalogInputType;
};


export type MutationUpdateCptArgs = {
  cptDetail: CptCatalogInputType;
  id: Scalars['ID'];
};


export type MutationInitUserHybridSubscriptionArgs = {
  address: AddressInputType;
  birthCountry?: InputMaybe<Scalars['String']>;
  birthCountryCode: CountryCode;
  dateOfBirth: Scalars['Date'];
  educationAnswers: EducationAnswersInputType;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender: GenderType;
  height: Scalars['String'];
  lastName: Scalars['String'];
  nationalityCountryCode: CountryCode;
  occupation: Scalars['String'];
  practiceSports: Scalars['Boolean'];
  pronouns?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  telephoneInput: Scalars['String'];
  weight: Scalars['String'];
};


export type MutationSetupUserHealthplanApplicationArgs = {
  applicationSource?: InputMaybe<HealthPlanApplicationSource>;
  businessCode?: InputMaybe<Scalars['String']>;
  createPrefilledItem?: InputMaybe<Scalars['Boolean']>;
  dateOfBirth: Scalars['Date'];
  dentalMembershipAddon?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  gender?: InputMaybe<GenderType>;
  heightCm?: InputMaybe<Scalars['Int']>;
  incentiveCode?: InputMaybe<Scalars['String']>;
  insuredAmountCents?: InputMaybe<InsuredAmountCents>;
  lastName: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  productFamilySubscriptionIntent?: InputMaybe<ProductFamilies>;
  pronouns?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  subscriptionState?: InputMaybe<SubscriptionState>;
  telephoneInput: Scalars['String'];
  weightKg?: InputMaybe<Scalars['Int']>;
};


export type MutationCheckBusinessAccountArgs = {
  email: Scalars['String'];
};


export type MutationUpdateHealthPlanApplicationArgs = {
  acceptApplicationAt?: InputMaybe<Scalars['DateTime']>;
  businessCode?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  removeAcceptApplicationAt?: InputMaybe<Scalars['Boolean']>;
  removeBusinessCode?: InputMaybe<Scalars['Boolean']>;
  requestAntiquity?: InputMaybe<Scalars['Boolean']>;
  sponsorType?: InputMaybe<SponsorType>;
  subscriptionType?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateHealthPlanApplicationAnswersArgs = {
  educationAnswers: Scalars['JSONString'];
  id: Scalars['Int'];
};


export type MutationUpdateHealthPlanApplicationIncentiveCodeArgs = {
  id: Scalars['ID'];
  incentiveCode: Scalars['String'];
};


export type MutationDeleteHealthPlanApplicationIncentiveCodeArgs = {
  id: Scalars['ID'];
};


export type MutationSubmitHealthPlanApplicationArgs = {
  id: Scalars['Int'];
};


export type MutationSubmitHealthPlanApplicationWithTokenArgs = {
  id: Scalars['Int'];
  token: Scalars['String'];
};


export type MutationAcceptHealthPlanApplicationArgs = {
  id: Scalars['Int'];
};


export type MutationAttemptPreApprovedSubscriptionByUserArgs = {
  id: Scalars['Int'];
};


export type MutationCloseHealthPlanApplicationArgs = {
  id: Scalars['Int'];
};


export type MutationSetWaitingHealthPlanApplicationArgs = {
  id: Scalars['Int'];
  intermediateState: HealthPlanApplicationState;
};


export type MutationNotifyHealthPlanApplicationStateArgs = {
  id: Scalars['Int'];
};


export type MutationSendHealthPlanAcceptDocumentsArgs = {
  id: Scalars['Int'];
};


export type MutationResendWelcomeEmailsToCollectiveArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateHealthPlanApplicationItemArgs = {
  covidVaccinationStatus?: InputMaybe<CovidVaccinationStatus>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  dentalMembershipAddon?: InputMaybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  healthPlanValidSince?: InputMaybe<Scalars['DateTime']>;
  initialExclusions?: InputMaybe<Scalars['String']>;
  insuredAmountCents?: InputMaybe<InsuredAmountCents>;
  intlCoverageAddon?: InputMaybe<Scalars['Boolean']>;
  maternityAddon?: InputMaybe<Scalars['Boolean']>;
  questionnaireAnswers?: InputMaybe<Scalars['JSONString']>;
  questionnaireAnswersValidated?: InputMaybe<Scalars['JSONString']>;
  rejectionReason?: InputMaybe<HealthPlanApplicationItemRejectionReason>;
  relationship?: InputMaybe<Relationship>;
  updateProduct?: InputMaybe<Scalars['Boolean']>;
};


export type MutationForceHealthPlanApplicationItemCheckArgs = {
  id: Scalars['Int'];
};


export type MutationCreateHealthPlanApplicationItemArgs = {
  healthPlanApplicationId: Scalars['Int'];
  memberId?: InputMaybe<Scalars['Int']>;
  productId: Scalars['Int'];
  relationship?: InputMaybe<Relationship>;
};


export type MutationCreateHealthPlanApplicationItemV2Args = {
  healthPlanApplicationId: Scalars['ID'];
  memberId?: InputMaybe<Scalars['ID']>;
  productFamily?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Relationship>;
};


export type MutationDuplicateHealthPlanApplicationItemArgs = {
  id: Scalars['ID'];
};


export type MutationCancelHealthPlanApplicationItemArgs = {
  id: Scalars['Int'];
  rejectionReason?: InputMaybe<HealthPlanApplicationItemRejectionReason>;
};


export type MutationDeleteHealthPlanApplicationItemArgs = {
  id: Scalars['Int'];
};


export type MutationUploadPhotoHealthPlanApplicationItemArgs = {
  id: Scalars['Int'];
};


export type MutationUploadHealthPlanApplicationItemDocumentArgs = {
  healthPlanApplicationItemId: Scalars['Int'];
  documentCategory: DocumentCategory;
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationUploadSignatureForWebArgs = {
  mimeType: Scalars['String'];
  subscriptionType?: InputMaybe<Scalars['String']>;
  totalPriceCents?: InputMaybe<Scalars['Int']>;
};


export type MutationCommentOnHealthPlanApplicationItemArgs = {
  comment: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationSetWaitingHealthPlanApplicationItemArgs = {
  id: Scalars['Int'];
};


export type MutationCreateHealthPlanQuoteArgs = {
  healthPlanApplicationItemId: Scalars['Int'];
  productParametersValues?: InputMaybe<Scalars['JSONString']>;
  subscriptionType?: InputMaybe<SubscriptionType>;
};


export type MutationUpdateHealthPlanQuoteArgs = {
  id: Scalars['Int'];
  productParametersValues: Scalars['JSONString'];
};


export type MutationUpdateHealthPlanApplicationItemAnswersArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
  questionnaireAnswers?: InputMaybe<Scalars['JSONString']>;
  questionnaireAnswersValidated?: InputMaybe<Scalars['JSONString']>;
};


export type MutationSetSubscriptionCheckStateArgs = {
  id: Scalars['Int'];
  comments?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
};


export type MutationSetProfilingDataCheckStateArgs = {
  comments?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  state: CheckState;
};


export type MutationRefreshProfilingDataStateArgs = {
  id: Scalars['ID'];
};


export type MutationApproveCollectiveHealthPlanApplicationItemChecksAfterProfilingArgs = {
  id: Scalars['ID'];
};


export type MutationRetriggerAutoSubscriptionChecksArgs = {
  healthPlanApplicationItemId: Scalars['Int'];
};


export type MutationCheckProductParametersArgs = {
  healthPlanApplicationItemId: Scalars['Int'];
  productParametersValues: Scalars['JSONString'];
};


export type MutationGetInstallmentsAvailableForPaymentMethodArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateHealthPlanApplicationInterestFreePlanArgs = {
  id: Scalars['Int'];
  interestFreePlanSelected: Scalars['JSONString'];
};


export type MutationSendReminderArgs = {
  reminderHours: Scalars['Int'];
};


export type MutationSetBusinessAsHealthPlanApplicationOwnerArgs = {
  id: Scalars['Int'];
};


export type MutationSendSubscriptionPriceEstimationEmailArgs = {
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  dentalMembershipAddon?: InputMaybe<Scalars['Boolean']>;
  discountPercent?: InputMaybe<Scalars['Float']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  groupName?: InputMaybe<Scalars['String']>;
  input: Array<SubscriptionPriceInputType>;
  insuredAmountCents?: InputMaybe<InsuredAmountCents>;
  insuredAmountCentsList?: InputMaybe<Array<InsuredAmountCents>>;
  intlCoverageAddon?: InputMaybe<Scalars['Boolean']>;
  maternityAddon?: InputMaybe<Scalars['Boolean']>;
  quoteType?: InputMaybe<QuoteType>;
  to?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationGenerateSubscriptionPriceEstimationDocumentArgs = {
  businessName?: InputMaybe<Scalars['String']>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  dentalMembershipAddon?: InputMaybe<Scalars['Boolean']>;
  discountPercent?: InputMaybe<Scalars['Float']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  groupName?: InputMaybe<Scalars['String']>;
  input: Array<SubscriptionPriceInputType>;
  insuredAmountCents?: InputMaybe<InsuredAmountCents>;
  insuredAmountCentsList?: InputMaybe<Array<InsuredAmountCents>>;
  intlCoverageAddon?: InputMaybe<Scalars['Boolean']>;
  maternityAddon?: InputMaybe<Scalars['Boolean']>;
  quoteType?: InputMaybe<QuoteType>;
};


export type MutationGenerateSubscriptionPriceEstimationDocumentForCollectiveArgs = {
  input?: InputMaybe<Array<InputMaybe<Array<SubscriptionPriceInputType>>>>;
  scenarios: Array<ScenarioInputType>;
};


export type MutationUpdateHealthPlanQuoteSubscriptionTypeArgs = {
  id: Scalars['ID'];
  subscriptionType: SubscriptionType;
};


export type MutationStartMiniSubscriptionArgs = {
  countryCode?: InputMaybe<CountryCode>;
  dateOfBirth: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nickname: Scalars['String'];
  password: Scalars['String'];
  pronouns?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  sponsorType: SponsorType;
  telephoneInput: Scalars['String'];
};


export type MutationUpdateMedicalRecommendationArgs = {
  id: Scalars['Int'];
  medicalRecommendation: MedicalRecommendation;
  medicalRecommendationNotes?: InputMaybe<Scalars['String']>;
  sendNotification?: InputMaybe<Scalars['Boolean']>;
};


export type MutationAddMedicalRecommendationCommentArgs = {
  id: Scalars['Int'];
  text: Scalars['String'];
};


export type MutationUpdateMedicalAdditionalInformationArgs = {
  id: Scalars['Int'];
  medicalAdditionalInformationNotes?: InputMaybe<Scalars['String']>;
  medicalAdditionalInformationReasons?: InputMaybe<Array<InputMaybe<MedicalAdditionalInformationReason>>>;
};


export type MutationToggleWaitingMemberToAcceptExclusionsArgs = {
  id: Scalars['Int'];
  value: Scalars['Boolean'];
};


export type MutationSubmitActivationScoresArgs = {
  preventiveVideoConsultScore: Scalars['Float'];
  specialtyScore: Scalars['Float'];
  urgencyScore: Scalars['Float'];
  videoConsultScore: Scalars['Float'];
};


export type MutationUpdateScheduledApprovalCallArgs = {
  assignedTo?: InputMaybe<Scalars['ID']>;
  callUrl?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  scheduledApprovalCallId: Scalars['ID'];
  status?: InputMaybe<ScheduledApprovalCallStatus>;
};


export type MutationSetupActivationHealthPlanApplicationArgs = {
  incentiveCode?: InputMaybe<Scalars['String']>;
};


export type MutationAddProfilingHospitalizationArgs = {
  date?: InputMaybe<Scalars['Date']>;
  hospitalizationType: ProfilingHospitalizationType;
  profilingDataId: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationAddProfilingCancerArgs = {
  date?: InputMaybe<Scalars['Date']>;
  profilingDataId: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationAddProfilingChronicDiseaseArgs = {
  date?: InputMaybe<Scalars['Date']>;
  profilingDataId: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationAddProfilingMentalHealthDiagnosisArgs = {
  date?: InputMaybe<Scalars['Date']>;
  diagnosis: Scalars['String'];
  profilingDataId: Scalars['ID'];
  treatment?: InputMaybe<Scalars['String']>;
};


export type MutationAddProfilingAllergyArgs = {
  allergen: Scalars['String'];
  profilingDataId: Scalars['ID'];
  reaction?: InputMaybe<Scalars['String']>;
};


export type MutationAddProfilingMedicineArgs = {
  name: Scalars['String'];
  profilingDataId: Scalars['ID'];
  relatedDisease?: InputMaybe<Scalars['String']>;
};


export type MutationAddProfilingGynecologyDiseaseArgs = {
  date?: InputMaybe<Scalars['Date']>;
  name: Scalars['String'];
  profilingDataId: Scalars['ID'];
  treatment?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProfilingHospitalizationArgs = {
  date?: InputMaybe<Scalars['Date']>;
  hospitalizationType?: InputMaybe<ProfilingHospitalizationType>;
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProfilingCancerArgs = {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProfilingChronicDiseaseArgs = {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProfilingMentalHealthDiagnosisArgs = {
  date?: InputMaybe<Scalars['Date']>;
  diagnosis?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  treatment?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProfilingAllergyArgs = {
  allergen?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  reaction?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProfilingMedicineArgs = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  relatedDisease?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProfilingGynecologyDiseaseArgs = {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  treatment?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProfilingMedicalInfoArgs = {
  id: Scalars['ID'];
  isPregnant?: InputMaybe<Scalars['Boolean']>;
  otherAilments?: InputMaybe<Array<InputMaybe<ProfilingAilment>>>;
};


export type MutationDeleteProfilingHospitalizationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProfilingCancerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProfilingChronicDiseaseArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProfilingMentalHealthDiagnosisArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProfilingAllergyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProfilingMedicineArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProfilingGynecologyDiseaseArgs = {
  id: Scalars['ID'];
};


export type MutationAddProfilingMedicalCommentArgs = {
  profilingDataId: Scalars['ID'];
  text: Scalars['String'];
};


export type MutationTokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};


export type MutationCreateInternalUserArgs = {
  email: Scalars['String'];
  groups?: InputMaybe<Array<GroupName>>;
  member?: InputMaybe<InternalUserMemberInputType>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateInternalUserArgs = {
  groups?: InputMaybe<Array<GroupName>>;
  id: Scalars['ID'];
  member?: InputMaybe<InternalUserMemberInputType>;
};


export type MutationCreateUserForMemberArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  memberId: Scalars['Int'];
  telephone: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  beneficiaryWelcomed?: InputMaybe<Scalars['Boolean']>;
  collectiveOnboarded?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  isOnboarded?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  newPassword?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  oldPassword?: InputMaybe<Scalars['String']>;
  productFamilySubscriptionIntent?: InputMaybe<ProductFamilies>;
  shippingAddress?: InputMaybe<AddressInputType>;
  telephone?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  unverifiedEmail?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  welcomed?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetUserGroupsArgs = {
  groups?: InputMaybe<Array<GroupName>>;
  id: Scalars['ID'];
  isSuperuser?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRequestUserEmailUpdateArgs = {
  newEmail: Scalars['String'];
};


export type MutationConfirmUserEmailUpdateArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type MutationRequestUserPasswordUpdateArgs = {
  currentPassword: Scalars['String'];
};


export type MutationConfirmEmailOtpAuthArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type MutationRequestEmailChangeArgs = {
  email: Scalars['String'];
};


export type MutationUploadPhotoMemberArgs = {
  informal?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationAppForgetPasswordUserArgs = {
  telephone: Scalars['String'];
};


export type MutationForgetPasswordUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationResetPasswordUserArgs = {
  actionType?: InputMaybe<ActionTypeEnum>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCreateMemberArgs = {
  actualGender?: InputMaybe<Scalars['String']>;
  birthCountry?: InputMaybe<Scalars['String']>;
  birthCountryCode?: InputMaybe<Scalars['String']>;
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  heightCm?: InputMaybe<Scalars['Int']>;
  idIsPassport?: InputMaybe<Scalars['Boolean']>;
  nationalityCountryCode?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  relatedUserId?: InputMaybe<Scalars['Int']>;
  residenceAddress?: InputMaybe<AddressInputType>;
  rfc?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  weightKg?: InputMaybe<Scalars['Int']>;
  welcomeConsultStatus?: InputMaybe<WelcomeConsultStatusCategory>;
};


export type MutationUpdateMemberArgs = {
  actualGender?: InputMaybe<Scalars['String']>;
  assignedPrimaryCareDoctorId?: InputMaybe<Scalars['ID']>;
  birthCountry?: InputMaybe<Scalars['String']>;
  birthCountryCode?: InputMaybe<Scalars['String']>;
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  heightCm?: InputMaybe<Scalars['Int']>;
  huliPatientFileId?: InputMaybe<Scalars['ID']>;
  idIsPassport?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  nationalityCountryCode?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  relatedUserId?: InputMaybe<Scalars['Int']>;
  residenceAddress?: InputMaybe<AddressInputType>;
  rfc?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  weightKg?: InputMaybe<Scalars['Int']>;
  welcomeConsultStatus?: InputMaybe<WelcomeConsultStatusCategory>;
};


export type MutationAddCommentToMemberArgs = {
  memberId: Scalars['Int'];
  text: Scalars['String'];
};


export type MutationRequestUpdateMemberArgs = {
  birthCountry?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationCreateEmergencyContactArgs = {
  beneficiaryMemberId: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  primary: Scalars['Boolean'];
  relationship: Relationship;
  surname: Scalars['String'];
};


export type MutationUpdateEmergencyContactArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  primary: Scalars['Boolean'];
  relationship: Relationship;
  surname: Scalars['String'];
};


export type MutationDeleteEmergencyContactArgs = {
  id: Scalars['ID'];
};


export type MutationCreateStripeSourceArgs = {
  primary?: InputMaybe<Scalars['Boolean']>;
  sourceId: Scalars['String'];
};


export type MutationSetPrimaryStripeSourceArgs = {
  id: Scalars['String'];
};


export type MutationDeleteStripeSourceArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMemberArgs = {
  id: Scalars['Int'];
};


export type MutationAddPushTokenToUserArgs = {
  platform: Scalars['String'];
  platformVersion: Scalars['String'];
  token: Scalars['String'];
};


export type MutationDeletePushTokenFromUserArgs = {
  token: Scalars['String'];
};


export type MutationSendTelephoneConfirmArgs = {
  telephoneInput: Scalars['String'];
};


export type MutationConfirmTelephoneArgs = {
  code: Scalars['String'];
  telephoneInput: Scalars['String'];
};


export type MutationPhoneTokenAuthArgs = {
  password: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationUpdateUserTelephoneArgs = {
  code: Scalars['String'];
  telephoneInput: Scalars['String'];
};


export type MutationGrantRepresentedUserAccessArgs = {
  email: Scalars['String'];
  responsibleForServices?: InputMaybe<ResponsibleForServices>;
  telephone: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationReplaceDocumentArgs = {
  id: Scalars['Int'];
  documentSubtype?: InputMaybe<DocumentSubtype>;
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationUploadMemberDocumentArgs = {
  documentCategory: DocumentCategory;
  documentSubtype?: InputMaybe<DocumentSubtype>;
  memberId: Scalars['Int'];
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationUploadAddressDocumentArgs = {
  addressId: Scalars['Int'];
  documentSubtype?: InputMaybe<DocumentSubtype>;
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationUploadClaimsInvoiceDocumentArgs = {
  documentCategory: DocumentCategory;
  documentSubtype?: InputMaybe<DocumentSubtype>;
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationUploadAmendmentAttachmentDocumentArgs = {
  description?: InputMaybe<Scalars['String']>;
  documentCategory: DocumentCategory;
  documentSubtype?: InputMaybe<DocumentSubtype>;
  id: Scalars['Int'];
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationCreateAuthorizationTokenArgs = {
  capability: Scalars['String'];
  category?: InputMaybe<AuthorizationTokenCategory>;
  channel?: InputMaybe<AuthorizationTokenChannel>;
  tokenUserId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateAuthorizationCodeArgs = {
  capability: AuthorizationCodeCapability;
  email: Scalars['String'];
};


export type MutationConsumeAuthorizationTokenArgs = {
  capability: Scalars['String'];
  token: Scalars['String'];
  tokenUserId?: InputMaybe<Scalars['Int']>;
};


export type MutationResetCollectiveUserCredentialsArgs = {
  email: Scalars['String'];
};


export type MutationResetB2bAdminUserCredentialsArgs = {
  email: Scalars['String'];
};


export type MutationRequestForgotDoctorPasswordArgs = {
  email: Scalars['String'];
};


export type MutationSetCollectiveUserCredentialsArgs = {
  password: Scalars['String'];
  telephone: Scalars['String'];
  token: Scalars['String'];
};


export type MutationConfirmCollectiveUserCredentialsArgs = {
  password: Scalars['String'];
  telephone: Scalars['String'];
};


export type MutationSetSubscriptionStateArgs = {
  state: SubscriptionState;
};


export type MutationSetSubscriptionStateV2Args = {
  state: SubscriptionState;
};


export type MutationToggleAutomaticRenewArgs = {
  healthPlanId: Scalars['Int'];
};


export type MutationUpdateFeatureFlagValueArgs = {
  featureFlagName: FeatureFlags;
  featureFlagValue?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type MutationUserIntercomTagArgs = {
  tagName: Scalars['String'];
  tagValue?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRunCommandArgs = {
  commands: Array<InputMaybe<Scalars['String']>>;
};


export type MutationUpdateHealthPlanApplicationItemMemberDataArgs = {
  addressZipCode?: InputMaybe<Scalars['String']>;
  birthCountry?: InputMaybe<Scalars['String']>;
  birthCountryCode?: InputMaybe<Scalars['String']>;
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  idIsPassport?: InputMaybe<Scalars['Boolean']>;
  legalName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  nationalityCountryCode?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  relatedUserId?: InputMaybe<Scalars['Int']>;
  requiresElectronicVoucher?: InputMaybe<Scalars['Boolean']>;
  residenceAddress?: InputMaybe<AddressInputType>;
  rfc?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  taxNumber?: InputMaybe<Scalars['String']>;
  taxRegime?: InputMaybe<SatRegime>;
  welcomeConsultStatus?: InputMaybe<WelcomeConsultStatusCategory>;
};


export type MutationImpersonateUserArgs = {
  impersonatedEmail: Scalars['String'];
};


export type MutationMagicLinkAuthArgs = {
  token: Scalars['String'];
};


export type MutationOtpTokenAuthArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type MutationFreeUpUserTelephoneOrEmailArgs = {
  freeUpEmail?: InputMaybe<Scalars['Boolean']>;
  freeUpTelephone?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['Int'];
};


export type MutationOtpVerificationArgs = {
  otpToken: Scalars['String'];
};


export type MutationConfirmOtpDeviceArgs = {
  otpToken: Scalars['String'];
};


export type MutationDeleteConfirmedOtpDeviceArgs = {
  otpToken: Scalars['String'];
};


export type MutationGenerateOtpChallengeArgs = {
  messageMethod?: InputMaybe<OtpMessageMethod>;
};


export type MutationCreateMemberExclusionArgs = {
  memberExclusionInput: MemberExclusionInputType;
};


export type MutationDoctorCreateMemberExclusionArgs = {
  doctorMemberExclusionInput: DoctorMemberExclusionInputType;
};


export type MutationUpdateMemberExclusionArgs = {
  memberExclusionId: Scalars['ID'];
  memberExclusionInput: MemberExclusionInputType;
};


export type MutationDeleteMemberExclusionArgs = {
  memberExclusionId: Scalars['ID'];
};


export type MutationUpdateActivationCampaignStateArgs = {
  activationCampaignId: Scalars['ID'];
  activationCampaignState: ActivationCampaignState;
};


export type MutationAddProviderToMemberFavouritesArgs = {
  isDoctor: Scalars['Boolean'];
  memberId: Scalars['ID'];
  providerId: Scalars['ID'];
};


export type MutationRemoveProviderFromMemberFavouritesArgs = {
  isDoctor: Scalars['Boolean'];
  memberId: Scalars['ID'];
  providerId: Scalars['ID'];
};


export type MutationChangeRepresentationOnDependentUserArgs = {
  representativeMemberId: Scalars['ID'];
  targetMemberId: Scalars['ID'];
};


export type MutationResetMemberRepresentationToSponsorUserArgs = {
  memberId: Scalars['ID'];
};


export type MutationSendReferralInvitationCodeArgs = {
  email: Scalars['String'];
};


export type MutationProvidersTokenArgs = {
  email?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationCreateDoctorFriendArgs = {
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  taxNumber?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDoctorFriendArgs = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  taxNumber?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationCreateProviderCategoryArgs = {
  color?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};


export type MutationUpdateProviderCategoryArgs = {
  color?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationDeleteProviderCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationCreateProviderArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  contractCategory?: InputMaybe<ProviderContractCategory>;
  endDate?: InputMaybe<Scalars['Date']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  website?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProviderArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  contractCategory?: InputMaybe<ProviderContractCategory>;
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  state?: InputMaybe<ProviderState>;
  website?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteProviderArgs = {
  id: Scalars['Int'];
};


export type MutationCreateProviderBranchOfficeArgs = {
  adminUser: CreateUserInputType;
  billingInfo?: InputMaybe<BillingInfoInputType>;
  csgCertified?: InputMaybe<Scalars['Boolean']>;
  deferInvoice?: InputMaybe<Scalars['Boolean']>;
  filesFolderUrl?: InputMaybe<Scalars['String']>;
  isReferenceBranch?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<DoctorPaymentMethodInputType>;
  providerId: Scalars['Int'];
  showInApp?: InputMaybe<Scalars['Boolean']>;
  telephones?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tier?: InputMaybe<ProviderTier>;
  website?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProviderBranchOfficeArgs = {
  adminUser?: InputMaybe<UpdateUserInputType>;
  billingInfo?: InputMaybe<BillingInfoInputType>;
  csgCertified?: InputMaybe<Scalars['Boolean']>;
  deferInvoice?: InputMaybe<Scalars['Boolean']>;
  filesFolderUrl?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isReferenceBranch?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<DoctorPaymentMethodInputType>;
  showInApp?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<ProviderState>;
  telephones?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tier?: InputMaybe<ProviderTier>;
  website?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteProviderBranchOfficeArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateBranchOfficeContactInfoArgs = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateBranchOfficeHospitalInfoArgs = {
  hospitalInfoInput: BranchOfficeHospitalInfoInputType;
};


export type MutationCreateDoctorSpecialistDetailArgs = {
  doctorId: Scalars['ID'];
  doctorSpecialistDetailInput: DoctorSpecialistDetailInputType;
};


export type MutationUpdateDoctorSpecialistDetailArgs = {
  doctorSpecialistDetailInput: DoctorSpecialistDetailInputType;
  id: Scalars['ID'];
};


export type MutationDeleteDoctorSpecialistDetailArgs = {
  id: Scalars['ID'];
};


export type MutationUploadDoctorSpecialistDetailDocumentArgs = {
  category: DoctorSpecialistDetailDocuments;
  doctorSpecialistDetailId: Scalars['ID'];
  mimeType: Scalars['String'];
};


export type MutationAddDoctorSpecializationCertificateValidityArgs = {
  doctorSpecialistDetailId: Scalars['ID'];
  validUntil: Scalars['Date'];
};


export type MutationCreateDoctorArgs = {
  allowPrimaryCareMembersAssignment?: InputMaybe<Scalars['Boolean']>;
  autoEmitEnabled?: InputMaybe<Scalars['Boolean']>;
  availableOnDemand?: InputMaybe<Scalars['Boolean']>;
  billingInfo?: InputMaybe<DoctorBillingInfoInputType>;
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  deferInvoice?: InputMaybe<Scalars['Boolean']>;
  descriptor?: InputMaybe<Scalars['String']>;
  directAppointment?: InputMaybe<Scalars['Boolean']>;
  doctorSpecialistDetails?: InputMaybe<Array<DoctorSpecialistDetailInputType>>;
  doctoraliaUrl?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  huliId?: InputMaybe<Scalars['Int']>;
  internalNotes?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAuthorizedForInPersonConsult?: InputMaybe<Scalars['Boolean']>;
  isAuthorizedForOnDemand?: InputMaybe<Scalars['Boolean']>;
  isPediatric?: InputMaybe<Scalars['Boolean']>;
  isPediatricsCareDoctor?: InputMaybe<Scalars['Boolean']>;
  isPrimaryCareDoctor?: InputMaybe<Scalars['Boolean']>;
  licenseInstitution?: InputMaybe<Scalars['String']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  namePrefix?: InputMaybe<Scalars['String']>;
  officeAddress?: InputMaybe<AddressInputType>;
  paymentMethod: DoctorPaymentMethodInputType;
  priorityLevel?: InputMaybe<DoctorPriorityLevel>;
  providerBranchOfficeId?: InputMaybe<Scalars['ID']>;
  residencyHospital?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  secondaryOfficeAddress?: InputMaybe<AddressInputType>;
  showDoctorInApp?: InputMaybe<Scalars['Boolean']>;
  specializationFareId: Scalars['ID'];
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDoctorArgs = {
  autoEmitEnabled?: InputMaybe<Scalars['Boolean']>;
  availableOnDemand?: InputMaybe<Scalars['Boolean']>;
  deactivatedAt?: InputMaybe<Scalars['Date']>;
  directAppointment?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  licenseInstitution?: InputMaybe<Scalars['String']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  officeAddress?: InputMaybe<AddressInputType>;
  onLeaveFrom?: InputMaybe<Scalars['Date']>;
  onLeaveUntil?: InputMaybe<Scalars['Date']>;
  onboardingModalWatched?: InputMaybe<Scalars['Boolean']>;
  onboardingTourWatched?: InputMaybe<Scalars['Boolean']>;
  onboardingTrainingStep?: InputMaybe<DoctorTrainingStep>;
  secondaryOfficeAddress?: InputMaybe<AddressInputType>;
  showDoctorInApp?: InputMaybe<Scalars['Boolean']>;
  signingKey?: InputMaybe<Scalars['String']>;
  signingPassphrase?: InputMaybe<Scalars['String']>;
  specializationFareId?: InputMaybe<Scalars['ID']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDoctorByIdArgs = {
  allowPrimaryCareMembersAssignment?: InputMaybe<Scalars['Boolean']>;
  appointmentTelephone?: InputMaybe<Scalars['String']>;
  autoEmitEnabled?: InputMaybe<Scalars['Boolean']>;
  availabilityHours?: InputMaybe<Scalars['Int']>;
  availableOnDemand?: InputMaybe<Scalars['Boolean']>;
  billingInfo?: InputMaybe<DoctorBillingInfoInputType>;
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  deactivatedAt?: InputMaybe<Scalars['Date']>;
  deferInvoice?: InputMaybe<Scalars['Boolean']>;
  descriptor?: InputMaybe<Scalars['String']>;
  directAppointment?: InputMaybe<Scalars['Boolean']>;
  doctorSpecialistDetails?: InputMaybe<Array<DoctorSpecialistDetailInputType>>;
  doctoraliaUrl?: InputMaybe<Scalars['String']>;
  extraInfo?: InputMaybe<Scalars['String']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  huliId?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  internalNotes?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAuthorizedForInPersonConsult?: InputMaybe<Scalars['Boolean']>;
  isAuthorizedForOnDemand?: InputMaybe<Scalars['Boolean']>;
  isPediatric?: InputMaybe<Scalars['Boolean']>;
  isPediatricsCareDoctor?: InputMaybe<Scalars['Boolean']>;
  isPrimaryCareDoctor?: InputMaybe<Scalars['Boolean']>;
  leaveReason?: InputMaybe<DoctorLeaveReason>;
  licenseInstitution?: InputMaybe<Scalars['String']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  namePrefix?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  officeAddress?: InputMaybe<AddressInputType>;
  onLeaveFrom?: InputMaybe<Scalars['Date']>;
  onLeaveUntil?: InputMaybe<Scalars['Date']>;
  paymentMethod?: InputMaybe<DoctorPaymentMethodInputType>;
  priorityLevel?: InputMaybe<DoctorPriorityLevel>;
  providerBranchOfficeId?: InputMaybe<Scalars['ID']>;
  residencyHospital?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  secondaryOfficeAddress?: InputMaybe<AddressInputType>;
  showDoctorInApp?: InputMaybe<Scalars['Boolean']>;
  specializationFareId?: InputMaybe<Scalars['ID']>;
  telephone?: InputMaybe<Scalars['String']>;
  triviaInfo?: InputMaybe<Scalars['String']>;
  unverifiedEmail?: InputMaybe<Scalars['String']>;
};


export type MutationUploadDoctorDocumentArgs = {
  category: DoctorDocuments;
  doctorId: Scalars['ID'];
  mimeType: Scalars['String'];
};


export type MutationUpdateDoctorGeneralInfoArgs = {
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  extraInfo?: InputMaybe<Scalars['String']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  internalNotes?: InputMaybe<Scalars['String']>;
  namePrefix?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  triviaInfo?: InputMaybe<Scalars['String']>;
  unverifiedEmail?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDoctorPaymentInfoArgs = {
  autoEmitEnabled?: InputMaybe<Scalars['Boolean']>;
  billingInfo?: InputMaybe<DoctorBillingInfoInputType>;
  deferInvoice?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  paymentMethod?: InputMaybe<DoctorPaymentMethodInputType>;
  providerBranchOfficeId?: InputMaybe<Scalars['ID']>;
  specializationFareId?: InputMaybe<Scalars['ID']>;
};


export type MutationSetDoctorLeaveOfAbsenseArgs = {
  id: Scalars['ID'];
  leaveReason?: InputMaybe<DoctorLeaveReason>;
  onLeaveFrom: Scalars['Date'];
  onLeaveUntil: Scalars['Date'];
};


export type MutationRemoveDoctorLeaveOfAbsenseArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateDoctorStatusInfoArgs = {
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  priorityLevel?: InputMaybe<DoctorPriorityLevel>;
  showDoctorInApp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateDoctorServicesInfoArgs = {
  allowPrimaryCareMembersAssignment?: InputMaybe<Scalars['Boolean']>;
  availabilityHours?: InputMaybe<Scalars['Int']>;
  availableOnDemand?: InputMaybe<Scalars['Boolean']>;
  directAppointment?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isPediatric?: InputMaybe<Scalars['Boolean']>;
  isPediatricsCareDoctor?: InputMaybe<Scalars['Boolean']>;
  isPrimaryCareDoctor?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateDoctorOfficeAddressArgs = {
  addressInput: AddressInputType;
  doctorId: Scalars['ID'];
};


export type MutationUpdateDoctorOfficeAddressArgs = {
  addressInput: AddressInputType;
  doctorId: Scalars['ID'];
  officeAddressId: Scalars['ID'];
};


export type MutationDeleteDoctorOfficeAddressArgs = {
  doctorId: Scalars['ID'];
  officeAddressId: Scalars['ID'];
};


export type MutationCreateSpecializationFareArgs = {
  amountCents: Scalars['Int'];
  medicalSpecializationId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateSpecializationFareArgs = {
  id: Scalars['ID'];
  specializationFare: SpecializationFareInputType;
};


export type MutationCreateDoctorReminderArgs = {
  consultId?: InputMaybe<Scalars['Int']>;
  memberUuid: Scalars['UUID'];
  message?: InputMaybe<Scalars['String']>;
  remindAt: Scalars['DateTime'];
  title: Scalars['String'];
};


export type MutationUpdateDoctorReminderArgs = {
  id: Scalars['Int'];
  message?: InputMaybe<Scalars['String']>;
  remindAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<DoctorReminderStatus>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationScheduleAppointmentAttemptNotificationArgs = {
  doctorId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationImportDoctorDraftFromAirtableArgs = {
  email: Scalars['String'];
};


export type MutationCreateDoctorAssistantArgs = {
  allowedDoctorOfficeAddresses?: InputMaybe<Array<Scalars['ID']>>;
  canScheduleOfficeAddress?: InputMaybe<Scalars['Boolean']>;
  canScheduleSecondaryOfficeAddress?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  secondLastName?: InputMaybe<Scalars['String']>;
  telephone: Scalars['String'];
};


export type MutationUpdateDoctorAssistantArgs = {
  allowedDoctorOfficeAddresses?: InputMaybe<Array<Scalars['ID']>>;
  canScheduleOfficeAddress?: InputMaybe<Scalars['Boolean']>;
  canScheduleSecondaryOfficeAddress?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateMedicalSpecializationArgs = {
  description?: InputMaybe<Scalars['String']>;
  inCatalog?: InputMaybe<Scalars['Boolean']>;
  isPrimaryCare?: InputMaybe<Scalars['Boolean']>;
  medicalSpecializationGroupId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  referencedByIds?: InputMaybe<Array<Scalars['ID']>>;
  type?: InputMaybe<SpecializationType>;
};


export type MutationUpdateMedicalSpecializationArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  inCatalog?: InputMaybe<Scalars['Boolean']>;
  isPrimaryCare?: InputMaybe<Scalars['Boolean']>;
  medicalSpecializationGroupId?: InputMaybe<Scalars['ID']>;
  referencedByIds?: InputMaybe<Array<Scalars['ID']>>;
  type?: InputMaybe<SpecializationType>;
};


export type MutationCreateMedicalSpecializationGroupArgs = {
  name: Scalars['String'];
};


export type MutationUpdateDoctorLeadInfoArgs = {
  billingInfo?: InputMaybe<DoctorBillingInfoInputType>;
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  licenseInstitution?: InputMaybe<Scalars['String']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  namePrefix?: InputMaybe<NamePrefix>;
  officeAddress?: InputMaybe<AddressInputType>;
  paymentMethod?: InputMaybe<DoctorPaymentMethodInputType>;
  secondaryOfficeAddress?: InputMaybe<AddressInputType>;
};


export type MutationUpdateDoctorLeadInfoStateArgs = {
  leadInfoState: DoctorLeadInfoState;
};


export type MutationUpdateDoctorLeadInfoStateByIdArgs = {
  doctorId: Scalars['ID'];
  leadInfoState: DoctorLeadInfoState;
};


export type MutationSendDoctorContractByIdArgs = {
  doctorId: Scalars['ID'];
};


export type MutationUploadDoctorLeadDocumentArgs = {
  category: DoctorDocuments;
  mimeType: Scalars['String'];
};


export type MutationCreateDoctorSpecialistDetailForLeadArgs = {
  available?: InputMaybe<Scalars['Boolean']>;
  certificateId?: InputMaybe<Scalars['ID']>;
  certificateValidUntil?: InputMaybe<Scalars['Date']>;
  medicalSpecializationId: Scalars['ID'];
  specialistInstitution: Scalars['String'];
  specialistNumber?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDoctorSpecialistDetailForLeadArgs = {
  available?: InputMaybe<Scalars['Boolean']>;
  certificateId?: InputMaybe<Scalars['ID']>;
  certificateValidUntil?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  specialistInstitution?: InputMaybe<Scalars['String']>;
  specialistNumber?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteDoctorSpecialistDetailForLeadArgs = {
  id: Scalars['ID'];
};


export type MutationSetDoctorUserGroupsArgs = {
  groups?: InputMaybe<Array<GroupName>>;
  id: Scalars['ID'];
};


export type MutationCreatePharmacyOrderArgs = {
  prescriptionId: Scalars['Int'];
};


export type MutationUpdatePharmacyOrderArgs = {
  address?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['Int']>;
  handledBy?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  items?: InputMaybe<Array<InputMaybe<PharmacyItemInput>>>;
  xmlProvided: Scalars['Boolean'];
};


export type MutationCancelPharmacyOrderArgs = {
  id: Scalars['Int'];
};


export type MutationConfirmCoinsuredPharmacyOrderArgs = {
  id: Scalars['Int'];
};


export type MutationUploadPharmacyInvoiceDocumentArgs = {
  documentCategory: DocumentCategory;
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationSendPharmacyOrderEmailArgs = {
  id: Scalars['Int'];
};


export type MutationCreateGroupArgs = {
  name: Scalars['String'];
  permissionIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationUpdateGroupArgs = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  permissionIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationDeleteGroupArgs = {
  id: Scalars['Int'];
};


export type MutationCreatePaymentMethodArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  isWeb?: InputMaybe<Scalars['Boolean']>;
  paymentGatewayFrontendSourceId?: InputMaybe<Scalars['String']>;
  paymentGatewayType: Scalars['String'];
  paymentMethodType: Scalars['String'];
};


export type MutationMakePaymentMethodPrimaryArgs = {
  paymentMethodId: Scalars['Int'];
};


export type MutationDeletePaymentMethodArgs = {
  paymentMethodId: Scalars['Int'];
};


export type MutationMakePayoutPaymentMethodPrimaryArgs = {
  paymentMethodId: Scalars['Int'];
};


export type MutationMakePayinPaymentMethodPrimaryArgs = {
  paymentMethodId: Scalars['Int'];
  userId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateBillingInfoArgs = {
  address?: InputMaybe<AddressInputType>;
  addressZipCode?: InputMaybe<Scalars['String']>;
  legalName: Scalars['String'];
  requiresElectronicVoucher?: InputMaybe<Scalars['Boolean']>;
  taxNumber: Scalars['String'];
  taxRegime?: InputMaybe<SatRegime>;
};


export type MutationUpdateBillingInfoArgs = {
  address?: InputMaybe<AddressInputType>;
  billingInfoId: Scalars['Int'];
  legalName: Scalars['String'];
  taxNumber: Scalars['String'];
};


export type MutationCreatePaymentMethodForUserArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  paymentFlow: PaymentFlowTypes;
  paymentGatewayFrontendSourceId?: InputMaybe<Scalars['String']>;
  paymentGatewayType: Scalars['String'];
  paymentMethodType: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationCreateManualPaymentMethodForUserArgs = {
  invoiceId: Scalars['Int'];
  metadata: Scalars['GenericScalar'];
};


export type MutationEditManualPaymentGatewaySourceArgs = {
  metadata: Scalars['GenericScalar'];
  paymentGatewaySourceId: Scalars['Int'];
};


export type MutationAddSpeiPaymentMethodForUserArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  paymentGatewayFrontendSourceId?: InputMaybe<Scalars['String']>;
  paymentGatewayType: Scalars['String'];
};


export type MutationCreatePaymentMethodWithMultipleSourcesArgs = {
  forceRetrySubscription?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  paymentGatewaySources?: InputMaybe<Array<InputMaybe<PaymentGatewaySourceInputType>>>;
  paymentMethodType: Scalars['String'];
};


export type MutationCreateSetupIntentsArgs = {
  paymentGateways: Array<InputMaybe<Scalars['String']>>;
};


export type MutationCreateInvoiceDisputeArgs = {
  disputeDescription: Scalars['String'];
  invoiceId: Scalars['Int'];
};


export type MutationRuleInvoiceDisputeArgs = {
  disputeId: Scalars['Int'];
  rulingType: PaymentDisputeRulingTypes;
};


export type MutationFetchTransactionCepArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateUserBillingInfoArgs = {
  addressZipCode?: InputMaybe<Scalars['String']>;
  billingInfoId: Scalars['Int'];
  legalName?: InputMaybe<Scalars['String']>;
  requiresElectronicVoucher?: InputMaybe<Scalars['Boolean']>;
  taxNumber?: InputMaybe<Scalars['String']>;
  taxRegime?: InputMaybe<SatRegime>;
  userId: Scalars['Int'];
};


export type MutationUpdatePayoutMethodTierArgs = {
  paymentMethodId: Scalars['ID'];
  payoutTier: PayoutTiers;
};


export type MutationCreateLocationArgs = {
  city: Scalars['String'];
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  isCore?: InputMaybe<Scalars['Boolean']>;
  municipality?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  neighborhoodType?: InputMaybe<Scalars['String']>;
  stateName: Scalars['String'];
  zipcode: Scalars['String'];
};


export type MutationUpdateLocationArgs = {
  city?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  isCore?: InputMaybe<Scalars['Boolean']>;
  municipality?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  neighborhoodType?: InputMaybe<Scalars['String']>;
  stateName?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateInBatchLocationArgs = {
  city?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  isCore?: InputMaybe<Scalars['Boolean']>;
  municipality?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  neighborhoodType?: InputMaybe<Scalars['String']>;
  stateName?: InputMaybe<Scalars['String']>;
};


export type MutationRetryChargeInvoiceArgs = {
  id: Scalars['Int'];
};


export type MutationGenerateElectronicVoucherForInvoiceItemArgs = {
  id: Scalars['Int'];
};


export type MutationConfirmManualInvoicePaymentArgs = {
  confirmedDate?: InputMaybe<Scalars['DateTime']>;
  invoiceId: Scalars['Int'];
  paymentGatewaySourceId: Scalars['Int'];
};


export type MutationRefundInvoiceArgs = {
  id: Scalars['Int'];
};


export type MutationRequestElectronicVoucherDocumentDataArgs = {
  documentCategory: DocumentCategory;
  documentSubtype?: InputMaybe<DocumentSubtype>;
  electronicVoucherId?: InputMaybe<Scalars['Int']>;
  invoiceId: Scalars['Int'];
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationValidateElectronicVoucherXmlArgs = {
  alreadyValidated?: InputMaybe<Scalars['Boolean']>;
  documentId: Scalars['Int'];
  invoiceItemId: Scalars['Int'];
};


export type MutationValidateDraftElectronicVoucherXmlArgs = {
  documentId: Scalars['Int'];
};


export type MutationReplaceInvoiceDocumentArgs = {
  id: Scalars['Int'];
  documentSubtype?: InputMaybe<DocumentSubtype>;
  electronicVoucherId: Scalars['Int'];
  invoiceId: Scalars['Int'];
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationUploadElectronicVoucherArgs = {
  description?: InputMaybe<Scalars['String']>;
  documentCategory: DocumentCategory;
  documentSubtype?: InputMaybe<DocumentSubtype>;
  invoiceItemId: Scalars['Int'];
  mimeType?: InputMaybe<Scalars['String']>;
  voucherType: SatVoucherType;
};


export type MutationUploadDraftElectronicVoucherArgs = {
  description?: InputMaybe<Scalars['String']>;
  documentCategory: DocumentCategory;
  documentSubtype?: InputMaybe<DocumentSubtype>;
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationPayoutInvoiceArgs = {
  confirmationNumber?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['Int'];
  paymentMethodId?: InputMaybe<Scalars['Int']>;
};


export type MutationBundleInvoiceItemsArgs = {
  electronicVoucher: Scalars['Int'];
  invoice?: InputMaybe<Scalars['Int']>;
  invoiceItems: Array<InputMaybe<Scalars['Int']>>;
};


export type MutationPayoutInvoiceOfflineArgs = {
  confirmationNumber: Scalars['String'];
  invoiceId: Scalars['Int'];
};


export type MutationDownloadInvoicePayoutCepArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateElectronicVoucherArgs = {
  id: Scalars['Int'];
  inputData: ElectronicVoucherInputType;
};


export type MutationRetrySignElectronicVoucherArgs = {
  id: Scalars['Int'];
};


export type MutationAutoEmitMissingElectronicVoucherArgs = {
  id: Scalars['Int'];
};


export type MutationManualElectronicVoucherCancellationArgs = {
  id: Scalars['Int'];
  reason: Scalars['String'];
};


export type MutationCancelScheduledPayoutArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationAddCommentToInvoiceArgs = {
  id: Scalars['Int'];
  text: Scalars['String'];
};


export type MutationAttemptToSignUsersElectronicVouchersArgs = {
  userId: Scalars['Int'];
};


export type MutationSetCopayInvoiceAsUncollectibleArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveInvoiceItemElectronicVoucherArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateInvoiceItemsWithElectronicVoucherAmountsArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationSetInvoiceUnpayableArgs = {
  comment: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationCancelPayoutInvoiceArgs = {
  comment: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationSetPayinInvoiceAsUncollectibleArgs = {
  comment: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationCancelServicePayinInvoiceArgs = {
  comment: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationUpdateIncentiveCodeBannerArgs = {
  bannerState: BannerState;
  id: Scalars['ID'];
};


export type MutationEditIncentiveCodeArgs = {
  campaignId: Scalars['Int'];
  code: Scalars['String'];
  discountPercent: Scalars['Float'];
  id: Scalars['ID'];
  referrerUserId: Scalars['Int'];
  validFrom: Scalars['Date'];
  validTo: Scalars['Date'];
};


export type MutationCreateIncentiveCodeArgs = {
  campaignId: Scalars['Int'];
  code: Scalars['String'];
  discountPercent: Scalars['Float'];
  referrerUserId: Scalars['Int'];
  validFrom: Scalars['Date'];
  validTo: Scalars['Date'];
};


export type MutationCreateHealthPlanAmendmentArgs = {
  healthPlanId: Scalars['Int'];
};


export type MutationUpdateHealthPlanAmendmentArgs = {
  amendmentCategory?: InputMaybe<AmendmentCategory>;
  amendmentDetails?: InputMaybe<Scalars['JSONString']>;
  amendmentExtras?: InputMaybe<Scalars['JSONString']>;
  healthPlanAmendmentId: Scalars['Int'];
  reviewResult?: InputMaybe<AmendmentReviewResult>;
};


export type MutationCreateReissueApplicationArgs = {
  coinsuranceConfig?: InputMaybe<CoInsuranceConfigFactor>;
  configGroupId?: InputMaybe<Scalars['Int']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  dentalMembershipAddon?: InputMaybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<GenderType>;
  id: Scalars['Int'];
  incentiveCode?: InputMaybe<Scalars['String']>;
  insuredAmountCents?: InputMaybe<InsuredAmountCents>;
  maternityAddon?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCancelHealthPlanArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  reason?: InputMaybe<CancelReason>;
};


export type MutationCancelEmployeeAndFamilyHealthplansArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  reason?: InputMaybe<CancelReason>;
};


export type MutationUpdateAntiquityRequestArgs = {
  antiquitySince?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  insurer?: InputMaybe<Scalars['String']>;
};


export type MutationSubmitAntiquityRequestArgs = {
  id: Scalars['ID'];
};


export type MutationCancelAntiquityRequestArgs = {
  id: Scalars['ID'];
};


export type MutationGetAntiquityRequestDocumentS3UploadDataArgs = {
  category: AntiquityDocuments;
  id: Scalars['ID'];
  mimeType: Scalars['String'];
};


export type MutationCreateAntiquityRequestDocumentArgs = {
  category: AntiquityDocuments;
  id: Scalars['ID'];
  mimeType: Scalars['String'];
};


export type MutationAddDentalMembershipHealthPlanArgs = {
  healthPlanId: Scalars['ID'];
  validSince?: InputMaybe<Scalars['DateTime']>;
};


export type MutationCancelDentalMembershipHealthPlanArgs = {
  healthPlanId: Scalars['ID'];
  validSince?: InputMaybe<Scalars['DateTime']>;
};


export type MutationSetConsultTypeArgs = {
  id: Scalars['ID'];
  entryType: EmrType;
};


export type MutationSetClassificationNotesArgs = {
  classificationNotes: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationAuthorizeInPersonConsultsArgs = {
  consults: Array<InputMaybe<Scalars['Int']>>;
};


export type MutationCreatePrescriptionArgs = {
  consultId: Scalars['Int'];
  diagnosis?: InputMaybe<Scalars['String']>;
  hasAntibiotics?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<PrescriptionItemInputType>>;
  prescriptionItems?: InputMaybe<Scalars['JSONString']>;
  recommendations?: InputMaybe<Scalars['String']>;
};


export type MutationCreateOnDemandConsultArgs = {
  classification?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['Int']>;
  scheduledConsultId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateOnDemandConsultNewArgs = {
  classification?: InputMaybe<OnDemandConsultClassificationType>;
  memberId?: InputMaybe<Scalars['Int']>;
  scheduledConsultId?: InputMaybe<Scalars['Int']>;
};


export type MutationAddReasonOnDemandConsultArgs = {
  id: Scalars['Int'];
  reason: Scalars['String'];
};


export type MutationClaimOnDemandConsultArgs = {
  id: Scalars['Int'];
  triggerCall?: InputMaybe<Scalars['Boolean']>;
};


export type MutationStartOnDemandConsultArgs = {
  id: Scalars['Int'];
};


export type MutationStopOnDemandConsultArgs = {
  id: Scalars['Int'];
};


export type MutationCancelOnDemandConsultArgs = {
  id: Scalars['Int'];
};


export type MutationCloseOnDemandConsultArgs = {
  id: Scalars['Int'];
};


export type MutationSetReadyAgainOnDemandConsultArgs = {
  id: Scalars['Int'];
};


export type MutationStartCallConsultArgs = {
  id: Scalars['Int'];
};


export type MutationEndCallConsultArgs = {
  id: Scalars['Int'];
};


export type MutationWrapUpConsultArgs = {
  id: Scalars['Int'];
  diagnosis?: InputMaybe<Scalars['String']>;
  hasAntibiotics?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<PrescriptionItemInputType>>;
  prescriptionItems?: InputMaybe<Scalars['JSONString']>;
  recommendations?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateEmrArgs = {
  diagnosis?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  labs?: InputMaybe<Scalars['String']>;
  medicines?: InputMaybe<Scalars['JSONString']>;
  notes?: InputMaybe<Scalars['String']>;
  otherRecommendations?: InputMaybe<Scalars['String']>;
};


export type MutationSendTextMessageArgs = {
  consultId: Scalars['Int'];
  content: Scalars['String'];
  toUserId: Scalars['Int'];
};


export type MutationSetMessagesReadArgs = {
  ids: Array<InputMaybe<Scalars['Int']>>;
  read?: InputMaybe<Scalars['Boolean']>;
};


export type MutationStartAttachmentMessageArgs = {
  consultId: Scalars['Int'];
  toUserId: Scalars['Int'];
};


export type MutationCompleteAttachmentMessageArgs = {
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};


export type MutationRequestMedicinePostOnDemandConsultArgs = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  id: Scalars['Int'];
  neighborhood: Scalars['String'];
  phone: Scalars['String'];
  saveShippingAddress?: InputMaybe<Scalars['Boolean']>;
  zipcode: Scalars['String'];
};


export type MutationUpdatePrescriptionMemberChecksArgs = {
  checkupsCheck?: InputMaybe<Scalars['Boolean']>;
  labsCheck?: InputMaybe<Scalars['Boolean']>;
  medicinesCheck?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  prescriptionItemsMemberChecks?: InputMaybe<Scalars['JSONString']>;
  referencesCheck?: InputMaybe<Scalars['Boolean']>;
  urgenciesCheck?: InputMaybe<Scalars['Boolean']>;
};


export type MutationChargeInPersonConsultArgs = {
  id: Scalars['ID'];
};


export type MutationChargeInPersonConsultByProviderArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCalendarEventArgs = {
  dateTime: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  doctorId: Scalars['ID'];
  location?: InputMaybe<Scalars['String']>;
  memberId: Scalars['ID'];
};


export type MutationUpdateCalendarEventArgs = {
  dateTime?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  doctorId: Scalars['ID'];
  eventId: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
  memberId: Scalars['ID'];
};


export type MutationDeleteCalendarEventArgs = {
  doctorId: Scalars['ID'];
  eventId: Scalars['String'];
};


export type MutationAssignUserToConsultArgs = {
  consultId: Scalars['ID'];
  userId?: InputMaybe<Scalars['ID']>;
};


export type MutationAddCommentToConsultArgs = {
  consultId: Scalars['ID'];
  text: Scalars['String'];
};


export type MutationCreateEmptyScheduledConsultRequestArgs = {
  doctorId?: InputMaybe<Scalars['ID']>;
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  memberId: Scalars['ID'];
  onDemandConsultClassification?: InputMaybe<OnDemandConsultClassificationType>;
  scheduledConsultClassification: GrapheneScheduledConsultClassification;
  scheduledConsultKind?: InputMaybe<ScheduledConsultKind>;
  symptoms?: InputMaybe<Scalars['String']>;
};


export type MutationScheduleOutOfNetworkConsultArgs = {
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  id?: InputMaybe<Scalars['ID']>;
  locationState?: InputMaybe<Scalars['String']>;
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  medicalSpecializationName?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<States>;
  symptoms?: InputMaybe<Scalars['String']>;
};


export type MutationCreateScheduledConsultRequestArgs = {
  addressId?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<ConsultCategory>;
  dayWindow?: InputMaybe<AppointmentDayWindow>;
  dayWindowDescription?: InputMaybe<Scalars['String']>;
  doctorId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  memberId?: InputMaybe<Scalars['ID']>;
  onDemandConsultClassification?: InputMaybe<OnDemandConsultClassificationType>;
  scheduledConsultClassification: GrapheneScheduledConsultClassification;
  scheduledConsultKind?: InputMaybe<ScheduledConsultKind>;
  secondaryAddressId?: InputMaybe<Scalars['ID']>;
  symptoms?: InputMaybe<Scalars['String']>;
  timeWindow?: InputMaybe<AppointmentTimeWindow>;
  timeWindowDescription?: InputMaybe<Scalars['String']>;
  urgency?: InputMaybe<AppointmentUrgency>;
  urgencyDescription?: InputMaybe<Scalars['String']>;
};


export type MutationCreateScheduledConsultArgs = {
  addressId: Scalars['ID'];
  category?: InputMaybe<ConsultCategory>;
  doctorId: Scalars['ID'];
  memberId: Scalars['ID'];
  onDemandConsultClassification?: InputMaybe<OnDemandConsultClassificationType>;
  scheduledAt: Scalars['DateTime'];
  scheduledConsultClassification: GrapheneScheduledConsultClassification;
  scheduledConsultKind?: InputMaybe<ScheduledConsultKind>;
  symptoms?: InputMaybe<Scalars['String']>;
};


export type MutationScheduleConsultArgs = {
  addressId?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<ConsultCategory>;
  doctorId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  inPersonConsultClassification?: InputMaybe<InPersonConsultClassificationType>;
  memberId?: InputMaybe<Scalars['ID']>;
  onDemandConsultClassification?: InputMaybe<OnDemandConsultClassificationType>;
  scheduledAt: Scalars['DateTime'];
  scheduledConsultClassification: GrapheneScheduledConsultClassification;
  scheduledConsultKind?: InputMaybe<ScheduledConsultKind>;
  shouldEndAt?: InputMaybe<Scalars['DateTime']>;
  specialInstructions?: InputMaybe<Scalars['String']>;
  symptoms?: InputMaybe<Scalars['String']>;
};


export type MutationDoctorAssistantScheduleConsultArgs = {
  addressId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  memberId?: InputMaybe<Scalars['ID']>;
  scheduledAt: Scalars['DateTime'];
  shouldEndAt?: InputMaybe<Scalars['DateTime']>;
  specialInstructions?: InputMaybe<Scalars['String']>;
  symptoms?: InputMaybe<Scalars['String']>;
};


export type MutationConfirmScheduledConsultArgs = {
  id: Scalars['ID'];
};


export type MutationCancelScheduledConsultArgs = {
  cancellationReason?: InputMaybe<ConsultCancellationReason>;
  cancellationReasonDetails?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationDoctorAssistantCancelScheduledConsultArgs = {
  cancellationReason?: InputMaybe<ConsultCancellationReason>;
  cancellationReasonDetails?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationUpdateScheduledConsultRequestArgs = {
  extraNotes?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationMemberCancelScheduledConsultArgs = {
  id: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationMemberUpdateScheduledConsultArgs = {
  addressId: Scalars['ID'];
  category?: InputMaybe<ConsultCategory>;
  doctorId: Scalars['ID'];
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  onDemandConsultClassification?: InputMaybe<OnDemandConsultClassificationType>;
  scheduledAt: Scalars['DateTime'];
  scheduledConsultClassification: GrapheneScheduledConsultClassification;
  scheduledConsultKind?: InputMaybe<ScheduledConsultKind>;
  symptoms?: InputMaybe<Scalars['String']>;
};


export type MutationMemberUpdateScheduledConsultRequestArgs = {
  addressId?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<ConsultCategory>;
  dayWindow?: InputMaybe<AppointmentDayWindow>;
  dayWindowDescription?: InputMaybe<Scalars['String']>;
  doctorId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  onDemandConsultClassification?: InputMaybe<OnDemandConsultClassificationType>;
  scheduledConsultClassification: GrapheneScheduledConsultClassification;
  scheduledConsultKind?: InputMaybe<ScheduledConsultKind>;
  secondaryAddressId?: InputMaybe<Scalars['ID']>;
  symptoms?: InputMaybe<Scalars['String']>;
  timeWindow?: InputMaybe<AppointmentTimeWindow>;
  timeWindowDescription?: InputMaybe<Scalars['String']>;
  urgency?: InputMaybe<AppointmentUrgency>;
  urgencyDescription?: InputMaybe<Scalars['String']>;
};


export type MutationReviewComplianceAlertArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  internalNotes?: InputMaybe<Scalars['String']>;
  officerReported?: InputMaybe<Scalars['Boolean']>;
  reportToAuthority?: InputMaybe<Scalars['Boolean']>;
  reviewedByCommittee?: InputMaybe<Scalars['Boolean']>;
  reviewedByCommitteeAt?: InputMaybe<Scalars['Date']>;
  reviewedByOfficer?: InputMaybe<Scalars['Boolean']>;
  reviewedByOfficerAt?: InputMaybe<Scalars['Date']>;
};


export type MutationSendAmlReportArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  memberId?: InputMaybe<Scalars['String']>;
  officerReported?: InputMaybe<Scalars['Boolean']>;
  reportToAuthority?: InputMaybe<Scalars['Boolean']>;
  reportType: ComplianceReportType;
  reviewedByCommittee?: InputMaybe<Scalars['Boolean']>;
  reviewedByOfficer?: InputMaybe<Scalars['Boolean']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type MutationDoctorCreateDiseaseCaseArgs = {
  description: Scalars['String'];
  memberId: Scalars['Int'];
};


export type MutationCreateDiseaseCaseArgs = {
  description: Scalars['String'];
  medicalDescription?: InputMaybe<Scalars['String']>;
  memberId: Scalars['Int'];
  originDiagnosisIcd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdateDiseaseCaseArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  medicalDescription?: InputMaybe<Scalars['String']>;
  originDiagnosisIcd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreateServiceArgs = {
  diseaseCaseId?: InputMaybe<Scalars['Int']>;
  eventId?: InputMaybe<Scalars['ID']>;
  memberId: Scalars['Int'];
  scheduled?: InputMaybe<Scalars['Boolean']>;
  serviceTypeId: Scalars['Int'];
  specification?: InputMaybe<Scalars['String']>;
};


export type MutationMemberCreateServiceArgs = {
  memberId: Scalars['Int'];
  serviceType: MemberCreateServiceTypesEnum;
};


export type MutationUpdateServiceArgs = {
  coverageTag?: InputMaybe<CoverageTag>;
  cpt?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  diagnosisDescription?: InputMaybe<Scalars['String']>;
  diagnosisIcd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dischargeDate?: InputMaybe<Scalars['Date']>;
  diseaseCaseId?: InputMaybe<Scalars['Int']>;
  externalProvider?: InputMaybe<Scalars['String']>;
  hospitalizationDays?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  litigation?: InputMaybe<Scalars['Boolean']>;
  occurrenceDate?: InputMaybe<Scalars['Date']>;
  providerId?: InputMaybe<Scalars['Int']>;
  rulingDifficulty?: InputMaybe<RulingDifficulty>;
  scheduled?: InputMaybe<Scalars['Boolean']>;
  serviceDescription?: InputMaybe<Scalars['String']>;
  specification?: InputMaybe<Scalars['String']>;
  supportSystemLink?: InputMaybe<Scalars['String']>;
};


export type MutationCreatePrescriptionServiceArgs = {
  group: PrescriptionItemGroup;
  prescriptionId: Scalars['Int'];
};


export type MutationUpdateServiceDoctorArgs = {
  doctorId: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationUpdateServiceDoctorFriendArgs = {
  doctorId: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationDeleteServiceArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateServiceCoverageRulingArgs = {
  allowPayoutOnRejection?: InputMaybe<Scalars['Boolean']>;
  declinedReason?: InputMaybe<RulingDeclinationReasons>;
  healthPlanId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  initialCostCents?: InputMaybe<Scalars['Int']>;
  postauthorizationDocId?: InputMaybe<Scalars['Int']>;
  preauthorizationDocId?: InputMaybe<Scalars['Int']>;
  rejectionComment?: InputMaybe<Scalars['String']>;
  totalCostCents?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateRejectionMessageForUserArgs = {
  id: Scalars['ID'];
  rejectionMessageName: RejectionMessageForUser;
  rejectionMessageValue: Scalars['String'];
};


export type MutationUpdateServiceCoverageRulingStateArgs = {
  id: Scalars['Int'];
  state: RulingState;
};


export type MutationUpdateClaimRulingCheckArgs = {
  id: Scalars['Int'];
  state: RulingCheckState;
};


export type MutationAcceptAllRulingChecksArgs = {
  rulingId: Scalars['ID'];
};


export type MutationGroupServicesOnDiseaseCaseArgs = {
  diseaseCaseId: Scalars['Int'];
  servicesIds: Array<InputMaybe<Scalars['Int']>>;
};


export type MutationCreateEvidenceArgs = {
  doctorId?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['Int'];
  providerId?: InputMaybe<Scalars['Int']>;
  reportType: TypeformReportTypes;
  serviceId: Scalars['Int'];
};


export type MutationCompleteShortReimbursementSofiaFormArgs = {
  diagnosis?: InputMaybe<Scalars['String']>;
  formUuid: Scalars['UUID'];
  occurrenceDate: Scalars['Date'];
};


export type MutationEvidencesReviewedArgs = {
  serviceId: Scalars['Int'];
};


export type MutationRequestMoreEvidencesArgs = {
  serviceId: Scalars['Int'];
};


export type MutationCreateMemberReimbursementArgs = {
  diseaseCaseId?: InputMaybe<Scalars['Int']>;
  doctorId?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['Int'];
  providerId?: InputMaybe<Scalars['Int']>;
  relatedServiceId?: InputMaybe<Scalars['Int']>;
  reportType: TypeformReportTypes;
  scheduled?: InputMaybe<Scalars['Boolean']>;
  serviceTypeId: Scalars['Int'];
  specification?: InputMaybe<Scalars['String']>;
};


export type MutationCreateOutOfNetworkConsultMemberReimbursementArgs = {
  memberId: Scalars['ID'];
  scheduledConsultId: Scalars['ID'];
};


export type MutationCreatePreapprovedAmountArgs = {
  currency?: InputMaybe<Currency>;
  description: Scalars['String'];
  serviceCoverageRulingId: Scalars['Int'];
  subtotalAmountCents: Scalars['Int'];
  totalAmountCents: Scalars['Int'];
  vatAmountCents: Scalars['Int'];
};


export type MutationUpdateOrCreateServiceCoveragePayoutArgs = {
  amountAnalysisDocumentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceSubtotalCents?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceTotalCents?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceVatCents?: InputMaybe<Scalars['Int']>;
  payinMethod?: InputMaybe<PayInMethods>;
  payoutMode?: InputMaybe<ServiceCoveragePayoutMode>;
  rejectedAmountCentsComment?: InputMaybe<Scalars['String']>;
  rejectedAmountCentsMessageForMember?: InputMaybe<Scalars['String']>;
  rejectedAmountSubtotalCents?: InputMaybe<Scalars['Int']>;
  rejectedAmountTotalCents?: InputMaybe<Scalars['Int']>;
  rejectedAmountVatCents?: InputMaybe<Scalars['Int']>;
  serviceCoverageRulingId: Scalars['Int'];
  subtotalAmountCents: Scalars['Int'];
  totalAmountCents: Scalars['Int'];
  vatAmountCents: Scalars['Int'];
};


export type MutationCreateServiceCoveragePayoutInvoiceArgs = {
  invoiceUuid: Scalars['String'];
  payoutId: Scalars['Int'];
  pdfDocumentId: Scalars['Int'];
  xmlDocumentId: Scalars['Int'];
};


export type MutationSetAmountsOnServiceCoveragePayoutArgs = {
  amountAnalysisDocumentId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  paidCoinsuranceSubtotalCents?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceTotalCents?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceVatCents?: InputMaybe<Scalars['Int']>;
  payoutMode: ServiceCoveragePayoutMode;
  rejectedAmountCentsComment?: InputMaybe<Scalars['String']>;
  rejectedAmountCentsMessageForMember?: InputMaybe<Scalars['String']>;
  rejectedAmountSubtotalCents?: InputMaybe<Scalars['Int']>;
  rejectedAmountTotalCents?: InputMaybe<Scalars['Int']>;
  rejectedAmountVatCents?: InputMaybe<Scalars['Int']>;
  subtotalAmountCents: Scalars['Int'];
  totalAmountCents: Scalars['Int'];
  vatAmountCents: Scalars['Int'];
};


export type MutationApproveServiceCoveragePayoutArgs = {
  id: Scalars['Int'];
  payoutMode: ServiceCoveragePayoutMode;
};


export type MutationRequestServicePayInArgs = {
  id: Scalars['Int'];
  method?: InputMaybe<PayInMethods>;
};


export type MutationUploadDataForClaimDocumentArgs = {
  description?: InputMaybe<EvidenceDescription>;
  formUuid?: InputMaybe<Scalars['UUID']>;
  mimeType?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['Int'];
};


export type MutationCreateServiceElectronicVoucherItemsArgs = {
  pdfDocumentId?: InputMaybe<Scalars['ID']>;
  serviceId: Scalars['ID'];
  xmlDocumentId: Scalars['ID'];
};


export type MutationSetAcceptedElectronicVoucherItemsArgs = {
  serviceElectronicVouchersItemsInput: Array<ServiceElectronicVoucherItemBulkInputType>;
  serviceId: Scalars['ID'];
};


export type MutationBundleEventInvoiceItemsArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>;
  invoiceItemIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationCancelServiceArgs = {
  comments?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['ID'];
};


export type MutationAssignUserToServiceArgs = {
  serviceId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationUpdateServiceMedicalRecommendationNotesArgs = {
  medicalRecommendationNotes: Scalars['String'];
  sendNotification?: InputMaybe<Scalars['Boolean']>;
  serviceId: Scalars['ID'];
  suggestedRulingState: RulingState;
};


export type MutationAddServiceCommentArgs = {
  comment: Scalars['String'];
  serviceId: Scalars['ID'];
};


export type MutationUpdateServiceEvidenceFlagsArgs = {
  flagName: EvidenceStateFlag;
  flagValue: Scalars['Boolean'];
  serviceId: Scalars['ID'];
};


export type MutationProcessServiceCoveragePayoutArgs = {
  id: Scalars['Int'];
  payoutMode: ServiceCoveragePayoutMode;
};


export type MutationValidateCoveragePayoutAmountsArgs = {
  id: Scalars['ID'];
  paidCoinsuranceIncluded?: InputMaybe<Scalars['Boolean']>;
  paidCoinsuranceSubtotalCents?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceTotalCents?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceVatCents?: InputMaybe<Scalars['Int']>;
  rejectedAmountCentsComment?: InputMaybe<Scalars['Int']>;
  rejectedAmountCentsMessageForMember?: InputMaybe<Scalars['Int']>;
  rejectedAmountSubtotalCents?: InputMaybe<Scalars['Int']>;
  rejectedAmountTotalCents?: InputMaybe<Scalars['Int']>;
  rejectedAmountVatCents?: InputMaybe<Scalars['Int']>;
  subtotalAmountCents: Scalars['Int'];
  totalAmountCents: Scalars['Int'];
  vatAmountCents: Scalars['Int'];
};


export type MutationUpdateServiceCoveragePayoutArgs = {
  calculateBreakdown?: InputMaybe<Scalars['Boolean']>;
  coinsuredSubtotalCents?: InputMaybe<Scalars['Int']>;
  coinsuredTotalCents?: InputMaybe<Scalars['Int']>;
  coinsuredVatCents?: InputMaybe<Scalars['Int']>;
  coveredSubtotalCents?: InputMaybe<Scalars['Int']>;
  coveredTotalCents?: InputMaybe<Scalars['Int']>;
  coveredVatCents?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceIncluded?: InputMaybe<Scalars['Boolean']>;
  paidCoinsuranceSubtotalCents?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceTotalCents?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceVatCents?: InputMaybe<Scalars['Int']>;
  payoutAmountSubtotalCents?: InputMaybe<Scalars['Int']>;
  payoutAmountTotalCents?: InputMaybe<Scalars['Int']>;
  payoutAmountVatCents?: InputMaybe<Scalars['Int']>;
  rejectedAmountCentsComment?: InputMaybe<Scalars['String']>;
  rejectedAmountCentsMessageForMember?: InputMaybe<Scalars['String']>;
  rejectedAmountSubtotalCents?: InputMaybe<Scalars['Int']>;
  rejectedAmountTotalCents?: InputMaybe<Scalars['Int']>;
  rejectedAmountVatCents?: InputMaybe<Scalars['Int']>;
  serviceCoveragePayoutId: Scalars['Int'];
  subtotalAmountCents: Scalars['Int'];
  totalAmountCents: Scalars['Int'];
  vatAmountCents: Scalars['Int'];
  withholdTaxesCents?: InputMaybe<Scalars['Int']>;
};


export type MutationConfirmServicePayInAmountsArgs = {
  id: Scalars['Int'];
  method: PayInMethods;
};


export type MutationBundleServicePayInRequestsArgs = {
  serviceIds: Array<Scalars['Int']>;
};


export type MutationUploadDataForPayoutCfdiArgs = {
  description: CfdiDocumentDescriptionEnum;
  invoiceItemId: Scalars['ID'];
};


export type MutationValidatePayoutCfdiArgs = {
  pdfDocumentId: Scalars['ID'];
  serviceId: Scalars['ID'];
  xmlDocumentId: Scalars['ID'];
};


export type MutationMarkEvidenceAsWrongArgs = {
  evidenceId: Scalars['ID'];
};


export type MutationRequestEvidenceCorrectionArgs = {
  evidenceId: Scalars['ID'];
};


export type MutationAcceptEvidenceArgs = {
  evidenceId: Scalars['ID'];
};


export type MutationCreateMedicalFeesFromMedicalNoteProcedureArgs = {
  performedProcedureId: Scalars['ID'];
};


export type MutationUpdateClaimsEvidenceResponseAttachmentStateArgs = {
  attachmentId: Scalars['ID'];
  attachmentState: AttachmentState;
  formUuid: Scalars['UUID'];
  serviceId: Scalars['ID'];
};


export type MutationRequestClaimsEvidenceAttachmentUploadArgs = {
  attachmentId: Scalars['ID'];
  formUuid: Scalars['UUID'];
  mimeType: Scalars['String'];
  serviceId: Scalars['ID'];
};


export type MutationCreateTypeformEvidenceArgs = {
  reportType: TypeformReportTypes;
  serviceId: Scalars['Int'];
};


export type MutationCreateServiceAndTypeformEvidenceArgs = {
  diseaseCaseId?: InputMaybe<Scalars['Int']>;
  doctorFriendId?: InputMaybe<Scalars['Int']>;
  doctorId?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['Int'];
  providerId?: InputMaybe<Scalars['Int']>;
  relatedServiceId?: InputMaybe<Scalars['Int']>;
  reportType: TypeformReportTypes;
  scheduled?: InputMaybe<Scalars['Boolean']>;
  serviceType: ServiceType;
  specification?: InputMaybe<Scalars['String']>;
};


export type MutationCreateOutOfNetworkConsultMemberReimbursementForInAppEvidenceArgs = {
  memberId: Scalars['ID'];
  scheduledConsultId: Scalars['ID'];
};


export type MutationBundleServiceCoveragePayoutsArgs = {
  coveragePayoutsIds: Array<InputMaybe<Scalars['Int']>>;
  electronicVoucher: Scalars['Int'];
  invoice?: InputMaybe<Scalars['Int']>;
};


export type MutationBusinessTokenArgs = {
  email?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationCreateBusinessDraftArgs = {
  adminMail: Scalars['String'];
  adminNickname: Scalars['String'];
  adminTelephone: Scalars['String'];
  commonName: Scalars['String'];
  hasMedicalExclusions?: InputMaybe<Scalars['Boolean']>;
  hubspotDeal?: InputMaybe<Scalars['BigIntScalar']>;
  incentiveCode?: InputMaybe<Scalars['String']>;
  isCollective: Scalars['Boolean'];
  legalName: Scalars['String'];
  maternityAddon?: InputMaybe<Scalars['Boolean']>;
  maternityAddonWaitingPeriodMonths?: InputMaybe<MaternityWaitingPeriodMonths>;
  nationalityCountryCode: CountryCode;
  originalQuoteAmountCents: Scalars['Int'];
  personCategory: PersonCategory;
  pipedriveDeal?: InputMaybe<Scalars['Int']>;
  quoteBeneficiariesNumber: Scalars['Int'];
  subscriptionType?: InputMaybe<SubscriptionType>;
};


export type MutationUpdateBusinessInformationArgs = {
  commonName?: InputMaybe<Scalars['String']>;
  digitalSignatureSerialNumber?: InputMaybe<Scalars['String']>;
  hasMedicalExclusions?: InputMaybe<Scalars['Boolean']>;
  hubspotDeal?: InputMaybe<Scalars['BigIntScalar']>;
  id: Scalars['ID'];
  incorporationDate?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  nationalityCountryCode?: InputMaybe<CountryCode>;
  notionExceptionsUrl?: InputMaybe<Scalars['String']>;
  originalQuoteAmountCents?: InputMaybe<Scalars['Int']>;
  previousInsurance?: InputMaybe<Scalars['String']>;
  previousInsuranceValidSince?: InputMaybe<Scalars['String']>;
  previousInsuranceValidThrough?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<Scalars['String']>;
  quoteBeneficiariesNumber?: InputMaybe<Scalars['Int']>;
  tradeRegistrationId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateBusinessGlobalConfigurationArgs = {
  id: Scalars['ID'];
  isCollective?: InputMaybe<Scalars['Boolean']>;
  maternityAddon?: InputMaybe<Scalars['Boolean']>;
  maternityAddonWaitingPeriodMonths?: InputMaybe<MaternityWaitingPeriodMonths>;
  subscriptionType?: InputMaybe<SubscriptionType>;
};


export type MutationUpdateBusinessIncentiveCodeArgs = {
  id: Scalars['ID'];
  incentiveCode: Scalars['String'];
};


export type MutationUpdateBusinessArgs = {
  adminEmail?: InputMaybe<Scalars['String']>;
  adminTelephone?: InputMaybe<Scalars['String']>;
  administratorName?: InputMaybe<Scalars['String']>;
  businessBillingAddress?: InputMaybe<AddressInputType>;
  businessId: Scalars['ID'];
  commonName?: InputMaybe<Scalars['String']>;
  incorporationDate?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  tradeRegistrationId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateOrCreateBillingInfoArgs = {
  addressZipCode?: InputMaybe<Scalars['String']>;
  businessBillingAddress: AddressInputType;
  businessId: Scalars['ID'];
  legalName?: InputMaybe<Scalars['String']>;
  satUseType?: InputMaybe<SatInvoiceUseType>;
  taxNumber?: InputMaybe<Scalars['String']>;
  taxRegime?: InputMaybe<SatRegime>;
};


export type MutationUpdateBusinessReadyToMigrateDentalMembershipArgs = {
  businessId: Scalars['ID'];
  readyToMigrateDentalMembership: Scalars['Boolean'];
};


export type MutationUpdateBusinessPaymentInformationArgs = {
  businessId: Scalars['ID'];
  payoutClabe: Scalars['String'];
  subscriptionType: SubscriptionType;
};


export type MutationUpdateOrCreateBusinessPayoutClabeArgs = {
  businessId: Scalars['ID'];
  payoutClabe: Scalars['String'];
};


export type MutationUpdateOrCreateBusinessOwnerArgs = {
  businessId: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationRetriggerBusinessChecksArgs = {
  businessId: Scalars['ID'];
};


export type MutationRequestBusinessDocumentUploadDataArgs = {
  documentCategory: DocumentCategory;
  id: Scalars['Int'];
  mimeType: Scalars['String'];
};


export type MutationGetBusinessS3UploadDataArgs = {
  category: BusinessDocuments;
  id?: InputMaybe<Scalars['ID']>;
  mimeType: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationCreateBusinessDocumentArgs = {
  category: BusinessDocuments;
  id?: InputMaybe<Scalars['ID']>;
  mimeType: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationApproveBusinessDocumentCheckArgs = {
  businessId: Scalars['ID'];
  documentCategory: DocumentCategory;
};


export type MutationToggleJustifiableAbsenceForBusinessDocumentCheckArgs = {
  businessCheckType: BusinessCheckType;
  businessId: Scalars['ID'];
};


export type MutationSetAbsenceJustificationForBusinessDocumentCheckArgs = {
  absenceJustification: Scalars['String'];
  businessCheckType: BusinessCheckType;
  businessId: Scalars['ID'];
};


export type MutationTransitionBusinessStateArgs = {
  businessId: Scalars['ID'];
};


export type MutationHandleCorrectingInfoBusinessStateTransitionsArgs = {
  businessId: Scalars['ID'];
};


export type MutationHandleDocumentsReviewCompleteBusinessStateTransitionArgs = {
  businessId: Scalars['ID'];
};


export type MutationHandleContractSentBusinessStateTransitionArgs = {
  businessId: Scalars['ID'];
};


export type MutationHandleContractSignedBusinessStateTransitionArgs = {
  businessId: Scalars['ID'];
};


export type MutationHandleApprovedBusinessStateTransitionArgs = {
  businessId: Scalars['ID'];
};


export type MutationHandleRevokedBusinessStateTransitionArgs = {
  businessId: Scalars['ID'];
};


export type MutationContractUpdateStateDateArgs = {
  businessId: Scalars['ID'];
  contractSentAt?: InputMaybe<Scalars['DateTime']>;
  contractSignedAt?: InputMaybe<Scalars['DateTime']>;
};


export type MutationCreateBusinessStakeholderArgs = {
  birthCountry?: InputMaybe<Scalars['String']>;
  birthCountryCode?: InputMaybe<CountryCode>;
  businessControl?: InputMaybe<Scalars['Boolean']>;
  businessId: Scalars['ID'];
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  extraRoleProofDocNeeded?: InputMaybe<Scalars['Boolean']>;
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  legalRepresentative?: InputMaybe<Scalars['Boolean']>;
  nationalityCountryCode?: InputMaybe<CountryCode>;
  secondLastName?: InputMaybe<Scalars['String']>;
  shareholder?: InputMaybe<Scalars['Boolean']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateBusinessStakeholderArgs = {
  birthCountry?: InputMaybe<Scalars['String']>;
  birthCountryCode?: InputMaybe<CountryCode>;
  businessControl?: InputMaybe<Scalars['Boolean']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  extraRoleProofDocNeeded?: InputMaybe<Scalars['Boolean']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  legalRepresentative?: InputMaybe<Scalars['Boolean']>;
  nationalityCountryCode?: InputMaybe<CountryCode>;
  secondLastName?: InputMaybe<Scalars['String']>;
  shareholder?: InputMaybe<Scalars['Boolean']>;
  stakeholderState?: InputMaybe<StakeHolderState>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateLegalRepresentativeArgs = {
  birthCountry?: InputMaybe<Scalars['String']>;
  birthCountryCode?: InputMaybe<CountryCode>;
  curpAbsenceJustification?: InputMaybe<Scalars['String']>;
  curpIncludedInIdDoc?: InputMaybe<Scalars['Boolean']>;
  curpJustifiableAbsence?: InputMaybe<Scalars['Boolean']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  extraRoleProofDocNeeded?: InputMaybe<Scalars['Boolean']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  foreignerDocProofNeeded?: InputMaybe<Scalars['Boolean']>;
  nationalityCountryCode?: InputMaybe<CountryCode>;
  secondLastName?: InputMaybe<Scalars['String']>;
  stakeholderId: Scalars['ID'];
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationRetriggerBusinessStakeholderChecksArgs = {
  businessStakeholderId: Scalars['ID'];
};


export type MutationRequestBusinessStakeholderDocumentUploadDataArgs = {
  documentCategory: DocumentCategory;
  id: Scalars['Int'];
  mimeType: Scalars['String'];
};


export type MutationGetBusinessStakeholderS3UploadDataArgs = {
  category: StakeholderDocuments;
  id: Scalars['ID'];
  mimeType: Scalars['String'];
};


export type MutationCreateBusinessStakeholderDocumentArgs = {
  category: StakeholderDocuments;
  id: Scalars['ID'];
  mimeType: Scalars['String'];
};


export type MutationCreateBusinessEmployeeArgs = {
  beneficiaryCategory?: InputMaybe<BusinessBeneficiaryCategory>;
  businessGroupConfigId: Scalars['ID'];
  businessId?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  employeeId?: InputMaybe<Scalars['ID']>;
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  invitationDate?: InputMaybe<Scalars['Date']>;
  secondLastName?: InputMaybe<Scalars['String']>;
};


export type MutationCreateApplicationsForBusinessBeneficiaryArgs = {
  acceptAt?: InputMaybe<Scalars['DateTime']>;
  beneficiaryCategory?: InputMaybe<BusinessBeneficiaryCategory>;
  birthCountry?: InputMaybe<Scalars['String']>;
  businessBeneficiaryEmployeeId?: InputMaybe<Scalars['ID']>;
  businessGroupConfigId?: InputMaybe<Scalars['ID']>;
  businessId?: InputMaybe<Scalars['ID']>;
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth: Scalars['String'];
  email: Scalars['String'];
  familyMembersList?: InputMaybe<Array<BusinessBeneficiaryFamilyMemberInputType>>;
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  gender: Scalars['String'];
  occupation: Scalars['String'];
  residenceAddress: AddressInputType;
  rfc: Scalars['String'];
  secondLastName?: InputMaybe<Scalars['String']>;
};


export type MutationCreateBusinessBeneficiaryArgs = {
  acceptAt?: InputMaybe<Scalars['DateTime']>;
  beneficiaryCategory?: InputMaybe<BusinessBeneficiaryCategory>;
  birthCountry?: InputMaybe<Scalars['String']>;
  businessBeneficiaryEmployeeId?: InputMaybe<Scalars['ID']>;
  businessGroupConfigId?: InputMaybe<Scalars['ID']>;
  businessId: Scalars['ID'];
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth: Scalars['String'];
  email: Scalars['String'];
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  gender: Scalars['String'];
  occupation: Scalars['String'];
  residenceAddress: AddressInputType;
  rfc: Scalars['String'];
  secondLastName?: InputMaybe<Scalars['String']>;
};


export type MutationCreateBusinessEmployeesArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  input: Array<CreateBusinessEmployeesInputType>;
};


export type MutationCreateCollectiveBusinessBeneficiariesArgs = {
  businessId: Scalars['ID'];
  collectiveBusinessBeneficiariesInput: Array<CollectiveBusinessBeneficiariesInputType>;
};


export type MutationCreateCollectiveBusinessBeneficiaryRelativesArgs = {
  input: Array<CollectiveBusinessBeneficiariesInputType>;
};


export type MutationSendBeneficiariesDataToOpsArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  input: Array<CollectiveBusinessBeneficiariesInputType>;
};


export type MutationEditBusinessEmployeeArgs = {
  businessGroupConfigId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  invitationDate?: InputMaybe<Scalars['Date']>;
  memberId?: InputMaybe<Scalars['ID']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  sendWelcomeEmail?: InputMaybe<Scalars['Boolean']>;
};


export type MutationDeleteBusinessEmployeeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationSendBusinessEmployeeWelcomeEmailArgs = {
  id: Scalars['ID'];
};


export type MutationSendBusinessAdminWelcomeEmailArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBusinessPaymentMethodArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  paymentGatewayFrontendSourceId?: InputMaybe<Scalars['String']>;
  paymentGatewayType: Scalars['String'];
  paymentMethodType: Scalars['String'];
};


export type MutationMakeBusinessPayinPaymentMethodPrimaryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBusinessPaymentMethodArgs = {
  id: Scalars['ID'];
};


export type MutationBusinessEarlyCutoffProcessArgs = {
  businessId: Scalars['ID'];
};


export type MutationCreateBusinessAdminArgs = {
  businessId: Scalars['ID'];
  email: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  role: BusinessAdminRole;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateBusinessAdminArgs = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  nickname?: InputMaybe<Scalars['String']>;
  role: BusinessAdminRole;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteBusinessAdminArgs = {
  id: Scalars['ID'];
};


export type MutationMakeBusinessAdminOwnerArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBusinessGroupsConfigArgs = {
  allowMultimember?: InputMaybe<Scalars['Boolean']>;
  businessId: Scalars['ID'];
  coinsuranceConfig?: InputMaybe<CoInsuranceConfigFactor>;
  default?: InputMaybe<Scalars['Boolean']>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  dentalMembershipAddon?: InputMaybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  insuredAmountCentsCoverage?: InputMaybe<InsuredAmountCents>;
  intlCoverageAddon?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateBusinessGroupsConfigArgs = {
  allowMultimember?: InputMaybe<Scalars['Boolean']>;
  coinsuranceConfig?: InputMaybe<CoInsuranceConfigFactor>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  dentalMembershipAddon?: InputMaybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  insuredAmountCentsCoverage?: InputMaybe<InsuredAmountCents>;
  intlCoverageAddon?: InputMaybe<Scalars['Boolean']>;
};


export type MutationAsyncExecuteChangesForActiveBusinessGroupsConfigArgs = {
  allowMultimember?: InputMaybe<Scalars['Boolean']>;
  businessGroupsConfigId: Scalars['ID'];
  cancelReason?: InputMaybe<CancelReason>;
  cancellationComment?: InputMaybe<Scalars['String']>;
  coinsuranceConfig?: InputMaybe<CoInsuranceConfigFactor>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  dentalMembershipAddon?: InputMaybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  insuredAmountCentsCoverage?: InputMaybe<InsuredAmountCents>;
  intlCoverageAddon?: InputMaybe<Scalars['Boolean']>;
};


export type MutationExecuteChangesForActiveBusinessGroupsConfigArgs = {
  allowMultimember?: InputMaybe<Scalars['Boolean']>;
  businessGroupsConfigId: Scalars['ID'];
  cancelReason?: InputMaybe<CancelReason>;
  cancellationComment?: InputMaybe<Scalars['String']>;
  coinsuranceConfig?: InputMaybe<CoInsuranceConfigFactor>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  dentalMembershipAddon?: InputMaybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  insuredAmountCentsCoverage?: InputMaybe<InsuredAmountCents>;
  intlCoverageAddon?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSendBusinessNotRegisteredBeneficiaryReminderEmailArgs = {
  beneficiaryIdList: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationUpdateBusinessPlansGeneralConfigProposalArgs = {
  id: Scalars['ID'];
  incentiveCode?: InputMaybe<Scalars['String']>;
  isCollective?: InputMaybe<Scalars['Boolean']>;
  maternityAddon?: InputMaybe<Scalars['Boolean']>;
  maternityAddonWaitingPeriodMonths?: InputMaybe<MaternityWaitingPeriodMonths>;
  subscriptionType: SubscriptionType;
};


export type MutationUpdateBusinessPlansGroupConfigProposalArgs = {
  allowMultimember?: InputMaybe<Scalars['Boolean']>;
  coinsuranceConfig: CoInsuranceConfigFactor;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  dentalMembershipAddon?: InputMaybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  insuredAmountCentsCoverage: InsuredAmountCents;
  intlCoverageAddon?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateBusinessBeneficiariesProposalsGroupArgs = {
  beneficiaryConfigProposalIds: Array<InputMaybe<Scalars['ID']>>;
  generalConfigProposalId: Scalars['ID'];
  groupConfigProposalId: Scalars['ID'];
};


export type MutationUpdateDefaultBusinessPlansGroupConfigProposalArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationCancelBusinessCollectiveHealthPlanArgs = {
  businessId: Scalars['ID'];
  comment: Scalars['String'];
  confirmText: Scalars['String'];
  reason: CancelReason;
};


export type MutationReactivateBusinessArgs = {
  businessId: Scalars['ID'];
  state?: InputMaybe<BusinessPreApprovedStatesType>;
  usePreviousState?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateCollectiveBusinessRelativeArgs = {
  acceptAt?: InputMaybe<Scalars['DateTime']>;
  businessId: Scalars['ID'];
  curp: Scalars['String'];
  employeeId: Scalars['ID'];
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  relationship: CollectiveRelationshipType;
  secondLastName?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCollectiveBusinessRelativeArgs = {
  acceptAt?: InputMaybe<Scalars['DateTime']>;
  employeeId: Scalars['ID'];
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  relationship: CollectiveRelationshipType;
  secondLastName?: InputMaybe<Scalars['String']>;
};


export type MutationCreateBundleBusinessRelativeArgs = {
  businessId: Scalars['ID'];
  employeeId: Scalars['ID'];
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  secondLastName?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateBundleBusinessRelativeArgs = {
  employeeId: Scalars['ID'];
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  relationship?: InputMaybe<CollectiveRelationshipType>;
  secondLastName?: InputMaybe<Scalars['String']>;
};


export type MutationAsyncExecuteMaternityAddonChangesForActiveBusinessArgs = {
  businessId: Scalars['ID'];
  cancelReason?: InputMaybe<CancelReason>;
  cancellationComment?: InputMaybe<Scalars['String']>;
  maternityAddon: Scalars['Boolean'];
  maternityAddonWaitingPeriodMonths?: InputMaybe<MaternityWaitingPeriodMonths>;
};


export type MutationExecuteMaternityAddonChangesForActiveBusinessArgs = {
  businessId: Scalars['ID'];
  cancelReason?: InputMaybe<CancelReason>;
  cancellationComment?: InputMaybe<Scalars['String']>;
  maternityAddon: Scalars['Boolean'];
  maternityAddonWaitingPeriodMonths?: InputMaybe<MaternityWaitingPeriodMonths>;
};


export type MutationCancelActiveHealthPlansForReissueArgs = {
  businessId: Scalars['ID'];
  cancellationComment: Scalars['String'];
  proposalId: Scalars['ID'];
};


export type MutationCreateProposalToChangeBusinessSubscriptionTypeArgs = {
  businessId: Scalars['ID'];
  subscriptionType: SubscriptionType;
};


export type MutationActivateConfigurationProposalArgs = {
  businessId: Scalars['ID'];
  proposalId: Scalars['ID'];
};


export type MutationCreateHealthPlanApplicationsFromProposalAndSubmitThemArgs = {
  businessId: Scalars['ID'];
  proposalId: Scalars['ID'];
};


export type MutationMoveBusinessBeneficiaryToBundleArgs = {
  bundleBeneficiaryId: Scalars['ID'];
  collectiveBeneficiaryId: Scalars['ID'];
  heightCm: Scalars['Int'];
  relativesInput?: InputMaybe<Array<BusinessRelativeToBundleInput>>;
  weightKg: Scalars['Int'];
};


export type MutationUpdateBusinessBillingDayArgs = {
  billingDay: Scalars['Int'];
  businessId: Scalars['ID'];
};


export type MutationDeactivateBusinessGroupConfigArgs = {
  businessGroupConfigId: Scalars['ID'];
};


export type MutationMigrateYearlyBusinessToDentalMembershipArgs = {
  businessId: Scalars['ID'];
};


export type MutationAcceptBusinessProposalArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBusinessMaternityReissueProposalArgs = {
  businessId: Scalars['ID'];
  maternityAddon: Scalars['Boolean'];
  maternityAddonWaitingPeriodMonths?: InputMaybe<MaternityWaitingPeriodMonths>;
  requestedBy?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateBusinessGroupConfigReissueProposalArgs = {
  beneficiariesToExcludeIds?: InputMaybe<Array<Scalars['ID']>>;
  businessGroupConfigId: Scalars['ID'];
  coinsuranceConfig: CoInsuranceConfigFactor;
  dentalAddon: Scalars['Boolean'];
  dentalMembershipAddon: Scalars['Boolean'];
  extendedHospitalsCoverage: Scalars['Boolean'];
  insuredAmountCents: InsuredAmountCents;
  intlCoverageAddon: Scalars['Boolean'];
  requestedBy?: InputMaybe<Scalars['ID']>;
};


export type MutationCancelBusinessReissueProposalBeneficiariesArgs = {
  businessProposalId: Scalars['ID'];
  comment: Scalars['String'];
};


export type MutationApplyBusinessMaternityReissueProposalChangesArgs = {
  businessProposalId: Scalars['ID'];
};


export type MutationApplyBusinessGroupConfigReissueProposalChangesArgs = {
  groupConfigProposalId: Scalars['ID'];
};


export type MutationCreateBusinessReissueProposalApplicationArgs = {
  businessProposalId: Scalars['ID'];
};


export type MutationRejectBusinessReissueProposalArgs = {
  businessProposalId: Scalars['ID'];
};


export type MutationApproveBusinessProposalApplicationItemsArgs = {
  businessProposalId: Scalars['ID'];
};


export type MutationCreateBusinessBeneficiariesReportDocumentArgs = {
  businessId: Scalars['ID'];
};


export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type MutationRevokeTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type CreateDoctorLead = {
  __typename?: 'CreateDoctorLead';
  doctorLead: DoctorLeadType;
  uploadData?: Maybe<UploadPublicDataType>;
  document?: Maybe<PublicDocumentType>;
};

export type UploadPublicDataType = {
  __typename?: 'UploadPublicDataType';
  url?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSONString']>;
};

export type PublicDocumentType = {
  __typename?: 'PublicDocumentType';
  id: Scalars['ID'];
  mimeType?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum DoctorDocuments {
  DoctorCurriculumVitae = 'DOCTOR_CURRICULUM_VITAE',
  DoctorLiabilityInsurance = 'DOCTOR_LIABILITY_INSURANCE',
  DoctorPhoto = 'DOCTOR_PHOTO',
  TaxCertificate = 'TAX_CERTIFICATE'
}

export type RequestDoctorLeadCv = {
  __typename?: 'RequestDoctorLeadCv';
  ok: Scalars['Boolean'];
};

export type RequestDoctorLeadUserCreation = {
  __typename?: 'RequestDoctorLeadUserCreation';
  ok: Scalars['Boolean'];
};

export type UpdateDoctorLead = {
  __typename?: 'UpdateDoctorLead';
  doctorLead: DoctorLeadType;
};

export type CreateUserFromLead = {
  __typename?: 'CreateUserFromLead';
  user: UserType;
};

export type UploadPublicDoctorLeadDocument = {
  __typename?: 'UploadPublicDoctorLeadDocument';
  uploadData: UploadPublicDataType;
  document: PublicDocumentType;
};

export type ConsumeAuthTokenAndPullContactDataFromWebQuotation = {
  __typename?: 'ConsumeAuthTokenAndPullContactDataFromWebQuotation';
  leadInformation?: Maybe<LeadInformationType>;
  ok: Scalars['Boolean'];
};

export enum AuthorizationCodeCapability {
  B2CQuote = 'B2C_QUOTE'
}

export type CreateMedicalHistoryForMember = {
  __typename?: 'CreateMedicalHistoryForMember';
  medicalHistory: MedicalHistoryType;
};

export type AllergyInputType = {
  allergen: Scalars['String'];
  reaction?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Severity>;
};

export type FamilyPathologyInputType = {
  pathology: Scalars['String'];
  cieNotes?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  relative?: InputMaybe<Relative>;
};

export type HospitalizationInputType = {
  date: Scalars['Date'];
  reason?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};

export type MalformationInputType = {
  name: Scalars['String'];
  treatment?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};

export type MedicineInputType = {
  drugKey: Scalars['String'];
  ailment: Scalars['String'];
};

export type MentalHealthDiagnosisInputType = {
  diagnosis: Scalars['String'];
  treatment?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};

export type NonPathologicalHistoryInputType = {
  lifestyleNotes?: InputMaybe<Scalars['String']>;
  sleepHours?: InputMaybe<Scalars['Int']>;
  sleepNotes?: InputMaybe<Scalars['String']>;
  nutritionNotes?: InputMaybe<Scalars['String']>;
  hasExercise?: InputMaybe<Scalars['Boolean']>;
  exerciseActivities?: InputMaybe<Scalars['String']>;
  exerciseWeeklyFrequency?: InputMaybe<Scalars['Int']>;
  exerciseNotes?: InputMaybe<Scalars['String']>;
  smokingConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  smokingCigarettes?: InputMaybe<Scalars['Int']>;
  smokingYears?: InputMaybe<Scalars['Int']>;
  smokingSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  smokingNotes?: InputMaybe<Scalars['String']>;
  alcoholConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  alcoholFrequency?: InputMaybe<Scalars['String']>;
  alcoholConsumptionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  alcoholSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  alcoholNotes?: InputMaybe<Scalars['String']>;
  drugsConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  drugsType?: InputMaybe<Drug>;
  drugsName?: InputMaybe<Scalars['String']>;
  drugsSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  drugsNotes?: InputMaybe<Scalars['String']>;
};

export type ObstetricGynecologicalHistoryInputType = {
  hasBirthControl?: InputMaybe<Scalars['Boolean']>;
  birthControlName?: InputMaybe<BirthControlName>;
  birthControlTimeUsed?: InputMaybe<Scalars['String']>;
  birthControlNotes?: InputMaybe<Scalars['String']>;
  hasMenstrualCycle?: InputMaybe<Scalars['Boolean']>;
  isMenstrualCycleRegular?: InputMaybe<Scalars['Boolean']>;
  hasDysmenorrhea?: InputMaybe<Scalars['Boolean']>;
  menstrualCycleNotes?: InputMaybe<Scalars['String']>;
  hasBeenPregnant?: InputMaybe<Scalars['Boolean']>;
  numPregnancies?: InputMaybe<Scalars['Int']>;
  numDeliveries?: InputMaybe<Scalars['Int']>;
  numAbortions?: InputMaybe<Scalars['Int']>;
  numStillBirths?: InputMaybe<Scalars['Int']>;
  numCesareans?: InputMaybe<Scalars['Int']>;
  numLiveBirths?: InputMaybe<Scalars['Int']>;
  numDeaths?: InputMaybe<Scalars['Int']>;
  gynecologicalExamsNotes?: InputMaybe<Scalars['String']>;
};

export enum BirthControlName {
  Intrauterine = 'INTRAUTERINE',
  Injectable = 'INJECTABLE',
  BarrierMethod = 'BARRIER_METHOD',
  NaturalMethod = 'NATURAL_METHOD',
  SurgicalMethod = 'SURGICAL_METHOD',
  Oral = 'ORAL',
  Subdermal = 'SUBDERMAL'
}

export type ParentPerinatalHistoryInputType = {
  motherPregnancies?: InputMaybe<Scalars['Int']>;
  motherAbortions?: InputMaybe<Scalars['Int']>;
  motherDeliveries?: InputMaybe<Scalars['Int']>;
  motherCaesareanSections?: InputMaybe<Scalars['Int']>;
  motherPregnancyAge?: InputMaybe<Scalars['Int']>;
  motherHeight?: InputMaybe<Scalars['Float']>;
  fatherHeight?: InputMaybe<Scalars['Float']>;
  expectedHeight?: InputMaybe<Scalars['Int']>;
  pregnancyEvolution?: InputMaybe<PregnancyEvolutionType>;
  obstetrician?: InputMaybe<Scalars['String']>;
  deliveryType?: InputMaybe<DeliveryType>;
  notes?: InputMaybe<Scalars['String']>;
};

export enum PregnancyEvolutionType {
  Normal = 'NORMAL',
  Abnormal = 'ABNORMAL',
  HighRisk = 'HIGH_RISK'
}

export enum DeliveryType {
  Spontaneous = 'SPONTANEOUS',
  Conducted = 'CONDUCTED',
  Instrumented = 'INSTRUMENTED',
  IntrapartumCaesarean = 'INTRAPARTUM_CAESAREAN',
  ElectiveCaesarean = 'ELECTIVE_CAESAREAN'
}

export type PathologyInputType = {
  cieKey?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  diagnosisDate: Scalars['Date'];
  treatment?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  cieNotes?: InputMaybe<Scalars['String']>;
};

export type PerinatalHistoryInputType = {
  gestationalAgeWeeks?: InputMaybe<Scalars['Int']>;
  gestationalAgeDays?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Float']>;
  height?: InputMaybe<Scalars['Float']>;
  headCircumference?: InputMaybe<Scalars['Float']>;
  gestationalAgeClassification?: InputMaybe<GestationalAgeClassification>;
  gestationalWeightClassification?: InputMaybe<GestationalWeightClassification>;
  apgar1MinScore?: InputMaybe<Scalars['Int']>;
  apgar5MinScore?: InputMaybe<Scalars['Int']>;
  reanimationRequired?: InputMaybe<Scalars['Boolean']>;
  incubationRequired?: InputMaybe<Scalars['Boolean']>;
  other?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};

export enum GestationalAgeClassification {
  Rnpr = 'RNPR',
  Rnt = 'RNT',
  Rnpt = 'RNPT'
}

export enum GestationalWeightClassification {
  Peg = 'PEG',
  Aeg = 'AEG',
  Geg = 'GEG'
}

export type SurgeriesInputType = {
  name: Scalars['String'];
  cptKey?: InputMaybe<Scalars['String']>;
  date: Scalars['Date'];
  cptNotes?: InputMaybe<Scalars['String']>;
};

export type VaccineInputType = {
  name: Scalars['String'];
  date: Scalars['Date'];
  notes?: InputMaybe<Scalars['String']>;
};

export type UpdateMedicalHistoryForMember = {
  __typename?: 'UpdateMedicalHistoryForMember';
  medicalHistory: MedicalHistoryType;
};

export type AddMedicalHistoryAllergy = {
  __typename?: 'AddMedicalHistoryAllergy';
  allergy: AllergyType;
};

export type UpdateMedicalHistoryAllergy = {
  __typename?: 'UpdateMedicalHistoryAllergy';
  allergy: AllergyType;
};

export type DeleteMedicalHistoryAllergy = {
  __typename?: 'DeleteMedicalHistoryAllergy';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UploadMedicalHistoryExamDocument = {
  __typename?: 'UploadMedicalHistoryExamDocument';
  uploadData: UploadDataType;
};

export type UploadDataType = {
  __typename?: 'UploadDataType';
  url?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSONString']>;
};

export type UploadExamDocumentToMedicalNote = {
  __typename?: 'UploadExamDocumentToMedicalNote';
  uploadData: UploadDataType;
};

export type AssignMedicalHistoryExamToMedicalNote = {
  __typename?: 'AssignMedicalHistoryExamToMedicalNote';
  exam: ExamType;
};

export type DeleteMedicalHistoryExam = {
  __typename?: 'DeleteMedicalHistoryExam';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateMedicalHistoryExam = {
  __typename?: 'UpdateMedicalHistoryExam';
  exam: ExamType;
};

export type DoctorDeleteMedicalNoteExam = {
  __typename?: 'DoctorDeleteMedicalNoteExam';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddFamilyPathology = {
  __typename?: 'AddFamilyPathology';
  familyPathology: FamilyPathologyType;
};

export type UpdateFamilyPathology = {
  __typename?: 'UpdateFamilyPathology';
  familyPathology: FamilyPathologyType;
};

export type DeleteFamilyPathology = {
  __typename?: 'DeleteFamilyPathology';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddHospitalization = {
  __typename?: 'AddHospitalization';
  hospitalization: HospitalizationType;
};

export type UpdateHospitalization = {
  __typename?: 'UpdateHospitalization';
  hospitalization: HospitalizationType;
};

export type DeleteHospitalization = {
  __typename?: 'DeleteHospitalization';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddMalformation = {
  __typename?: 'AddMalformation';
  malformation: MalformationType;
};

export type UpdateMalformation = {
  __typename?: 'UpdateMalformation';
  malformation: MalformationType;
};

export type DeleteMalformation = {
  __typename?: 'DeleteMalformation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddMedicalHistoryMedicine = {
  __typename?: 'AddMedicalHistoryMedicine';
  medicine: MedicineType;
};

export type UpdateMedicalHistoryMedicine = {
  __typename?: 'UpdateMedicalHistoryMedicine';
  medicine: MedicineType;
};

export type DeleteMedicalHistoryMedicine = {
  __typename?: 'DeleteMedicalHistoryMedicine';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateNonPathologicalHistory = {
  __typename?: 'UpdateNonPathologicalHistory';
  nonPathologicalHistory: NonPathologicalHistoryType;
};

export type MemberUpdateNonPathologicalHistory = {
  __typename?: 'MemberUpdateNonPathologicalHistory';
  ok: Scalars['Boolean'];
};

export type UpdateObstetricGynecologicalHistory = {
  __typename?: 'UpdateObstetricGynecologicalHistory';
  obstetricGynecologicalHistory: ObstetricGynecologicalHistoryType;
};

export type AddPregnancyDelivery = {
  __typename?: 'AddPregnancyDelivery';
  pregnancyDelivery: PregnancyDeliveryType;
};

export type UpdatePregnancyDelivery = {
  __typename?: 'UpdatePregnancyDelivery';
  pregnancyDelivery: PregnancyDeliveryType;
};

export type DeletePregnancyDelivery = {
  __typename?: 'DeletePregnancyDelivery';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddGynecologicalExam = {
  __typename?: 'AddGynecologicalExam';
  gynecologicalExam: GynecologicalExamType;
};

export type UpdateGynecologicalExam = {
  __typename?: 'UpdateGynecologicalExam';
  gynecologicalExam: GynecologicalExamType;
};

export type DeleteGynecologicalExam = {
  __typename?: 'DeleteGynecologicalExam';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddPathology = {
  __typename?: 'AddPathology';
  pathology: PathologyType;
};

export type UpdatePathology = {
  __typename?: 'UpdatePathology';
  pathology: PathologyType;
};

export type DeletePathology = {
  __typename?: 'DeletePathology';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddPregnancy = {
  __typename?: 'AddPregnancy';
  pregnancy: PregnancyType;
};

export type UpdatePregnancy = {
  __typename?: 'UpdatePregnancy';
  pregnancy: PregnancyType;
};

export type DeletePregnancy = {
  __typename?: 'DeletePregnancy';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddSurgery = {
  __typename?: 'AddSurgery';
  surgery: SurgeryType;
};

export type UpdateSurgery = {
  __typename?: 'UpdateSurgery';
  surgery: SurgeryType;
};

export type DeleteSurgery = {
  __typename?: 'DeleteSurgery';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddVaccine = {
  __typename?: 'AddVaccine';
  vaccine: VaccineType;
};

export type UpdateVaccine = {
  __typename?: 'UpdateVaccine';
  vaccine: VaccineType;
};

export type DeleteVaccine = {
  __typename?: 'DeleteVaccine';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateMedicalHistoryBasicInfo = {
  __typename?: 'UpdateMedicalHistoryBasicInfo';
  basicInfo: BasicInfoType;
};

export type AddMentalHealthDiagnosis = {
  __typename?: 'AddMentalHealthDiagnosis';
  mentalHealthDiagnosis: MentalHealthDiagnosisType;
};

export type UpdateMentalHealthDiagnosis = {
  __typename?: 'UpdateMentalHealthDiagnosis';
  mentalHealthDiagnosis: MentalHealthDiagnosisType;
};

export type DeleteMentalHealthDiagnosis = {
  __typename?: 'DeleteMentalHealthDiagnosis';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdatePerinatalHistory = {
  __typename?: 'UpdatePerinatalHistory';
  perinatalHistory: PerinatalHistoryType;
};

export type UpdateParentPerinatalHistory = {
  __typename?: 'UpdateParentPerinatalHistory';
  parentPerinatalHistory: ParentPerinatalHistoryType;
};

export type AddGeneralObservation = {
  __typename?: 'AddGeneralObservation';
  generalObservation: GeneralObservationType;
};

export type UpdateGeneralObservation = {
  __typename?: 'UpdateGeneralObservation';
  generalObservation: GeneralObservationType;
};

export type DeleteGeneralObservation = {
  __typename?: 'DeleteGeneralObservation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateServiceMedicalNote = {
  __typename?: 'UpdateServiceMedicalNote';
  medicalNote: MedicalNoteType;
};

export type CreateNewMedicalNote = {
  __typename?: 'CreateNewMedicalNote';
  medicalNote: MedicalNoteType;
};

export type CreateConsultMedicalNote = {
  __typename?: 'CreateConsultMedicalNote';
  medicalNote: MedicalNoteType;
};

export type CreateScheduledConsultMedicalNote = {
  __typename?: 'CreateScheduledConsultMedicalNote';
  medicalNote: MedicalNoteType;
};

export type UpdateMedicalNote = {
  __typename?: 'UpdateMedicalNote';
  medicalNote: MedicalNoteType;
};

export type UpdateMedicalNoteIsAudited = {
  __typename?: 'UpdateMedicalNoteIsAudited';
  medicalNote: MedicalNoteType;
};

export type DeleteMedicalNote = {
  __typename?: 'DeleteMedicalNote';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CancelMedicalNote = {
  __typename?: 'CancelMedicalNote';
  medicalNote: MedicalNoteType;
};

export type CompleteMedicalNote = {
  __typename?: 'CompleteMedicalNote';
  medicalNote: MedicalNoteType;
};

export type PrescriptionItemInputType = {
  group: PrescriptionItemGroup;
  prescriptionItemDetails?: InputMaybe<PrescriptionItemDetailsInputType>;
  description: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  checked?: InputMaybe<Scalars['String']>;
};

export type PrescriptionItemDetailsInputType = {
  time?: InputMaybe<Scalars['String']>;
  dose?: InputMaybe<Scalars['String']>;
  presentation?: InputMaybe<Scalars['String']>;
  administration?: InputMaybe<Scalars['String']>;
  preparation?: InputMaybe<Scalars['String']>;
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  multipleFilling?: InputMaybe<Scalars['Boolean']>;
};

export type AddPrescriptionToMedicalNote = {
  __typename?: 'AddPrescriptionToMedicalNote';
  prescription: PrescriptionType;
};

export type ReclassifyMedicalNote = {
  __typename?: 'ReclassifyMedicalNote';
  medicalNote: MedicalNoteType;
};

export type UpdateEvaluation = {
  __typename?: 'UpdateEvaluation';
  evaluation: EvaluationType;
};

export type AddInterrogation = {
  __typename?: 'AddInterrogation';
  interrogation: InterrogationType;
};

export type UpdateInterrogation = {
  __typename?: 'UpdateInterrogation';
  interrogation: InterrogationType;
};

export type DeleteInterrogation = {
  __typename?: 'DeleteInterrogation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddExploration = {
  __typename?: 'AddExploration';
  exploration: ExplorationType;
};

export type UpdateExploration = {
  __typename?: 'UpdateExploration';
  exploration: ExplorationType;
};

export type DeleteExploration = {
  __typename?: 'DeleteExploration';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddProcedure = {
  __typename?: 'AddProcedure';
  procedure: ProcedureType;
};

export type UpdateProcedure = {
  __typename?: 'UpdateProcedure';
  procedure: ProcedureType;
};

export type UploadProcedureDocument = {
  __typename?: 'UploadProcedureDocument';
  uploadData: UploadDataType;
};

export type DeleteProcedure = {
  __typename?: 'DeleteProcedure';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddAddendum = {
  __typename?: 'AddAddendum';
  addendum: AddendumType;
};

export type UpdateAddendum = {
  __typename?: 'UpdateAddendum';
  addendum: AddendumType;
};

export type UploadAddendumDocument = {
  __typename?: 'UploadAddendumDocument';
  uploadData: UploadDataType;
};

export type DeleteAddendum = {
  __typename?: 'DeleteAddendum';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdatePhysicalExam = {
  __typename?: 'UpdatePhysicalExam';
  physicalExam: PhysicalExamType;
};

export type UpdateNoteBasicInfo = {
  __typename?: 'UpdateNoteBasicInfo';
  noteBasicInfo: NoteBasicInfoType;
};

export type UpdateDischargeInfo = {
  __typename?: 'UpdateDischargeInfo';
  dischargeInfo: DischargeInfoType;
};

export type UpdatePhysicalActivity = {
  __typename?: 'UpdatePhysicalActivity';
  physicalActivity: PhysicalActivityType;
};

export type UpdateObstetricGynecologicalInfo = {
  __typename?: 'UpdateObstetricGynecologicalInfo';
  obstetricGynecologicalInfo: ObstetricGynecologicalInfoType;
};

export type EndMedicalProgram = {
  __typename?: 'EndMedicalProgram';
  medicalProgram: MedicalProgramType;
};

export type CreateMaternityProgram = {
  __typename?: 'CreateMaternityProgram';
  maternityProgram: MaternityProgramType;
};

export type UpdateMaternityProgram = {
  __typename?: 'UpdateMaternityProgram';
  maternityProgram: MaternityProgramType;
};

export type CreateBariatricsProgram = {
  __typename?: 'CreateBariatricsProgram';
  bariatricsProgram: BariatricsProgramType;
};

export type UpdateBariatricsProgram = {
  __typename?: 'UpdateBariatricsProgram';
  bariatricsProgram: BariatricsProgramType;
};

export type CreateProcedureCategory = {
  __typename?: 'CreateProcedureCategory';
  procedureCategory: ProcedureCategoryType;
};

export type UpdateProcedureCategory = {
  __typename?: 'UpdateProcedureCategory';
  procedureCategory: ProcedureCategoryType;
};

export type AddPerformedProcedure = {
  __typename?: 'AddPerformedProcedure';
  performedProcedure: PerformedProcedureType;
};

export type UpdatePerformedProcedure = {
  __typename?: 'UpdatePerformedProcedure';
  performedProcedure: PerformedProcedureType;
};

export type UploadPerformedProcedureEvidence = {
  __typename?: 'UploadPerformedProcedureEvidence';
  uploadData: UploadDataType;
};

export type DeletePerformedProcedureEvidence = {
  __typename?: 'DeletePerformedProcedureEvidence';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeletePerformedProcedure = {
  __typename?: 'DeletePerformedProcedure';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddScheduledProcedure = {
  __typename?: 'AddScheduledProcedure';
  scheduledProcedure: ScheduledProcedureType;
};

export type UpdateScheduledProcedure = {
  __typename?: 'UpdateScheduledProcedure';
  scheduledProcedure: ScheduledProcedureType;
};

export type UploadScheduledProcedureEvidence = {
  __typename?: 'UploadScheduledProcedureEvidence';
  uploadData: UploadDataType;
};

export type DeleteScheduledProcedureEvidence = {
  __typename?: 'DeleteScheduledProcedureEvidence';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteScheduledProcedure = {
  __typename?: 'DeleteScheduledProcedure';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateMedicalNotePrescription = {
  __typename?: 'CreateMedicalNotePrescription';
  prescription: PrescriptionType;
};

export type UpdateMedicalNotePrescription = {
  __typename?: 'UpdateMedicalNotePrescription';
  prescription: PrescriptionType;
};

export type DeleteMedicalNotePrescription = {
  __typename?: 'DeleteMedicalNotePrescription';
  ok: Scalars['Boolean'];
};

export type CreatePrescriptionItem = {
  __typename?: 'CreatePrescriptionItem';
  prescriptionItem: PrescriptionItemType;
};

export type UpdatePrescriptionItem = {
  __typename?: 'UpdatePrescriptionItem';
  prescriptionItem: PrescriptionItemType;
};

export type DeletePrescriptionItem = {
  __typename?: 'DeletePrescriptionItem';
  ok: Scalars['Boolean'];
};

export type SignMedicalNotePrescription = {
  __typename?: 'SignMedicalNotePrescription';
  prescription: PrescriptionType;
};

export type CreateExclusion = {
  __typename?: 'CreateExclusion';
  exclusion: ExclusionsCatalogType;
};

export type ExclusionsCatalogInputType = {
  name: Scalars['String'];
  otherNames?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  icds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  blacklist?: InputMaybe<Scalars['Boolean']>;
  medicalSpecializationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UpdateExclusion = {
  __typename?: 'UpdateExclusion';
  exclusion: ExclusionsCatalogType;
};

export type CreateCaseEvent = {
  __typename?: 'CreateCaseEvent';
  caseEvent: CaseEventType;
};

export type DeleteCaseEvent = {
  __typename?: 'DeleteCaseEvent';
  ok: Scalars['Boolean'];
};

export type GroupServicesOnCaseEvent = {
  __typename?: 'GroupServicesOnCaseEvent';
  ok: Scalars['Boolean'];
  caseEvent: CaseEventType;
};

export type AssignUserToCaseEvent = {
  __typename?: 'AssignUserToCaseEvent';
  caseEvent: CaseEventType;
};

export type UpdateCaseEvent = {
  __typename?: 'UpdateCaseEvent';
  ok: Scalars['Boolean'];
  caseEvent: CaseEventType;
};

export type RemoveServicesFromCaseEvent = {
  __typename?: 'RemoveServicesFromCaseEvent';
  ok: Scalars['Boolean'];
};

export type AddEventComment = {
  __typename?: 'AddEventComment';
  comment?: Maybe<EventCommentType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddCaseEventSummary = {
  __typename?: 'AddCaseEventSummary';
  summary?: Maybe<CaseEventSummaryType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateEventQuote = {
  __typename?: 'CreateEventQuote';
  caseEventQuote: CaseEventQuoteType;
};

export type QuoteItemInputType = {
  id?: InputMaybe<Scalars['ID']>;
  baseCents: Scalars['Int'];
  percentage: Scalars['Float'];
  subtotalCents: Scalars['Int'];
  vatCents: Scalars['Int'];
  cptCode: Scalars['String'];
  providerName?: InputMaybe<Scalars['String']>;
  friendlyDescription?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  totalCents?: InputMaybe<Scalars['Int']>;
  providerClass?: InputMaybe<CptProviderClassification>;
  referenceSource?: InputMaybe<CptPriceSource>;
};

export type DeleteEventQuote = {
  __typename?: 'DeleteEventQuote';
  ok: Scalars['Boolean'];
};

export type BuildEventQuoteLetter = {
  __typename?: 'BuildEventQuoteLetter';
  caseEventQuote: CaseEventQuoteType;
};

export enum EventQuoteLetters {
  DoctorsOfficeProcedureApprovalLetter = 'DOCTORS_OFFICE_PROCEDURE_APPROVAL_LETTER',
  ProvisionalDoctorsEventLetter = 'PROVISIONAL_DOCTORS_EVENT_LETTER',
  ProvisionalDoctorsOfficeProcedureLetter = 'PROVISIONAL_DOCTORS_OFFICE_PROCEDURE_LETTER',
  ProvisionalHospitalEventLetter = 'PROVISIONAL_HOSPITAL_EVENT_LETTER',
  ProvisionalMemberEventLetter = 'PROVISIONAL_MEMBER_EVENT_LETTER',
  ProvisionalMemberUrgencyLetter = 'PROVISIONAL_MEMBER_URGENCY_LETTER',
  ProvisionalMembersOfficeProcedureLetter = 'PROVISIONAL_MEMBERS_OFFICE_PROCEDURE_LETTER',
  ProvisionalUrgencyHospitalLetter = 'PROVISIONAL_URGENCY_HOSPITAL_LETTER'
}

export type UpdateAdministrativeRulingTasks = {
  __typename?: 'UpdateAdministrativeRulingTasks';
  ok: Scalars['Boolean'];
  caseEvent: CaseEventType;
};

export type UpdateMedicalPreRulingTasks = {
  __typename?: 'UpdateMedicalPreRulingTasks';
  ok: Scalars['Boolean'];
  caseEvent: CaseEventType;
};

export type UpdateQuotingAndSchedulingTasks = {
  __typename?: 'UpdateQuotingAndSchedulingTasks';
  ok: Scalars['Boolean'];
  caseEvent: CaseEventType;
};

export type UpdateMedicalProcedureOccurrenceTasks = {
  __typename?: 'UpdateMedicalProcedureOccurrenceTasks';
  ok: Scalars['Boolean'];
  caseEvent: CaseEventType;
};

export type UpdateDischargeTasks = {
  __typename?: 'UpdateDischargeTasks';
  ok: Scalars['Boolean'];
  caseEvent: CaseEventType;
};

export type UpdateCaseEventPaymentStatusTasks = {
  __typename?: 'UpdateCaseEventPaymentStatusTasks';
  ok: Scalars['Boolean'];
  caseEvent: CaseEventType;
};

export type UpdateEventQuote = {
  __typename?: 'UpdateEventQuote';
  caseEventQuote: CaseEventQuoteType;
};

export type DeleteEventQuoteItems = {
  __typename?: 'DeleteEventQuoteItems';
  caseEventQuote: CaseEventQuoteType;
};

export type SendEventQuoteLetter = {
  __typename?: 'SendEventQuoteLetter';
  letter: CaseEventQuoteLetterType;
};

export type CreateCptPriceReference = {
  __typename?: 'CreateCptPriceReference';
  cptPrice: CptPriceReferenceType;
};

export type UpdateCptPriceReference = {
  __typename?: 'UpdateCptPriceReference';
  cptPrice: CptPriceReferenceType;
};

export type PriceReferencePriceInputType = {
  baseAmountCents?: InputMaybe<Scalars['Int']>;
  providerClass?: InputMaybe<CptProviderClassification>;
  referenceSource?: InputMaybe<CptPriceSource>;
  cptCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

export type UploadDataForEventBudget = {
  __typename?: 'UploadDataForEventBudget';
  uploadData: UploadDataType;
};

export type DeleteEventBudget = {
  __typename?: 'DeleteEventBudget';
  ok: Scalars['Boolean'];
};

export type BuildDefaultEventQuoteLetters = {
  __typename?: 'BuildDefaultEventQuoteLetters';
  ok: Scalars['Boolean'];
  caseEventQuote: CaseEventQuoteType;
};

export type ChangeQuoteLetterSentState = {
  __typename?: 'ChangeQuoteLetterSentState';
  letter: CaseEventQuoteLetterType;
};

export type DeleteQuoteLetter = {
  __typename?: 'DeleteQuoteLetter';
  ok: Scalars['Boolean'];
};

export type ValidateServiceCoveragePayoutElectronicVoucherAmounts = {
  __typename?: 'ValidateServiceCoveragePayoutElectronicVoucherAmounts';
  ok: Scalars['Boolean'];
  warningMessages: Array<Scalars['String']>;
};

export type SetDocumentXmlElectronicVoucher = {
  __typename?: 'SetDocumentXmlElectronicVoucher';
  ok?: Maybe<Scalars['Boolean']>;
  payout?: Maybe<ServiceCoveragePayoutType>;
};

export type GetServiceCoveragePayoutDocumentXmlUploadData = {
  __typename?: 'GetServiceCoveragePayoutDocumentXmlUploadData';
  uploadData: UploadDataType;
};

export type CancelCaseEvent = {
  __typename?: 'CancelCaseEvent';
  caseEvent?: Maybe<CaseEventType>;
};

export type PauseCaseEvent = {
  __typename?: 'PauseCaseEvent';
  caseEvent?: Maybe<CaseEventType>;
};

export type BuildDefaultCaseEventQuoteAndQuoteLetters = {
  __typename?: 'BuildDefaultCaseEventQuoteAndQuoteLetters';
  ok: Scalars['Boolean'];
  caseEvent: CaseEventType;
};

export type CreateCaseEventFromScheduledProcedure = {
  __typename?: 'CreateCaseEventFromScheduledProcedure';
  caseEvent: CaseEventType;
};

export type CreateFinalLetterData = {
  __typename?: 'CreateFinalLetterData';
  caseEvent: CaseEventType;
};

export type UpdateFinalLetterData = {
  __typename?: 'UpdateFinalLetterData';
  letterData: CaseEventFinalLetterDataType;
  created: Scalars['Boolean'];
};

export type ChangeFinalLetterSentState = {
  __typename?: 'ChangeFinalLetterSentState';
  letter: CaseEventFinalLetterType;
};

export type DeleteFinalLetter = {
  __typename?: 'DeleteFinalLetter';
  ok: Scalars['Boolean'];
};

export type SendFinalLetter = {
  __typename?: 'SendFinalLetter';
  letter: CaseEventFinalLetterType;
};

export enum EventFinalLetters {
  HospitalFinalLetter = 'HOSPITAL_FINAL_LETTER',
  DoctorFinalLetter = 'DOCTOR_FINAL_LETTER',
  MemberFinalLetter = 'MEMBER_FINAL_LETTER'
}

export type CreateCaseEventChannel = {
  __typename?: 'CreateCaseEventChannel';
  caseEvent: CaseEventType;
};

export type InviteUserToEventChannel = {
  __typename?: 'InviteUserToEventChannel';
  caseEvent: CaseEventType;
};

export type NotifyToCaseEventChannel = {
  __typename?: 'NotifyToCaseEventChannel';
  ok: Scalars['Boolean'];
};

export type CreateCpt = {
  __typename?: 'CreateCpt';
  cpt: CptCatalogType;
};

export type CptCatalogInputType = {
  cptCode: Scalars['String'];
  description: Scalars['String'];
};

export type UpdateCpt = {
  __typename?: 'UpdateCpt';
  cpt: CptCatalogType;
};

export type InitUserHybridSubscriptionContext = {
  __typename?: 'InitUserHybridSubscriptionContext';
  user: UserType;
};

export type AddressInputType = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  municipality?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  stateName?: InputMaybe<Scalars['String']>;
  zipcode: Scalars['String'];
  references?: InputMaybe<Scalars['String']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  telephone?: InputMaybe<Scalars['String']>;
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  huliClinicId?: InputMaybe<Scalars['Int']>;
  temporaryVirtualOffice?: InputMaybe<Scalars['Boolean']>;
  appointmentTelephone?: InputMaybe<Scalars['String']>;
  appointmentUrl?: InputMaybe<Scalars['String']>;
};

export type EducationAnswersInputType = {
  insuranceExperience: Scalars['String'];
  insuranceKnowledge: Scalars['String'];
  sofiaKnowledge: Scalars['String'];
};

export type SetUpUserHealthPlanApplication = {
  __typename?: 'SetUpUserHealthPlanApplication';
  user: UserType;
};

export enum SubscriptionState {
  AccountType = 'ACCOUNT_TYPE',
  MemberList = 'MEMBER_LIST',
  PriceMembership = 'PRICE_MEMBERSHIP',
  Documents = 'DOCUMENTS',
  Payment = 'PAYMENT',
  Approval = 'APPROVAL',
  Knowledge = 'KNOWLEDGE',
  Education = 'EDUCATION',
  PersonalQuestionnaire = 'PERSONAL_QUESTIONNAIRE',
  HealthQuestionnaire = 'HEALTH_QUESTIONNAIRE',
  CovidQuestionnaire = 'COVID_QUESTIONNAIRE'
}

export type CheckBusinessAccount = {
  __typename?: 'CheckBusinessAccount';
  businessScheme: BusinessScheme;
};

export enum BusinessScheme {
  Collective = 'COLLECTIVE',
  Bundle = 'BUNDLE'
}

export type CreateHealthPlanApplication = {
  __typename?: 'CreateHealthPlanApplication';
  healthPlanApplication: HealthPlanApplicationType;
};

export type UpdateHealthPlanApplication = {
  __typename?: 'UpdateHealthPlanApplication';
  healthPlanApplication: HealthPlanApplicationType;
};

export type UpdateHealthPlanApplicationAnswers = {
  __typename?: 'UpdateHealthPlanApplicationAnswers';
  healthPlanApplication: HealthPlanApplicationType;
};

export type UpdateHealthPlanApplicationIncentiveCode = {
  __typename?: 'UpdateHealthPlanApplicationIncentiveCode';
  healthPlanApplication: HealthPlanApplicationType;
};

export type DeleteHealthPlanApplicationIncentiveCode = {
  __typename?: 'DeleteHealthPlanApplicationIncentiveCode';
  healthPlanApplication: HealthPlanApplicationType;
};

export type SubmitHealthPlanApplication = {
  __typename?: 'SubmitHealthPlanApplication';
  healthPlanApplication: HealthPlanApplicationType;
};

export type SubmitHealthPlanApplicationWithToken = {
  __typename?: 'SubmitHealthPlanApplicationWithToken';
  healthPlanApplication: HealthPlanApplicationType;
};

export type AcceptHealthPlanApplication = {
  __typename?: 'AcceptHealthPlanApplication';
  ok: Scalars['Boolean'];
  healthPlans?: Maybe<Array<Maybe<HealthPlanType>>>;
};

export type AttemptPreApprovedSubscriptionByUser = {
  __typename?: 'AttemptPreApprovedSubscriptionByUser';
  ok: Scalars['Boolean'];
};

export type CloseHealthPlanApplication = {
  __typename?: 'CloseHealthPlanApplication';
  healthPlanApplication: HealthPlanApplicationType;
};

export type SetWaitingHealthPlanApplication = {
  __typename?: 'SetWaitingHealthPlanApplication';
  healthPlanApplication: HealthPlanApplicationType;
};

export type NotifyHealthPlanApplicationState = {
  __typename?: 'NotifyHealthPlanApplicationState';
  ok: Scalars['Boolean'];
};

export type SendHealthPlanAcceptDocuments = {
  __typename?: 'SendHealthPlanAcceptDocuments';
  ok: Scalars['Boolean'];
};

export type ResendCollectiveWelcomeEmail = {
  __typename?: 'ResendCollectiveWelcomeEmail';
  ok: Scalars['Boolean'];
};

export type UpdateHealthPlanApplicationItem = {
  __typename?: 'UpdateHealthPlanApplicationItem';
  healthPlanApplicationItem?: Maybe<HealthPlanApplicationItemType>;
};

export type ForceHealthPlanApplicationItemCheck = {
  __typename?: 'ForceHealthPlanApplicationItemCheck';
  healthPlanApplicationItem?: Maybe<HealthPlanApplicationItemType>;
};

export type CreateHealthPlanApplicationItem = {
  __typename?: 'CreateHealthPlanApplicationItem';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type CreateHealthPlanApplicationItemV2 = {
  __typename?: 'CreateHealthPlanApplicationItemV2';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type DuplicateHealthPlanApplicationItem = {
  __typename?: 'DuplicateHealthPlanApplicationItem';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type CancelHealthPlanApplicationItem = {
  __typename?: 'CancelHealthPlanApplicationItem';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type DeleteHealthPlanApplicationItem = {
  __typename?: 'DeleteHealthPlanApplicationItem';
  ok: Scalars['Boolean'];
};

export type UploadPhotoHealthPlanApplicationItem = {
  __typename?: 'UploadPhotoHealthPlanApplicationItem';
  uploadData: UploadDataType;
};

export type UploadHealthPlanApplicationItemDocument = {
  __typename?: 'UploadHealthPlanApplicationItemDocument';
  uploadData: UploadDataType;
};

export type UploadSignatureForWeb = {
  __typename?: 'UploadSignatureForWeb';
  uploadData: UploadDataType;
};

export type CommentOnHealthPlanApplicationItem = {
  __typename?: 'CommentOnHealthPlanApplicationItem';
  comment?: Maybe<HealthPlanApplicationItemCommentType>;
};

export type SetWaitingHealthPlanApplicationItem = {
  __typename?: 'SetWaitingHealthPlanApplicationItem';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type CreateHealthPlanQuote = {
  __typename?: 'CreateHealthPlanQuote';
  healthPlanQuote: HealthPlanQuoteType;
};

export type UpdateHealthPlanQuote = {
  __typename?: 'UpdateHealthPlanQuote';
  healthPlanQuote: HealthPlanQuoteType;
};

export type UpdateHealthPlanApplicationItemAnswers = {
  __typename?: 'UpdateHealthPlanApplicationItemAnswers';
  healthPlanApplicationItem?: Maybe<HealthPlanApplicationItemType>;
};

export type SetSubscriptionCheckState = {
  __typename?: 'SetSubscriptionCheckState';
  check: HealthPlanApplicationItemCheckType;
};

export type SetProfilingDataCheckState = {
  __typename?: 'SetProfilingDataCheckState';
  profilingDataCheck: ProfilingDataCheckType;
};

export enum CheckState {
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  MoreInfoNeeded = 'MORE_INFO_NEEDED'
}

export type RefreshProfilingDataState = {
  __typename?: 'RefreshProfilingDataState';
  profilingData: ProfilingDataType;
};

export type ApproveCollectiveHealthPlanApplicationItemChecksAfterProfiling = {
  __typename?: 'ApproveCollectiveHealthPlanApplicationItemChecksAfterProfiling';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type RetriggerAutoSubscriptionChecks = {
  __typename?: 'RetriggerAutoSubscriptionChecks';
  ok: Scalars['Boolean'];
};

export type CheckProductParameters = {
  __typename?: 'CheckProductParameters';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type GetInstallmentsAvailableForPaymentMethod = {
  __typename?: 'GetInstallmentsAvailableForPaymentMethod';
  cardOptions?: Maybe<Scalars['JSONString']>;
};

export type UpdateHealthplanApplicationInterestFreePlan = {
  __typename?: 'UpdateHealthplanApplicationInterestFreePlan';
  healthPlanApplication: HealthPlanApplicationType;
};

export type SendReminder = {
  __typename?: 'SendReminder';
  ok: Scalars['Boolean'];
};

export type SetBusinessAsHealthPlanApplicationOwner = {
  __typename?: 'SetBusinessAsHealthPlanApplicationOwner';
  ok: Scalars['Boolean'];
};

export type SendSubscriptionPriceEstimationEmail = {
  __typename?: 'SendSubscriptionPriceEstimationEmail';
  ok: Scalars['Boolean'];
};

export type GenerateSubscriptionPriceEstimationDocument = {
  __typename?: 'GenerateSubscriptionPriceEstimationDocument';
  url: Scalars['String'];
};

export type GenerateSubscriptionPriceEstimationDocumentForCollective = {
  __typename?: 'GenerateSubscriptionPriceEstimationDocumentForCollective';
  url: Scalars['String'];
};

export type ScenarioInputType = {
  name?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Array<Scalars['Int']>>;
  insuredAmountCents?: InputMaybe<InsuredAmountCents>;
  percent?: InputMaybe<Scalars['Int']>;
  maternityZero?: InputMaybe<Scalars['Boolean']>;
  maternitySix?: InputMaybe<Scalars['Boolean']>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  dentalMembershipAddon?: InputMaybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  intlCoverageAddon?: InputMaybe<Scalars['Boolean']>;
  discountPercent?: InputMaybe<Scalars['Float']>;
};

export type UpdateHealthPlanQuoteSubscriptionType = {
  __typename?: 'UpdateHealthPlanQuoteSubscriptionType';
  healthPlanQuote: HealthPlanQuoteType;
};

export type StartMiniSubscription = {
  __typename?: 'StartMiniSubscription';
  user: UserType;
};

export type UpdateMedicalRecommendation = {
  __typename?: 'UpdateMedicalRecommendation';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type AddMedicalRecommendationComment = {
  __typename?: 'AddMedicalRecommendationComment';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type UpdateMedicalAdditionalInformation = {
  __typename?: 'UpdateMedicalAdditionalInformation';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type ToggleWaitingMemberToAcceptExclusions = {
  __typename?: 'ToggleWaitingMemberToAcceptExclusions';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type SubmitActivationScores = {
  __typename?: 'SubmitActivationScores';
  activationCategory: ActivationCategory;
};

export enum ActivationCategory {
  PreventiveVideoConsult = 'PREVENTIVE_VIDEO_CONSULT',
  VideoConsult = 'VIDEO_CONSULT',
  Speciality = 'SPECIALITY',
  Urgency = 'URGENCY'
}

export type UpdateScheduledApprovalCall = {
  __typename?: 'UpdateScheduledApprovalCall';
  scheduledApprovalCall: ScheduledApprovalCallType;
};

export type SetupActivationHealthPlanApplication = {
  __typename?: 'SetupActivationHealthPlanApplication';
  healthPlanApplication: HealthPlanApplicationType;
};

export type AddProfilingHospitalization = {
  __typename?: 'AddProfilingHospitalization';
  profilingHospitalization: ProfilingHospitalizationModelType;
};

export type AddProfilingCancer = {
  __typename?: 'AddProfilingCancer';
  profilingCancer: ProfilingCancerType;
};

export type AddProfilingChronicDisease = {
  __typename?: 'AddProfilingChronicDisease';
  profilingChronicDisease: ProfilingChronicDiseaseType;
};

export type AddProfilingMentalHealthDiagnosis = {
  __typename?: 'AddProfilingMentalHealthDiagnosis';
  profilingMentalHealthDiagnosis: ProfilingMentalHealthDiagnosisType;
};

export type AddProfilingAllergy = {
  __typename?: 'AddProfilingAllergy';
  profilingAllergy: ProfilingAllergyType;
};

export type AddProfilingMedicine = {
  __typename?: 'AddProfilingMedicine';
  profilingMedicine: ProfilingMedicineType;
};

export type AddProfilingGynecologyDisease = {
  __typename?: 'AddProfilingGynecologyDisease';
  gynecologyDisease: ProfilingGynecologyDiseaseType;
};

export type UpdateProfilingHospitalization = {
  __typename?: 'UpdateProfilingHospitalization';
  profilingHospitalization: ProfilingHospitalizationModelType;
};

export type UpdateProfilingCancer = {
  __typename?: 'UpdateProfilingCancer';
  profilingCancer: ProfilingCancerType;
};

export type UpdateProfilingChronicDisease = {
  __typename?: 'UpdateProfilingChronicDisease';
  profilingChronicDisease: ProfilingChronicDiseaseType;
};

export type UpdateProfilingMentalHealthDiagnosis = {
  __typename?: 'UpdateProfilingMentalHealthDiagnosis';
  profilingMentalHealthDiagnosis: ProfilingMentalHealthDiagnosisType;
};

export type UpdateProfilingAllergy = {
  __typename?: 'UpdateProfilingAllergy';
  profilingAllergy: ProfilingAllergyType;
};

export type UpdateProfilingMedicine = {
  __typename?: 'UpdateProfilingMedicine';
  profilingMedicine: ProfilingMedicineType;
};

export type UpdateProfilingGynecologyDisease = {
  __typename?: 'UpdateProfilingGynecologyDisease';
  gynecologyDisease: ProfilingGynecologyDiseaseType;
};

export type UpdateProfilingMedicalInfo = {
  __typename?: 'UpdateProfilingMedicalInfo';
  medicalInfo: ProfilingMedicalInfoType;
};

export type DeleteProfilingHospitalization = {
  __typename?: 'DeleteProfilingHospitalization';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteProfilingCancer = {
  __typename?: 'DeleteProfilingCancer';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteProfilingChronicDisease = {
  __typename?: 'DeleteProfilingChronicDisease';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteProfilingMentalHealthDiagnosis = {
  __typename?: 'DeleteProfilingMentalHealthDiagnosis';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteProfilingAllergy = {
  __typename?: 'DeleteProfilingAllergy';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteProfilingMedicine = {
  __typename?: 'DeleteProfilingMedicine';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteProfilingGynecologyDisease = {
  __typename?: 'DeleteProfilingGynecologyDisease';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddProfilingMedicalComment = {
  __typename?: 'AddProfilingMedicalComment';
  profilingData: ProfilingDataType;
};

export type SubmitCollectiveProfiling = {
  __typename?: 'SubmitCollectiveProfiling';
  user: UserType;
};

export type FinishCollectiveOnboardingFlow = {
  __typename?: 'FinishCollectiveOnboardingFlow';
  user: UserType;
};

export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type CreateInternalUser = {
  __typename?: 'CreateInternalUser';
  user: UserType;
};

export type InternalUserMemberInputType = {
  firstName?: InputMaybe<Scalars['String']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<GenderType>;
};

export type UpdateInternalUser = {
  __typename?: 'UpdateInternalUser';
  user: UserType;
};

export type CreateUserForMember = {
  __typename?: 'CreateUserForMember';
  user: UserType;
};

export type UpdateUser = {
  __typename?: 'UpdateUser';
  user: UserType;
};

export type SetUserGroups = {
  __typename?: 'SetUserGroups';
  user: UserType;
};

export type RequestUserEmailUpdate = {
  __typename?: 'RequestUserEmailUpdate';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ConfirmUserEmailUpdate = {
  __typename?: 'ConfirmUserEmailUpdate';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RequestUserPasswordUpdate = {
  __typename?: 'RequestUserPasswordUpdate';
  token?: Maybe<Scalars['String']>;
};

export type ConfirmEmailOtpAuth = {
  __typename?: 'ConfirmEmailOtpAuth';
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  payload: Scalars['GenericScalar'];
};

export type RequestEmailChange = {
  __typename?: 'RequestEmailChange';
  ok: Scalars['Boolean'];
};

export type UploadPhotoMember = {
  __typename?: 'UploadPhotoMember';
  uploadData: UploadDataType;
};

export type AppForgetPasswordUser = {
  __typename?: 'AppForgetPasswordUser';
  ok: Scalars['Boolean'];
};

export type ForgetPasswordUser = {
  __typename?: 'ForgetPasswordUser';
  ok: Scalars['Boolean'];
};

export type ResetPasswordUser = {
  __typename?: 'ResetPasswordUser';
  ok: Scalars['Boolean'];
};

export enum ActionTypeEnum {
  Create = 'CREATE',
  ConfirmAccess = 'CONFIRM_ACCESS'
}

export type LogoutUserFromAllSessions = {
  __typename?: 'LogoutUserFromAllSessions';
  ok: Scalars['Boolean'];
};

export type CreateMember = {
  __typename?: 'CreateMember';
  member: MemberType;
};

export type UpdateMember = {
  __typename?: 'UpdateMember';
  member: MemberType;
};

export type AddCommentToMember = {
  __typename?: 'AddCommentToMember';
  ok: Scalars['Boolean'];
  member: MemberType;
};

export type RequestUpdateMember = {
  __typename?: 'RequestUpdateMember';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateEmergencyContact = {
  __typename?: 'CreateEmergencyContact';
  emergencyContact: EmergencyContactType;
};

export type RequestSosCall = {
  __typename?: 'RequestSosCall';
  ok: Scalars['Boolean'];
};

export type UpdateEmergencyContact = {
  __typename?: 'UpdateEmergencyContact';
  emergencyContact: EmergencyContactType;
};

export type DeleteEmergencyContact = {
  __typename?: 'DeleteEmergencyContact';
  ok: Scalars['Boolean'];
};

export type CreateStripeSource = {
  __typename?: 'CreateStripeSource';
  stripeSource: StripeSourceType;
};

export type SetPrimaryStripeSource = {
  __typename?: 'SetPrimaryStripeSource';
  ok: Scalars['Boolean'];
};

export type DeleteStripeSource = {
  __typename?: 'DeleteStripeSource';
  ok: Scalars['Boolean'];
};

export type DeleteMember = {
  __typename?: 'DeleteMember';
  ok: Scalars['Boolean'];
};

export type AddPushTokenToUser = {
  __typename?: 'AddPushTokenToUser';
  ok: Scalars['Boolean'];
};

export type DeletePushTokenFromUser = {
  __typename?: 'DeletePushTokenFromUser';
  ok: Scalars['Boolean'];
};

export type SendTelephoneConfirm = {
  __typename?: 'SendTelephoneConfirm';
  ok: Scalars['Boolean'];
};

export type ConfirmTelephone = {
  __typename?: 'ConfirmTelephone';
  ok: Scalars['Boolean'];
};

export type PhoneTokenAuth = {
  __typename?: 'PhoneTokenAuth';
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  payload: Scalars['GenericScalar'];
};

export type UpdateUserTelephone = {
  __typename?: 'UpdateUserTelephone';
  ok: Scalars['Boolean'];
};

export type GrantRepresentedUserAccess = {
  __typename?: 'GrantRepresentedUserAccess';
  user: UserType;
};

export type ReplaceDocument = {
  __typename?: 'ReplaceDocument';
  uploadData: UploadDataType;
  document: DocumentType;
};

export type UploadMemberDocument = {
  __typename?: 'UploadMemberDocument';
  uploadData: UploadDataType;
};

export type UploadAddressDocument = {
  __typename?: 'UploadAddressDocument';
  uploadData?: Maybe<UploadDataType>;
};

export type UploadClaimsInvoiceDocument = {
  __typename?: 'UploadClaimsInvoiceDocument';
  uploadData: UploadDataType;
  id?: Maybe<Scalars['Int']>;
};

export type UploadAmendmentAttachmentDocument = {
  __typename?: 'UploadAmendmentAttachmentDocument';
  uploadData: UploadDataType;
};

export type CreateAuthorizationToken = {
  __typename?: 'CreateAuthorizationToken';
  token: Scalars['String'];
};

export enum AuthorizationTokenCategory {
  Digits = 'DIGITS',
  Token = 'TOKEN',
  LongDigits = 'LONG_DIGITS'
}

export enum AuthorizationTokenChannel {
  Sms = 'SMS',
  Email = 'EMAIL',
  Response = 'RESPONSE',
  Both = 'BOTH'
}

export type CreateAuthorizationCode = {
  __typename?: 'CreateAuthorizationCode';
  ok: Scalars['Boolean'];
};

export type ConsumeAuthorizationToken = {
  __typename?: 'ConsumeAuthorizationToken';
  ok: Scalars['Boolean'];
};

export type ResetCollectiveUserCredentials = {
  __typename?: 'ResetCollectiveUserCredentials';
  ok: Scalars['Boolean'];
};

export type ResetB2BAdminUserCredentials = {
  __typename?: 'ResetB2BAdminUserCredentials';
  ok: Scalars['Boolean'];
};

export type RequestForgotDoctorPassword = {
  __typename?: 'RequestForgotDoctorPassword';
  ok: Scalars['Boolean'];
};

export type SetCollectiveUserCredentials = {
  __typename?: 'SetCollectiveUserCredentials';
  ok: Scalars['Boolean'];
};

export type ConfirmCollectiveUserCredentials = {
  __typename?: 'ConfirmCollectiveUserCredentials';
  ok: Scalars['Boolean'];
};

export type SetSubscriptionState = {
  __typename?: 'SetSubscriptionState';
  ok: Scalars['Boolean'];
};

export type SetSubscriptionStateV2 = {
  __typename?: 'SetSubscriptionStateV2';
  user: UserType;
};

export type ToggleAutomaticRenew = {
  __typename?: 'ToggleAutomaticRenew';
  healthPlan: HealthPlanType;
};

export type UpdateFeatureFlagValue = {
  __typename?: 'UpdateFeatureFlagValue';
  ok: Scalars['Boolean'];
};

export enum FeatureFlags {
  WebVc = 'WEB_VC',
  SummaryUpFront = 'SUMMARY_UP_FRONT',
  InterestFreePayments = 'INTEREST_FREE_PAYMENTS',
  ReferralCode = 'REFERRAL_CODE'
}

export type UserIntercomTag = {
  __typename?: 'UserIntercomTag';
  ok: Scalars['Boolean'];
};

export type RunCommand = {
  __typename?: 'RunCommand';
  ok: Scalars['Boolean'];
};

export type UpdateHealthPlanApplicationItemMemberData = {
  __typename?: 'UpdateHealthPlanApplicationItemMemberData';
  member: MemberType;
};

export type ImpersonateUser = {
  __typename?: 'ImpersonateUser';
  token: Scalars['String'];
  payload: Scalars['JSONString'];
};

export type LogoutFromImpersonation = {
  __typename?: 'LogoutFromImpersonation';
  ok: Scalars['Boolean'];
};

export type MagicLinkAuth = {
  __typename?: 'MagicLinkAuth';
  token: Scalars['String'];
};

export type OtpTokenAuth = {
  __typename?: 'OtpTokenAuth';
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  payload: Scalars['GenericScalar'];
};

export type FreeUpUserTelephoneOrEmail = {
  __typename?: 'FreeUpUserTelephoneOrEmail';
  user: UserType;
};

export type OtpVerification = {
  __typename?: 'OtpVerification';
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  payload: Scalars['GenericScalar'];
};

export type CreateOtpDevice = {
  __typename?: 'CreateOtpDevice';
  configUrl: Scalars['String'];
};

export type ConfirmOtpDevice = {
  __typename?: 'ConfirmOtpDevice';
  ok: Scalars['Boolean'];
};

export type DeleteConfirmedOtpDevice = {
  __typename?: 'DeleteConfirmedOtpDevice';
  ok: Scalars['Boolean'];
};

export type DeleteUnconfirmedOtpDevices = {
  __typename?: 'DeleteUnconfirmedOtpDevices';
  ok: Scalars['Boolean'];
};

export type GenerateOtpChallenge = {
  __typename?: 'GenerateOtpChallenge';
  expiresInSeconds: Scalars['String'];
};

export enum OtpMessageMethod {
  Sms = 'SMS',
  Whatsapp = 'WHATSAPP'
}

export type CreateMemberExclusion = {
  __typename?: 'CreateMemberExclusion';
  memberExclusion: MemberExclusionType;
};

export type MemberExclusionInputType = {
  memberId: Scalars['ID'];
  exclusionId: Scalars['ID'];
  state: MemberExclusionState;
  initialDate?: InputMaybe<Scalars['Date']>;
  causedBy?: InputMaybe<ContentObjectInputType>;
  voidedMonths?: InputMaybe<MemberExclusionVoidedMonths>;
  coverageCapComments?: InputMaybe<Scalars['String']>;
};

export type ContentObjectInputType = {
  objectId: Scalars['ID'];
  contentTypeId: Scalars['ID'];
};

export type DoctorCreateMemberExclusion = {
  __typename?: 'DoctorCreateMemberExclusion';
  memberExclusion: MemberExclusionType;
};

export type DoctorMemberExclusionInputType = {
  memberId: Scalars['ID'];
  exclusionId: Scalars['ID'];
  medicalNoteId: Scalars['ID'];
  initialDate?: InputMaybe<Scalars['Date']>;
  voidedMonths?: InputMaybe<MemberExclusionVoidedMonths>;
  coverageCapComments?: InputMaybe<Scalars['String']>;
};

export type UpdateMemberExclusion = {
  __typename?: 'UpdateMemberExclusion';
  memberExclusion: MemberExclusionType;
};

export type DeleteMemberExclusion = {
  __typename?: 'DeleteMemberExclusion';
  memberExclusion: MemberExclusionType;
};

export type UpdateActivationCampaignState = {
  __typename?: 'UpdateActivationCampaignState';
  activationCampaign: ActivationCampaignType;
};

export type AddProviderToMemberFavourites = {
  __typename?: 'AddProviderToMemberFavourites';
  ok: Scalars['Boolean'];
};

export type RemoveProviderFromMemberFavourites = {
  __typename?: 'RemoveProviderFromMemberFavourites';
  ok: Scalars['Boolean'];
};

export type ChangeRepresentationOnDependentUser = {
  __typename?: 'ChangeRepresentationOnDependentUser';
  targetMember: MemberType;
  representativeMember: MemberType;
};

export type ResetMemberRepresentationToSponsorUser = {
  __typename?: 'ResetMemberRepresentationToSponsorUser';
  member: MemberType;
};

export type SendReferralInvitationCode = {
  __typename?: 'SendReferralInvitationCode';
  sent?: Maybe<Scalars['Boolean']>;
};

export type ProvidersToken = {
  __typename?: 'ProvidersToken';
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  payload: Scalars['GenericScalar'];
};

export type CreateDoctorFriend = {
  __typename?: 'CreateDoctorFriend';
  doctorFriend: DoctorFriendType;
  validationWarning?: Maybe<Scalars['String']>;
};

export type UpdateDoctorFriend = {
  __typename?: 'UpdateDoctorFriend';
  doctorFriend: DoctorFriendType;
  validationWarning?: Maybe<Scalars['String']>;
};

export type CreateProviderCategory = {
  __typename?: 'CreateProviderCategory';
  providerCategory: ProviderCategoryType;
};

export type UpdateProviderCategory = {
  __typename?: 'UpdateProviderCategory';
  providerCategory: ProviderCategoryType;
};

export type DeleteProviderCategory = {
  __typename?: 'DeleteProviderCategory';
  ok: Scalars['Boolean'];
};

export type CreateProvider = {
  __typename?: 'CreateProvider';
  provider: ProvidersType;
};

export type UpdateProvider = {
  __typename?: 'UpdateProvider';
  provider: ProvidersType;
};

export type DeleteProvider = {
  __typename?: 'DeleteProvider';
  ok: Scalars['Boolean'];
};

export type CreateProviderBranchOffice = {
  __typename?: 'CreateProviderBranchOffice';
  providerBranchOffice: ProviderBranchOfficeType;
};

export type CreateUserInputType = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<AddressInputType>;
  telephoneInput?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  appStage?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
};

export type BillingInfoInputType = {
  legalName: Scalars['String'];
  taxNumber: Scalars['String'];
  address?: InputMaybe<AddressInputType>;
};

export type DoctorPaymentMethodInputType = {
  clabe: Scalars['String'];
  bank: Scalars['Int'];
};

export type UpdateProviderBranchOffice = {
  __typename?: 'UpdateProviderBranchOffice';
  providerBranchOffice: ProviderBranchOfficeType;
};

export type UpdateUserInputType = {
  token?: InputMaybe<Scalars['String']>;
  oldPassword?: InputMaybe<Scalars['String']>;
  newPassword?: InputMaybe<Scalars['String']>;
  unverifiedEmail?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<AddressInputType>;
  nickname?: InputMaybe<Scalars['String']>;
  welcomed?: InputMaybe<Scalars['Boolean']>;
};

export type DeleteProviderBranchOffice = {
  __typename?: 'DeleteProviderBranchOffice';
  ok: Scalars['Boolean'];
};

export type UpdateBranchOfficeContactInfo = {
  __typename?: 'UpdateBranchOfficeContactInfo';
  branchOfficeContactInfo: BranchOfficeContactInfoType;
};

export type UpdateBranchOfficeHospitalInfo = {
  __typename?: 'UpdateBranchOfficeHospitalInfo';
  branchOfficeHospitalInfo: BranchOfficeHospitalInfoType;
};

export type BranchOfficeHospitalInfoInputType = {
  providerBranchOfficeId: Scalars['ID'];
  laboratory?: InputMaybe<Scalars['Boolean']>;
  cabinet?: InputMaybe<Scalars['Boolean']>;
  bloodBank?: InputMaybe<Scalars['Boolean']>;
  inhalationTherapy?: InputMaybe<Scalars['Boolean']>;
  rehabilitation?: InputMaybe<Scalars['Boolean']>;
  hemodialysisUnit?: InputMaybe<Scalars['Boolean']>;
  chemotherapyUnit?: InputMaybe<Scalars['Boolean']>;
  catheterizationRoom?: InputMaybe<Scalars['Boolean']>;
  adultIcu?: InputMaybe<Scalars['Boolean']>;
  pediatricIcu?: InputMaybe<Scalars['Boolean']>;
  neonatalIcu?: InputMaybe<Scalars['Boolean']>;
  urgencies?: InputMaybe<Scalars['Boolean']>;
  operatingRoom?: InputMaybe<Scalars['Boolean']>;
  maternalAndChildArea?: InputMaybe<Scalars['Boolean']>;
  shortStayUnit?: InputMaybe<Scalars['Boolean']>;
  endoscopyUnit?: InputMaybe<Scalars['Boolean']>;
  transfusionService?: InputMaybe<Scalars['Boolean']>;
  computedAxialTomography?: InputMaybe<Scalars['Boolean']>;
  magneticResonance?: InputMaybe<Scalars['Boolean']>;
  mammograph?: InputMaybe<Scalars['Boolean']>;
  xRays?: InputMaybe<Scalars['Boolean']>;
  densitometer?: InputMaybe<Scalars['Boolean']>;
  electroencephalogram?: InputMaybe<Scalars['Boolean']>;
  echocardiogram?: InputMaybe<Scalars['Boolean']>;
  ultrasound?: InputMaybe<Scalars['Boolean']>;
  pet?: InputMaybe<Scalars['Boolean']>;
  countableBeds?: InputMaybe<Scalars['Int']>;
  uncountableBeds?: InputMaybe<Scalars['Int']>;
  standardRooms?: InputMaybe<Scalars['Int']>;
  totalRooms?: InputMaybe<Scalars['Int']>;
  emergencyOffices?: InputMaybe<Scalars['Int']>;
  emergencyCubicles?: InputMaybe<Scalars['Int']>;
  operatingRooms?: InputMaybe<Scalars['Int']>;
  offices?: InputMaybe<Scalars['Int']>;
};

export type CreateDoctorSpecialistDetail = {
  __typename?: 'CreateDoctorSpecialistDetail';
  doctorSpecialistDetail: DoctorSpecialistDetailType;
};

export type DoctorSpecialistDetailInputType = {
  specialistNumber?: InputMaybe<Scalars['String']>;
  specialistInstitution?: InputMaybe<Scalars['String']>;
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  certificateId?: InputMaybe<Scalars['ID']>;
  certificateValidUntil?: InputMaybe<Scalars['Date']>;
  available?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateDoctorSpecialistDetail = {
  __typename?: 'UpdateDoctorSpecialistDetail';
  doctorSpecialistDetail: DoctorSpecialistDetailType;
};

export type DeleteDoctorSpecialistDetail = {
  __typename?: 'DeleteDoctorSpecialistDetail';
  ok: Scalars['Boolean'];
};

export type UploadDoctorSpecialistDetailDocument = {
  __typename?: 'UploadDoctorSpecialistDetailDocument';
  uploadData: UploadDataType;
  document: DocumentType;
};

export enum DoctorSpecialistDetailDocuments {
  DoctorSpecializationCertificate = 'DOCTOR_SPECIALIZATION_CERTIFICATE'
}

export type AddDoctorSpecializationCertificateValidity = {
  __typename?: 'AddDoctorSpecializationCertificateValidity';
  doctorSpecialistDetail: DoctorSpecialistDetailType;
};

export type CreateDoctor = {
  __typename?: 'CreateDoctor';
  doctor: DoctorType;
};

export type DoctorBillingInfoInputType = {
  taxNumber?: InputMaybe<Scalars['String']>;
  satProductServiceKey?: InputMaybe<Scalars['String']>;
  taxRegime?: InputMaybe<SatRegime>;
};

export type UpdateDoctor = {
  __typename?: 'UpdateDoctor';
  doctor: DoctorType;
};

export type UpdateDoctorById = {
  __typename?: 'UpdateDoctorById';
  doctor: DoctorType;
};

export type UploadDoctorSignatureImage = {
  __typename?: 'UploadDoctorSignatureImage';
  uploadData: UploadDataType;
};

export type UploadDoctorDocument = {
  __typename?: 'UploadDoctorDocument';
  uploadData: UploadDataType;
  document: DocumentType;
};

export type UpdateDoctorGeneralInfo = {
  __typename?: 'UpdateDoctorGeneralInfo';
  doctor: DoctorType;
};

export type UpdateDoctorPaymentInfo = {
  __typename?: 'UpdateDoctorPaymentInfo';
  doctor: DoctorType;
};

export type SetDoctorLeaveOfAbsense = {
  __typename?: 'SetDoctorLeaveOfAbsense';
  doctor: DoctorType;
};

export type RemoveDoctorLeaveOfAbsense = {
  __typename?: 'RemoveDoctorLeaveOfAbsense';
  ok: Scalars['Boolean'];
};

export type UpdateDoctorStatusInfo = {
  __typename?: 'UpdateDoctorStatusInfo';
  doctor: DoctorType;
};

export type UpdateDoctorServicesInfo = {
  __typename?: 'UpdateDoctorServicesInfo';
  doctor: DoctorType;
};

export type CreateDoctorOfficeAddress = {
  __typename?: 'CreateDoctorOfficeAddress';
  officeAddress: AddressType;
};

export type UpdateDoctorOfficeAddress = {
  __typename?: 'UpdateDoctorOfficeAddress';
  officeAddress: AddressType;
};

export type DeleteDoctorOfficeAddress = {
  __typename?: 'DeleteDoctorOfficeAddress';
  ok: Scalars['Boolean'];
};

export type CreateSpecializationFare = {
  __typename?: 'CreateSpecializationFare';
  specializationFare: SpecializationFareType;
};

export type UpdateSpecializationFare = {
  __typename?: 'UpdateSpecializationFare';
  specializationFare: SpecializationFareType;
};

export type SpecializationFareInputType = {
  name?: InputMaybe<Scalars['String']>;
  medicalSpecializationId?: InputMaybe<Scalars['ID']>;
  amountCents?: InputMaybe<Scalars['Int']>;
};

export type CreateDoctorReminder = {
  __typename?: 'CreateDoctorReminder';
  doctorReminder: DoctorReminderType;
};

export type UpdateDoctorReminder = {
  __typename?: 'UpdateDoctorReminder';
  doctorReminder: DoctorReminderType;
};

export type ScheduleAppointmentAttemptNotification = {
  __typename?: 'ScheduleAppointmentAttemptNotification';
  ok: Scalars['Boolean'];
};

export type ImportDoctorDraftFromAirtable = {
  __typename?: 'ImportDoctorDraftFromAirtable';
  ok: Scalars['Boolean'];
};

export type CreateDoctorAssistant = {
  __typename?: 'CreateDoctorAssistant';
  doctorAssistant: DoctorAssistantType;
};

export type UpdateDoctorAssistant = {
  __typename?: 'UpdateDoctorAssistant';
  doctorAssistant: DoctorAssistantType;
};

export type CreateMedicalSpecialization = {
  __typename?: 'CreateMedicalSpecialization';
  medicalSpecialization: MedicalSpecializationModelType;
};

export type UpdateMedicalSpecialization = {
  __typename?: 'UpdateMedicalSpecialization';
  medicalSpecialization: MedicalSpecializationModelType;
};

export type CreateMedicalSpecializationGroup = {
  __typename?: 'CreateMedicalSpecializationGroup';
  medicalSpecializationGroup: MedicalSpecializationGroupType;
};

export type UpdateDoctorLeadInfo = {
  __typename?: 'UpdateDoctorLeadInfo';
  doctor: DoctorType;
};

export enum NamePrefix {
  Dr = 'DR',
  Dra = 'DRA'
}

export type UpdateDoctorLeadInfoState = {
  __typename?: 'UpdateDoctorLeadInfoState';
  doctor: DoctorType;
};

export type UpdateDoctorLeadInfoStateById = {
  __typename?: 'UpdateDoctorLeadInfoStateById';
  doctor: DoctorType;
};

export type SendDoctorContractById = {
  __typename?: 'SendDoctorContractById';
  ok: Scalars['Boolean'];
};

export type UploadDoctorLeadDocument = {
  __typename?: 'UploadDoctorLeadDocument';
  uploadData: UploadDataType;
  document: DocumentType;
};

export type CreateDoctorSpecialistDetailForLead = {
  __typename?: 'CreateDoctorSpecialistDetailForLead';
  doctorSpecialistDetail: DoctorSpecialistDetailType;
};

export type UpdateDoctorSpecialistDetailForLead = {
  __typename?: 'UpdateDoctorSpecialistDetailForLead';
  doctorSpecialistDetail: DoctorSpecialistDetailType;
};

export type DeleteDoctorSpecialistDetailForLead = {
  __typename?: 'DeleteDoctorSpecialistDetailForLead';
  ok: Scalars['Boolean'];
};

export type SetDoctorUserGroups = {
  __typename?: 'SetDoctorUserGroups';
  doctor: DoctorType;
};

export type CreatePharmacyOrder = {
  __typename?: 'CreatePharmacyOrder';
  pharmacyOrder: PharmacyOrderType;
};

export type UpdatePharmacyOrder = {
  __typename?: 'UpdatePharmacyOrder';
  pharmacyOrder: PharmacyOrderType;
};

export type PharmacyItemInput = {
  subtotal: Scalars['Int'];
  vat: Scalars['Int'];
  amount: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
};

export type CancelPharmacyOrder = {
  __typename?: 'CancelPharmacyOrder';
  pharmacyOrder: PharmacyOrderType;
};

export type ConfirmCoinsuredPharmacyOrder = {
  __typename?: 'ConfirmCoinsuredPharmacyOrder';
  pharmacyOrder: PharmacyOrderType;
};

export type UploadPharmacyInvoiceDocument = {
  __typename?: 'UploadPharmacyInvoiceDocument';
  uploadData: UploadDataType;
  id?: Maybe<Scalars['Int']>;
};

export type SendPharmacyOrderEmail = {
  __typename?: 'SendPharmacyOrderEmail';
  emailSent: Scalars['Boolean'];
};

export type CreateGroup = {
  __typename?: 'CreateGroup';
  group: GroupType;
};

export type UpdateGroup = {
  __typename?: 'UpdateGroup';
  group: GroupType;
};

export type DeleteGroup = {
  __typename?: 'DeleteGroup';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreatePaymentMethod = {
  __typename?: 'CreatePaymentMethod';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type MakePaymentMethodPrimary = {
  __typename?: 'MakePaymentMethodPrimary';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type DeletePaymentMethod = {
  __typename?: 'DeletePaymentMethod';
  ok?: Maybe<Scalars['Boolean']>;
};

export type MakePayoutPaymentMethodPrimary = {
  __typename?: 'MakePayoutPaymentMethodPrimary';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type MakePayinPaymentMethodPrimary = {
  __typename?: 'MakePayinPaymentMethodPrimary';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type CreateBillingInfo = {
  __typename?: 'CreateBillingInfo';
  billingInfo: BillingInfoType;
};

export type UpdateBillingInfo = {
  __typename?: 'UpdateBillingInfo';
  billingInfo: BillingInfoType;
};

export type CreatePaymentMethodForUser = {
  __typename?: 'CreatePaymentMethodForUser';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type CreateManualPaymentMethodForUser = {
  __typename?: 'CreateManualPaymentMethodForUser';
  ok?: Maybe<Scalars['Boolean']>;
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type EditManualPaymentGatewaySource = {
  __typename?: 'EditManualPaymentGatewaySource';
  ok?: Maybe<Scalars['Boolean']>;
  paymentGatewaySource?: Maybe<PaymentGatewaySourcesType>;
};

export type AddSpeiPaymentMethodForUser = {
  __typename?: 'AddSpeiPaymentMethodForUser';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type CreatePaymentMethodWithMultipleSources = {
  __typename?: 'CreatePaymentMethodWithMultipleSources';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type PaymentGatewaySourceInputType = {
  sourceId: Scalars['String'];
  paymentGatewayType: PaymentGatewayType;
  paymentGatewaySourceReference?: InputMaybe<Scalars['JSONString']>;
};

export type RequestStripeSetupIntent = {
  __typename?: 'RequestStripeSetupIntent';
  setupIntent?: Maybe<Scalars['JSONString']>;
};

export type CreateSetupIntents = {
  __typename?: 'CreateSetupIntents';
  setupIntents?: Maybe<Scalars['JSONString']>;
};

export type CreateInvoiceDispute = {
  __typename?: 'CreateInvoiceDispute';
  paymentDispute?: Maybe<PaymentDisputeType>;
};

export type RuleInvoiceDispute = {
  __typename?: 'RuleInvoiceDispute';
  paymentDispute?: Maybe<PaymentDisputeType>;
};

export type FetchTransactionCep = {
  __typename?: 'FetchTransactionCep';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserBillingInfo = {
  __typename?: 'UpdateUserBillingInfo';
  billingInfo: BillingInfoType;
};

export type UpdatePayoutMethodTier = {
  __typename?: 'UpdatePayoutMethodTier';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type CreateLocation = {
  __typename?: 'CreateLocation';
  location: LocationType;
};

export type UpdateLocation = {
  __typename?: 'UpdateLocation';
  location: LocationType;
};

export type UpdateInBatchLocation = {
  __typename?: 'UpdateInBatchLocation';
  locations: Array<Maybe<LocationType>>;
};

export type RetryChargeInvoice = {
  __typename?: 'RetryChargeInvoice';
  ok: Scalars['Boolean'];
};

export type GenerateElectronicVoucherForInvoiceItem = {
  __typename?: 'GenerateElectronicVoucherForInvoiceItem';
  ok: Scalars['Boolean'];
};

export type ConfirmManualInvoicePayment = {
  __typename?: 'ConfirmManualInvoicePayment';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RefundInvoice = {
  __typename?: 'RefundInvoice';
  ok: Scalars['Boolean'];
};

export type RequestElectronicVoucherDocumentData = {
  __typename?: 'RequestElectronicVoucherDocumentData';
  uploadData: UploadDataType;
  id?: Maybe<Scalars['Int']>;
};

export type ValidateElectronicVoucherXml = {
  __typename?: 'ValidateElectronicVoucherXml';
  result?: Maybe<Scalars['JSONString']>;
};

export type ValidateDraftElectronicVoucherXml = {
  __typename?: 'ValidateDraftElectronicVoucherXml';
  electronicVoucher: ElectronicVoucherType;
};

export type ReplaceInvoiceDocument = {
  __typename?: 'ReplaceInvoiceDocument';
  uploadData: UploadDataType;
  document: DocumentType;
};

export type UploadElectronicVoucher = {
  __typename?: 'UploadElectronicVoucher';
  uploadData: UploadDataType;
  document: DocumentType;
};

export type UploadDraftElectronicVoucher = {
  __typename?: 'UploadDraftElectronicVoucher';
  uploadData: UploadDataType;
  document: DocumentType;
};

export type PayoutInvoice = {
  __typename?: 'PayoutInvoice';
  ok: Scalars['Boolean'];
};

export type BundleInvoiceItems = {
  __typename?: 'BundleInvoiceItems';
  invoice: InvoiceType;
};

export type PayoutInvoiceOffline = {
  __typename?: 'PayoutInvoiceOffline';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DownloadInvoicePayoutCep = {
  __typename?: 'DownloadInvoicePayoutCep';
  ok: Scalars['Boolean'];
};

export type UpdateElectronicVoucher = {
  __typename?: 'UpdateElectronicVoucher';
  electronicVoucher: ElectronicVoucherType;
};

export type ElectronicVoucherInputType = {
  recipientName: Scalars['String'];
  recipientAddressZipcode: Scalars['String'];
  recipientTaxRegime: SatRegime;
  recipientTaxNumber?: InputMaybe<Scalars['String']>;
  recipientUseCase?: InputMaybe<SatInvoiceUseType>;
};

export type RetrySignElectronicVoucher = {
  __typename?: 'RetrySignElectronicVoucher';
  ok: Scalars['Boolean'];
  electronicVoucher: ElectronicVoucherType;
};

export type AutoEmitMissingElectronicVoucher = {
  __typename?: 'AutoEmitMissingElectronicVoucher';
  ok: Scalars['Boolean'];
};

export type ManualElectronicVoucherCancellation = {
  __typename?: 'ManualElectronicVoucherCancellation';
  ok: Scalars['Boolean'];
  electronicVoucher: ElectronicVoucherType;
};

export type CancelScheduledPayout = {
  __typename?: 'CancelScheduledPayout';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddCommentToInvoice = {
  __typename?: 'AddCommentToInvoice';
  ok: Scalars['Boolean'];
  invoice: InvoiceType;
};

export type AttemptToSignUsersElectronicVouchers = {
  __typename?: 'AttemptToSignUsersElectronicVouchers';
  ok: Scalars['Boolean'];
};

export type SetCopayInvoiceAsUncollectible = {
  __typename?: 'SetCopayInvoiceAsUncollectible';
  ok: Scalars['Boolean'];
};

export type RemoveInvoiceItemElectronicVoucher = {
  __typename?: 'RemoveInvoiceItemElectronicVoucher';
  ok: Scalars['Boolean'];
};

export type UpdateInvoiceItemsWithElectronicVoucherAmounts = {
  __typename?: 'UpdateInvoiceItemsWithElectronicVoucherAmounts';
  invoice: InvoiceType;
};

export type SetInvoiceUnpayable = {
  __typename?: 'SetInvoiceUnpayable';
  ok: Scalars['Boolean'];
};

export type CancelPayoutInvoice = {
  __typename?: 'CancelPayoutInvoice';
  ok: Scalars['Boolean'];
};

export type SetPayinInvoiceAsUncollectible = {
  __typename?: 'SetPayinInvoiceAsUncollectible';
  ok: Scalars['Boolean'];
};

export type CancelServicePayinInvoice = {
  __typename?: 'CancelServicePayinInvoice';
  ok: Scalars['Boolean'];
};

export type UpdateIncentiveCodeBanner = {
  __typename?: 'UpdateIncentiveCodeBanner';
  incentiveCode?: Maybe<IncentiveCodeType>;
};

export type EditIncentiveCode = {
  __typename?: 'EditIncentiveCode';
  incentiveCode?: Maybe<IncentiveCodeType>;
};

export type CreateIncentiveCode = {
  __typename?: 'CreateIncentiveCode';
  incentiveCode?: Maybe<IncentiveCodeType>;
};

export type CreateHealthPlanAmendment = {
  __typename?: 'CreateHealthPlanAmendment';
  amendment: HealthPlanAmendmentType;
};

export type UpdateHealthPlanAmendment = {
  __typename?: 'UpdateHealthPlanAmendment';
  amendment: HealthPlanAmendmentType;
};

export enum AmendmentCategory {
  Empty = 'EMPTY',
  VoluntaryCancellation = 'VOLUNTARY_CANCELLATION',
  DebtCancellation = 'DEBT_CANCELLATION',
  MemberDataCorrection = 'MEMBER_DATA_CORRECTION',
  ProductParamsCorrection = 'PRODUCT_PARAMS_CORRECTION',
  FraudCancellation = 'FRAUD_CANCELLATION',
  BetaToFreeVideoconsults = 'BETA_TO_FREE_VIDEOCONSULTS',
  SubscriptionAmendment = 'SUBSCRIPTION_AMENDMENT',
  BillingInfoAmendment = 'BILLING_INFO_AMENDMENT',
  MaternityAmendment = 'MATERNITY_AMENDMENT',
  DentalAmendment = 'DENTAL_AMENDMENT',
  IntlCoverageAmendment = 'INTL_COVERAGE_AMENDMENT',
  DentalMembershipAmendment = 'DENTAL_MEMBERSHIP_AMENDMENT',
  AddonCancellation = 'ADDON_CANCELLATION',
  CollectiveSubscription = 'COLLECTIVE_SUBSCRIPTION',
  CollectiveAdditionalSubscription = 'COLLECTIVE_ADDITIONAL_SUBSCRIPTION',
  CollectiveDentalAmendment = 'COLLECTIVE_DENTAL_AMENDMENT',
  CollectiveMaternityAmendment = 'COLLECTIVE_MATERNITY_AMENDMENT',
  CollectiveIntlCoverageAmendment = 'COLLECTIVE_INTL_COVERAGE_AMENDMENT',
  CollectiveDentalMembershipAmendment = 'COLLECTIVE_DENTAL_MEMBERSHIP_AMENDMENT',
  CollectivePolicyCancellation = 'COLLECTIVE_POLICY_CANCELLATION',
  CollectiveDebtCancellation = 'COLLECTIVE_DEBT_CANCELLATION',
  CollectiveGroupCancellation = 'COLLECTIVE_GROUP_CANCELLATION',
  CollectiveCertificatesCancellation = 'COLLECTIVE_CERTIFICATES_CANCELLATION',
  CollectiveAddonCancellation = 'COLLECTIVE_ADDON_CANCELLATION'
}

export enum AmendmentReviewResult {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Denied = 'DENIED'
}

export type CreateReissueApplication = {
  __typename?: 'CreateReissueApplication';
  application?: Maybe<HealthPlanApplicationType>;
};

export type CancelHealthPlan = {
  __typename?: 'CancelHealthPlan';
  ok: Scalars['Boolean'];
};

export enum CancelReason {
  Costly = 'COSTLY',
  Errors = 'ERRORS',
  ProblemNotSolved = 'PROBLEM_NOT_SOLVED',
  BadCustomerService = 'BAD_CUSTOMER_SERVICE',
  NotSure = 'NOT_SURE',
  Debt = 'DEBT',
  Fraud = 'FRAUD',
  OutOfArea = 'OUT_OF_AREA',
  JobInsurance = 'JOB_INSURANCE',
  WantsToChange = 'WANTS_TO_CHANGE',
  DidNotLike = 'DID_NOT_LIKE',
  NotUse = 'NOT_USE',
  BusinessCancellation = 'BUSINESS_CANCELLATION',
  BusinessMigration = 'BUSINESS_MIGRATION',
  Reissued = 'REISSUED',
  NotCoveredPreexistence = 'NOT_COVERED_PREEXISTENCE',
  EmployeeChurn = 'EMPLOYEE_CHURN',
  BusinessBelowExpService = 'BUSINESS_BELOW_EXP_SERVICE',
  BusinessBelowExpProduct = 'BUSINESS_BELOW_EXP_PRODUCT',
  BusinessTooCostly = 'BUSINESS_TOO_COSTLY',
  EmployeePrefersOtherInsurance = 'EMPLOYEE_PREFERS_OTHER_INSURANCE'
}

export type CancelEmployeeAndFamilyHealthplans = {
  __typename?: 'CancelEmployeeAndFamilyHealthplans';
  ok: Scalars['Boolean'];
};

export type UpdateAntiquityRequest = {
  __typename?: 'UpdateAntiquityRequest';
  antiquityRequest: AntiquityRequestType;
};

export type SubmitAntiquityRequest = {
  __typename?: 'SubmitAntiquityRequest';
  antiquityRequest: AntiquityRequestType;
};

export type CancelAntiquityRequest = {
  __typename?: 'CancelAntiquityRequest';
  antiquityRequest: AntiquityRequestType;
};

export type GetAntiquityRequestDocumentS3UploadData = {
  __typename?: 'GetAntiquityRequestDocumentS3UploadData';
  uploadData: UploadDataType;
};

export type CreateAntiquityRequestDocument = {
  __typename?: 'CreateAntiquityRequestDocument';
  document: DocumentType;
};

export type AddDentalMembershipHealthPlan = {
  __typename?: 'AddDentalMembershipHealthPlan';
  ok: Scalars['Boolean'];
};

export type CancelDentalMembershipHealthPlan = {
  __typename?: 'CancelDentalMembershipHealthPlan';
  healthPlan: HealthPlanType;
};

export type SetConsultType = {
  __typename?: 'SetConsultType';
  ok: Scalars['Boolean'];
};

export type SetClassificationNotes = {
  __typename?: 'SetClassificationNotes';
  ok: Scalars['Boolean'];
};

export type AuthorizeInPersonConsult = {
  __typename?: 'AuthorizeInPersonConsult';
  ok: Scalars['Boolean'];
  couldNotAuthorize?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type CreatePrescription = {
  __typename?: 'CreatePrescription';
  prescription: PrescriptionType;
};

export type CreateOnDemandConsult = {
  __typename?: 'CreateOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type CreateOnDemandConsultNew = {
  __typename?: 'CreateOnDemandConsultNew';
  onDemandConsult: OnDemandConsultType;
};

export type AddReasonOnDemandConsult = {
  __typename?: 'AddReasonOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type ClaimOnDemandConsult = {
  __typename?: 'ClaimOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type StartOnDemandConsult = {
  __typename?: 'StartOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type StopOnDemandConsult = {
  __typename?: 'StopOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type CancelOnDemandConsult = {
  __typename?: 'CancelOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type CloseOnDemandConsult = {
  __typename?: 'CloseOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type SetReadyAgainOnDemandConsult = {
  __typename?: 'SetReadyAgainOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type StartCallConsult = {
  __typename?: 'StartCallConsult';
  onDemandConsult: OnDemandConsultType;
};

export type EndCallConsult = {
  __typename?: 'EndCallConsult';
  onDemandConsult: OnDemandConsultType;
};

export type WrapUpConsult = {
  __typename?: 'WrapUpConsult';
  ok: Scalars['Boolean'];
};

export type UpdateEmr = {
  __typename?: 'UpdateEmr';
  ok: Scalars['Boolean'];
};

export type SendTextMessage = {
  __typename?: 'SendTextMessage';
  message: MessageType;
};

export type SetMessagesRead = {
  __typename?: 'SetMessagesRead';
  ok: Scalars['Boolean'];
};

export type StartAttachmentMessage = {
  __typename?: 'StartAttachmentMessage';
  message: MessageType;
  uploadData: UploadDataType;
};

export type CompleteAttachmentMessage = {
  __typename?: 'CompleteAttachmentMessage';
  message: MessageType;
};

export type RequestMedicinePostOnDemandConsult = {
  __typename?: 'RequestMedicinePostOnDemandConsult';
  ok: Scalars['Boolean'];
};

export type UpdatePrescriptionMemberChecks = {
  __typename?: 'UpdatePrescriptionMemberChecks';
  prescription: PrescriptionType;
};

export type ChargeInPersonConsult = {
  __typename?: 'ChargeInPersonConsult';
  inPersonConsult: InPersonConsultType;
};

export type ChargeInPersonConsultByProvider = {
  __typename?: 'ChargeInPersonConsultByProvider';
  inPersonConsult: InPersonConsultByProviderType;
};

export type CreateCalendarEvent = {
  __typename?: 'CreateCalendarEvent';
  created: Scalars['Boolean'];
};

export type UpdateCalendarEvent = {
  __typename?: 'UpdateCalendarEvent';
  event: Scalars['JSONString'];
};

export type DeleteCalendarEvent = {
  __typename?: 'DeleteCalendarEvent';
  deleted: Scalars['Boolean'];
};

export type AssignUserToConsult = {
  __typename?: 'AssignUserToConsult';
  scheduledConsult: ScheduledConsultType;
};

export type AddCommentToConsult = {
  __typename?: 'AddCommentToConsult';
  scheduledConsult: ScheduledConsultType;
};

export type CreateEmptyScheduledConsultRequest = {
  __typename?: 'CreateEmptyScheduledConsultRequest';
  scheduledConsult: ScheduledConsultType;
};

export type ScheduleOutOfNetworkConsult = {
  __typename?: 'ScheduleOutOfNetworkConsult';
  scheduledConsult: ScheduledConsultType;
};

export type CreateScheduledConsultRequest = {
  __typename?: 'CreateScheduledConsultRequest';
  scheduledConsult: ScheduledConsultType;
};

export type CreateScheduledConsult = {
  __typename?: 'CreateScheduledConsult';
  scheduledConsult: ScheduledConsultType;
};

export type ScheduleConsult = {
  __typename?: 'ScheduleConsult';
  scheduledConsult: ScheduledConsultType;
};

export type DoctorAssistantScheduleConsult = {
  __typename?: 'DoctorAssistantScheduleConsult';
  scheduledConsult: LimitedScheduledConsultType;
};

export type ConfirmScheduledConsult = {
  __typename?: 'ConfirmScheduledConsult';
  scheduledConsult: ScheduledConsultType;
};

export type CancelScheduledConsult = {
  __typename?: 'CancelScheduledConsult';
  scheduledConsult: ScheduledConsultType;
};

export type DoctorAssistantCancelScheduledConsult = {
  __typename?: 'DoctorAssistantCancelScheduledConsult';
  scheduledConsult: LimitedScheduledConsultType;
};

export type UpdateScheduledConsultRequest = {
  __typename?: 'UpdateScheduledConsultRequest';
  scheduledConsult: ScheduledConsultType;
};

export type MemberCancelScheduledConsult = {
  __typename?: 'MemberCancelScheduledConsult';
  scheduledConsult: ScheduledConsultType;
};

export type MemberUpdateScheduledConsult = {
  __typename?: 'MemberUpdateScheduledConsult';
  scheduledConsult: ScheduledConsultType;
};

export type MemberUpdateScheduledConsultRequest = {
  __typename?: 'MemberUpdateScheduledConsultRequest';
  scheduledConsult: ScheduledConsultType;
};

export type ReviewComplianceAlert = {
  __typename?: 'ReviewComplianceAlert';
  alert: ComplianceAlertType;
};

export type SendAmlReport = {
  __typename?: 'SendAMLReport';
  sent?: Maybe<Scalars['Boolean']>;
};

export enum ComplianceReportType {
  Relevant = 'RELEVANT',
  Unusual = 'UNUSUAL',
  Concerning = 'CONCERNING',
  PldFile = 'PLD_FILE',
  Alerts = 'ALERTS',
  File = 'FILE'
}

export type DoctorCreateDiseaseCase = {
  __typename?: 'DoctorCreateDiseaseCase';
  diseaseCase: LimitedDiseaseCaseType;
};

export type CreateDiseaseCase = {
  __typename?: 'CreateDiseaseCase';
  diseaseCase: DiseaseCaseType;
};

export type UpdateDiseaseCase = {
  __typename?: 'UpdateDiseaseCase';
  diseaseCase: DiseaseCaseType;
};

export type CreateService = {
  __typename?: 'CreateService';
  service?: Maybe<ServicesType>;
};

export type MemberCreateService = {
  __typename?: 'MemberCreateService';
  serviceId: Scalars['ID'];
};

export enum MemberCreateServiceTypesEnum {
  ExtVideoConsult = 'EXT_VIDEO_CONSULT',
  ExternalConsult = 'EXTERNAL_CONSULT',
  HospitalService = 'HOSPITAL_SERVICE',
  LabStudies = 'LAB_STUDIES',
  Medicines = 'MEDICINES',
  Urgency = 'URGENCY'
}

export type UpdateService = {
  __typename?: 'UpdateService';
  service?: Maybe<ServicesType>;
};

export enum CoverageTag {
  CheckUp = 'CHECK_UP',
  MajorMedicalExpense = 'MAJOR_MEDICAL_EXPENSE',
  InPersonConsult = 'IN_PERSON_CONSULT',
  Medicine = 'MEDICINE',
  Vaccine = 'VACCINE',
  VideoConsultation = 'VIDEO_CONSULTATION',
  OfficeProcedure = 'OFFICE_PROCEDURE',
  Maternity = 'MATERNITY',
  ClinicalLaboratoryExamsDiagnostic = 'CLINICAL_LABORATORY_EXAMS_DIAGNOSTIC',
  ClinicalLaboratoryExamsPrevention = 'CLINICAL_LABORATORY_EXAMS_PREVENTION',
  ImagingAndCabinetExamsDiagnostic = 'IMAGING_AND_CABINET_EXAMS_DIAGNOSTIC',
  ImagingAndCabinetExamsPrevention = 'IMAGING_AND_CABINET_EXAMS_PREVENTION'
}

export enum RulingDifficulty {
  Red = 'RED',
  Green = 'GREEN',
  Yellow = 'YELLOW'
}

export type CreatePrescriptionService = {
  __typename?: 'CreatePrescriptionService';
  serviceId: Scalars['ID'];
};

export type UpdateServiceDoctor = {
  __typename?: 'UpdateServiceDoctor';
  service?: Maybe<ServicesType>;
};

export type UpdateServiceDoctorFriend = {
  __typename?: 'UpdateServiceDoctorFriend';
  service?: Maybe<ServicesType>;
};

export type DeleteService = {
  __typename?: 'DeleteService';
  ok: Scalars['Boolean'];
};

export type UpdateServiceCoverageRuling = {
  __typename?: 'UpdateServiceCoverageRuling';
  serviceCoverageRuling: ServicesCoverageRulingType;
};

export enum RulingDeclinationReasons {
  MemberIdentity = 'MEMBER_IDENTITY',
  HealthPlanTerm = 'HEALTH_PLAN_TERM',
  PreExistingConditions = 'PRE_EXISTING_CONDITIONS',
  Exclusions = 'EXCLUSIONS',
  WaitingTimes = 'WAITING_TIMES',
  NoMedicalNeed = 'NO_MEDICAL_NEED',
  OutOfNetwork = 'OUT_OF_NETWORK',
  InsufficientCoverageAmount = 'INSUFFICIENT_COVERAGE_AMOUNT',
  InconsistentPrescriptionInvoice = 'INCONSISTENT_PRESCRIPTION_INVOICE'
}

export type UpdateRejectionMessageForUser = {
  __typename?: 'UpdateRejectionMessageForUser';
  serviceCoverageRuling: ServicesCoverageRulingType;
};

export enum RejectionMessageForUser {
  Preexistence = 'PREEXISTENCE',
  WaitingTimes = 'WAITING_TIMES',
  AestheticProcedures = 'AESTHETIC_PROCEDURES',
  ContraceptionGeneralConditions = 'CONTRACEPTION_GENERAL_CONDITIONS',
  GeneralConditions = 'GENERAL_CONDITIONS',
  NoDentalCoverage = 'NO_DENTAL_COVERAGE',
  NoMaternityCoverage = 'NO_MATERNITY_COVERAGE',
  NoCoverage = 'NO_COVERAGE',
  OutOfNetworkReason = 'OUT_OF_NETWORK_REASON',
  Other = 'OTHER'
}

export type UpdateServiceCoverageRulingState = {
  __typename?: 'UpdateServiceCoverageRulingState';
  serviceCoverageRuling: ServicesCoverageRulingType;
};

export type UpdateClaimRulingCheck = {
  __typename?: 'UpdateClaimRulingCheck';
  claimRulingCheck: ClaimRulingChecksType;
};

export type AcceptAllRulingChecks = {
  __typename?: 'AcceptAllRulingChecks';
  claimRulingChecks: Array<ClaimRulingChecksType>;
};

export type GroupServicesOnDiseaseCase = {
  __typename?: 'GroupServicesOnDiseaseCase';
  ok: Scalars['Boolean'];
};

export type CreateEvidence = {
  __typename?: 'CreateEvidence';
  evidence: EvidenceType;
};

export enum TypeformReportTypes {
  MemberDisease = 'MemberDisease',
  MemberPostService = 'MemberPostService',
  MemberPostServiceShort = 'MemberPostServiceShort',
  MemberPostServiceShortSofiaForm = 'MemberPostServiceShortSofiaForm',
  OtherProvider = 'OtherProvider',
  HospitalService = 'HospitalService',
  DoctorFullProcedure = 'DoctorFullProcedure',
  DoctorOfficeService = 'DoctorOfficeService',
  DoctorPostService = 'DoctorPostService',
  DoctorFullHospitalService = 'DoctorFullHospitalService',
  DoctorPreService = 'DoctorPreService',
  DoctorPostHospitalService = 'DoctorPostHospitalService',
  DoctorInterconsultant = 'DoctorInterconsultant',
  OutOfNetworkInPersonConsultSofiaForm = 'OutOfNetworkInPersonConsultSofiaForm',
  MemberDiseaseDev = 'MemberDiseaseDev',
  MemberPostServiceDev = 'MemberPostServiceDev',
  MemberPostServiceShortDev = 'MemberPostServiceShortDev',
  MemberPostServiceShortSofiaFormDev = 'MemberPostServiceShortSofiaFormDev',
  OtherProviderDev = 'OtherProviderDev',
  HospitalServiceDev = 'HospitalServiceDev',
  DoctorFullProcedureDev = 'DoctorFullProcedureDev',
  DoctorOfficeServiceDev = 'DoctorOfficeServiceDev',
  DoctorPostServiceDev = 'DoctorPostServiceDev',
  DoctorFullHospitalServiceDev = 'DoctorFullHospitalServiceDev',
  DoctorPreServiceDev = 'DoctorPreServiceDev',
  DoctorPostHospitalServiceDev = 'DoctorPostHospitalServiceDev',
  DoctorInterconsultantDev = 'DoctorInterconsultantDev',
  Prescription = 'Prescription',
  OutOfNetworkInPersonConsultSofiaFormDev = 'OutOfNetworkInPersonConsultSofiaFormDev'
}

export type CompleteShortReimbursementSofiaForm = {
  __typename?: 'CompleteShortReimbursementSofiaForm';
  ok: Scalars['Boolean'];
};

export type EvidencesReviewed = {
  __typename?: 'EvidencesReviewed';
  ok: Scalars['Boolean'];
};

export type RequestMoreEvidences = {
  __typename?: 'RequestMoreEvidences';
  ok: Scalars['Boolean'];
};

export type CreateMemberReimbursement = {
  __typename?: 'CreateMemberReimbursement';
  service?: Maybe<LimitedServicesType>;
  evidence: LimitedEvidenceType;
};

export type CreateOutOfNetworkConsultMemberReimbursement = {
  __typename?: 'CreateOutOfNetworkConsultMemberReimbursement';
  service?: Maybe<LimitedServicesType>;
  evidence: LimitedEvidenceType;
};

export type CreatePreapprovedAmount = {
  __typename?: 'CreatePreapprovedAmount';
  preapprovedAmount: PreapprovedAmountHistoryType;
};

export enum Currency {
  MexicanPeso = 'MEXICAN_PESO',
  UnitedStatesDollar = 'UNITED_STATES_DOLLAR',
  None = 'NONE'
}

export type UpdateOrCreateServiceCoveragePayout = {
  __typename?: 'UpdateOrCreateServiceCoveragePayout';
  payoutRequest: ServiceCoveragePayoutType;
};

export type CreateServiceCoveragePayoutInvoice = {
  __typename?: 'CreateServiceCoveragePayoutInvoice';
  payoutRequest: ServiceCoveragePayoutType;
};

export type SetAmountsOnServiceCoveragePayout = {
  __typename?: 'SetAmountsOnServiceCoveragePayout';
  payoutRequest: ServiceCoveragePayoutType;
};

export type ApproveServiceCoveragePayout = {
  __typename?: 'ApproveServiceCoveragePayout';
  payoutRequest: ServiceCoveragePayoutType;
  remarks?: Maybe<Scalars['String']>;
};

export type RequestServicePayIn = {
  __typename?: 'RequestServicePayIn';
  payInRequest: ServicePayInRequestType;
};

export type UploadDataForClaimDocument = {
  __typename?: 'UploadDataForClaimDocument';
  uploadData: UploadDataType;
};

export enum EvidenceDescription {
  CfdiPdf = 'CFDI_PDF',
  CfdiXml = 'CFDI_XML',
  LabResult = 'LAB_RESULT',
  ConsultEvidence = 'CONSULT_EVIDENCE',
  ExtraEvidence = 'EXTRA_EVIDENCE'
}

export type CreateServiceElectronicVoucherItems = {
  __typename?: 'CreateServiceElectronicVoucherItems';
  serviceElectronicVoucherItems?: Maybe<Array<ServiceElectronicVoucherItemType>>;
};

export type SetAcceptedElectronicVoucherItems = {
  __typename?: 'SetAcceptedElectronicVoucherItems';
  ok: Scalars['Boolean'];
};

export type ServiceElectronicVoucherItemBulkInputType = {
  id: Scalars['String'];
  accepted?: InputMaybe<Scalars['Boolean']>;
  unitTotalAuthorizedDecimals?: InputMaybe<Scalars['Float']>;
  quantityAuthorizedDecimals?: InputMaybe<Scalars['Float']>;
};

export type BundleEventInvoiceItems = {
  __typename?: 'BundleEventInvoiceItems';
  invoice: InvoiceType;
};

export type CancelService = {
  __typename?: 'CancelService';
  service?: Maybe<ServicesType>;
};

export type AssignUserToService = {
  __typename?: 'AssignUserToService';
  service?: Maybe<ServicesType>;
};

export type UpdateServiceMedicalRecommendationNotes = {
  __typename?: 'UpdateServiceMedicalRecommendationNotes';
  service?: Maybe<ServicesType>;
};

export type AddServiceComment = {
  __typename?: 'AddServiceComment';
  comment?: Maybe<ServiceCommentType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateServiceEvicenceFlagsMutation = {
  __typename?: 'UpdateServiceEvicenceFlagsMutation';
  service?: Maybe<ServicesType>;
};

export enum EvidenceStateFlag {
  PendingDoctor = 'PENDING_DOCTOR',
  PendingMember = 'PENDING_MEMBER',
  PreexistenceResearch = 'PREEXISTENCE_RESEARCH'
}

export type ProcessServiceCoveragePayout = {
  __typename?: 'ProcessServiceCoveragePayout';
  payoutRequest: ServiceCoveragePayoutType;
};

export type ValidateCoveragePayoutAmounts = {
  __typename?: 'ValidateCoveragePayoutAmounts';
  ok: Scalars['Boolean'];
  warningMessages: Array<Scalars['String']>;
  errorMessages: Array<Scalars['String']>;
};

export type UpdateServiceCoveragePayout = {
  __typename?: 'UpdateServiceCoveragePayout';
  payoutRequest: ServiceCoveragePayoutType;
};

export type ConfirmServicePayInAmounts = {
  __typename?: 'ConfirmServicePayInAmounts';
  payInRequest: ServicePayInRequestType;
};

export type BundleServicePayInRequests = {
  __typename?: 'BundleServicePayInRequests';
  invoice: InvoiceType;
};

export type UploadDataForPayoutCfdi = {
  __typename?: 'UploadDataForPayoutCFDI';
  documentId: Scalars['ID'];
  uploadData: UploadDataType;
};

export enum CfdiDocumentDescriptionEnum {
  CfdiXml = 'CFDI_XML',
  CfdiPdf = 'CFDI_PDF'
}

export type ValidatePayoutCfdi = {
  __typename?: 'ValidatePayoutCFDI';
  isValid: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
};

export type MarkEvidenceAsWrong = {
  __typename?: 'MarkEvidenceAsWrong';
  evidence: EvidenceType;
};

export type RequestEvidenceCorrection = {
  __typename?: 'RequestEvidenceCorrection';
  evidence: EvidenceType;
};

export type AcceptEvidence = {
  __typename?: 'AcceptEvidence';
  evidence: EvidenceType;
};

export type CreateMedicalFeesFromMedicalNoteProcedure = {
  __typename?: 'CreateMedicalFeesFromMedicalNoteProcedure';
  medicalFees: MedicalFeeType;
};

export type UpdateClaimsEvidenceResponseAttachmentState = {
  __typename?: 'UpdateClaimsEvidenceResponseAttachmentState';
  ok: Scalars['Boolean'];
};

export type RequestClaimsEvidenceAttachmentUpload = {
  __typename?: 'RequestClaimsEvidenceAttachmentUpload';
  uploadData: UploadDataType;
};

export type CreateTypeformEvidence = {
  __typename?: 'CreateTypeformEvidence';
  typeformToken: LimitedEvidenceType;
};

export type CreateServiceAndTypeformEvidence = {
  __typename?: 'CreateServiceAndTypeformEvidence';
  service?: Maybe<LimitedServicesType>;
  evidence: LimitedEvidenceType;
};

export type CreateOutOfNetworkConsultMemberReimbursementForInAppEvidence = {
  __typename?: 'CreateOutOfNetworkConsultMemberReimbursementForInAppEvidence';
  service?: Maybe<LimitedServicesType>;
  evidence: LimitedEvidenceType;
};

export type BundleServiceCoveragePayouts = {
  __typename?: 'BundleServiceCoveragePayouts';
  invoice?: Maybe<InvoiceType>;
  processingAsync?: Maybe<Scalars['Boolean']>;
};

export type BusinessToken = {
  __typename?: 'BusinessToken';
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  payload: Scalars['GenericScalar'];
};

export type CreateBusinessDraft = {
  __typename?: 'CreateBusinessDraft';
  business: BusinessType;
};

export type UpdateBusinessInformation = {
  __typename?: 'UpdateBusinessInformation';
  business: BusinessType;
};

export type UpdateBusinessGlobalConfiguration = {
  __typename?: 'UpdateBusinessGlobalConfiguration';
  business: BusinessType;
};

export type UpdateBusinessIncentiveCode = {
  __typename?: 'UpdateBusinessIncentiveCode';
  ok: Scalars['Boolean'];
};

export type UpdateBusiness = {
  __typename?: 'UpdateBusiness';
  business: BusinessType;
};

export type UpdateOrCreateBillingInfo = {
  __typename?: 'UpdateOrCreateBillingInfo';
  business: BusinessType;
};

export type UpdateBusinessReadyToMigrateDentalMembership = {
  __typename?: 'UpdateBusinessReadyToMigrateDentalMembership';
  business: BusinessType;
};

export type UpdateBusinessPaymentInformation = {
  __typename?: 'UpdateBusinessPaymentInformation';
  business: BusinessType;
};

export type UpdateOrCreateBusinessPayoutClabe = {
  __typename?: 'UpdateOrCreateBusinessPayoutClabe';
  business: BusinessType;
};

export type UpdateOrCreateBusinessOwner = {
  __typename?: 'UpdateOrCreateBusinessOwner';
  business: BusinessType;
};

export type RetriggerBusinessChecks = {
  __typename?: 'RetriggerBusinessChecks';
  ok: Scalars['Boolean'];
};

export type RequestBusinessDocumentUploadData = {
  __typename?: 'RequestBusinessDocumentUploadData';
  uploadData: UploadDataType;
  document: DocumentType;
};

export type GetBusinessS3UploadData = {
  __typename?: 'GetBusinessS3UploadData';
  uploadData: UploadDataType;
};

export enum BusinessDocuments {
  BusinessContractDocument = 'BUSINESS_CONTRACT_DOCUMENT',
  BusinessDigitalTaxSignatureProof = 'BUSINESS_DIGITAL_TAX_SIGNATURE_PROOF',
  BusinessProofOfAddress = 'BUSINESS_PROOF_OF_ADDRESS',
  BusinessQuoteDocument = 'BUSINESS_QUOTE_DOCUMENT',
  BusinessRegistrationToCommercePublicRegistry = 'BUSINESS_REGISTRATION_TO_COMMERCE_PUBLIC_REGISTRY',
  BusinessTaxRegistration = 'BUSINESS_TAX_REGISTRATION',
  BusinessTradeRegistrationProof = 'BUSINESS_TRADE_REGISTRATION_PROOF'
}

export type CreateBusinessDocument = {
  __typename?: 'CreateBusinessDocument';
  document: DocumentBusinessLiteType;
};

export type ApproveBusinessDocumentCheck = {
  __typename?: 'ApproveBusinessDocumentCheck';
  ok: Scalars['Boolean'];
};

export type ToggleJustifiableAbsenceForBusinessDocumentCheck = {
  __typename?: 'ToggleJustifiableAbsenceForBusinessDocumentCheck';
  ok: Scalars['Boolean'];
};

export enum BusinessCheckType {
  NotBanned = 'NOT_BANNED',
  NotRestricted = 'NOT_RESTRICTED',
  RiskLevel = 'RISK_LEVEL',
  AddressProof = 'ADDRESS_PROOF',
  TaxRegistration = 'TAX_REGISTRATION',
  DigitalTaxSignature = 'DIGITAL_TAX_SIGNATURE',
  TradeRegistration = 'TRADE_REGISTRATION',
  RegistrationToCommercePublicRegistry = 'REGISTRATION_TO_COMMERCE_PUBLIC_REGISTRY',
  ContractStatus = 'CONTRACT_STATUS'
}

export type SetAbsenceJustificationForBusinessDocumentCheck = {
  __typename?: 'SetAbsenceJustificationForBusinessDocumentCheck';
  ok: Scalars['Boolean'];
};

export type TransitionBusinessState = {
  __typename?: 'TransitionBusinessState';
  ok: Scalars['Boolean'];
};

export type HandleCorrectingInfoBusinessStateTransitions = {
  __typename?: 'HandleCorrectingInfoBusinessStateTransitions';
  ok: Scalars['Boolean'];
};

export type HandleDocumentsReviewCompleteBusinessStateTransition = {
  __typename?: 'HandleDocumentsReviewCompleteBusinessStateTransition';
  ok: Scalars['Boolean'];
};

export type HandleContractSentBusinessStateTransition = {
  __typename?: 'HandleContractSentBusinessStateTransition';
  ok: Scalars['Boolean'];
};

export type HandleContractSignedBusinessStateTransition = {
  __typename?: 'HandleContractSignedBusinessStateTransition';
  ok: Scalars['Boolean'];
};

export type HandleApprovedBusinessStateTransition = {
  __typename?: 'HandleApprovedBusinessStateTransition';
  ok: Scalars['Boolean'];
};

export type HandleRevokedBusinessStateTransition = {
  __typename?: 'HandleRevokedBusinessStateTransition';
  ok: Scalars['Boolean'];
};

export type ContractUpdateStateDate = {
  __typename?: 'ContractUpdateStateDate';
  ok: Scalars['Boolean'];
};

export type CreateBusinessStakeholder = {
  __typename?: 'CreateBusinessStakeholder';
  stakeholder: BusinessStakeholderType;
};

export type UpdateBusinessStakeholder = {
  __typename?: 'UpdateBusinessStakeholder';
  stakeholder: BusinessStakeholderType;
};

export type UpdateLegalRepresentative = {
  __typename?: 'UpdateLegalRepresentative';
  stakeholder: BusinessStakeholderType;
};

export type RetriggerBusinessStakeholderChecks = {
  __typename?: 'RetriggerBusinessStakeholderChecks';
  ok: Scalars['Boolean'];
};

export type RequestBusinessStakeholderDocumentUploadData = {
  __typename?: 'RequestBusinessStakeholderDocumentUploadData';
  uploadData: UploadDataType;
  document: DocumentType;
};

export type GetBusinessStakeholderS3UploadData = {
  __typename?: 'GetBusinessStakeholderS3UploadData';
  uploadData: UploadDataType;
};

export enum StakeholderDocuments {
  StakeholderCurpDoc = 'STAKEHOLDER_CURP_DOC',
  StakeholderForeignerDoc = 'STAKEHOLDER_FOREIGNER_DOC',
  StakeholderId = 'STAKEHOLDER_ID',
  StakeholderRoleProof = 'STAKEHOLDER_ROLE_PROOF'
}

export type CreateBusinessStakeholderDocument = {
  __typename?: 'CreateBusinessStakeholderDocument';
  document: DocumentBusinessLiteType;
};

export type CreateBusinessEmployee = {
  __typename?: 'CreateBusinessEmployee';
  businessEmployee: BusinessEmployeeType;
};

export type CreateApplicationsForBusinessBeneficiary = {
  __typename?: 'CreateApplicationsForBusinessBeneficiary';
  businessEmployee: BusinessEmployeeType;
  familyBusinessBeneficiaries?: Maybe<Array<BusinessEmployeeType>>;
};

export type BusinessBeneficiaryFamilyMemberInputType = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  firstLastName: Scalars['String'];
  secondLastName?: InputMaybe<Scalars['String']>;
  relationship: Scalars['String'];
  gender: Scalars['String'];
  dateOfBirth: Scalars['String'];
  birthCountry?: InputMaybe<Scalars['String']>;
  curp?: InputMaybe<Scalars['String']>;
};

export type CreateBusinessBeneficiary = {
  __typename?: 'CreateBusinessBeneficiary';
  businessEmployee: BusinessEmployeeType;
};

export type CreateBusinessEmployees = {
  __typename?: 'CreateBusinessEmployees';
  errors?: Maybe<Array<CreateBusinessEmployeesErrorType>>;
  results?: Maybe<Array<BusinessEmployeeType>>;
  total?: Maybe<Scalars['Int']>;
};

export type CreateBusinessEmployeesErrorType = {
  __typename?: 'CreateBusinessEmployeesErrorType';
  title: Scalars['String'];
  description: Scalars['String'];
};

export type CreateBusinessEmployeesInputType = {
  email: Scalars['String'];
  businessGroupConfigName: Scalars['String'];
  firstName: Scalars['String'];
  firstLastName: Scalars['String'];
  secondLastName?: InputMaybe<Scalars['String']>;
};

export type CreateCollectiveBusinessBeneficiaries = {
  __typename?: 'CreateCollectiveBusinessBeneficiaries';
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CollectiveBusinessBeneficiariesInputType = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  firstLastName: Scalars['String'];
  dateOfBirth: Scalars['String'];
  birthCountry?: InputMaybe<Scalars['String']>;
  gender: Scalars['String'];
  secondLastName?: InputMaybe<Scalars['String']>;
  rfc?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Scalars['String']>;
  employeeEmail?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  businessGroupConfigId?: InputMaybe<Scalars['ID']>;
  curp?: InputMaybe<Scalars['String']>;
};

export type CreateCollectiveBusinessBeneficiaryRelatives = {
  __typename?: 'CreateCollectiveBusinessBeneficiaryRelatives';
  errors?: Maybe<Array<CreateBusinessEmployeesErrorType>>;
  results?: Maybe<Array<BusinessEmployeeType>>;
  total?: Maybe<Scalars['Int']>;
};

export type SendBeneficiariesDataToOps = {
  __typename?: 'SendBeneficiariesDataToOps';
  ok: Scalars['Boolean'];
  total?: Maybe<Scalars['Int']>;
};

export type EditBusinessEmployee = {
  __typename?: 'EditBusinessEmployee';
  businessEmployee: BusinessEmployeeType;
};

export type DeleteBusinessEmployee = {
  __typename?: 'DeleteBusinessEmployee';
  ok: Scalars['Boolean'];
};

export type SendBusinessEmployeeWelcomeEmail = {
  __typename?: 'SendBusinessEmployeeWelcomeEmail';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SendBusinessAdminWelcomeEmail = {
  __typename?: 'SendBusinessAdminWelcomeEmail';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateBusinessPaymentMethod = {
  __typename?: 'CreateBusinessPaymentMethod';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type MakeBusinessPayinPaymentMethodPrimary = {
  __typename?: 'MakeBusinessPayinPaymentMethodPrimary';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type DeleteBusinessPaymentMethod = {
  __typename?: 'DeleteBusinessPaymentMethod';
  ok?: Maybe<Scalars['Boolean']>;
};

export type BusinessEarlyCutoffProcess = {
  __typename?: 'BusinessEarlyCutoffProcess';
  ok: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type CreateBusinessAdmin = {
  __typename?: 'CreateBusinessAdmin';
  businessAdmin?: Maybe<BusinessAdminType>;
};

export type UpdateBusinessAdmin = {
  __typename?: 'UpdateBusinessAdmin';
  businessAdmin: BusinessAdminType;
};

export type DeleteBusinessAdmin = {
  __typename?: 'DeleteBusinessAdmin';
  ok?: Maybe<Scalars['Boolean']>;
};

export type MakeBusinessAdminOwner = {
  __typename?: 'MakeBusinessAdminOwner';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateBusinessGroupsConfig = {
  __typename?: 'CreateBusinessGroupsConfig';
  businessGroupsConfig?: Maybe<BusinessGroupsConfigType>;
};

export type UpdateBusinessGroupsConfig = {
  __typename?: 'UpdateBusinessGroupsConfig';
  businessGroupsConfig?: Maybe<BusinessGroupsConfigType>;
};

export type AsyncExecuteChangesForActiveBusinessGroupsConfig = {
  __typename?: 'AsyncExecuteChangesForActiveBusinessGroupsConfig';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ExecuteChangesForActiveBusinessGroupsConfig = {
  __typename?: 'ExecuteChangesForActiveBusinessGroupsConfig';
  ok?: Maybe<Scalars['Boolean']>;
  businessPlansGeneralConfigProposal?: Maybe<BusinessPlansGeneralConfigProposalType>;
};

export type SendBusinessNotRegisteredBeneficiaryReminderEmail = {
  __typename?: 'SendBusinessNotRegisteredBeneficiaryReminderEmail';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateBusinessPlansGeneralConfigProposal = {
  __typename?: 'UpdateBusinessPlansGeneralConfigProposal';
  businessPlansGeneralConfigProposal?: Maybe<BusinessPlansGeneralConfigProposalType>;
};

export type AcceptBusinessPlansGeneralConfigProposal = {
  __typename?: 'AcceptBusinessPlansGeneralConfigProposal';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateBusinessPlansGroupConfigProposal = {
  __typename?: 'UpdateBusinessPlansGroupConfigProposal';
  businessPlansGroupConfigProposal?: Maybe<BusinessPlansGroupConfigProposalType>;
};

export type UpdateBusinessBeneficiariesProposalsGroup = {
  __typename?: 'UpdateBusinessBeneficiariesProposalsGroup';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateDefaultBusinessPlansGroupConfigProposal = {
  __typename?: 'UpdateDefaultBusinessPlansGroupConfigProposal';
  ok?: Maybe<Scalars['Boolean']>;
};

export type GenerateConciliationFile = {
  __typename?: 'GenerateConciliationFile';
  doc?: Maybe<DocumentType>;
};

export type CancelBusinessCollectiveHealthPlan = {
  __typename?: 'CancelBusinessCollectiveHealthPlan';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ConfirmBusinessConfigurations = {
  __typename?: 'ConfirmBusinessConfigurations';
  ok: Scalars['Boolean'];
};

export type ReactivateBusiness = {
  __typename?: 'ReactivateBusiness';
  ok: Scalars['Boolean'];
};

export enum BusinessPreApprovedStatesType {
  Draft = 'DRAFT',
  PortalActivated = 'PORTAL_ACTIVATED',
  UploadingInfo = 'UPLOADING_INFO',
  ComplianceReview = 'COMPLIANCE_REVIEW',
  DocumentsReviewComplete = 'DOCUMENTS_REVIEW_COMPLETE',
  CorrectingInfo = 'CORRECTING_INFO',
  ContractSent = 'CONTRACT_SENT',
  ContractSigned = 'CONTRACT_SIGNED',
  ConfigsConfirmed = 'CONFIGS_CONFIRMED'
}

export type CreateCollectiveBusinessRelative = {
  __typename?: 'CreateCollectiveBusinessRelative';
  businessBeneficiary: BusinessEmployeeType;
};

export type UpdateCollectiveBusinessRelative = {
  __typename?: 'UpdateCollectiveBusinessRelative';
  businessBeneficiary: BusinessEmployeeType;
};

export type CreateBundleBusinessRelative = {
  __typename?: 'CreateBundleBusinessRelative';
  businessBeneficiary: BusinessEmployeeType;
};

export type UpdateBundleBusinessRelative = {
  __typename?: 'UpdateBundleBusinessRelative';
  businessBeneficiary: BusinessEmployeeType;
};

export type AsyncExecuteMaternityAddonChangesForActiveBusiness = {
  __typename?: 'AsyncExecuteMaternityAddonChangesForActiveBusiness';
  ok: Scalars['Boolean'];
};

export type ExecuteMaternityAddonChangesForActiveBusiness = {
  __typename?: 'ExecuteMaternityAddonChangesForActiveBusiness';
  ok?: Maybe<Scalars['Boolean']>;
  businessPlansGeneralConfigProposal?: Maybe<BusinessPlansGeneralConfigProposalType>;
};

export type CancelActiveHealthPlansForReissue = {
  __typename?: 'CancelActiveHealthPlansForReissue';
  ok: Scalars['Boolean'];
};

export type CreateProposalToChangeBusinessSubscriptionType = {
  __typename?: 'CreateProposalToChangeBusinessSubscriptionType';
  businessPlansGeneralConfigProposal?: Maybe<BusinessPlansGeneralConfigProposalType>;
};

export type ActivateConfigurationProposal = {
  __typename?: 'ActivateConfigurationProposal';
  ok: Scalars['Boolean'];
};

export type CreateHealthPlanApplicationsFromProposalAndSubmitThem = {
  __typename?: 'CreateHealthPlanApplicationsFromProposalAndSubmitThem';
  ok: Scalars['Boolean'];
};

export type MoveBusinessBeneficiaryToBundle = {
  __typename?: 'MoveBusinessBeneficiaryToBundle';
  bundleBeneficiary: BusinessEmployeeType;
};

export type BusinessRelativeToBundleInput = {
  collectiveBeneficiaryId: Scalars['ID'];
  bundleBeneficiaryId: Scalars['ID'];
  heightCm: Scalars['Int'];
  weightKg: Scalars['Int'];
  relationshipValue: CollectiveRelationshipType;
};

export type UpdateBusinessBillingDay = {
  __typename?: 'UpdateBusinessBillingDay';
  ok: Scalars['Boolean'];
};

export type DeactivateBusinessGroupConfig = {
  __typename?: 'DeactivateBusinessGroupConfig';
  ok: Scalars['Boolean'];
};

export type MigrateYearlyBusinessToDentalMembership = {
  __typename?: 'MigrateYearlyBusinessToDentalMembership';
  ok: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type AcceptBusinessProposal = {
  __typename?: 'AcceptBusinessProposal';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateBusinessMaternityReissueProposal = {
  __typename?: 'CreateBusinessMaternityReissueProposal';
  businessProposal: BusinessPlansGeneralConfigProposalType;
};

export type CreateBusinessGroupConfigReissueProposal = {
  __typename?: 'CreateBusinessGroupConfigReissueProposal';
  groupConfigProposal: BusinessPlansGroupConfigProposalType;
};

export type CancelBusinessReissueProposalBeneficiaries = {
  __typename?: 'CancelBusinessReissueProposalBeneficiaries';
  ok: Scalars['Boolean'];
};

export type ApplyBusinessMaternityReissueProposalChanges = {
  __typename?: 'ApplyBusinessMaternityReissueProposalChanges';
  businessProposal: BusinessPlansGeneralConfigProposalType;
};

export type ApplyBusinessGroupConfigReissueProposalChanges = {
  __typename?: 'ApplyBusinessGroupConfigReissueProposalChanges';
  groupConfigProposal: BusinessPlansGroupConfigProposalType;
};

export type CreateBusinessReissueProposalApplication = {
  __typename?: 'CreateBusinessReissueProposalApplication';
  healthPlanApplication: HealthPlanApplicationType;
};

export type RejectBusinessReissueProposal = {
  __typename?: 'RejectBusinessReissueProposal';
  businessProposal: BusinessPlansGeneralConfigProposalType;
};

export type ApproveBusinessProposalApplicationItems = {
  __typename?: 'ApproveBusinessProposalApplicationItems';
  ok: Scalars['Boolean'];
};

export type CreateBusinessBeneficiariesReportDocument = {
  __typename?: 'CreateBusinessBeneficiariesReportDocument';
  document: DocumentType;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type Revoke = {
  __typename?: 'Revoke';
  revoked: Scalars['Int'];
};

export type Subscription = {
  __typename?: 'Subscription';
  waitingRoomItem: SubscriptionConsultType;
  waitingRoom?: Maybe<Array<WatchOnDemandConsultType>>;
  watchConsult?: Maybe<WatchOnDemandConsultType>;
  userActiveConsults?: Maybe<Array<WatchOnDemandConsultType>>;
  countSeconds?: Maybe<Scalars['Float']>;
};


export type SubscriptionWatchConsultArgs = {
  consultId?: InputMaybe<Scalars['Int']>;
};


export type SubscriptionCountSecondsArgs = {
  upTo?: InputMaybe<Scalars['Int']>;
};

export type DoctorFragment = { __typename?: 'PublicDoctorType', uuid: string, namePrefix?: string | null, firstName: string, firstLastName?: string | null, fullName?: string | null, prefixedFullName?: string | null, secondLastName?: string | null, nickname: string, secondaryOfficeAddress?: { __typename?: 'PublicAddressType', coordinates?: Array<number | null> | null, temporaryVirtualOffice?: boolean | null, zipcode?: string | null, id?: string | null, neighborhood?: string | null, references?: string | null, googleMapsUrl?: string | null, city?: string | null, address2?: string | null, address1?: string | null } | null, officeAddress?: { __typename?: 'PublicAddressType', coordinates?: Array<number | null> | null, temporaryVirtualOffice?: boolean | null, zipcode?: string | null, id?: string | null, neighborhood?: string | null, references?: string | null, googleMapsUrl?: string | null, city?: string | null, address2?: string | null, address1?: string | null } | null, officeAddresses?: Array<{ __typename?: 'PublicAddressType', coordinates?: Array<number | null> | null, temporaryVirtualOffice?: boolean | null, zipcode?: string | null, id?: string | null, neighborhood?: string | null, references?: string | null, googleMapsUrl?: string | null, city?: string | null, address2?: string | null, address1?: string | null }> | null, doctorSpecialistDetails?: Array<{ __typename?: 'PublicDoctorSpecialistDetailType', id: string, specialistInstitution?: string | null, specialistNumber?: string | null, specializationName?: string | null, medicalSpecialization: { __typename?: 'PublicMedicalSpecializationModelType', id: string, type?: SpecializationType | null, name: string, description?: string | null } }> | null };

export type OfficeAddressFragment = { __typename?: 'PublicAddressType', coordinates?: Array<number | null> | null, temporaryVirtualOffice?: boolean | null, zipcode?: string | null, id?: string | null, neighborhood?: string | null, references?: string | null, googleMapsUrl?: string | null, city?: string | null, address2?: string | null, address1?: string | null };

export type DoctorSpecialistDetailsFragment = { __typename?: 'PublicDoctorSpecialistDetailType', id: string, specialistInstitution?: string | null, specialistNumber?: string | null, specializationName?: string | null, medicalSpecialization: { __typename?: 'PublicMedicalSpecializationModelType', id: string, type?: SpecializationType | null, name: string, description?: string | null } };

export type MedicalSpecializationFragment = { __typename?: 'PublicMedicalSpecializationModelType', id: string, type?: SpecializationType | null, name: string, description?: string | null };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken?: { __typename?: 'Refresh', token: string, payload: any, refreshExpiresIn: number, refreshToken: string } | null };

export type GetMedicalSpecializationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMedicalSpecializationsQuery = { __typename?: 'Query', publicMedicalSpecializations?: Array<{ __typename?: 'PublicMedicalSpecializationModelType', id: string, type?: SpecializationType | null, name: string, description?: string | null }> | null };

export type GetDoctorsAmountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDoctorsAmountQuery = { __typename?: 'Query', doctorsAmount?: number | null };

export type GetDoctorProfileQueryVariables = Exact<{
  uuid: Scalars['UUID'];
}>;


export type GetDoctorProfileQuery = { __typename?: 'Query', publicDoctorProfile?: { __typename?: 'PublicDoctorType', uuid: string, namePrefix?: string | null, firstName: string, firstLastName?: string | null, fullName?: string | null, prefixedFullName?: string | null, secondLastName?: string | null, nickname: string, secondaryOfficeAddress?: { __typename?: 'PublicAddressType', coordinates?: Array<number | null> | null, temporaryVirtualOffice?: boolean | null, zipcode?: string | null, id?: string | null, neighborhood?: string | null, references?: string | null, googleMapsUrl?: string | null, city?: string | null, address2?: string | null, address1?: string | null } | null, officeAddress?: { __typename?: 'PublicAddressType', coordinates?: Array<number | null> | null, temporaryVirtualOffice?: boolean | null, zipcode?: string | null, id?: string | null, neighborhood?: string | null, references?: string | null, googleMapsUrl?: string | null, city?: string | null, address2?: string | null, address1?: string | null } | null, officeAddresses?: Array<{ __typename?: 'PublicAddressType', coordinates?: Array<number | null> | null, temporaryVirtualOffice?: boolean | null, zipcode?: string | null, id?: string | null, neighborhood?: string | null, references?: string | null, googleMapsUrl?: string | null, city?: string | null, address2?: string | null, address1?: string | null }> | null, doctorSpecialistDetails?: Array<{ __typename?: 'PublicDoctorSpecialistDetailType', id: string, specialistInstitution?: string | null, specialistNumber?: string | null, specializationName?: string | null, medicalSpecialization: { __typename?: 'PublicMedicalSpecializationModelType', id: string, type?: SpecializationType | null, name: string, description?: string | null } }> | null } | null };

export type GetDoctorsDirectoryQueryVariables = Exact<{
  locationState?: InputMaybe<States>;
  medicalSpecializationIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
}>;


export type GetDoctorsDirectoryQuery = { __typename?: 'Query', publicDoctorsDirectory?: Array<{ __typename?: 'PublicDoctorType', uuid: string, namePrefix?: string | null, firstName: string, firstLastName?: string | null, fullName?: string | null, prefixedFullName?: string | null, secondLastName?: string | null, nickname: string, secondaryOfficeAddress?: { __typename?: 'PublicAddressType', coordinates?: Array<number | null> | null, temporaryVirtualOffice?: boolean | null, zipcode?: string | null, id?: string | null, neighborhood?: string | null, references?: string | null, googleMapsUrl?: string | null, city?: string | null, address2?: string | null, address1?: string | null } | null, officeAddress?: { __typename?: 'PublicAddressType', coordinates?: Array<number | null> | null, temporaryVirtualOffice?: boolean | null, zipcode?: string | null, id?: string | null, neighborhood?: string | null, references?: string | null, googleMapsUrl?: string | null, city?: string | null, address2?: string | null, address1?: string | null } | null, officeAddresses?: Array<{ __typename?: 'PublicAddressType', coordinates?: Array<number | null> | null, temporaryVirtualOffice?: boolean | null, zipcode?: string | null, id?: string | null, neighborhood?: string | null, references?: string | null, googleMapsUrl?: string | null, city?: string | null, address2?: string | null, address1?: string | null }> | null, doctorSpecialistDetails?: Array<{ __typename?: 'PublicDoctorSpecialistDetailType', id: string, specialistInstitution?: string | null, specialistNumber?: string | null, specializationName?: string | null, medicalSpecialization: { __typename?: 'PublicMedicalSpecializationModelType', id: string, type?: SpecializationType | null, name: string, description?: string | null } }> | null }> | null };

export const OfficeAddressFragmentDoc = gql`
    fragment OfficeAddress on PublicAddressType {
  coordinates
  temporaryVirtualOffice
  zipcode
  id
  neighborhood
  references
  googleMapsUrl
  city
  address2
  address1
}
    `;
export const MedicalSpecializationFragmentDoc = gql`
    fragment MedicalSpecialization on PublicMedicalSpecializationModelType {
  id
  type
  name
  description
}
    `;
export const DoctorSpecialistDetailsFragmentDoc = gql`
    fragment DoctorSpecialistDetails on PublicDoctorSpecialistDetailType {
  id
  specialistInstitution
  specialistNumber
  specializationName
  medicalSpecialization {
    ...MedicalSpecialization
  }
}
    ${MedicalSpecializationFragmentDoc}`;
export const DoctorFragmentDoc = gql`
    fragment Doctor on PublicDoctorType {
  uuid
  secondaryOfficeAddress {
    ...OfficeAddress
  }
  officeAddress {
    ...OfficeAddress
  }
  officeAddresses {
    ...OfficeAddress
  }
  namePrefix
  doctorSpecialistDetails {
    ...DoctorSpecialistDetails
  }
  firstName
  firstLastName
  fullName
  prefixedFullName
  secondLastName
  nickname
}
    ${OfficeAddressFragmentDoc}
${DoctorSpecialistDetailsFragmentDoc}`;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    token
    payload
    refreshExpiresIn
    refreshToken
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const GetMedicalSpecializationsDocument = gql`
    query getMedicalSpecializations {
  publicMedicalSpecializations {
    ...MedicalSpecialization
  }
}
    ${MedicalSpecializationFragmentDoc}`;

/**
 * __useGetMedicalSpecializationsQuery__
 *
 * To run a query within a React component, call `useGetMedicalSpecializationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMedicalSpecializationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMedicalSpecializationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMedicalSpecializationsQuery(baseOptions?: Apollo.QueryHookOptions<GetMedicalSpecializationsQuery, GetMedicalSpecializationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMedicalSpecializationsQuery, GetMedicalSpecializationsQueryVariables>(GetMedicalSpecializationsDocument, options);
      }
export function useGetMedicalSpecializationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMedicalSpecializationsQuery, GetMedicalSpecializationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMedicalSpecializationsQuery, GetMedicalSpecializationsQueryVariables>(GetMedicalSpecializationsDocument, options);
        }
export type GetMedicalSpecializationsQueryHookResult = ReturnType<typeof useGetMedicalSpecializationsQuery>;
export type GetMedicalSpecializationsLazyQueryHookResult = ReturnType<typeof useGetMedicalSpecializationsLazyQuery>;
export type GetMedicalSpecializationsQueryResult = Apollo.QueryResult<GetMedicalSpecializationsQuery, GetMedicalSpecializationsQueryVariables>;
export const GetDoctorsAmountDocument = gql`
    query getDoctorsAmount {
  doctorsAmount
}
    `;

/**
 * __useGetDoctorsAmountQuery__
 *
 * To run a query within a React component, call `useGetDoctorsAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoctorsAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoctorsAmountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDoctorsAmountQuery(baseOptions?: Apollo.QueryHookOptions<GetDoctorsAmountQuery, GetDoctorsAmountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDoctorsAmountQuery, GetDoctorsAmountQueryVariables>(GetDoctorsAmountDocument, options);
      }
export function useGetDoctorsAmountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDoctorsAmountQuery, GetDoctorsAmountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDoctorsAmountQuery, GetDoctorsAmountQueryVariables>(GetDoctorsAmountDocument, options);
        }
export type GetDoctorsAmountQueryHookResult = ReturnType<typeof useGetDoctorsAmountQuery>;
export type GetDoctorsAmountLazyQueryHookResult = ReturnType<typeof useGetDoctorsAmountLazyQuery>;
export type GetDoctorsAmountQueryResult = Apollo.QueryResult<GetDoctorsAmountQuery, GetDoctorsAmountQueryVariables>;
export const GetDoctorProfileDocument = gql`
    query getDoctorProfile($uuid: UUID!) {
  publicDoctorProfile(uuid: $uuid) {
    ...Doctor
  }
}
    ${DoctorFragmentDoc}`;

/**
 * __useGetDoctorProfileQuery__
 *
 * To run a query within a React component, call `useGetDoctorProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoctorProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoctorProfileQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetDoctorProfileQuery(baseOptions: Apollo.QueryHookOptions<GetDoctorProfileQuery, GetDoctorProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDoctorProfileQuery, GetDoctorProfileQueryVariables>(GetDoctorProfileDocument, options);
      }
export function useGetDoctorProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDoctorProfileQuery, GetDoctorProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDoctorProfileQuery, GetDoctorProfileQueryVariables>(GetDoctorProfileDocument, options);
        }
export type GetDoctorProfileQueryHookResult = ReturnType<typeof useGetDoctorProfileQuery>;
export type GetDoctorProfileLazyQueryHookResult = ReturnType<typeof useGetDoctorProfileLazyQuery>;
export type GetDoctorProfileQueryResult = Apollo.QueryResult<GetDoctorProfileQuery, GetDoctorProfileQueryVariables>;
export const GetDoctorsDirectoryDocument = gql`
    query getDoctorsDirectory($locationState: States, $medicalSpecializationIds: [ID!], $name: String) {
  publicDoctorsDirectory(
    locationState: $locationState
    medicalSpecializationIds: $medicalSpecializationIds
    name: $name
  ) {
    ...Doctor
  }
}
    ${DoctorFragmentDoc}`;

/**
 * __useGetDoctorsDirectoryQuery__
 *
 * To run a query within a React component, call `useGetDoctorsDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoctorsDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoctorsDirectoryQuery({
 *   variables: {
 *      locationState: // value for 'locationState'
 *      medicalSpecializationIds: // value for 'medicalSpecializationIds'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetDoctorsDirectoryQuery(baseOptions?: Apollo.QueryHookOptions<GetDoctorsDirectoryQuery, GetDoctorsDirectoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDoctorsDirectoryQuery, GetDoctorsDirectoryQueryVariables>(GetDoctorsDirectoryDocument, options);
      }
export function useGetDoctorsDirectoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDoctorsDirectoryQuery, GetDoctorsDirectoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDoctorsDirectoryQuery, GetDoctorsDirectoryQueryVariables>(GetDoctorsDirectoryDocument, options);
        }
export type GetDoctorsDirectoryQueryHookResult = ReturnType<typeof useGetDoctorsDirectoryQuery>;
export type GetDoctorsDirectoryLazyQueryHookResult = ReturnType<typeof useGetDoctorsDirectoryLazyQuery>;
export type GetDoctorsDirectoryQueryResult = Apollo.QueryResult<GetDoctorsDirectoryQuery, GetDoctorsDirectoryQueryVariables>;