import { styled } from "~/styles/stitches.config";
import {
  Accordion,
  AccordionItem,
  AccordionItemProps,
  PaddedScreenContainer,
} from "~/components";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  paddingLeft: 14,
  paddingRight: 14,
  gap: 48,
  mb: "$10",
  "@bp1": {
    gap: 32,
    padding: 0,
  },
});

const FAQS: AccordionItemProps[] = [
  {
    value: "faq1",
    title: "¿Qué es Sofía?",
    text: "Somos una empresa dedicada al cuidado de la salud. Usamos la tecnología para crear productos y servicios que cumplan ese objetivo: brindar las herramientas necesarias y fáciles de usar para que las personas cambien la forma en que se cuidan, todo a través de la app que nosotros mismos construimos.",
  },
  {
    value: "faq2",
    title: "¿Cómo funciona el servicio de videoconsultas?",
    text: (
      <>
        <p>
          Tu seguro en Sofía incluye videoconsultas ilimitadas y sin costo
          adicional los 365 días al año, de 8 de la mañana a 9 de la noche con
          médicos internistas.
        </p>
        <p>
          En el caso de los pediatras los encuentras de lunes a domingo de 8 a
          11 am y a tu pediatra de cabecera con cita de lunes a viernes de 5 a 8
          pm.
        </p>
        <p>Lo único que necesitas para tener una videoconsulta es:</p>
        <ul>
          <li>Entrar a la aplicación con tu usuario y contraseña</li>
          <li>
            Seleccionar la opción de videoconsulta en la pantalla principal
          </li>
          <li>
            Llenar la información del formulario. Así nuestros médicos saben
            cuáles son tus síntomas y edad (en el caso de las consultas de
            pediatría)
          </li>
          <li>
            Cuando termine tu videoconsulta el doctor te mandará una receta con
            tu diagnóstico y las medicinas que necesitas a través de la app y
            por mail.
          </li>
          <li>
            Si es necesario tener una consulta presencial, tu médico te lo hará
            saber. Un@ de nuestr@s Sabi@s te contactará para programar la cita
          </li>
          <li>
            Por el momento no ofrecemos contratar el servicio de videoconsultas
            por separado.
          </li>
        </ul>
      </>
    ),
  },
  {
    value: "faq3",
    title: "¿Puedo ir con cualquier especialista y ustedes lo cubren?",
    text: (
      <>
        <p>
          Si el doctor/a con quien quieres ir no pertenece a nuestra red, no
          podremos cubrir esos honorarios. Para que te quedes tranquil@
          seleccionamos a nuestros especialistas con mucho cuidado y tod@s son
          excelentes médicos.
        </p>
        <p>
          Si prefieres ir a consulta con otro médico, tú deberás cubrir el costo
          total de la consulta.
        </p>
      </>
    ),
  },
  {
    value: "faq4",
    title:
      "¿Qué pasa si necesito alguna especialidad y no la tienen en su equipo médico?",
    text: "Si no encuentras la especialidad que necesitas puedes escribirle a un/a Sabi@ a través de la app para que encontremos al médico especialista que buscas. En cuanto todo esté listo, te contactaremos.",
  },
  {
    value: "faq5",
    title: "¿Cómo funciona el envío de medicinas a domicilio?",
    text: (
      <>
        <p>
          Al terminar una videoconsulta recibirás una receta en PDF con las
          indicaciones del médico, tienes la opción de imprimirla y comprar las
          medicinas por tu cuenta o pedirle a L@s Sabi@s que las manden a tu
          casa.
        </p>
        <p>
          Este servicio no aplica para medicamentos controlados y solo está
          disponible si la receta fue hecha por uno de nuestros médicos.
        </p>
        <p>
          Al solicitar tus medicinas, un/a Sabi@ se comunicará contigo a través
          de la app para que puedas elegir la presentación que prefieras.
        </p>
        <p>
          Como con todo, tú pagas 30% del total de las medicinas que necesites y
          nosotros cubrimos el resto, incluyendo el envío.
        </p>
      </>
    ),
  },
];

export const HomeFaq = () => {
  return (
    <PaddedScreenContainer>
      <Container>
        <h4>Preguntas frecuentes</h4>
        <Accordion type={"single"} collapsible defaultValue={FAQS[0].value}>
          {FAQS.map(faq => (
            <AccordionItem key={faq.value} {...faq} />
          ))}
        </Accordion>
      </Container>
    </PaddedScreenContainer>
  );
};
