import { GetServerSideProps, NextPage } from "next";
import {
  DoctorFragment,
  GetDoctorsDirectoryDocument,
  GetDoctorsDirectoryQuery,
  GetDoctorsDirectoryQueryVariables,
  GetMedicalSpecializationsDocument,
  GetMedicalSpecializationsQuery,
  GetMedicalSpecializationsQueryVariables,
  MedicalSpecializationFragment,
  States,
  useGetDoctorsDirectoryLazyQuery,
} from "~/types/graphql";
import { initializeApollo } from "~/server/apollo";
import { useList, useToggle, useUpdateEffect } from "@react-hookz/web";
import ListAppHeader from "~/components/list-app-header/ListAppHeader";
import ListHeader from "~/components/list-header/ListHeader";
import DoctorListItem from "~/components/doctor-list-item/DoctorListItem";
import { styled } from "~/styles/stitches.config";
import { getSelectorsByUserAgent } from "react-device-detect";
import { useRouter } from "next/router";
import {
  Button,
  DoctorProfile,
  DoctorsMap,
  Loader,
  NoResults,
} from "~/components";
import * as ToastR from "@radix-ui/react-toast";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import Flex from "~/components/flex/Flex";
import { StatesTranslations } from "~/utils/states";
import { isSsr } from "~/utils/server";

export const DOCTOR_ID_QUERY_VAR = "doctorId";
export const SPECIALIZATION_ID_QUERY_VAR = "specialization";
export const LOCATION_QUERY_VAR = "location";
export const SEARCH_QUERY_VAR = "busqueda";

interface DoctorsListProps {
  medicalSpecializations: MedicalSpecializationFragment[];
  initialDoctors: DoctorFragment[];
  isMobile?: boolean;
}

interface ToastProviderProps {
  children?: ReactNode;
}

const ToastProvider = ({ children }: ToastProviderProps) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);
  if (!isClient) return null;
  return <ToastR.Provider label={"Notification"}>{children}</ToastR.Provider>;
};

const Main = styled("main", {
  display: "flex",
  flexDirection: "column",
  minHeight: "100%",
  maxHeight: "100%",
  height: "100%",
  backgroundColor: "#FAFAFA",
  "@bp1": {
    width: "100%",
    overflow: "hidden",
    alignItems: "center",
  },
});

const MainContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  flexGrow: 1,
  overflow: "scroll",
  justifySelf: "stretch",
  "@bp1": {
    flexGrow: 1,
    flexShrink: 0,
    maxWidth: 1080,
    width: "100%",
    flexDirection: "row",
    overflow: "visible",
    height: "calc(100% - 115px)",
  },
});

const ListContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  flexGrow: 1,
  overflowY: "scroll",
  justifySelf: "stretch",
  "@bp1": {
    flexGrow: 1,
    flexShrink: 0,
    height: "100%",
    maxHeight: "100%",
    overflow: "hidden",
    justifySelf: "flex-start",
    width: "50%",
    pt: "$4",
  },
});

const ListInnerContainer = styled("div", {
  padding: 16,
  "@bp1": {
    flex: 1,
    padding: 0,
    paddingBottom: 32,
    overflowY: "scroll",
  },
});

const List = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 16,
  "@bp1": {
    overflowY: "hidden",
  },
});

const MapButtonContainer = styled(Flex, {
  position: "fixed",
  zIndex: 3,
  width: "100%",
  bottom: 0,
  left: 0,
  pointerEvents: "none",
  height: 85,
  background:
    "linear-gradient(360deg, #FFFFFF 15.91%, rgba(255, 255, 255, 0.1) 88.07%)",
  "@bp1": {
    display: "none",
  },
});

const DoctorsList: NextPage<DoctorsListProps> = ({
  medicalSpecializations,
  initialDoctors,
  isMobile,
}) => {
  const router = useRouter();
  const queryVariables = router.query;
  const doctorId = queryVariables[DOCTOR_ID_QUERY_VAR] as string | undefined;
  const specializationId = queryVariables[SPECIALIZATION_ID_QUERY_VAR] as
    | string
    | undefined;
  const location = queryVariables[LOCATION_QUERY_VAR] as States | undefined;
  const searchTerm =
    (queryVariables[SEARCH_QUERY_VAR] as string | undefined) ?? "";
  const [doctors, doctorsActions] = useList<DoctorFragment>(initialDoctors);
  const [loading, setLoading] = useState<boolean>(false);
  const [getDoctorQuery, { refetch: getDoctors, data, called }] =
    useGetDoctorsDirectoryLazyQuery();
  const [mapOpened, setMapOpened] = useToggle(false);
  const [selectedPin, setSelectedPin] = useState<string>();
  const [hoveredDoctor, setHoveredDoctor] = useState<string>();
  const doctorsAmount = doctors.length;
  ``;
  const isEmpty = doctorsAmount === 0;

  const specialtyName = useMemo(() => {
    if (specializationId) {
      return (
        medicalSpecializations.find(
          specialization => specialization.id === specializationId,
        )?.name ?? undefined
      );
    }
    return undefined;
  }, [medicalSpecializations, specializationId]);

  const stateName = useMemo(() => {
    if (location) {
      return StatesTranslations[location];
    }
    return undefined;
  }, [location]);

  useUpdateEffect(() => {
    setLoading(true);
    setSelectedPin(undefined);
    const vars: GetDoctorsDirectoryQueryVariables = {
      locationState:
        (location as string) === "TODOS"
          ? undefined
          : (location as States | undefined),
      medicalSpecializationIds: specializationId
        ? [specializationId]
        : undefined,
      name: searchTerm,
    };
    if (!called) {
      getDoctorQuery({ variables: vars });
    } else {
      getDoctors(vars);
    }
  }, [specializationId, location, searchTerm]);

  useUpdateEffect(() => {
    setLoading(false);
    if (
      data?.publicDoctorsDirectory !== null &&
      data?.publicDoctorsDirectory !== undefined
    ) {
      doctorsActions.set(data.publicDoctorsDirectory);
    }
  }, [data]);

  useUpdateEffect(() => {
    if (isEmpty && loading) {
      setLoading(false);
    }
  }, [isEmpty, loading]);

  const onDismissDoctorProfile = () => {
    setDoctorId(undefined);
  };

  const onClickDoctor = (uuid: string) => {
    setDoctorId(uuid);
  };

  const setDoctorId = (uuid: string | undefined) => {
    router.push(
      {
        query: {
          ...router.query,
          [DOCTOR_ID_QUERY_VAR]: uuid,
        },
      },
      undefined,
      {
        shallow: true,
      },
    );
  };

  return (
    <ToastProvider>
      <Main>
        <ListAppHeader
          medicalSpecializations={medicalSpecializations}
          isMobile={isMobile}
          initialSpeciality={specializationId}
          initialState={location}
        />
        <MainContainer>
          <ListContainer>
            <ListHeader
              doctorsAmount={doctorsAmount}
              initialSearchTerm={searchTerm}
              location={stateName}
              specialization={specialtyName}
            />
            {loading ? (
              <Loader />
            ) : isEmpty ? (
              <NoResults />
            ) : (
              <ListInnerContainer>
                <List>
                  {doctors.map((doctor, index) => (
                    <DoctorListItem
                      key={`${doctor.uuid}-${index}`}
                      doctor={doctor}
                      onClick={() => onClickDoctor(doctor.uuid)}
                      onHover={() => setHoveredDoctor(doctor.uuid)}
                      onHoverOut={() => setHoveredDoctor(undefined)}
                    />
                  ))}
                </List>
              </ListInnerContainer>
            )}
          </ListContainer>
          <DoctorsMap
            doctors={doctors}
            opened={mapOpened}
            selectedPin={selectedPin}
            setSelectedPin={setSelectedPin}
            hoveredDoctor={hoveredDoctor}
          />
        </MainContainer>
        {isSsr ? null : (
          <DoctorProfile
            uuid={doctorId}
            isMobile={isMobile}
            visible={!!doctorId}
            onDismiss={onDismissDoctorProfile}
          />
        )}
        {!isEmpty && !loading ? (
          <MapButtonContainer justify={"center"} align={"center"}>
            <Button
              title={mapOpened ? "Mostrar lista" : "Mostrar mapa"}
              onClick={setMapOpened}
            />
          </MapButtonContainer>
        ) : null}
      </Main>
      <ToastViewport />
    </ToastProvider>
  );
};

const VIEWPORT_PADDING = 25;

const ToastViewport = styled(ToastR.Viewport, {
  position: "fixed",
  top: 0,
  right: 0,
  display: "flex",
  flexDirection: "column",
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 390,
  maxWidth: "100vw",
  margin: 0,
  listStyle: "none",
  zIndex: 2147483647,
  outline: "none",
});

export default DoctorsList;

export const getServerSideProps: GetServerSideProps<
  DoctorsListProps
> = async ctx => {
  const client = initializeApollo({ ctx });
  const userAgent = ctx.req.headers["user-agent"] ?? "";
  const { isMobile } = getSelectorsByUserAgent(userAgent);
  try {
    const medicalSpecializations = await client.query<
      GetMedicalSpecializationsQuery,
      GetMedicalSpecializationsQueryVariables
    >({
      query: GetMedicalSpecializationsDocument,
    });

    const state = ctx.query[LOCATION_QUERY_VAR]
      ? ctx.query[LOCATION_QUERY_VAR] === "TODOS"
        ? undefined
        : (ctx.query[LOCATION_QUERY_VAR] as States | undefined)
      : undefined;

    const doctors = await client.query<
      GetDoctorsDirectoryQuery,
      GetDoctorsDirectoryQueryVariables
    >({
      query: GetDoctorsDirectoryDocument,
      variables: {
        medicalSpecializationIds: ctx.query[SPECIALIZATION_ID_QUERY_VAR]
          ? [ctx.query[SPECIALIZATION_ID_QUERY_VAR] as string]
          : undefined,
        locationState: state,
        name: ctx.query[SEARCH_QUERY_VAR]
          ? (ctx.query[SEARCH_QUERY_VAR] as string | undefined)
          : undefined,
      },
    });
    return {
      props: {
        medicalSpecializations: medicalSpecializations.data
          .publicMedicalSpecializations as MedicalSpecializationFragment[],
        initialDoctors: doctors.data.publicDoctorsDirectory ?? [],
        isMobile,
      },
    };
  } catch (e) {
    console.error(e);
    return {
      props: {
        medicalSpecializations: [],
        initialDoctors: [],
        isMobile,
      },
    };
  }
};
