import { styled } from "~/styles/stitches.config";
import { useEffect, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { ChevronDownIcon } from "@sofia-eng/web-icons";
import Select, { OptionProps } from "react-select";
import { isSsr } from "~/utils/server";

export interface HeaderSelectOption {
  value?: string;
  label: string;
  subTitle?: string | null;
}

export interface HeaderSelectProps {
  value?: string;
  label: string;
  options: HeaderSelectOption[];
  onChange?: (value?: string) => void;
  isMobile?: boolean;
  width?: number;
}

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  flexShrink: 0,
  overflow: "hidden",
  "@bp1": {
    width: 256,
  },
});

const BottomSheetOption = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignSelf: "stretch",
  p: ["$2"],
  borderBottom: "2px solid #F2F4F8",
});

const OptionTile = styled("div", {
  fontSize: 16,
  fontWeight: 700,
  lineHeight: "20px",
  color: "$blueGray300",
});
const OptionSubtitle = styled("div", {
  fontSize: 14,
  fontWeight: 500,
  color: "$blueGray200",
  lineHeight: "20px",
});

const Label = styled("p", {
  fontSize: "12px !important",
  fontWeight: "700 !important",
  lineHeight: "16px !important",
  color: "$blueGray300 !important",
  alignSelf: "flex-start",
});

const MobileSelect = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 8,
  overflow: "hidden",
  height: 28,
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid $blueGray75",
  "& p": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const OptionSelectTitle = styled("div", {
  fontSize: 16,
  fontWeight: 700,
  lineHeight: "20px",
  color: "$blueGray300",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
});

const OptionSelectSubtitle = styled("div", {
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "20px",
  color: "$blueGray200",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
});

const OptionContainer = styled("div", {
  width: "100%",
  padding: 16,
  backgroundColor: "$white",
  cursor: "pointer",
  borderBottom: "2px solid $blueGray50",
  overflow: "hidden",
  "&:hover": {
    backgroundColor: "$primary500",
    borderBottom: "none",
    [`& ${OptionSelectTitle}`]: {
      color: "$white",
    },
    [`& ${OptionSelectSubtitle}`]: {
      color: "$white",
    },
  },
  variants: {
    selected: {
      true: {
        borderBottom: "none",
        backgroundColor: "$primary500",
        [`& ${OptionSelectTitle}`]: {
          color: "$white",
        },
        [`& ${OptionSelectSubtitle}`]: {
          color: "$white",
        },
      },
    },
  },
});

const Option = (props: OptionProps<HeaderSelectOption>) => {
  return (
    <OptionContainer
      selected={props.isSelected}
      onClick={() => {
        props.selectOption(props.data);
      }}
    >
      <OptionSelectTitle>{props.data.label}</OptionSelectTitle>
      {props.data.subTitle ? (
        <OptionSelectSubtitle>{props.data.subTitle}</OptionSelectSubtitle>
      ) : null}
    </OptionContainer>
  );
};

export const HeaderSelect = ({
  label,
  value,
  options,
  onChange,
  isMobile,
  width,
}: HeaderSelectProps) => {
  const [open, setOpen] = useState(false);

  const onClickHandler = () => {
    if (isMobile) {
      setOpen(true);
    }
  };

  const onChangeHandler = (value?: string) => {
    onChange?.(value);
  };

  const onDismiss = () => {
    if (isMobile) {
      setOpen(false);
    }
  };

  useEffect(() => {
    onDismiss();
  }, [value]);

  return (
    <Container onClick={onClickHandler}>
      <Label>{label}</Label>
      {isMobile ? (
        <MobileSelect>
          <p>
            {value
              ? options.find(option => option.value === value)?.label
              : "Elige una"}
          </p>
          <ChevronDownIcon color={"primary500"} />
        </MobileSelect>
      ) : (
        <div>
          <Select<HeaderSelectOption>
            placeholder={"Elige una"}
            isSearchable
            unstyled
            menuPlacement={"auto"}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 999 }),
              menu: base => ({
                ...base,
                zIndex: 999,
                padding: 0,
                width,
                borderRadius: 8,
                top: 9,
                overflow: "hidden",
                backgroundColor: "white",
                boxShadow: "0px 3px 8px 0px #E9E9E9",
              }),
              menuList: base => ({ ...base, padding: 0 }),
              control: base => ({
                ...base,
                cursor: "pointer",
                borderBottom: "1px solid #DEE2E9",
              }),
              singleValue: base => ({
                ...base,
                fontSize: 16,
                fontWeight: 400,
                lineHeight: "20px",
                color: "#323C61",
              }),
            }}
            components={{ Option }}
            value={
              value
                ? {
                    value: value,
                    label:
                      options.find(option => option.value === value)?.label ??
                      "",
                    subTitle: options.find(option => option.value === value)
                      ?.subTitle,
                  }
                : undefined
            }
            onChange={newValue => {
              if (newValue?.value) {
                onChangeHandler(newValue.value);
              }
            }}
            menuPortalTarget={isSsr ? null : document.body}
            options={options.map(option => {
              return {
                value: option.value,
                label: option.label,
                subTitle: option.subTitle,
              };
            })}
          />
        </div>
      )}
      <BottomSheet
        open={open}
        onDismiss={onDismiss}
        initialFocusRef={false}
        defaultSnap={({ maxHeight }) => maxHeight * 0.9}
        snapPoints={({ maxHeight }) => [maxHeight * 0.9]}
      >
        {options.map((option, index) => (
          <BottomSheetOption
            key={`${index}-${option.value}`}
            onClick={() => {
              onChangeHandler(option.value);
            }}
          >
            <OptionTile>{option.label}</OptionTile>
            {option.subTitle ? (
              <OptionSubtitle>{option.subTitle}</OptionSubtitle>
            ) : null}
          </BottomSheetOption>
        ))}
      </BottomSheet>
    </Container>
  );
};
