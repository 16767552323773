import React from "react";
import { MedicalSpecializationFragment, States } from "~/types/graphql";
import { styled } from "~/styles/stitches.config";
import { HeaderSelect } from "~/components";
import { useRouter } from "next/router";
import { LOCATION_QUERY_VAR, SPECIALIZATION_ID_QUERY_VAR } from "~/pages/lista";
import { StatesList } from "~/utils/states";
import { SearchIcon } from "@sofia-eng/web-icons";

export interface ListAppHeaderProps {
  initialSpeciality?: string;
  initialState?: States;
  medicalSpecializations: MedicalSpecializationFragment[];
  isMobile?: boolean;
}

const Container = styled("div", {
  backgroundColor: "$linen",
  height: 78,
  p: ["$2"],
  flexShrink: 0,
  zIndex: 3,
  top: 0,
  position: "sticky",
  boxShadow: "0px 3px 8px 0px #E9E9E9",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: 12,
  "@bp1": {
    height: 78,
    width: "100%",
  },
});

const Content = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 12,
  justifyContent: "stretch",
  flex: 1,
  "@bp1": {
    maxWidth: 1080,
    width: "100%",
    justifyContent: "space-between",
  },
});

const Logo = styled("div", {
  display: "none",
  "@bp1": {
    display: "block",
    width: 104,
    height: 34,
    flexShrink: 0,
  },
});

const Controls = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 24,
  flex: 1,
  "@bp1": {
    flex: 0,
    gap: 12,
  },
});

const SearchButton = styled("button", {
  unset: "all",
  border: "none",
  outline: "none",
  width: 40,
  height: 40,
  backgroundColor: "$primary500",
  borderRadius: 100,
  justifyContent: "center",
  alignItems: "center",
  display: "none",
  "@bp1": {},
});

const ListAppHeader = ({
  initialSpeciality,
  initialState,
  medicalSpecializations,
  isMobile = false,
}: ListAppHeaderProps) => {
  const router = useRouter();

  const setSpecialty = (value?: string) => {
    router.push(
      {
        query: {
          ...router.query,
          [SPECIALIZATION_ID_QUERY_VAR]: value,
        },
      },
      undefined,
      { shallow: true },
    );
  };

  const setState = (value?: string) => {
    console.log("changed state: " + value);
    router.push(
      {
        query: {
          ...router.query,
          [LOCATION_QUERY_VAR]: value,
        },
      },
      undefined,
      { shallow: true },
    );
  };

  return (
    <Container>
      <Content>
        <Logo>
          <svg
            fill="none"
            height="36"
            viewBox="0 0 104 36"
            width="104"
            xmlns="http://www.w3.org/2000/svg"
          >
            <clipPath id="a">
              <path d="m0 .94043h104v34.1191h-104z" />
            </clipPath>
            <g clipPath="url(#a)" fill="#4a25aa">
              <path d="m0 26.9199h5.86856c.33373 2.5762 2.24253 3.58 4.67574 3.58 1.8603 0 3.483-.7151 3.483-2.4332 0-1.8126-1.6227-2.5763-4.19899-3.2446l-2.24253-.5721c-3.19606-.7629-6.822087-2.051-6.822087-6.7268 0-4.7676 4.198137-7.2997 8.921597-7.2997 6.44071 0 9.83611 3.4899 10.15941 8.5887h-5.8685c-.2003-2.9472-2.0007-4.0559-4.24324-4.0559-1.86025-.0018-3.14492.9049-3.14492 2.4315 0 1.6695 1.62187 2.0995 3.91206 2.7193l2.2426.5729c4.1504 1.0489 7.1081 2.9577 7.1081 7.4428 0 4.9142-4.199 7.1081-9.2562 7.1081-6.73019 0-10.260864-3.1969-10.5946-8.111z" />
              <path d="m76.6303 11.4596h-6.0827v22.8527h6.0827z" />
              <path d="m104 30.0223v4.3291h-2.566c-2.2329 0-3.9873-.4335-4.7987-2.6179-1.6158 2.0431-4.0863 3.3261-7.8407 3.3261-4.8465 0-8.4084-2.2807-8.4084-6.9851 0-4.5613 3.4674-6.7952 7.8407-7.2225l7.4124-.7603v-.9986c0-2.6135-1.6626-3.8956-3.9875-3.8956-2.5659 0-4.1158 1.4589-4.4964 3.5974h-6.0878c.475-4.941 4.4963-8.4448 10.5755-8.4448 5.56 0 10.0739 2.8034 10.0739 9.1712v8.6954c0 1.2352.57 1.8056 1.711 1.8056zm-8.3625-5.7498-5.3224.5704c-2.1853.2375-3.8488 1.1399-3.8488 2.9932 0 1.901 1.4736 2.5659 3.3738 2.5659 2.5658 0 5.7974-1.2353 5.7974-4.9896z" />
              <path d="m35.4236 35.0084c6.8658 0 12.4315-5.5657 12.4315-12.4314s-5.5657-12.4315-12.4315-12.4315c-6.8657 0-12.4314 5.5658-12.4314 12.4315s5.5657 12.4314 12.4314 12.4314z" />
              <path d="m67.635 2.13755c-1.1477-.41696-3.2923-1.19712-5.8001-1.19712-4.6125 0-7.8597 2.44711-7.8597 7.34133v3.19084h-4.941v4.7529h4.941v18.1267h6.0237v-18.1267h6.1139v-4.7529h-6.1139v-2.86057c0-1.97641 1.1772-2.87013 2.9178-2.87013 1.439 0 2.9473.55565 3.6902.78016z" />
              <path d="m71.3096 8.42564h4.2241c.1562.00025.3099-.03944.4464-.1153.1366-.07586.2515-.18536.3338-.31812l4.1296-6.69986h-5.416c-.1796-.00008-.3552.05275-.5048.15188-.1497.09913-.2669.24017-.3369.4055z" />
            </g>
          </svg>
        </Logo>
        <Controls>
          <HeaderSelect
            value={initialSpeciality}
            onChange={setSpecialty}
            label={"Especialidad"}
            options={medicalSpecializations.map(specialization => {
              return {
                value: specialization.id,
                label: specialization.name,
                subTitle: specialization.description,
              };
            })}
            width={484}
            isMobile={isMobile}
          />
          <HeaderSelect
            value={initialState}
            onChange={setState}
            options={StatesList()}
            label={"Localización"}
            isMobile={isMobile}
            width={256}
          />
          <SearchButton>
            <SearchIcon size={"sm"} color={"white"} />
          </SearchButton>
        </Controls>
      </Content>
    </Container>
  );
};

export default ListAppHeader;
