import React from "react";
import { Grid, HomeFeatureCard, PaddedScreenContainer } from "~/components";
import { DoctorIcon, HospitalIcon, MedicinesIcon } from "@sofia-eng/web-icons";
import { CSS } from "~/styles/stitches.config";

const gridCss: CSS = {
  rowGap: 16,
  mb: "$10",
  "@bp1": {
    rowGap: 20,
    columnGap: 20,
    marginBottom: 100,
  },
};

export interface HomeFeaturesProps {
  doctorsAmount: number;
}

export const HomeFeatures = ({ doctorsAmount }: HomeFeaturesProps) => {
  return (
    <PaddedScreenContainer>
      <Grid css={gridCss}>
        <HomeFeatureCard
          tagColor={"#A1D0CF"}
          tagTitle={"Doctor@s"}
          tagIcon={<DoctorIcon size={"md"} />}
          text={
            <span>
              Contamos con más de{" "}
              <strong>{doctorsAmount} doctor@s especialistas</strong> y seguimos
              creciendo día a día.{" "}
            </span>
          }
          target={"_self"}
          href={"/lista"}
        />
        <HomeFeatureCard
          tagColor={"#F8C27F"}
          tagTitle={"Hospitales"}
          tagIcon={<HospitalIcon size={"md"} />}
          text={
            "En nuestra red podrás encontrar hospitales y clínicas con la mejor calidad de atención."
          }
          href={"https://airtable.com/appeLmm6zzgfkZAo3/shr9SNeBlMyXHrISB"}
        />
        <HomeFeatureCard
          tagColor={"#B3B3FF"}
          tagTitle={"Farmacias y laboratorios"}
          tagIcon={<MedicinesIcon size={"md"} />}
          text={
            "Podrás realizarte estudios y comprar medicinas en todas estas farmacias y laboratorios."
          }
          href={"https://airtable.com/appeLmm6zzgfkZAo3/shrJAiSCCQ5MGKxqW"}
        />
        <HomeFeatureCard
          tagColor={"#E3D3FA"}
          tagTitle={"HospitalesExtend"}
          tagIcon={<HospitalIcon size={"md"} />}
          text={
            "Si tu plan de salud incluye HospitalesExtend, también puedes atenderte en esos hospitales."
          }
          href={"https://airtable.com/appeLmm6zzgfkZAo3/shryQjvabAf5n8dXL"}
        />
      </Grid>
    </PaddedScreenContainer>
  );
};
